import { createBrowserHistory } from 'history';
import { Modal } from 'antd';

import { getTranslatedText } from '../utils/intl';

export interface IBlockPageMessage {
  title?: string;
  description?: string;
  cancelText?: string;
  okText?: string;
}

const history = createBrowserHistory({
  getUserConfirmation(messageComponents, callback) {
    const { title, description, cancelText, okText }: IBlockPageMessage =
      JSON.parse(messageComponents);

    const { confirm } = Modal;

    confirm({
      title:
        title || getTranslatedText('services.history.userConfirmation.title'),
      content:
        description ||
        getTranslatedText('services.history.userConfirmation.description'),
      cancelText:
        cancelText ||
        getTranslatedText('services.history.userConfirmation.cancelText'),
      okText:
        okText || getTranslatedText('services.history.userConfirmation.okText'),
      okButtonProps: {
        danger: true,
      },
      onOk() {
        callback(true);
      },
      onCancel() {
        callback(false);
      },
    });

    // Show some custom dialog to the user and call
    // callback(true) to continue the transiton, or
    // callback(false) to abort it.
  },
});

export default history;
