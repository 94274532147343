export interface ICardGameAd {
  _id: string;
  type: AdsTypeEnum;
  title: string;
  text: string;
  url: string;
  isTemporary: boolean;
  _upload?: {
    _id: string;
  };
}

export enum AdsTypeEnum {
  IMAGE = 'image',
  VIDEO = 'video',
}
