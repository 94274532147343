import React, { useCallback, useRef } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { FiArrowLeft } from 'react-icons/fi';
import { Formik, Field, FieldProps, FormikProps } from 'formik';
import * as Yup from 'yup';
import * as _ from 'lodash';

import logoImg from '../../assets/logo-vertical.svg';

import Input from '../../components/Input';
import CustomAntButton from '../../components/CustomAntButton';

import api from '../../services/api';

import { showToast } from '../../hooks/showToast';
import { useIntl } from '../../context/IntlContext';

import { Container, Content, AnimationContainer, Background } from './styles';
import { IForgotPasswordFormData } from './types';

const ForgotPassword: React.FC = () => {
  const { getTranslatedText } = useIntl();
  const history = useHistory();
  const formikRef = useRef<FormikProps<IForgotPasswordFormData>>(null);

  const forgotPasswordFormData: IForgotPasswordFormData = {
    email: '',
  };

  function checkIfIsSaveButtonEnabled({
    touched,
    errors,
  }: FormikProps<IForgotPasswordFormData>): boolean {
    const isNotFormTouched = !touched;
    const hasErrors = Object.entries(errors).length;

    if (isNotFormTouched || hasErrors) {
      return false;
    }

    return true;
  }

  const handleSubmit = useCallback(
    async (data: IForgotPasswordFormData) => {
      const body = {
        email: data.email,
      };

      try {
        const response = await api.post('/api/recovery-password', body);

        if (response.data) {
          showToast({
            type: 'success',
            title: getTranslatedText(
              'pages.forgotPassword.messages.forgotPasswordSubmitSuccess.title',
            ),
            description: getTranslatedText(
              'pages.forgotPassword.messages.forgotPasswordSubmitSuccess.description',
            ),
          });

          history.push('/');
        } else {
          showToast({
            type: 'error',
            title: getTranslatedText(
              'pages.forgotPassword.messages.forgotPasswordSubmitError.title',
            ),
            description: getTranslatedText(
              'pages.forgotPassword.messages.forgotPasswordSubmitError.description',
            ),
          });
        }
      } catch (err) {
        showToast({
          type: 'error',
          title: getTranslatedText(
            'pages.forgotPassword.messages.forgotPasswordSubmitError.title',
          ),
          description: getTranslatedText(
            'pages.forgotPassword.messages.forgotPasswordSubmitError.description',
          ),
        });
      }
    },
    [getTranslatedText, history],
  );

  return (
    <Container>
      <Background />
      <Content>
        <AnimationContainer>
          <img src={logoImg} alt="Titan369" />

          <Link to="/">
            <FiArrowLeft />
            {getTranslatedText('pages.forgotPassword.links.signInLink')}
          </Link>

          <Formik
            innerRef={formikRef}
            initialValues={forgotPasswordFormData}
            validationSchema={Yup.object().shape({
              email: Yup.string()
                .required(
                  getTranslatedText(
                    'pages.forgotPassword.form.email.validation.required',
                  ),
                )
                .email(
                  getTranslatedText(
                    'pages.forgotPassword.form.email.validation.invalid',
                  ),
                ),
            })}
            onSubmit={handleSubmit}
          >
            {formikProps => (
              <form
                onSubmit={e => {
                  e.preventDefault();
                  if (Object.entries(formikProps.errors).length > 0) {
                    showToast({
                      type: 'warn',
                      title: getTranslatedText(
                        'pages.forgotPassword.messages.forgotPasswordValidationFailure.title',
                      ),
                      description: getTranslatedText(
                        'pages.forgotPassword.messages.forgotPasswordValidationFailure.description',
                      ),
                    });
                  }
                  formikProps.handleSubmit(e);
                }}
              >
                <h5>{getTranslatedText('pages.forgotPassword.title')}</h5>
                <Field name="email">
                  {({ field, meta }: FieldProps) => {
                    return (
                      <Input
                        {...field}
                        label={getTranslatedText(
                          'pages.forgotPassword.form.email.label',
                        )}
                        placeholder={getTranslatedText(
                          'pages.forgotPassword.form.email.placeholder',
                        )}
                        id="ForgotPasswordEmail"
                        type="email"
                        error={meta?.touched && meta?.error && meta?.error}
                      />
                    );
                  }}
                </Field>
                <CustomAntButton
                  disabled={!checkIfIsSaveButtonEnabled(formikProps)}
                  loading={formikProps.isSubmitting}
                  type="primary"
                  htmlType="submit"
                >
                  {getTranslatedText('pages.forgotPassword.form.submitButton')}
                </CustomAntButton>
              </form>
            )}
          </Formik>
        </AnimationContainer>
      </Content>
    </Container>
  );
};

export default ForgotPassword;
