import { TContextTexts } from '../../en/context';

import AuthContext from './AuthContext';
import ChatContext from './ChatContext';
import ChatP2PContext from './ChatP2PContext';

const contextTexts: TContextTexts = {
  ...AuthContext,
  ...ChatContext,
  ...ChatP2PContext,
};

export default contextTexts;
