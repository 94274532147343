import React, { createContext, useCallback, useContext, useState } from 'react';

import ShareModal from '@/components/ShareModal';

interface ShareOptions {
  url: string;
}

interface ShareContextData {
  openShareAssistant(options: ShareOptions): void;
}

const ShareContext = createContext<ShareContextData>({} as ShareContextData);

const ShareProvider: React.FC = ({ children }) => {
  const [modalShareOpened, setModalShareOpened] = useState(false);
  const [shareOptions, setShareOptions] = useState<ShareOptions | null>(null);

  const handleCloseShareAssistant = useCallback(() => {
    setShareOptions(null);
    setModalShareOpened(false);
  }, []);

  const openShareAssistant = useCallback(
    (options: ShareOptions) => {
      setShareOptions(options);
      if (navigator.share) {
        navigator
          .share({
            title: 'Compartilhar',
            url: options.url,
          })
          .then(() => {
            handleCloseShareAssistant();
          })
          .catch(() => {
            handleCloseShareAssistant();
          });
        // .catch(console.error);
      } else {
        setModalShareOpened(true);
      }
    },
    [handleCloseShareAssistant],
  );

  return (
    <ShareContext.Provider
      value={{
        openShareAssistant,
      }}
    >
      {children}
      {shareOptions && (
        <ShareModal
          opened={modalShareOpened}
          onClose={handleCloseShareAssistant}
          url={shareOptions?.url}
        />
      )}
    </ShareContext.Provider>
  );
};

function useShare(): ShareContextData {
  const context = useContext(ShareContext);

  if (!context) {
    throw new Error(
      'useLoadingMasked must be used within an LoadingMaskedProvider',
    );
  }

  return context;
}

export { useShare, ShareProvider };
