import ForgotPassword from '@/pages/ForgotPassword';
import { IntlContextData } from '../context/IntlContext';

import SignIn from '../pages/SignIn';
import SignUp from '../pages/SignUp';

import Home from '../pages/Home';

import AthleteCardGame from '../pages/AthleteCard/CardGame';
import PlayAthleteCardGame from '../pages/AthleteCard/Play';
import AthleteCardGamesHistory from '../pages/AthleteCard/CardGamesHistory';
import GameDetails from '../pages/AthleteCard/GameDetails';

import Profile from '../pages/Profile';

import GlobalRanking from '../pages/Rankings/GlobalRanking';
import AthletesOfTheWeekRanking from '../pages/Rankings/AthletesOfTheWeekRanking';

import Chats from '../pages/Chats';

export function formatParam(paramName: string): string {
  return `:${paramName}([0-9a-fA-F]{24})`;
}

interface IRouteListItem {
  path: string;
  name: string;
  exact?: boolean;
  component: React.FC;
  isPrivate: boolean;
}

export function getRoutesList(intl: IntlContextData): IRouteListItem[] {
  return [
    {
      path: '/',
      name: intl.getTranslatedText('routes.routesList.signIn.name'),
      exact: true,
      component: SignIn,
      isPrivate: false,
    },
    {
      path: '/signup',
      name: intl.getTranslatedText('routes.routesList.signUp.name'),
      exact: false,
      component: SignUp,
      isPrivate: false,
    },
    {
      path: '/forgot',
      name: intl.getTranslatedText('routes.routesList.forgotPassword.name'),
      exact: true,
      component: ForgotPassword,
      isPrivate: false,
    },
    {
      path: '/home',
      name: intl.getTranslatedText('routes.routesList.home.name'),
      exact: false,
      component: Home,
      isPrivate: true,
    },
    {
      path: `/cards/${formatParam('card_game_id')}`,
      name: intl.getTranslatedText('routes.routesList.athleteCardGame.name'),
      exact: true,
      component: AthleteCardGame,
      isPrivate: true,
    },
    {
      path: `/cards/${formatParam('card_game_id')}/play`,
      name: intl.getTranslatedText(
        'routes.routesList.playAthleteCardGame.name',
      ),
      exact: false,
      component: PlayAthleteCardGame,
      isPrivate: true,
    },
    {
      path: `/cards/${formatParam('card_game_id')}/my_games/${formatParam(
        'played_card_game_id',
      )}`,
      name: intl.getTranslatedText(
        'routes.routesList.athleteCardGameDetails.name',
      ),
      exact: false,
      component: GameDetails,
      isPrivate: true,
    },
    {
      path: `/cards/${formatParam('card_game_id')}/users_games/${formatParam(
        'played_card_game_id',
      )}`,
      name: intl.getTranslatedText(
        'routes.routesList.athleteUserCardGameDetails.name',
      ),
      exact: false,
      component: GameDetails,
      isPrivate: true,
    },
    {
      path: `/cards/card_games_history`,
      name: intl.getTranslatedText(
        'routes.routesList.athleteCardGamesHistory.name',
      ),
      exact: true,
      component: AthleteCardGamesHistory,
      isPrivate: true,
    },
    {
      path: `/cards/card_games_history/${formatParam('played_card_game_id')}`,
      name: intl.getTranslatedText(
        'routes.routesList.athleteCardGameDetails.name',
      ),
      exact: false,
      component: GameDetails,
      isPrivate: true,
    },
    /* Profile */
    {
      path: `/profile`,
      name: intl.getTranslatedText('routes.routesList.profile.name'),
      component: Profile,
      isPrivate: true,
    },
    /* Rankings */
    {
      path: `/rankings/global`,
      name: intl.getTranslatedText(
        'routes.routesList.athletesOfTheWeekRanking.name',
      ),
      component: AthletesOfTheWeekRanking,
      isPrivate: true,
    },
    /* Chat */
    {
      path: `/chats`,
      name: intl.getTranslatedText('routes.routesList.chats.name'),
      component: Chats,
      isPrivate: true,
    },
  ];
}
