import styled, { css } from 'styled-components';
import { shade } from 'polished';

import CustomAntButton from '../CustomAntButton';

interface IContainerProps {
  scrolled: boolean;
}

interface ILogoImageProps {
  $type: 'desktop' | 'mobile';
}

interface INotificationButtonProps {
  $unreadNotifications?: boolean;
  // $notificationsMenuOpened?: boolean;
}

interface IChatButtonProps {
  $unreadChats?: number;
}

export const Container = styled.header<IContainerProps>`
  background-color: ${props => props.theme.colors.dark.dark0};
  width: 100%;
  height: 72px;
  display: flex;
  align-items: center;
  padding: 0 56px;
  /* border-bottom: 1px solid ${props => props.theme.colors.dark['dark+1']}; */
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  transition: all 0.15s linear;

  ${props =>
    props.scrolled &&
    css`
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
    `}

  /* > button {
    display: none;
  } */
  div.div_fake {
    display: none;
    width: 32px;
  }

  nav {
    display: flex;
    align-items: center;

    a {
      font-size: ${props => props.theme.typograph.sizes.h6}px;
      /* font-weight: ${props => props.theme.typograph.weights.bold}; */
      color: ${props => props.theme.colors.light.light0};

      &:hover {
        color: ${props => shade(0.2, props.theme.colors.light.light0)};
      }

      & + a {
        margin-left: 32px;
      }
    }
  }

  > div {
    margin-left: auto;
    display: flex;
    align-items: center;

    a:last-child {
      border: none;
      border-radius: 50%;
      margin-left: 16px;

      img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        object-fit: cover;
      }
    }
  }

  @media only screen and (max-width: 1199px) {
    height: 61px;
    padding: 0 40px;
    justify-content: space-between;

    /* > button {
      display: block;
    } */
    div.div_fake {
      display: block;
    }

    nav {
      display: none;
    }

    > div {
      margin-left: 0;
      /* button {
        display: none;
      } */

      button.tmp_button {
        display: block;
        margin-left: 8px;
      }
    }
  }

  @media only screen and (max-width: 600px) {
    padding: 0 16px;
  }
`;

export const LogoImage = styled.img<ILogoImageProps>`
  width: 124px;
  margin-right: 72px;
  display: block;

  ${props =>
    props.$type === 'mobile' &&
    css`
      display: none;
    `}

  @media only screen and (max-width: 1199px) {
    width: 51px;
    margin-right: 0;
    display: block;

    ${props =>
      props.$type === 'desktop' &&
      css`
        display: none;
      `}
  }
`;

export const NotificationButton = styled(
  CustomAntButton,
)<INotificationButtonProps>`
  margin-left: 8px;
  ${props =>
    props.$unreadNotifications &&
    css`
      &::after {
        content: '';
        width: 7px;
        position: absolute;
        height: 7px;
        background-color: ${!props.textPrimary
          ? props.theme.colors.primary.primary0
          : props.theme.colors.light.light0};
        border-radius: 50%;
        top: 3px;
        right: 5px;
      }
    `}
`;

// export const ChatButton = styled(CustomAntButton)<IChatButtonProps>`
//   margin-left: 8px;
//   ${props =>
//     props.$unreadChats &&
//     css`
//       &::after {
//         content: '';
//         width: 7px;
//         position: absolute;
//         height: 7px;
//         background-color: ${props.theme.colors.secondary.secondary0};
//         border-radius: 50%;
//         top: 3px;
//         right: 5px;
//       }
//     `}
// `;

export const ChatButton = styled(CustomAntButton)<IChatButtonProps>`
  margin-left: 8px;
  ${props => {
    if (props.$unreadChats) {
      return css`
        &::after {
          content: '${props.$unreadChats}';
          font-size: 12px;
          color: ${props.theme.colors.dark.dark0};
          width: 14px;
          position: absolute;
          height: 14px;
          background-color: ${props.theme.colors.secondary.secondary0};
          border-radius: 50%;
          top: 0px;
          right: 1px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      `;
    }

    return '';
  }}
`;
