import React, { useEffect, useRef } from 'react';
import lottie from 'lottie-web';

import unavailableWithdrawAnimation from '@/assets/unavailable-withdraw-animation.json';

import { AnimationContainer, Container } from './styles';

const WithdrawUnavailable: React.FC = () => {
  const animationContainer = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (animationContainer.current) {
      lottie.loadAnimation({
        container: animationContainer.current,
        autoplay: true,
        loop: true,
        animationData: unavailableWithdrawAnimation,
      });
    }
  }, []);

  return (
    <Container>
      <AnimationContainer ref={animationContainer} />
      <h6>Saque indisponível</h6>
      <p>
        Você atingiu o limite de 1 saque diário.
        <br />
        Tente novamente amanhã!
      </p>
    </Container>
  );
};

export default WithdrawUnavailable;
