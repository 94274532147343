const AuthContextTexts = {
  /* Messages */
  'contexts.authContext.messages.updateAvatarSubmitConfirm.title':
    'Deseja realmente atualizar o seu avatar?',
  'contexts.authContext.messages.updateAvatarSubmitConfirm.description':
    'Essa ação não poderá ser desfeita',
  'contexts.authContext.messages.updateAvatarSubmitConfirm.confirmButton':
    'Atualizar avatar',
  'contexts.authContext.messages.updateAvatarSubmitSuccess.title':
    'Seu avatar foi atualizado com sucesso!',
};

export default {
  ...AuthContextTexts,
};
