import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Formik, FormikHelpers, FormikProps } from 'formik';
import * as Yup from 'yup';
import { Spin, Tooltip } from 'antd';

import { EBulletPagePath, useBullet } from '@/context/BulletContext';
import { useIntl } from '@/context/IntlContext';
import { showToast } from '@/hooks/showToast';
import api from '@/services/api';

import SearchUser from './SearchUser';
import SetTransferValue from './SetTransferValue';
import ConfirmPassword from './ConfirmPassword';
import { ITransferFormData, ITransferQuote } from './transfer.types';

import { LoadingContainer } from '../styles';
import {
  Container,
  TransferAvailabilityContainer,
  TransferAvailabilityBarContainer,
  TransferAvailabilityBarProgress,
  TransferAvailabilityTitle,
  TransferQuoteValue,
  Content,
} from './styles';

const Transfer: React.FC = () => {
  const intl = useIntl();
  const bullet = useBullet();

  const formikRef = useRef<FormikProps<ITransferFormData>>(null);

  const initialFormValues: ITransferFormData = {
    selectedUser: undefined,
    amount: '',
    password: '',
  };

  const [transferQuote, setTransferQuote] = useState<ITransferQuote>(
    undefined as unknown as ITransferQuote,
  );
  const [loadingTransferQuote, setLoadingTransferQuote] = useState(true);
  const transferQuotePercent = useMemo(() => {
    if (!transferQuote) return 0;

    const totalPercent = 100;
    const transferLimit = transferQuote.quote;
    const todayTotalTransfer = transferQuote.amount;

    return (totalPercent * todayTotalTransfer) / transferLimit;
  }, [transferQuote]);

  const [step, setStep] = useState<'transfer' | 'password'>('transfer');

  const getTransferQuote = useCallback(async () => {
    try {
      const { data } = await api.get<ITransferQuote>('/api/transaction-quotes');

      setTransferQuote(data);
    } catch (error) {
      showToast({
        title: intl.getTranslatedText('common.errors.unexpectedError.title'),
        description: intl.getTranslatedText(
          'common.errors.unexpectedError.description',
        ),
        type: 'error',
      });
    }
    setLoadingTransferQuote(false);
  }, [intl]);

  useEffect(() => {
    getTransferQuote();
  }, [getTransferQuote]);

  const handleSubmit = useCallback(
    async (
      values: ITransferFormData,
      formikHelpers: FormikHelpers<ITransferFormData>,
    ) => {
      const { amount, password, selectedUser } = values;

      const body = {
        receiver: selectedUser?._id,
        amount: +amount,
        password,
      };

      try {
        await api.post('/api/transactions/p2p', body);
        formikHelpers.setSubmitting(false);
        bullet.navigateTo(EBulletPagePath.MAIN);
        showToast({
          title: 'Transferência realizada com sucesso!',
          type: 'success',
        });
      } catch (error) {
        formikHelpers.setSubmitting(false);
        if (error.response.status === 403) {
          const formik = formikRef.current;
          formik?.setFieldError('password', 'Senha incorreta');
        } else {
          showToast({
            title: intl.getTranslatedText(
              'common.errors.unexpectedError.title',
            ),
            description: intl.getTranslatedText(
              'common.errors.unexpectedError.description',
            ),
            type: 'error',
          });
        }
      }
    },
    [bullet, intl],
  );

  if (loadingTransferQuote) {
    return (
      <Container>
        <LoadingContainer>
          <Spin />
          <p>Carregando...</p>
        </LoadingContainer>
      </Container>
    );
  }

  return (
    <Container>
      <Content>
        <Formik
          innerRef={formikRef}
          initialValues={initialFormValues}
          validationSchema={Yup.object().shape({
            amount: Yup.number()
              .test(
                'higherThanBalanceOrTransferQuoteValidation',
                'A quantidade inserida é inválida',
                value => {
                  if (
                    value &&
                    (value > bullet.balance.amount ||
                      value > transferQuote.amountLimit)
                  ) {
                    return false;
                  }
                  return true;
                },
              )
              .integer('A quantidade de moedas informadas não é válida')
              .required('A quantidade de moedas é obrigatória'),
            password: Yup.string().required('A senha é obrigatória'),
          })}
          validateOnMount
          onSubmit={handleSubmit}
        >
          {formikProps => (
            <form
              onSubmit={e => {
                e.preventDefault();
                formikProps.handleSubmit(e);
              }}
            >
              {step === 'transfer' && (
                <TransferAvailabilityContainer>
                  <TransferAvailabilityTitle>
                    Total de transferências
                    <Tooltip title="Esse é o seu limite diário de transferência">
                      <span>?</span>
                    </Tooltip>
                  </TransferAvailabilityTitle>
                  <TransferAvailabilityBarContainer>
                    <TransferAvailabilityBarProgress
                      $progress={transferQuotePercent}
                    />
                  </TransferAvailabilityBarContainer>
                  <TransferQuoteValue>
                    <span>R${transferQuote.amount}</span> / R$
                    {transferQuote.quote}
                  </TransferQuoteValue>
                </TransferAvailabilityContainer>
              )}
              {!transferQuote.amountLimit && (
                <h6>
                  Ops... Parece que você atingiu o <span>limite</span> de
                  transferência diária. Tente novamente <span>amanhã</span>.
                </h6>
              )}
              {!!transferQuote.amountLimit &&
                step === 'transfer' &&
                !formikProps.values?.selectedUser && (
                  <SearchUser
                    handleSelectUser={selectedUser => {
                      formikProps.setFieldValue('selectedUser', selectedUser);
                      formikProps.setFieldValue('amount', '');
                    }}
                  />
                )}
              {!!transferQuote.amountLimit &&
                step === 'transfer' &&
                formikProps.values?.selectedUser && (
                  <SetTransferValue
                    formikProps={formikProps}
                    handleEditUser={() =>
                      formikProps.setFieldValue('selectedUser', undefined)
                    }
                    handleNext={() => setStep('password')}
                  />
                )}
              {!!transferQuote.amountLimit && step === 'password' && (
                <ConfirmPassword
                  formikProps={formikProps}
                  handleGoBack={() => setStep('transfer')}
                />
              )}
            </form>
          )}
        </Formik>
      </Content>
    </Container>
  );
};

export default Transfer;
