const ChatsTexts = {
  'pages.chats.title': 'Chats',
  'pages.chats.unreadChats': '{unreadChatsCount} not read',
};

const ChatsListTexts = {
  'pages.chats.chatsList.audioMessage': 'Audio message',

  'pages.chats.chatsList.viewMoreChatsButton': 'See more chat',

  'pages.chats.chatsList.messages.getChatsError.title':
    "Wasn't possible to loading chat",
  'pages.chats.chatsList.messages.loadingChats': 'Loading chat...',
  'pages.chats.chatsList.messages.noChats':
    "You haven't <span>chatted</span> with other users yet",
};

const ChatTexts = {
  'pages.chats.chat.loadingChat': 'Loading chat information...',

  'pages.chats.chat.messageTextarea.placeholder': 'Type a message',
  'pages.chats.chat.loadingMessages': 'Loading message...',
  /* Buttons */
  'pages.chats.chat.loadMoreMessagesButton': 'Loading more message',
  /* Messages */
  'pages.chats.chat.messages.startVoiceMessageRecordError.title':
    'Mic access permission are block',
  'pages.chats.chat.messages.startVoiceMessageRecordError.description':
    'Accept mic access permission and then refresh the page to send audio message.',
};

const ChatMessageTexts = {
  /* ReportMessageModal */
  'pages.chats.chat.chatMessage.reportMessageModal.title': 'Report message',
  'pages.chats.chat.chatMessage.reportMessageModal.sendReportButton':
    'Send report',
  'pages.chats.chat.chatMessage.reportMessageModal.reportReasonRadio.label':
    'Report content',
  'pages.chats.chat.chatMessage.reportMessageModal.reportReasonRadio.item1':
    'Sexual content',
  'pages.chats.chat.chatMessage.reportMessageModal.reportReasonRadio.item2':
    'Hateful or abusive content',
  'pages.chats.chat.chatMessage.reportMessageModal.reportReasonRadio.item3':
    'Harmful or dangerous acts',
  'pages.chats.chat.chatMessage.reportMessageModal.reportReasonRadio.item4':
    'Child abuse',
  'pages.chats.chat.chatMessage.reportMessageModal.reportReasonRadio.item5':
    'Promotes terrorism',
  'pages.chats.chat.chatMessage.reportMessageModal.reportReasonRadio.item6':
    'Spam or misleading',
  'pages.chats.chat.chatMessage.reportMessageModal.reportReasonRadio.item7':
    'Infringes my rights',

  /* Buttons */
  'pages.chats.chat.chatMessage.copyMessageButton': 'Copy',
  'pages.chats.chat.chatMessage.reportMessageButton': 'Report',
  'pages.chats.chat.chatMessage.deleteMessageButton': 'Remove',
  /* Messages */
  'pages.chats.chat.chatMessage.messages.copyMessageToClipboardSuccess.title':
    'Copied!',
};

export default {
  ...ChatsTexts,
  ...ChatsListTexts,
  ...ChatTexts,
  ...ChatMessageTexts,
};
