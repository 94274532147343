const HistoryTexts = {
  /* Gender */
  'services.history.userConfirmation.title': 'Warning!',
  'services.history.userConfirmation.description':
    'Do you really want to perform this operation?',
  'services.history.userConfirmation.cancelText': 'No',
  'services.history.userConfirmation.okText': 'Yes',
};

export default {
  ...HistoryTexts,
};
