const ChatContextTexts = {
  /* ---------- Messages ---------- */
  /* SubmitNewMessage */
  'contexts.chatContext.messages.submitNewMessageSuccess.title':
    'Mensagem enviada!',
  'contexts.chatContext.messages.submitNewMessageError.title':
    'Erro ao enviar mensagem',
  /* SubmitMessageReport */
  'contexts.chatContext.messages.messageReportSubmitConfirm.title':
    'Deseja realmente enviar essa denúncia?',
  'contexts.chatContext.messages.messageReportSubmitConfirm.confirmButton':
    'Enviar denúncia',
  'contexts.chatContext.messages.messageReportSubmitSuccess.title':
    'Denúncia enviada!',
  'contexts.chatContext.messages.messageReportSubmitSuccess.description':
    'Sua denúncia foi enviada e será analisada pelo suporte da plataforma Titan369',
  /* SubmitDeleteMessage */
  'contexts.chatContext.messages.deleteMessageSubmitConfirm.title':
    'Deseja realmente excluir essa mensagem?',
  'contexts.chatContext.messages.deleteMessageSubmitConfirm.confirmButton':
    'Excluir mensagem',
  'contexts.chatContext.messages.deleteMessageSubmitSuccess.title':
    'Mensagem excluída!',
  'contexts.chatContext.messages.deleteMessageSubmitError.title':
    'Erro ao excluir mensagem',
};

export default {
  ...ChatContextTexts,
};
