const RankingTexts = {
  'pages.ranking.globalRanking': 'Global',

  'pages.ranking.searchUserInput.placeholder': 'Search user',

  'pages.ranking.coachFriend.general': 'General',
  'pages.ranking.coachFriend.scoreboard': 'Scoreboard',
  'pages.ranking.coachFriend.minuteOfFirstGoal': '1st goal minute',
  'pages.ranking.coachFriend.players': 'Players',
  'pages.ranking.coachFriend.corner': 'Corner',
  'pages.ranking.coachFriend.offside': 'Offside',
  'pages.ranking.coachFriend.cards': 'Cards',

  'pages.ranking.messages.getRankingError.title':
    'Unexpected error to load this ranking',
  'pages.ranking.messages.userNotFound': '<span>User</span> not found',
  'pages.ranking.messages.noRanking': '<span>Ranking</span> not found',
  'pages.ranking.messages.loadingRanking': 'Loading ranking...',
  'pages.ranking.messages.loadingUserGames': "Loading user's games...",
};

export default {
  ...RankingTexts,
};
