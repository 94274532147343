const ProfileTexts = {
  'pages.profile.userInfo.title': 'User info',
  'pages.profile.userInfo.username.label': 'Name',
  'pages.profile.userInfo.email.label': 'Email',

  'pages.profile.personalData.title': 'Personal data',
  'pages.profile.personalData.name.label': 'Name',
  'pages.profile.personalData.gender.label': 'Gender',
  'pages.profile.personalData.docNumber.label': 'Doc number',
  'pages.profile.personalData.phone.label': 'Phone number',

  'pages.profile.changePasswordButton': 'Change password',
  'pages.profile.signOutButton': 'Quit',

  'pages.profile.messages.signOutConfirm.description': 'Do you want to quit?',
};

const ChangePasswordTexts = {
  'pages.profile.changePassword.title': 'Change password',

  'pages.profile.changePassword.submitChangePasswordConfirmButton':
    'Update password',

  /* Form */
  'pages.profile.changePassword.form.password.label': 'Your password',
  'pages.profile.changePassword.form.password.placeholder':
    'Type your password',
  'pages.profile.changePassword.form.password.validation.required':
    'Your password is required',

  'pages.profile.changePassword.form.newPassword.label': 'New password',
  'pages.profile.changePassword.form.newPassword.placeholder':
    'Type a new password',
  'pages.profile.changePassword.form.newPassword.validation.min':
    'Your password needs 6 digits or more.',
  'pages.profile.changePassword.form.newPassword.validation.required':
    'Your new password is required',

  'pages.profile.changePassword.form.confirmNewPassword.label':
    'Repeat your new password',
  'pages.profile.changePassword.form.confirmNewPassword.placeholder':
    'Repeat your new password',
  'pages.profile.changePassword.form.confirmNewPassword.validation.invalid':
    'You should repeat your new password',
  'pages.profile.changePassword.form.confirmNewPassword.validation.required':
    'You should repeat you new password correctly',

  'pages.profile.changePassword.messages.submitChangePasswordConfirm.title':
    'Would you like to change your password?',
  'pages.profile.changePassword.messages.submitChangePasswordConfirm.description':
    'Your password will be updated to a new one',
  'pages.profile.changePassword.messages.submitChangePasswordSuccess.title':
    'Update done!',
  'pages.profile.changePassword.messages.submitChangePasswordSuccess.description':
    'Use your new password to sign in when you needs.',
  'pages.profile.changePassword.messages.submitChangePasswordError.title':
    'Wrong password!',
  'pages.profile.changePassword.messages.submitChangePasswordError.description':
    'Try again!',
};

const UpdateAvatarTexts = {
  'pages.profile.updateAvatar.title': 'Edit avatar',

  'pages.profile.updateAvatar.selectImageButton':
    'Select an image to your avatar',
  'pages.profile.updateAvatar.updateAvatarButton': 'Update avatar',

  'pages.profile.updateAvatar.messages.unblockPageConfirm.title':
    'Do you want to quit this update?',
};

export default {
  ...ProfileTexts,
  ...ChangePasswordTexts,
  ...UpdateAvatarTexts,
};
