const AvatarCropperTexts = {
  'components.avatarCropper.selectImageButton': 'Selecione uma imagem',
  'components.avatarCropper.requiredImage': '(obrigatório)',
  'components.avatarCropper.previewContainer.removeImageButton':
    'Remover imagem',
  'components.avatarCropper.previewContainer.editImageButton': 'Editar imagem',
  'components.avatarCropper.cropContainer.removeImageButton': 'Remover',
  'components.avatarCropper.cropContainer.changeImageButton': 'Trocar imagem',
  'components.avatarCropper.cropContainer.finishButton': 'Finalizar',
};

export default {
  ...AvatarCropperTexts,
};
