import React, {
  createContext,
  useContext,
  useCallback,
  useState,
  useEffect,
} from 'react';

import api from '@/services/api';
import socket from '@/services/socket';

import { showToast } from '@/hooks/showToast';
import { useAuth } from './AuthContext';

interface IBulletPageParams {
  [key: string]: string;
}

export enum EBulletPagePath {
  MAIN = 'main',
  DEPOSIT = 'deposit',
  PAYMENT = 'payment',
  WITHDRAW = 'withdraw',
  TRANSFER = 'transfer',
}

interface IBulletPage {
  path: EBulletPagePath;
  name: string;
  params?: IBulletPageParams;
}
export interface IBalanceData {
  amount: number;
  _id: string;
}

interface IBulletContextData {
  bulletOpened: boolean;
  toggleBulletOpened(): void;
  balance: IBalanceData;
  updateBalance(amount: number, flow: 'in' | 'out'): void;
  currentPage: IBulletPage;
  navigateTo(path: string, params?: IBulletPageParams): void;
  checkIfBalanceIsAvailableToAction(price: number): boolean;
}

const bulletPages: IBulletPage[] = [
  {
    path: EBulletPagePath.MAIN,
    name: '',
  },
  {
    path: EBulletPagePath.DEPOSIT,
    name: 'Comprar moedas',
  },
  {
    path: EBulletPagePath.PAYMENT,
    name: 'Pagamento via PIX',
    params: {
      transactionId: '',
    },
  },
  {
    path: EBulletPagePath.WITHDRAW,
    name: 'Sacar dinheiro',
  },
  {
    path: EBulletPagePath.TRANSFER,
    name: 'Transferir moedas',
  },
];

function getBulletPage(
  path: EBulletPagePath,
  params?: IBulletPageParams,
): IBulletPage {
  const current = bulletPages.find(bulletPage => bulletPage.path === path);
  if (current && params) current.params = params;

  return current || ({} as IBulletPage);
}

const BulletContext = createContext<IBulletContextData>(
  {} as IBulletContextData,
);

const BulletProvider: React.FC = ({ children }) => {
  const { user: me } = useAuth();

  const [bulletOpened, setBulletOpened] = useState(false);
  const [currentPage, setCurrentPage] = useState<IBulletPage>(
    getBulletPage(EBulletPagePath.MAIN),
  );

  const [balance, setBalance] = useState<IBalanceData>({} as IBalanceData);

  const updateBalance = useCallback((amount: number, flow: 'in' | 'out') => {
    if (flow === 'in')
      setBalance(oldState => ({
        ...oldState,
        amount: oldState.amount + amount,
      }));
    if (flow === 'out')
      setBalance(oldState => ({
        ...oldState,
        amount: oldState.amount - amount,
      }));
  }, []);

  useEffect(() => {
    async function getBalance(): Promise<void> {
      const { data } = await api.get<IBalanceData>('/api/balance');
      setBalance(data);
    }

    if (me) {
      getBalance();

      socket.on(
        `balance:${me?._id}`,
        async (data: { amount: number; type: 'in' | 'out' }) => {
          updateBalance(data.amount, data.type);
        },
      );
    }

    return () => {
      if (me) {
        socket.off(`balance:${me?._id}`);
      }
    };
  }, [me, me?._id, updateBalance]);

  const toggleBulletOpened = useCallback(() => {
    setBulletOpened(oldState => !oldState);
    setCurrentPage(getBulletPage(EBulletPagePath.MAIN));
  }, []);

  const navigateTo = useCallback(
    (path: EBulletPagePath, params?: IBulletPageParams) => {
      setCurrentPage(getBulletPage(path, params));
    },
    [],
  );

  const checkIfBalanceIsAvailableToAction = useCallback(
    (price: number): boolean => {
      if (balance.amount < price) {
        showToast({
          type: 'error',
          title: 'Você não possui saldo suficiente!',
          description: 'Adicione moedas na plataforma antes de continuar',
        });
        return false;
      }
      return true;
    },
    [balance.amount],
  );

  return (
    <BulletContext.Provider
      value={{
        bulletOpened,
        toggleBulletOpened,
        balance,
        updateBalance,
        currentPage,
        navigateTo,
        checkIfBalanceIsAvailableToAction,
      }}
    >
      {children}
    </BulletContext.Provider>
  );
};

function useBullet(): IBulletContextData {
  const context = useContext(BulletContext);

  if (!context) {
    throw new Error('useBullet must be used within a BulletProvider');
  }

  return context;
}

export { BulletProvider, useBullet };
