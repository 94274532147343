const getBoxPositionOnWindowCenter = (
  width: number,
  height: number,
): { left: number; top: number } => ({
  left:
    window.outerWidth / 2 +
    (window.screenX || window.screenLeft || 0) -
    width / 2,
  top:
    window.outerHeight / 2 +
    (window.screenY || window.screenTop || 0) -
    height / 2,
});

const getBoxPositionOnScreenCenter = (
  width: number,
  height: number,
): { top: number; left: number } => ({
  top: (window.screen.height - height) / 2,
  left: (window.screen.width - width) / 2,
});

export default function handleWindowOpen(
  url: string,
  // {
  //   height,
  //   width,
  //   ...configRest
  // }: { height: number; width: number; [key: string]: any },
  onClose?: (dialog: Window | null) => void,
): Window | null {
  const windowHeight = 400;
  const windowPosition = 'windowCenter';
  const windowWidth = 550;

  const config: { [key: string]: string | number } = {
    height: windowHeight,
    width: windowWidth,
    location: 'no',
    toolbar: 'no',
    status: 'no',
    directories: 'no',
    menubar: 'no',
    scrollbars: 'yes',
    resizable: 'no',
    centerscreen: 'yes',
    chrome: 'yes',
    ...(windowPosition === 'windowCenter'
      ? getBoxPositionOnWindowCenter(windowWidth, windowHeight)
      : getBoxPositionOnScreenCenter(windowWidth, windowHeight)),
  };

  const shareDialog = window.open(
    url,
    '',
    Object.keys(config)
      .map(key => `${key}=${config[key]}`)
      .join(', '),
  );

  if (onClose) {
    const int = window.setInterval(() => {
      try {
        if (shareDialog === null || shareDialog.closed) {
          window.clearInterval(int);
          onClose(shareDialog);
        }
      } catch (e) {
        /* eslint-disable no-console */
        console.error(e);
        /* eslint-enable no-console */
      }
    }, 1000);
  }

  return shareDialog;
}
