import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { FiChevronDown } from 'react-icons/fi';

import { useIntl } from '@/context/IntlContext';
import { IOptionSelectProps } from './types';
import {
  Container,
  SelectButton,
  SelectDropdown,
  SelectDropdownWrapper,
  SelectOption,
} from './styles';

const OptionSelect: React.FC<IOptionSelectProps> = ({
  options,
  selectedOption,
  handleSelectOption,
}) => {
  const intl = useIntl();

  const isFirstRenderRef = useRef<boolean>(true);
  const [isOpened, setIsOpened] = useState(false);

  const handleToggle = useCallback(
    event => {
      event.stopPropagation();
      setIsOpened(oldIsOpened => !oldIsOpened);
      const _isOpened = !isOpened;
      if (_isOpened) {
        const athleteSelects = [
          ...document.querySelectorAll('.athlete-select'),
        ] as HTMLElement[];

        athleteSelects.forEach(select => {
          if (select.classList.contains('athlete-select-opened')) {
            select.click();
          }
        });
      }
    },
    [isOpened],
  );

  const handleSelect = useCallback(
    (event, option: string) => {
      event.stopPropagation();
      setIsOpened(false);
      handleSelectOption(option);
    },
    [handleSelectOption],
  );

  useEffect(() => {
    document.addEventListener('click', () => {
      setIsOpened(false);
    });

    const isFirstRender = isFirstRenderRef.current;
    if (isFirstRender) {
      isFirstRenderRef.current = false;
    }
  }, []);

  const athleteSelectCssClasses = useMemo(() => {
    let cssClasses = 'athlete-select';

    if (isOpened) {
      cssClasses += ' athlete-select-opened';
    }

    return cssClasses;
  }, [isOpened]);

  return (
    <Container>
      <SelectButton
        className={athleteSelectCssClasses}
        onClick={handleToggle}
        $isOpened={isOpened}
        $isFirstRender={isFirstRenderRef.current}
      >
        {selectedOption ? (
          <p>
            {selectedOption.name}
            <span>
              {selectedOption.value}{' '}
              {intl.getTranslatedText('common.scoredPointsDescription')}
            </span>
          </p>
        ) : (
          <p>Selecione</p>
        )}
        <FiChevronDown size={24} />
      </SelectButton>
      <SelectDropdownWrapper
        $isOpened={isOpened}
        $isFirstRender={isFirstRenderRef.current}
      >
        <SelectDropdown>
          {options.map(option => (
            <SelectOption
              key={option._id}
              onClick={event => handleSelect(event, option._id)}
            >
              <p>
                {option.name}
                <span>
                  {option.value}{' '}
                  {intl.getTranslatedText('common.scoredPointsDescription')}
                </span>
              </p>
            </SelectOption>
          ))}
        </SelectDropdown>
      </SelectDropdownWrapper>
    </Container>
  );
};

export default OptionSelect;
