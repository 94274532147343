import { IntlContextData } from '@/context/IntlContext';
import React from 'react';

export interface ISignUpFormData {
  name: string;
  username: string;
  docNumber: string;
  gender: 'M' | 'F' | 'O' | string;
  email: string;
  password: string;
  confirmPassword?: string;
  phone: string;
  photo: {
    file: Blob | null;
    previewUrl: string;
  };
}

export interface IAsyncValidationLoadingState {
  checkingSomeField: boolean;
  username: boolean;
  email: boolean;
  docNumber: boolean;
}

export interface IAvailableFieldsState {
  username: boolean;
  email: boolean;
  docNumber: boolean;
}

export enum UniqueFieldEnum {
  USERNAME = 'username',
  EMAIL = 'email',
  DOC_NUMBER = 'docNumber',
}

export interface IGetSignUpValidationSchemaParams {
  getTranslatedText: IntlContextData['getTranslatedText'];
  setAsyncValidationLoading: React.Dispatch<
    React.SetStateAction<IAsyncValidationLoadingState>
  >;
  focusedField: string;
  availableFields: IAvailableFieldsState;
  setAvailableFields: React.Dispatch<
    React.SetStateAction<IAvailableFieldsState>
  >;
}

export interface ICheckIfFieldIsAvailableParams {
  value: string;
  fieldName: UniqueFieldEnum;
  handleFieldAvailability: (isFieldAvailable: boolean) => void;
}
