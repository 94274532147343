import React, { useState, useCallback, useRef, useEffect } from 'react';
import { Button } from 'antd';
import ReactCrop from 'react-image-crop';

import { useIntl } from '../../context/IntlContext';

import {
  SelectImageButton,
  CropImageContainer,
  ImageContainer,
  ActionButtonsContainer,
  ViewerAvatarContainer,
  ActionButtonsViewerAvatarContainer,
} from './styles';

// Increase pixel density for crop preview quality on retina screens.
// const pixelRatio = window.devicePixelRatio || 1;

function AvatarCropper({
  croppedAvatar,
  setCroppedAvatar,
  setHaveChanges,
  selectImageButtonLabel,
  required,
  aspect = 1 / 1,
}) {
  const intl = useIntl();
  if (!setHaveChanges) setHaveChanges = () => {};

  const [croppedContainerOpened, setCroppedContainerOpened] = useState(false);

  const inputFileRef = useRef(null);
  const imgRef = useRef(null);

  const [upImg, setUpImg] = useState(null);
  const [cropSettings, setCropSettings] = useState({
    unit: '%',
    width: 30,
    aspect,
  });

  const [blobImage, setBlobImage] = useState(null);

  useEffect(() => {
    if (blobImage) return setHaveChanges(true);
    return setHaveChanges(false);
  }, [blobImage, setHaveChanges]);

  useEffect(() => {
    if (croppedContainerOpened)
      setCroppedAvatar({
        file: null,
        previewUrl: '',
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [croppedContainerOpened]);

  const handleSetCroppedImage = useCallback(
    croppedImage => {
      if (!croppedImage) {
        croppedImage = {
          file: null,
          previewUrl: '',
        };
      }

      setCroppedAvatar({
        file: croppedImage.file,
        previewUrl: croppedImage.previewUrl,
      });
    },
    [setCroppedAvatar],
  );

  function handleSelectFile(e) {
    if (e.target.files && e.target.files.length > 0) {
      setCroppedContainerOpened(true);

      const reader = new FileReader();
      reader.addEventListener('load', () => setUpImg(reader.result));
      reader.readAsDataURL(e.target.files[0]);
    }
  }

  function handleRemoveSelectedImage() {
    setUpImg(null);
    setCroppedContainerOpened(false);
    handleSetCroppedImage(null);
    setBlobImage(null);
    setCropSettings({
      unit: '%',
      width: 30,
      aspect,
    });
  }

  const generateFileCropped = useCallback(() => {
    if (!blobImage) {
      return;
    }

    const fileName = `${Date.now()}.png`;
    const file = new File([blobImage], fileName, { type: 'image/png' });
    const previewUrl = window.URL.createObjectURL(blobImage);

    handleSetCroppedImage({ file, previewUrl });
    setCroppedContainerOpened(false);
  }, [blobImage, handleSetCroppedImage]);

  const handleImageLoaded = useCallback(img => {
    imgRef.current = img;
  }, []);

  const handleCompletedCrop = useCallback((crop, perCrop) => {
    if (!crop || !imgRef.current) {
      return;
    }

    setCropSettings(perCrop);

    const image = imgRef.current;
    const canvas = document.createElement('canvas');

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext('2d');

    canvas.width = Math.ceil(crop.width * scaleX);
    canvas.height = Math.ceil(crop.height * scaleY);

    // canvas.width = crop.width * pixelRatio;
    // canvas.height = crop.height * pixelRatio;

    // ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = 'high';

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY,
    );

    // crop.width,
    // crop.height

    canvas.toBlob(
      blob => {
        setBlobImage(blob);
      },
      'image/png',
      1,
    );
  }, []);

  return (
    <>
      <input
        style={{ display: 'none' }}
        type="file"
        ref={inputFileRef}
        accept="image/*"
        onChange={handleSelectFile}
      />
      {croppedContainerOpened && (
        <CropImageContainer>
          <ImageContainer>
            <ReactCrop
              src={upImg}
              onImageLoaded={handleImageLoaded}
              crop={cropSettings}
              onChange={c => setCropSettings(c)}
              onComplete={(crop, percentCrop) =>
                handleCompletedCrop(crop, percentCrop)
              }
              keepSelection
            />
          </ImageContainer>
          <ActionButtonsContainer>
            <Button type="default" onClick={handleRemoveSelectedImage} danger>
              {intl.getTranslatedText(
                'components.avatarCropper.cropContainer.removeImageButton',
              )}
            </Button>
            <Button type="default" onClick={() => inputFileRef.current.click()}>
              {intl.getTranslatedText(
                'components.avatarCropper.cropContainer.changeImageButton',
              )}
            </Button>
            <Button type="primary" onClick={generateFileCropped}>
              {intl.getTranslatedText(
                'components.avatarCropper.cropContainer.finishButton',
              )}
            </Button>
          </ActionButtonsContainer>
        </CropImageContainer>
      )}
      {!croppedContainerOpened &&
        (!croppedAvatar?.previewUrl ? (
          <>
            <SelectImageButton onClick={() => inputFileRef.current.click()}>
              <p>
                {selectImageButtonLabel ||
                  intl.getTranslatedText(
                    'components.avatarCropper.selectImageButton',
                  )}
                {required && (
                  <small>
                    <span>
                      {intl.getTranslatedText(
                        'components.avatarCropper.requiredImage',
                      )}
                    </span>
                  </small>
                )}
              </p>
            </SelectImageButton>
          </>
        ) : (
          <ViewerAvatarContainer>
            <img src={croppedAvatar?.previewUrl} alt="Avatar" />
            <ActionButtonsViewerAvatarContainer>
              <Button type="primary" onClick={handleRemoveSelectedImage} danger>
                {intl.getTranslatedText(
                  'components.avatarCropper.previewContainer.removeImageButton',
                )}
              </Button>
              <Button
                type="default"
                onClick={() => setCroppedContainerOpened(true)}
              >
                {intl.getTranslatedText(
                  'components.avatarCropper.previewContainer.editImageButton',
                )}
              </Button>
            </ActionButtonsViewerAvatarContainer>
          </ViewerAvatarContainer>
        ))}
    </>
  );
}

export default AvatarCropper;
