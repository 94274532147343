import React, { createContext, useCallback, useContext, useState } from 'react';

import { useIntl } from './IntlContext';

interface LoadingMaskedContextData {
  loadingData: {
    description: string;
    shouldShow: boolean;
  };
  showLoading(description?: string): void;
  hideLoading(): void;
}

const LoadingMaskedContext = createContext<LoadingMaskedContextData>(
  {} as LoadingMaskedContextData,
);

const LoadingMaskedProvider: React.FC = ({ children }) => {
  const intl = useIntl();

  const [shouldShow, setShouldShow] = useState(false);
  const [description, setDescription] = useState('');

  const showLoading = useCallback(
    (loadingDescription?: string) => {
      setShouldShow(true);
      setDescription(
        loadingDescription ||
          intl.getTranslatedText('common.messages.defaultLoading'),
      );
    },
    [intl],
  );
  const hideLoading = useCallback(() => {
    setShouldShow(false);
    setDescription('');
  }, []);

  return (
    <LoadingMaskedContext.Provider
      value={{
        loadingData: {
          shouldShow,
          description,
        },
        showLoading,
        hideLoading,
      }}
    >
      {children}
    </LoadingMaskedContext.Provider>
  );
};

function useLoadingMasked(): LoadingMaskedContextData {
  const context = useContext(LoadingMaskedContext);

  if (!context) {
    throw new Error(
      'useLoadingMasked must be used within an LoadingMaskedProvider',
    );
  }

  return context;
}

export { useLoadingMasked, LoadingMaskedProvider };
