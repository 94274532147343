import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  > h6 {
    font-weight: ${props => props.theme.typograph.weights.medium};
    width: 100%;
    text-align: center;
  }

  > p {
    width: 100%;
    text-align: center;
    margin: 8px 0 0;
  }
`;

export const AnimationContainer = styled.div`
  width: 358px;
  transform: translateY(32px);
`;
