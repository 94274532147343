import styled from 'styled-components';

import notFoundBackground404 from '../../assets/404-not-found-background.jpg';

export const Container = styled.div`
  width: 100%;
  height: calc(100vh - 72px);
  background: linear-gradient(
      0deg,
      rgba(43, 43, 43, 0.97),
      rgba(43, 43, 43, 0.97)
    ),
    url(${notFoundBackground404}) no-repeat center;
  background-size: cover;

  display: flex;
  flex-direction: column;

  padding: 40px 56px;

  @media only screen and (max-width: 600px) {
    padding: 16px 16px 56px;
  }

  @media only screen and (max-width: 1199px) {
    height: calc(100vh - 61px);
    padding: 32px 32px 56px;
  }
`;

export const Content = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;

  div {
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;

    h4 {
      margin-top: 16px;

      span {
        color: ${props => props.theme.colors.primary.primary0};
      }
    }

    h6 {
      margin-bottom: 16px;
      font-weight: ${props => props.theme.typograph.weights.medium};
    }
  }
`;

export const AnimationContainer = styled.div`
  width: 296px;
`;
