import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { darken, shade } from 'polished';

interface IFilterItemProps {
  $selected: boolean;
}

interface ICardGameItemProps {
  $bg?: string;
}

function getBackgroundOfCardGameItem(bg = ''): string {
  if (bg) {
    return bg;
  }

  return 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSBP2zNNRqebdTAs6101-g1ZP-AENruAakJhA&usqp=CAU';
}

export const Container = styled.div`
  width: 100%;
  margin-top: 1rem;

  padding: 0 3.5rem;
  @media only screen and (max-width: ${props => props.theme.breakpoints.xl}) {
    padding: 0 2.5rem;
  }
  @media only screen and (max-width: ${props => props.theme.breakpoints.sm}) {
    padding: 0 1rem;
  }
`;

export const SearchContainer = styled.div`
  width: 100%;
  max-width: 43rem;
  display: flex;

  @media only screen and (max-width: ${props => props.theme.breakpoints.sm}) {
    max-width: 100%;
  }

  > a button {
    margin-left: 1rem;
  }
`;

export const TabListContainer = styled.div`
  width: 100%;
  max-width: 43rem;
  display: flex;
  margin-top: 1rem;
  display: flex;
  flex-wrap: nowrap;
  overflow-x: scroll;

  &::-webkit-scrollbar {
    display: none;
  }

  @media only screen and (max-width: ${props => props.theme.breakpoints.sm}) {
    max-width: 100%;
  }
`;

export const CardListMessagesContainer = styled.div`
  width: 100%;
  height: 11.25rem;
  margin-top: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;

  div {
    display: flex;

    p {
      margin-left: 1rem;
      color: ${props => props.theme.colors.light['light-2']};
    }
  }

  h6 {
    text-align: center;

    span {
      color: ${props => props.theme.colors.primary.primary0};
    }
  }
`;

export const CardListWrapper = styled.section`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 1.5rem;
`;

export const CardGameItem = styled(Link)<ICardGameItemProps>`
  display: inline-block;
  width: 33%;
  height: 11.25rem;
  border-radius: 8px;
  background: url(${props => getBackgroundOfCardGameItem(props.$bg)}) no-repeat
    center;
  background-size: cover;
  position: relative;
  margin-right: 0.33%;
  margin-bottom: 8px;
  transition: 0.2s transform;

  @media only screen and (max-width: ${props => props.theme.breakpoints.xl}) {
    width: 49.5%;
    margin-right: 0.25%;
  }

  @media only screen and (max-width: ${props => props.theme.breakpoints.sm}) {
    width: 100%;
    margin-right: 0;
  }

  &:hover {
    transform: translateY(-8px);
  }

  svg {
    color: ${props => props.theme.colors.primary['primary+1']};
    position: absolute;
    z-index: 10;
    top: 1rem;
    right: 1rem;
  }

  > div {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: linear-gradient(
      0deg,
      rgba(69, 69, 69, 0.8),
      rgba(69, 69, 69, 0.8)
    );

    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 1rem;
    border-radius: 8px;

    p {
      margin-top: 1rem;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 4; /* number of lines to show */
      -webkit-box-orient: vertical;
    }
  }
`;

export const LoadMoreCardsButton = styled.button`
  border: 0;
  width: 33%;
  margin-right: 0.33%;
  margin-bottom: 0.5rem;
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.theme.colors.dark['dark+1']};
  border-radius: 8px;
  transition: 0.2s background;

  &:hover {
    background-color: ${props => shade(0.1, props.theme.colors.dark['dark+1'])};
  }

  ${props =>
    props.disabled &&
    css`
      cursor: not-allowed;
      opacity: 0.8;
      &:hover {
        background-color: ${props.theme.colors.dark['dark+1']};
      }
    `}

  @media only screen and (max-width: ${props => props.theme.breakpoints.xl}) {
    width: 49.5%;
    margin-right: 0.25%;
  }

  @media only screen and (max-width: ${props => props.theme.breakpoints.sm}) {
    width: 100%;
    margin-right: 0;
  }

  p {
    color: ${props => props.theme.colors.light['light-2']};
    text-align: center;
    align-self: stretch;
  }
`;

export const RestTimerContainer = styled.div`
  display: flex;
  position: absolute;
  top: 1rem;
  right: 1rem;

  div {
    display: flex;
    flex-direction: column;

    & + div {
      margin-left: 1rem;
    }

    h6 {
      color: ${props => props.theme.colors.primary.primary0};
    }
    small {
      color: ${props => props.theme.colors.light['light-2']};
    }
  }
`;

export const FilterItem = styled.button<IFilterItemProps>`
  border: 0;
  padding: 0.25rem 0.5rem;
  background: ${props => props.theme.colors.dark['dark+2']};
  color: ${props => props.theme.colors.light['light-2']};
  border-radius: 4px;
  transition: 0.2s box-shadow;
  flex-shrink: 0;

  & + button {
    margin-left: 0.5rem;
  }

  ${props =>
    props.$selected &&
    css`
      background: ${props.theme.colors.primary['primary+1']};
      color: ${props.theme.colors.dark.dark0};
    `}

  p {
    display: -webkit-box;
    -webkit-line-clamp: 1;
  }

  &:hover {
    box-shadow: 1px 1px 3px
      ${props => darken(0.1, props.theme.colors.dark.dark0)};
  }
`;
