import styled, { css } from 'styled-components';
import { shade } from 'polished';

interface IContainerProps {
  $lastMessageOfGroup?: boolean;
}

interface IHourAndMessageStatusContainerProps {
  $isRead?: boolean;
}

export const Container = styled.button<IContainerProps>`
  /* width: min-content; */
  border: 0;
  text-align: start;
  max-width: 80%;
  border-radius: 8px;
  ${props =>
    props.$lastMessageOfGroup &&
    css`
      border-bottom-right-radius: 0;
    `}
  padding: 8px 8px 4px;
  background: ${props => props.theme.colors.primary['primary-1']};
  transition: 0.2s background;

  & + button {
    margin-top: 4px;
  }

  &:hover {
    background: ${props =>
      shade(0.07, props.theme.colors.primary['primary-1'])};
  }

  > p {
    width: 100%;
  }
`;

export const HourAndMessageStatusContainer = styled.div<IHourAndMessageStatusContainerProps>`
  width: 100%;
  margin-top: 4px;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  small {
    font-size: 12px;
    color: ${props => props.theme.colors.light['light-2']};
  }

  svg {
    color: ${props =>
      !props.$isRead
        ? props.theme.colors.light['light-2']
        : props.theme.colors.secondary['secondary+1']};
    margin-left: 4px;
  }
`;

export const MessageAudioPlayerContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  cursor: default;
`;
export const PlayOrStopButton = styled.button`
  border: 0;
  background: transparent;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    color: ${props => props.theme.colors.light.light0};
  }
`;
export const MessageAudioDurationAndCurrentTime = styled.small`
  font-size: 12px;
  color: ${props => props.theme.colors.light['light-2']};
  margin-right: auto;
  margin-left: 32px;
`;
