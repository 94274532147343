import { TComponentsTexts } from '../../en/components';

import AvatarCropper from './AvatarCropper';
import NotificationsTexts from './Notifications';
import UserAvatarTexts from './UserAvatar';
import PushGameModalTexts from './PushGameModal';
import ChatContainerTexts from './ChatContainer';

const componentsTexts: TComponentsTexts = {
  ...AvatarCropper,
  ...NotificationsTexts,
  ...UserAvatarTexts,
  ...PushGameModalTexts,
  ...ChatContainerTexts,
};

export default componentsTexts;
