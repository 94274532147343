const PushGameModalTexts = {
  'components.notifications.title': 'Notifications',

  'components.pushGameModal.matchInfoDetails.date': 'Date and time',
  'components.pushGameModal.matchInfoDetails.place': 'Local',
  'components.pushGameModal.countdown': '{seconds} seconds left',

  /* Buttons */
  'components.pushGameModal.showMoreDetailsOfMatchButton':
    'Click to see more detail',
  'components.pushGameModal.showLessDetailsOfMatchButton':
    'Click to see more details',
  /* --------- Messages --------- */
  'components.pushGameModal.messages.closePushGameModalConfirm.title':
    'Do you want leave?',
  'components.pushGameModal.messages.closePushGameModalConfirm.confirmButton':
    'Leave',
  /* */
  'components.pushGameModal.messages.submitPushGameConfirm.title':
    'Do you really want to play this game?',
  'components.pushGameModal.messages.submitPushGameConfirm.confirmButton':
    'Play game',
  'components.pushGameModal.messages.submitPushGameSuccess.title': 'Game made!',
};

export default {
  ...PushGameModalTexts,
};
