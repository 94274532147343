import { useAuth } from '@/context/AuthContext';
import { EBulletPagePath, useBullet } from '@/context/BulletContext';
import React, { useCallback, useMemo } from 'react';
import {
  ETransactionStatus,
  ITransaction,
  ETransactionModule,
} from '../../bullet.types';

import {
  Container,
  TransactionAmount,
  TransactionAmountAndStatusContainer,
  TransactionDetails,
} from './styles';
import TransactionIcon from './TransactionIcon';

export type TTransactionStatusType = 'pending' | 'success' | 'error';
interface ITransactionStatus {
  type: TTransactionStatusType;
  text: string;
}

interface IGetTransactionTypeParams {
  senderIsMe: boolean;
  receiverIsMe: boolean;
  transactionModule: ETransactionModule;
}

interface ITransactionProps {
  transaction: ITransaction;
}

const Transaction: React.FC<ITransactionProps> = ({ transaction }) => {
  const { user: me } = useAuth();
  const bullet = useBullet();

  function getTransactionFlow({
    senderIsMe,
    receiverIsMe,
    transactionModule,
  }: IGetTransactionTypeParams): 'in' | 'out' {
    if (senderIsMe && receiverIsMe) {
      if (transactionModule === ETransactionModule.DEPOSIT) return 'in';
      if (transactionModule === ETransactionModule.WITHDRAW) return 'out';
    }

    if (!senderIsMe && receiverIsMe) return 'in';
    return 'out';
  }

  function getTransactionAmount(flow: 'in' | 'out'): string {
    return `${flow === 'in' ? '+' : '-'} R$${transaction.amount.toFixed(2)}`;
  }

  const getTransactionStatus = useCallback((): ITransactionStatus => {
    if (
      transaction.details.module === ETransactionModule.DEPOSIT &&
      transaction.status === ETransactionStatus.WAITING
    ) {
      return {
        type: 'pending',
        text: 'Pendente',
      };
    }

    if (
      transaction.details.module === ETransactionModule.WITHDRAW &&
      transaction.status === ETransactionStatus.WITHDRAW_PENDING
    ) {
      return {
        type: 'pending',
        text: 'Em processamento',
      };
    }

    if (transaction.status === ETransactionStatus.CANCELED) {
      return {
        type: 'error',
        text: 'Cancelado',
      };
    }

    if (transaction.status === ETransactionStatus.FAILED) {
      return {
        type: 'error',
        text: 'Falhou',
      };
    }

    return {
      type: 'success',
      text: 'Sucesso',
    };
  }, [transaction.details.module, transaction.status]);

  const senderId = transaction.sender._id;
  const receiverId = transaction.receiver._id;
  const senderIsMe = senderId === me?._id;
  const receiverIsMe = receiverId === me?._id;

  const transactionFlow = getTransactionFlow({
    senderIsMe,
    receiverIsMe,
    transactionModule: transaction.details.module,
  });
  const isDepositAndIsWaiting = useMemo(
    () =>
      transaction.details.module === ETransactionModule.DEPOSIT &&
      transaction.status === ETransactionStatus.WAITING,
    [transaction],
  );
  const transactionAmount = getTransactionAmount(transactionFlow);
  const transactionStatus = getTransactionStatus();

  const handleClick = useCallback(() => {
    if (isDepositAndIsWaiting) {
      bullet.navigateTo(EBulletPagePath.PAYMENT, {
        transactionId: transaction._id,
      });
    }
  }, [bullet, transaction._id, isDepositAndIsWaiting]);

  return (
    <Container
      onClick={handleClick}
      key={transaction._id}
      $hasClickAction={isDepositAndIsWaiting}
    >
      <TransactionIcon
        module={transaction.details.module}
        type={transaction.details.type}
      />
      <TransactionDetails>
        <small>{transaction.details.title}</small>
        <strong>{transaction.details.description}</strong>
      </TransactionDetails>
      {transactionStatus.type === 'success' ? (
        <TransactionAmount $flow={transactionFlow}>
          {transactionAmount}
        </TransactionAmount>
      ) : (
        <TransactionAmountAndStatusContainer $type={transactionStatus.type}>
          <small>{transactionAmount}</small>
          <small>{transactionStatus.text}</small>
        </TransactionAmountAndStatusContainer>
      )}
    </Container>
  );
};

export default Transaction;
