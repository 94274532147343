import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
} from 'react';
import { FaCrown } from 'react-icons/fa';
import { AxiosResponse } from 'axios';
import { Carousel } from 'antd';
import { CarouselRef } from 'antd/lib/carousel';
import { Image, Shimmer } from 'react-shimmer';

import { UploadRequests } from '@/services/api/requests/Upload';
import Loading from '../../components/Loading';
import UserAvatar from '../../components/UserAvatar';

import { showToast } from '../../hooks/showToast';
import { useIntl } from '../../context/IntlContext';

import api from '../../services/api';

import { IAds, IAdsSettings, IRankingPosition, IRankings } from './types';
import CardList from './CardList';

import {
  Container,
  RankingsSession,
  RankingBanner,
  RankingTop3Item,
  AdvertisementItemContainer,
  CarouselContainer,
} from './styles';

const Home: React.FC = () => {
  const intl = useIntl();
  const carouselRef = useRef<CarouselRef>(null);

  const [ranking, setRankings] = useState<IRankings | null>(null);
  const selectedRanking = 'global';

  const [adverts, setAdverts] = useState<IAds[]>([]);

  const [loadingData, setLoadingData] = useState(true);

  const handleGetRankings = useCallback(async () => {
    const handleRanking = (
      url: string,
    ): Promise<
      AxiosResponse<{
        docs: IRankingPosition[];
      }>
    > => {
      return api.get<{
        docs: IRankingPosition[];
      }>(url, {
        params: {
          limit: 3,
        },
      });
    };

    const [globalRankingResponse] = await Promise.all([
      handleRanking('/api/ranking/athlete'),
    ]);

    setRankings({
      global: globalRankingResponse.data.docs,
    });
  }, []);

  const handleStartCarousel = useCallback(
    async (time: number): Promise<void> => {
      setInterval(() => {
        if (carouselRef.current) {
          carouselRef?.current?.next();
        }
      }, time);
    },
    [],
  );

  const handleGetAdvertisement = useCallback(async () => {
    const [adsResponse, adsSettingsResponse] = await Promise.all([
      api.get<{
        docs: IAds[];
      }>('/api/slide-main', {
        params: {
          slide: true,
        },
      }),
      api.get<{
        docs: IAdsSettings[];
      }>('/api/slide-main/settings'),
    ]);

    setAdverts(adsResponse.data.docs);
    if (adsSettingsResponse?.data?.docs.length > 0) {
      const advertsSettings: IAdsSettings = adsSettingsResponse.data.docs[0];
      handleStartCarousel(advertsSettings.time * 1000);
    }
  }, [handleStartCarousel]);

  const handleGetHomeData = useCallback(() => {
    Promise.all([handleGetRankings(), handleGetAdvertisement()])
      .then(() => {
        setLoadingData(false);
      })
      .catch(() => {
        setLoadingData(false);
        showToast({
          type: 'error',
          title: intl.getTranslatedText('pages.home.getHomeDataError.title'),
          description: intl.getTranslatedText(
            'common.errors.unexpectedError.description',
          ),
        });
      });
  }, [handleGetAdvertisement, handleGetRankings, intl]);

  useEffect(() => {
    handleGetHomeData();
  }, [handleGetHomeData]);

  const selectedRankingCardViewer = useMemo(() => {
    if (!ranking) {
      return <></>;
    }

    const selectedRankingData = {
      global: ranking.global,
    };

    const selectedRankingUrls = {
      global: '/rankings/global',
    };

    return (
      <RankingBanner
        to={selectedRankingUrls[selectedRanking]}
        $bg="https://img.freepik.com/free-photo/close-up-soccer-striker-ready-kicks-fiery-ball-stadium_207634-7.jpg?size=626&ext=jpg"
      >
        <div>
          <RankingTop3Item>
            <p>2</p>
            <UserAvatar
              photoId={
                selectedRankingData[selectedRanking][1]?._user?.photo?._id
              }
              size={85}
            />
            <p>
              {selectedRankingData[selectedRanking][1]?._user?.name || '-----'}
            </p>
            <small>
              {selectedRankingData[selectedRanking][1]?._user?.username ||
                '---'}
            </small>
            <small>
              {selectedRankingData[selectedRanking][1]?.totalScore
                ? `${
                    selectedRankingData[selectedRanking][1]?.totalScore
                  } ${intl.getTranslatedText('common.scoredPointsDescription')}`
                : `-- ${intl.getTranslatedText(
                    'common.scoredPointsDescription',
                  )}`}
            </small>
          </RankingTop3Item>
          <RankingTop3Item>
            <p>1</p>
            <FaCrown size={24} />
            <UserAvatar
              photoId={
                selectedRankingData[selectedRanking][0]?._user?.photo?._id
              }
              size={100}
            />
            <p>
              {selectedRankingData[selectedRanking][0]?._user?.name || '-----'}
            </p>
            <small>
              {selectedRankingData[selectedRanking][0]?._user?.username ||
                '---'}
            </small>
            <small>
              {selectedRankingData[selectedRanking][0]?.totalScore
                ? `${
                    selectedRankingData[selectedRanking][0]?.totalScore
                  } ${intl.getTranslatedText('common.scoredPointsDescription')}`
                : `-- ${intl.getTranslatedText(
                    'common.scoredPointsDescription',
                  )}`}
            </small>
          </RankingTop3Item>
          <RankingTop3Item>
            <p>3</p>
            <UserAvatar
              photoId={
                selectedRankingData[selectedRanking][2]?._user?.photo?._id
              }
              size={85}
            />
            <p>
              {selectedRankingData[selectedRanking][2]?._user?.name || '-----'}
            </p>
            <small>
              {selectedRankingData[selectedRanking][2]?._user?.username ||
                '---'}
            </small>
            <small>
              {selectedRankingData[selectedRanking][2]?.totalScore
                ? `${
                    selectedRankingData[selectedRanking][2]?.totalScore
                  } ${intl.getTranslatedText('common.scoredPointsDescription')}`
                : `-- ${intl.getTranslatedText(
                    'common.scoredPointsDescription',
                  )}`}
            </small>
          </RankingTop3Item>
        </div>
        <small>
          {intl.getTranslatedText('pages.home.rankingBannerButtonDescription')}
        </small>
      </RankingBanner>
    );
  }, [intl, ranking, selectedRanking]);

  if (loadingData) {
    return (
      <Container>
        <Loading />
      </Container>
    );
  }

  return (
    <Container>
      <RankingsSession>
        <div>
          <h5>{intl.getTranslatedText('pages.home.rankingsLabel')}</h5>
        </div>
        <div>{selectedRankingCardViewer}</div>
      </RankingsSession>
      {adverts.length ? (
        <CarouselContainer>
          <Carousel ref={carouselRef}>
            {adverts.map(advert => (
              <div key={advert._id}>
                <AdvertisementItemContainer>
                  <a
                    href={advert.url ? advert.url : advert.sponsorUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="_blank"
                    onClick={e => {
                      if (!advert.url && !advert.sponsorUrl) {
                        e.preventDefault();
                      }
                    }}
                  >
                    <Image
                      src={UploadRequests.getFileUrl(advert._upload)}
                      fallback={<Shimmer width={100} height={100} />}
                    />
                  </a>
                </AdvertisementItemContainer>
              </div>
            ))}
          </Carousel>
        </CarouselContainer>
      ) : (
        <></>
      )}
      <CardList />
    </Container>
  );
};

export default Home;
