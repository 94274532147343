const SignInTexts = {
  'pages.signIn.title': 'Login',
  /* ------------ Form ------------ */
  /* Username */
  'pages.signIn.form.username.label': 'Username, email or document',
  'pages.signIn.form.username.placeholder':
    'Type your username, email or document',
  'pages.signIn.form.username.validation': 'Username is required.',
  /* Password */
  'pages.signIn.form.password.label': 'Password',
  'pages.signIn.form.password.placeholder': 'Type your password',
  'pages.signIn.form.password.validation': 'Password is required',
  'pages.signIn.form.submitButton': 'Sign In',
  'pages.signIn.form.submitButtonLoading': 'Loading...',

  /* ------------ Links ------------ */
  'pages.signIn.forgotPasswordLink': 'I forgotten my password',
  'pages.signIn.signUpLink': 'New account',

  /* ------------ Messages ------------ */
  'pages.signIn.signInSubmitError.title': 'Authentication error',
  'pages.signIn.signInSubmitError.description':
    'Login error, check your credentials.',
};

export default {
  ...SignInTexts,
};
