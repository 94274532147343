import { useEffect } from 'react';

interface IUseRouteBlockerProps {
  when: boolean;
  message: string;
}

function useRouteBlocker({ when, message }: IUseRouteBlockerProps): void {
  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent): string => {
      event.preventDefault();
      // event.returnValue = message;
      return message;
    };

    if (when) {
      window.addEventListener('beforeunload', handleBeforeUnload);
    } else {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    }

    return () => window.removeEventListener('beforeunload', handleBeforeUnload);
  }, [when, message]);
}

export default useRouteBlocker;
