import React, { useMemo } from 'react';
import { Spin, AutoComplete } from 'antd';
import { Link, useRouteMatch } from 'react-router-dom';
import { FaCrown } from 'react-icons/fa';
import { LoadingOutlined } from '@ant-design/icons';
import * as dateFns from 'date-fns';

import { UploadRequests } from '@/services/api/requests/Upload';

import defaultAvatar from '../../../../assets/DefaultAvatar.svg';

import { useIntl } from '../../../../context/IntlContext';

import {
  Container,
  Top3Item,
  RankingPlayerItem,
  SearchPlayerItem,
  ViewMoreButton,
  LoadingAndNotFoundContainer,
  UserIsMeIndicator,
  SearchAutocompleteContentContainer,
  SelectedOnSearchUser,
  MyGamesContainer,
} from './styles';
import { IRankingProps, IRouteParams } from './types';
import { ECardTypeEnum } from '../../types';

const loadingIcon = <LoadingOutlined style={{ fontSize: 20 }} spin />;
const { parseISO, format } = dateFns;

const Ranking: React.FC<IRankingProps> = ({
  ranking,
  loading,
  cardType,
  loadMoreRanking,
  isOnLastPageOfRanking,
  handleSearchUser,
  searchUserValue,
  loadingSearchUsers,
  findedOnSearchUsers,
  isOnLastPageOfSearchUsers,
  loadMoreSearchUsers,
  handleGetUserSelected,
  userSelectedOnSearch,
  isOnLastPageOfGamesUserSelectedOnSearch,
  loadMoreGamesUserSelectedOnSearch,
  loadingUserSelectedOnSearch,
  myGames,
  setCurrentView,
}) => {
  const { url } = useRouteMatch<IRouteParams>();

  const intl = useIntl();

  const restRanking = useMemo(() => {
    return ranking.slice(3);
  }, [ranking]);

  const searchResultsViewer = useMemo(() => {
    if (searchUserValue.length <= 3) {
      return (
        <AutoComplete.Option value="" disabled>
          <SearchAutocompleteContentContainer>
            <p>{intl.getTranslatedText('common.messages.minCharToSearch')}</p>
          </SearchAutocompleteContentContainer>
        </AutoComplete.Option>
      );
    }
    if (loadingSearchUsers) {
      return (
        <AutoComplete.Option value="" disabled>
          <SearchAutocompleteContentContainer>
            <div>
              <Spin style={{ lineHeight: 0 }} indicator={loadingIcon} />
              <p>{intl.getTranslatedText('common.messages.defaultLoading')}</p>
            </div>
          </SearchAutocompleteContentContainer>
        </AutoComplete.Option>
      );
    }
    if (findedOnSearchUsers.length > 0) {
      return (
        <>
          {findedOnSearchUsers?.map(user => (
            <AutoComplete.Option value={user.username} key={user._id}>
              <SearchPlayerItem>
                <img
                  src={
                    user?.photo?.filename
                      ? UploadRequests.getFileUrl(user?.photo?.filename)
                      : defaultAvatar
                  }
                  alt={user?.name}
                />
                <div>
                  <p>{user?.name || '---'}</p>
                  <small>{user?.username || '---'}</small>
                </div>
                {user.isMe && (
                  <UserIsMeIndicator>
                    {' '}
                    {intl.getTranslatedText('common.meIdentifier')}
                  </UserIsMeIndicator>
                )}
              </SearchPlayerItem>
            </AutoComplete.Option>
          ))}
          {!isOnLastPageOfSearchUsers && (
            <AutoComplete.Option value="" disabled>
              <ViewMoreButton
                disabled={loadingSearchUsers}
                onClick={loadMoreSearchUsers}
              >
                {!loadingSearchUsers ? (
                  <p>{intl.getTranslatedText('common.buttons.viewMore')}</p>
                ) : (
                  <p>
                    {intl.getTranslatedText('common.messages.defaultLoading')}
                  </p>
                )}
              </ViewMoreButton>
            </AutoComplete.Option>
          )}
        </>
      );
    }
    return (
      <AutoComplete.Option value="" disabled>
        <SearchAutocompleteContentContainer>
          <h6>
            {intl.getTranslatedTextWithHTML(
              'pages.athletesOfTheWeek.card.ranking.messages.userNotFound',
            )}
          </h6>
        </SearchAutocompleteContentContainer>
      </AutoComplete.Option>
    );
  }, [
    findedOnSearchUsers,
    intl,
    isOnLastPageOfSearchUsers,
    loadMoreSearchUsers,
    loadingSearchUsers,
    searchUserValue.length,
  ]);

  const myGamesStatusViewer = useMemo(() => {
    if (cardType === ECardTypeEnum.PREPARING) {
      return (
        <MyGamesContainer $notGame>
          {intl.getTranslatedText(
            'pages.athletesOfTheWeek.card.ranking.messages.preparingStage',
          )}
        </MyGamesContainer>
      );
    }

    if (!myGames.length) {
      return (
        <MyGamesContainer $notGame>
          {intl.getTranslatedText(
            'pages.athletesOfTheWeek.card.ranking.messages.myGamesNotFound',
          )}
        </MyGamesContainer>
      );
    }

    if (
      myGames.length === 1 &&
      myGames.filter(myGame => myGame.totalScore >= 1).length === 1
    ) {
      return (
        <MyGamesContainer $notScore onClick={() => setCurrentView('myGames')}>
          <small>
            {intl.getTranslatedTextWithHTML(
              'pages.athletesOfTheWeek.card.ranking.messages.myGamesPlayedOnce',
              {
                totalScore: myGames[0].totalScore
                  .toFixed(2)
                  .replace(/[.,]00$/, ''),
              },
            )}
            {myGames[0].totalScore === 1
              ? intl.getTranslatedText('common.scoredPointDescription')
              : intl.getTranslatedText('common.scoredPointsDescription')}
          </small>
          <strong>
            {intl.getTranslatedText('common.buttons.viewDetails')}
          </strong>
        </MyGamesContainer>
      );
    }

    if (
      myGames.length === 1 &&
      myGames.filter(myGame => myGame.totalScore === 0).length === 1
    ) {
      return (
        <MyGamesContainer
          $notScore={false}
          onClick={() => setCurrentView('myGames')}
        >
          <small>
            {intl.getTranslatedText(
              'pages.athletesOfTheWeek.card.ranking.messages.myGamesPlayedOnce.noScore',
            )}
          </small>
          <strong>
            {intl.getTranslatedText('common.buttons.viewDetails')}
          </strong>
        </MyGamesContainer>
      );
    }

    if (
      myGames.length >= 2 &&
      myGames.filter(myGame => myGame.totalScore === 0).length ===
        myGames.length
    ) {
      return (
        <MyGamesContainer
          $notScore={false}
          onClick={() => setCurrentView('myGames')}
        >
          <small>
            {intl.getTranslatedText(
              'pages.athletesOfTheWeek.card.ranking.messages.myGamesPlayedManyTimes.noScore',
            )}
          </small>
          <strong>
            {intl.getTranslatedText('common.buttons.viewDetails')}
          </strong>
        </MyGamesContainer>
      );
    }

    if (
      myGames.length >= 2 &&
      myGames.filter(myGame => myGame.totalScore >= 1).length !== myGames.length
    ) {
      return (
        <MyGamesContainer $notScore onClick={() => setCurrentView('myGames')}>
          <small>
            {intl.getTranslatedText(
              'pages.athletesOfTheWeek.card.ranking.messages.myGamesPlayedManyTimes',
            )}
          </small>
          <strong>
            {intl.getTranslatedText('common.buttons.viewDetails')}
          </strong>
        </MyGamesContainer>
      );
    }

    return (
      <MyGamesContainer $notScore onClick={() => setCurrentView('myGames')}>
        <small>
          {intl.getTranslatedText(
            'pages.athletesOfTheWeek.card.ranking.messages.myGamesPlayedManyTimes.scoreAll',
          )}
        </small>
        <strong>{intl.getTranslatedText('common.buttons.viewDetails')}</strong>
      </MyGamesContainer>
    );
  }, [cardType, intl, myGames, setCurrentView]);

  const contentViewer = useMemo(() => {
    if (userSelectedOnSearch) {
      return (
        <SelectedOnSearchUser>
          <div>
            <img
              src={
                userSelectedOnSearch.photo
                  ? UploadRequests.getFileUrl(
                      userSelectedOnSearch.photo?.filename,
                    )
                  : defaultAvatar
              }
              alt={userSelectedOnSearch.name}
            />
            <div>
              <p>{userSelectedOnSearch.name}</p>
              <small>{userSelectedOnSearch.username}</small>
            </div>
            {userSelectedOnSearch.isMe && (
              <UserIsMeIndicator>
                {intl.getTranslatedText('common.meIdentifier')}
              </UserIsMeIndicator>
            )}
          </div>
          {userSelectedOnSearch.games.length > 0 ? (
            <ul>
              {userSelectedOnSearch.games.map(game => (
                <Link
                  to={`${url}/${
                    userSelectedOnSearch.isMe ? 'my_games' : 'users_games'
                  }/${game._id}`}
                  key={game._id}
                >
                  <p>
                    {format(
                      parseISO(game.createdAt),
                      intl.getTranslatedText('common.formatDates.carriedOut'),
                    )}
                  </p>
                  <small>
                    {game.totalScore}{' '}
                    {intl.getTranslatedText('common.scoredPointsDescription')}
                  </small>
                </Link>
              ))}
              {!isOnLastPageOfGamesUserSelectedOnSearch && (
                <button
                  onClick={loadMoreGamesUserSelectedOnSearch}
                  type="button"
                >
                  {!loadingUserSelectedOnSearch
                    ? intl.getTranslatedText('common.buttons.viewMore')
                    : intl.getTranslatedText('common.messages.defaultLoading')}
                </button>
              )}
            </ul>
          ) : (
            <div>
              <p>
                {intl.getTranslatedText(
                  'pages.athletesOfTheWeek.card.ranking.messages.userNotHasGameOnThisCard',
                )}
              </p>
            </div>
          )}
        </SelectedOnSearchUser>
      );
    }

    if (ranking.length === 0) {
      return (
        <LoadingAndNotFoundContainer>
          <div>
            <h6>
              {intl.getTranslatedTextWithHTML(
                'pages.athletesOfTheWeek.card.ranking.messages.noRanking',
              )}
            </h6>
          </div>
        </LoadingAndNotFoundContainer>
      );
    }

    return (
      <>
        <section>
          <Top3Item
            to={`${url}/${
              ranking[1]?._user?.isMe === false ? 'users_games' : 'my_games'
            }/${ranking[1]?._id}`}
            onClick={e => !ranking[1]?._id && e.preventDefault()}
            $disabled={!ranking[1]?._id}
          >
            <p>2</p>
            <img
              src={
                ranking[1]?._user?.photo
                  ? UploadRequests.getFileUrl(
                      ranking[1]?._user?.photo?.filename,
                    )
                  : defaultAvatar
              }
              alt={ranking[1]?._user?.name || '-----'}
            />
            <p>{ranking[1]?._user?.name || '-----'}</p>
            <small>{ranking[1]?._user?.username || '---'}</small>
            <small>
              {`${ranking[1]?.totalScore} ${intl.getTranslatedText(
                'common.scoredPointsDescription',
              )}` ||
                `-- ${intl.getTranslatedText(
                  'common.scoredPointsDescription',
                )}`}
            </small>
          </Top3Item>
          <Top3Item
            to={`${url}/${
              ranking[0]?._user?.isMe === false ? 'users_games' : 'my_games'
            }/${ranking[0]?._id}`}
            onClick={e => !ranking[0]?._id && e.preventDefault()}
            $disabled={!ranking[0]?._id}
          >
            <p>1</p>
            <FaCrown size={24} />
            <img
              src={
                ranking[0]?._user?.photo
                  ? UploadRequests.getFileUrl(
                      ranking[0]?._user?.photo?.filename,
                    )
                  : defaultAvatar
              }
              alt={ranking[0]?._user?.name || '-----'}
            />
            <p>{ranking[0]?._user?.name || '-----'}</p>
            <small>{ranking[0]?._user?.username || '---'}</small>
            <small>
              {`${ranking[0]?.totalScore} ${intl.getTranslatedText(
                'common.scoredPointsDescription',
              )}` ||
                `-- ${intl.getTranslatedText(
                  'common.scoredPointsDescription',
                )}`}
            </small>
          </Top3Item>
          <Top3Item
            to={`${url}/${
              ranking[2]?._user?.isMe === false ? 'users_games' : 'my_games'
            }/${ranking[2]?._id}`}
            onClick={e => !ranking[2]?._id && e.preventDefault()}
            $disabled={!ranking[2]?._id}
          >
            <p>3</p>
            <img
              src={
                ranking[2]?._user?.photo
                  ? UploadRequests.getFileUrl(
                      ranking[2]?._user?.photo?.filename,
                    )
                  : defaultAvatar
              }
              alt={ranking[2]?._user?.name || '-----'}
            />
            <p>{ranking[2]?._user?.name || '-----'}</p>
            <small>{ranking[2]?._user?.username || '---'}</small>
            <small>
              {`${ranking[2]?.totalScore} ${intl.getTranslatedText(
                'common.scoredPointsDescription',
              )}` ||
                `-- ${intl.getTranslatedText(
                  'common.scoredPointsDescription',
                )}`}
            </small>
          </Top3Item>
        </section>
        <ul>
          {restRanking.map((rankPosition, index) => (
            <RankingPlayerItem
              to={`${url}/${
                rankPosition?._user?.isMe === false ? 'users_games' : 'my_games'
              }/${rankPosition._id}`}
              key={rankPosition._id}
            >
              <p>{index + 4}</p>
              <img
                src={
                  rankPosition?._user?.photo?.filename
                    ? UploadRequests.getFileUrl(
                        rankPosition?._user?.photo?.filename,
                      )
                    : defaultAvatar
                }
                alt={rankPosition?._user?.name}
              />
              <div>
                <p>{rankPosition?._user?.name || '---'}</p>
                <small>{rankPosition?._user?.username || '---'}</small>
              </div>
              <div>
                <small>
                  {rankPosition.totalScore}{' '}
                  {intl.getTranslatedText('common.scoredPointsDescription')}
                </small>
                {rankPosition?._user?.isMe && (
                  <UserIsMeIndicator>
                    {intl.getTranslatedText('common.meIdentifier')}
                  </UserIsMeIndicator>
                )}
              </div>
            </RankingPlayerItem>
          ))}
          {!isOnLastPageOfRanking && (
            <ViewMoreButton disabled={loading} onClick={loadMoreRanking}>
              {!loading ? (
                <p>{intl.getTranslatedText('common.buttons.viewMore')}</p>
              ) : (
                <p>
                  {intl.getTranslatedText('common.messages.defaultLoading')}
                </p>
              )}
            </ViewMoreButton>
          )}
        </ul>
      </>
    );
  }, [
    intl,
    isOnLastPageOfGamesUserSelectedOnSearch,
    isOnLastPageOfRanking,
    loadMoreGamesUserSelectedOnSearch,
    loadMoreRanking,
    loading,
    loadingUserSelectedOnSearch,
    ranking,
    restRanking,
    url,
    userSelectedOnSearch,
  ]);

  if (loading && ranking.length === 0) {
    return (
      <LoadingAndNotFoundContainer>
        <div>
          <Spin style={{ lineHeight: 0 }} indicator={loadingIcon} />
          <p>
            {intl.getTranslatedText(
              'pages.athletesOfTheWeek.card.ranking.messages.loadingRanking',
            )}
          </p>
        </div>
      </LoadingAndNotFoundContainer>
    );
  }

  if (loadingUserSelectedOnSearch && !userSelectedOnSearch) {
    return (
      <LoadingAndNotFoundContainer>
        <div>
          <Spin style={{ lineHeight: 0 }} indicator={loadingIcon} />
          <p>
            {intl.getTranslatedText(
              'pages.athletesOfTheWeek.card.ranking.messages.loadingUserGames',
            )}
          </p>
        </div>
      </LoadingAndNotFoundContainer>
    );
  }

  return (
    <Container>
      <div>
        {cardType === ECardTypeEnum.RUNNING && (
          <small>
            {intl.getTranslatedText(
              'pages.athletesOfTheWeek.card.ranking.messages.partialInfo',
            )}
          </small>
        )}
        {myGamesStatusViewer}
        <AutoComplete
          placeholder={intl.getTranslatedText(
            'pages.athletesOfTheWeek.card.ranking.searchUserInput.placeholder',
          )}
          onSearch={handleSearchUser}
          onSelect={(value: string) => handleGetUserSelected(value)}
          value={searchUserValue}
          notFoundContent="User not found"
        >
          {searchResultsViewer}
        </AutoComplete>
      </div>
      {contentViewer}
    </Container>
  );
};

export default Ranking;
