import React, { useCallback, useRef, useState } from 'react';
import {
  FastField,
  FastFieldProps,
  Formik,
  FormikHelpers,
  FormikProps,
} from 'formik';
import * as Yup from 'yup';

import CustomAntButton from '@/components/CustomAntButton';
import Input from '@/components/Input';
import { CgUserList } from 'react-icons/cg';
import { BsPhoneFill } from 'react-icons/bs';
import { MdEmail } from 'react-icons/md';
import { FaKey } from 'react-icons/fa';

import { validateCpf } from '@/utils/validate';
import { useBullet } from '@/context/BulletContext';
import { showToast } from '@/hooks/showToast';
import api from '@/services/api';
import { useIntl } from '@/context/IntlContext';
import { useAuth } from '@/context/AuthContext';
import { Spin } from 'antd';
import {
  Container,
  KeyTypesSelectContainer,
  KeyTypeSelect,
  InputsContainer,
  Content,
  TransferAlert,
} from './styles';
import PixKeyInput from './PixKeyInput';
import ProcessingPayment from '../ProcessingPayment';
import ConfirmAction from '../ConfirmAction';
import WithdrawUnavailable from './WithdrawUnavailable';
import { LoadingContainer } from '../styles';

export type TPixKeyType = 'CPF' | 'PHONE' | 'EMAIL' | 'RANDOM' | '';

export interface IWithdrawFormData {
  pixKeyType: TPixKeyType;
  pixKey: string;
  amount: string;
}

function eneableSubmitButton(
  formikProps: FormikProps<IWithdrawFormData>,
): boolean {
  const { touched, errors } = formikProps;

  const noErrors = !errors?.amount && !errors?.pixKey && !errors?.pixKeyType;
  const allFieldsTouched =
    touched?.amount && touched?.pixKey && touched?.pixKeyType;

  if (noErrors && allFieldsTouched) return true;

  return false;
}

const Withdraw: React.FC = () => {
  const intl = useIntl();
  const { user: me } = useAuth();
  const bullet = useBullet();

  const formRef = useRef<HTMLFormElement>(null);

  const initialFormValues: IWithdrawFormData = {
    pixKeyType: '',
    pixKey: '',
    amount: '',
  };

  const [loadingWithdrawAvailability, setLoadingWithdrawAvailability] =
    useState(false);

  const [isWithdrawUnavailable, setIsWithdrawUnavailable] = useState(false);

  // TODO: add getWithdrawService status request

  const [shouldConfirmWithdraw, setShouldConfirmWithdraw] = useState(false);
  const [processingPaymentLoading, setProcessingWithdrawLoading] =
    useState(false);

  function handleChangeKeyType(
    newKeyType: TPixKeyType,
    formikProps: FormikProps<IWithdrawFormData>,
  ): void {
    formikProps.setFieldValue('pixKeyType', newKeyType);
    formikProps.setFieldTouched('pixKeyType', true);

    if (newKeyType === 'CPF') {
      formikProps.setFieldTouched('pixKey', true);
      formikProps.setFieldValue('pixKey', me?.docNumber);
    } else {
      formikProps.setFieldValue('pixKey', '');
      formikProps.setFieldTouched('pixKey', false);
    }
  }

  const handleSubmit = useCallback(
    async (
      values: IWithdrawFormData,
      formikHelpers: FormikHelpers<IWithdrawFormData>,
    ) => {
      const { amount, pixKeyType } = values;
      let { pixKey } = values;

      if (pixKeyType === 'CPF') {
        pixKey = pixKey.replace(/\./g, '').replace(/-/g, '');
      }
      if (pixKeyType === 'PHONE') {
        pixKey = `+55${pixKey}`
          .replace(/\(/g, '')
          .replace(/\)/g, '')
          .replace(/-/g, '');
      }

      const body = {
        amount,
        pixKeyType,
        pixKey,
      };

      try {
        await api.post('/api/withdraw', body);
        formikHelpers.setSubmitting(false);
        bullet.updateBalance(+amount, 'out');
        setProcessingWithdrawLoading(true);
      } catch (error) {
        formikHelpers.setSubmitting(false);
        showToast({
          title: intl.getTranslatedText('common.errors.unexpectedError.title'),
          description: intl.getTranslatedText(
            'common.errors.unexpectedError.description',
          ),
          type: 'error',
        });
      }
    },
    [bullet, intl],
  );

  if (loadingWithdrawAvailability) {
    return (
      <Container>
        <LoadingContainer>
          <Spin />
          <p>Carregando...</p>
        </LoadingContainer>
      </Container>
    );
  }

  if (processingPaymentLoading) {
    return (
      <Container>
        <ProcessingPayment flow="withdraw" />
      </Container>
    );
  }

  if (isWithdrawUnavailable) {
    return (
      <Container>
        <WithdrawUnavailable />
      </Container>
    );
  }

  return (
    <Container>
      <Content>
        <Formik
          initialValues={initialFormValues}
          validationSchema={Yup.object().shape({
            pixKeyType: Yup.string().required('A tipo da chave é obrigatório'),
            pixKey: Yup.string().when(
              'pixKeyType',
              (pixKeyType, schema: Yup.StringSchema<string | undefined>) => {
                if (pixKeyType === 'EMAIL') {
                  return schema
                    .required('A chave é obrigatória')
                    .email('O email informado não é válido');
                }

                if (pixKeyType === 'CPF') {
                  return schema
                    .required('A chave é obrigatória')
                    .transform(value =>
                      value.replace(/\./g, '').replace(/-/g, ''),
                    )
                    .test('cpf', 'O CPF inserido não é válido', value => {
                      const cpfValid = validateCpf(value);

                      if (cpfValid === 'valid') {
                        return true;
                      }
                      return false;
                    });
                }

                if (pixKeyType === 'PHONE') {
                  return schema
                    .required('A chave é obrigatória')
                    .transform(value =>
                      value
                        .replace(/\(/g, '')
                        .replace(/\)/g, '')
                        .replace(/-/g, ''),
                    )
                    .matches(/[0-9]{11}/, {
                      excludeEmptyString: true,
                      message: 'O telefone informado é inválido',
                    });
                }

                return Yup.string().required('A chave é obrigatória');
              },
            ),
            amount: Yup.number()
              .max(
                bullet.balance.amount,
                'A quantidade inserida é maior que o saldo atual',
              )
              .integer('A quantidade de moedas informadas não é válida')
              .required('A quantidade de moedas é obrigatória'),
          })}
          validateOnMount
          onSubmit={handleSubmit}
        >
          {formikProps => (
            <form
              ref={formRef}
              onSubmit={e => {
                e.preventDefault();
                formikProps.handleSubmit(e);
              }}
            >
              <p>Escolha um tipo de chave</p>
              <FastField name="pixKeyType">
                {({ field }: FastFieldProps) => {
                  return (
                    <KeyTypesSelectContainer>
                      <KeyTypeSelect
                        $selected={field.value === 'CPF'}
                        onClick={() => handleChangeKeyType('CPF', formikProps)}
                        type="button"
                      >
                        <CgUserList size={32} />
                        <small>CPF</small>
                      </KeyTypeSelect>
                      <KeyTypeSelect
                        $selected={field.value === 'PHONE'}
                        onClick={() =>
                          handleChangeKeyType('PHONE', formikProps)
                        }
                        type="button"
                      >
                        <BsPhoneFill size={32} />
                        <small>Celular</small>
                      </KeyTypeSelect>
                      <KeyTypeSelect
                        $selected={field.value === 'EMAIL'}
                        onClick={() =>
                          handleChangeKeyType('EMAIL', formikProps)
                        }
                        type="button"
                      >
                        <MdEmail size={32} />
                        <small>Email</small>
                      </KeyTypeSelect>
                      <KeyTypeSelect
                        $selected={field.value === 'RANDOM'}
                        onClick={() =>
                          handleChangeKeyType('RANDOM', formikProps)
                        }
                        type="button"
                      >
                        <FaKey size={32} />
                        <small>Chave aleatória</small>
                      </KeyTypeSelect>
                    </KeyTypesSelectContainer>
                  );
                }}
              </FastField>
              <TransferAlert
                message="Transfira para você mesmo"
                description="São permitidas apenas transferências para sua própria conta bancária"
                type="info"
                showIcon
              />
              {formikProps.values.pixKeyType && (
                <>
                  <InputsContainer>
                    <PixKeyInput pixKeyType={formikProps?.values?.pixKeyType} />
                    <FastField name="amount">
                      {({ field, meta }: FastFieldProps) => {
                        return (
                          <div>
                            <h6>Quantas moedas você quer sacar?</h6>
                            <Input
                              {...field}
                              placeholder="ex.: 20"
                              id="amountInput"
                              addonBefore="R$"
                              type="text"
                              error={
                                meta?.touched && meta?.error && meta?.error
                              }
                              onChange={e => {
                                const newValue = e.target.value;
                                formikProps.setFieldValue(
                                  'amount',
                                  newValue.replace(/\D/g, ''),
                                );
                              }}
                            />
                          </div>
                        );
                      }}
                    </FastField>
                  </InputsContainer>
                  <strong>
                    Saldo disponível: <span>R${bullet.balance.amount}</span>
                  </strong>
                  {!shouldConfirmWithdraw ? (
                    <CustomAntButton
                      htmlType="button"
                      disabled={
                        formikProps.isSubmitting ||
                        !eneableSubmitButton(formikProps)
                      }
                      type="primary"
                      onClick={() => setShouldConfirmWithdraw(true)}
                    >
                      {!formikProps.isSubmitting ? 'Sacar' : 'Carregando...'}
                    </CustomAntButton>
                  ) : (
                    <ConfirmAction
                      onConfirm={() => {
                        formRef.current?.requestSubmit();
                        setShouldConfirmWithdraw(false);
                      }}
                      onCancel={() => setShouldConfirmWithdraw(false)}
                    />
                  )}
                </>
              )}
            </form>
          )}
        </Formik>
      </Content>
    </Container>
  );
};

export default Withdraw;
