import styled, { css } from 'styled-components';

interface IContainer {
  $isOnChatPage?: boolean;
}

export const Container = styled.div<IContainer>`
  display: flex;
  flex-direction: column;
  flex: 1;

  postion: realtive;
  height: 100%;
  padding: 0 56px 0 56px;
  /* min-height: calc(100vh - 72px); */

  @media only screen and (max-width: 1199px) {
    padding: 0 32px 40px 32px;
    /* min-height: calc(100vh - 61px); */
  }
  @media only screen and (max-width: 600px) {
    /* padding: 32px 16px; */
    padding: 0 16px 32px 16px;
    /* min-height: calc(100vh - 61px); */
  }

  ${props =>
    props.$isOnChatPage &&
    css`
      padding-bottom: 0;
      @media only screen and (max-width: 1199px) {
        padding-bottom: 0;
      }
      @media only screen and (max-width: 600px) {
        padding-bottom: 0;
      }
    `}
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 16px;

  h4 {
    color: ${props => props.theme.colors.light.light0};
    line-height: 75%;
  }

  small {
    background: ${props => props.theme.colors.secondary.secondary0};
    padding: 1px 12px;
    border-radius: 8px;
    color: ${props => props.theme.colors.dark.dark0};
    cursor: default;
  }
`;
