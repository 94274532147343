import React from 'react';
import { ButtonProps } from 'antd/lib/button';

import { Button } from './styles';

interface IButtonProps extends ButtonProps {
  textPrimary?: boolean;
  useCustomIcon?: boolean;
  useCustomIconWithText?: boolean;
  useSecondary?: boolean;
}

const CustomAntButton: React.FC<IButtonProps> = ({
  textPrimary,
  useCustomIcon,
  useCustomIconWithText,
  useSecondary,
  icon,
  ...rest
}) => {
  return (
    <Button
      $textPrimary={textPrimary || false}
      $useCustomIcon={useCustomIcon || false}
      $useSecondary={useSecondary || false}
      icon={
        useCustomIconWithText ? <span className="anticon">{icon}</span> : icon
      }
      {...rest}
    />
  );
};

export default CustomAntButton;
