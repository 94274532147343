import React, { useCallback, useState } from 'react';
import { Modal } from 'antd';
import { RiRotateLockFill } from 'react-icons/ri';
import { FiLogOut } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';

import * as formatters from '../../../utils/formatters';

import { useAuth } from '../../../context/AuthContext';
import { useInternationalizationWrapper } from '../../../context/InternationalizationWrapperContext';
import { useIntl } from '../../../context/IntlContext';
import { showToast } from '../../../hooks/showToast';

import Select from '../../../components/Select';
import CustomAntButton from '../../../components/CustomAntButton';

import {
  Container,
  SessionHeader,
  SessionItems,
  ButtonsContainer,
} from './styles';

const { confirm } = Modal;

const Details: React.FC = () => {
  const history = useHistory();

  const { user: me, signOut } = useAuth();
  const { locale, handleChangeLocale } = useInternationalizationWrapper();
  const intl = useIntl();

  const [loadingSignOut, setLoadingSignOut] = useState(false);

  const handleSignOut = useCallback(async () => {
    try {
      await new Promise(resolve => {
        confirm({
          title: intl.getTranslatedText('common.messages.warning'),
          content: intl.getTranslatedText(
            'pages.profile.messages.signOutConfirm.description',
          ),
          okText: intl.getTranslatedText('common.buttons.exit'),
          cancelText: intl.getTranslatedText('common.buttons.cancel'),
          okButtonProps: {
            danger: true,
          },
          onOk() {
            resolve(true);
          },
        });
      });

      setLoadingSignOut(true);
      await signOut();
      history.push('/');
    } catch (error) {
      setLoadingSignOut(false);
      showToast({
        type: 'error',
        title: intl.getTranslatedText('common.errors.unexpectedError.title'),
        description: intl.getTranslatedText(
          'common.errors.unexpectedError.description',
        ),
      });
    }
  }, [history, intl, signOut]);

  return (
    <Container>
      <div>
        <SessionHeader>
          <h6>{intl.getTranslatedText('pages.profile.userInfo.title')}</h6>
          {/* <Link to={`${routeMatch.url}/edit_user_informations`}>
            <Button type="text" icon={<MdEdit height={18} />} />
          </Link> */}
        </SessionHeader>
        <SessionItems>
          <li>
            <small>
              {intl.getTranslatedText('pages.profile.userInfo.username.label')}
            </small>
            <p>{me?.username}</p>
          </li>
          <li>
            <small>
              {intl.getTranslatedText('pages.profile.userInfo.email.label')}
            </small>
            <p>{me?.email}</p>
          </li>
        </SessionItems>
      </div>
      <div>
        <SessionHeader>
          <h6>{intl.getTranslatedText('pages.profile.personalData.title')}</h6>
          {/* <Link to={`${routeMatch.url}/edit_personal_data`}>
            <Button type="text" icon={<MdEdit height={18} />} />
          </Link> */}
        </SessionHeader>
        <SessionItems>
          <li>
            <small>
              {intl.getTranslatedText('pages.profile.personalData.name.label')}
            </small>
            <p>{me?.name}</p>
          </li>
          <li>
            <small>
              {intl.getTranslatedText(
                'pages.profile.personalData.gender.label',
              )}
            </small>
            <p>
              {formatters.formatGender(
                me?.gender || intl.getTranslatedText('common.uninformed'),
              )}
            </p>
          </li>
          <li>
            <small>
              {intl.getTranslatedText(
                'pages.profile.personalData.docNumber.label',
              )}
            </small>
            <p>
              {formatters.formatCpf(
                me?.docNumber || intl.getTranslatedText('common.uninformed'),
              )}
            </p>
          </li>
          <li>
            <small>
              {intl.getTranslatedText('pages.profile.personalData.phone.label')}
            </small>
            <p>
              {formatters.formatPhone(
                me?.phone || intl.getTranslatedText('common.uninformed'),
              )}
            </p>
          </li>
        </SessionItems>
      </div>
      <ButtonsContainer>
        <CustomAntButton
          useCustomIconWithText
          icon={<RiRotateLockFill size={16} />}
          type="default"
          onClick={() => {
            history.push(`/profile/change_password`);
          }}
        >
          {intl.getTranslatedText('pages.profile.changePasswordButton')}
        </CustomAntButton>
        <CustomAntButton
          useCustomIconWithText
          icon={<FiLogOut size={16} />}
          type="primary"
          danger
          disabled={loadingSignOut}
          onClick={() => {
            handleSignOut();
          }}
        >
          {intl.getTranslatedText('pages.profile.signOutButton')}
        </CustomAntButton>
      </ButtonsContainer>
      <Select
        className="ant-select-language"
        id="language"
        options={[
          {
            label: `🇧🇷   ${intl.getTranslatedText(
              'common.selectLanguage.pt-BR',
            )}`,
            value: 'pt-BR',
          },
          {
            label: `🇺🇸   ${intl.getTranslatedText('common.selectLanguage.en')}`,
            value: 'en',
          },
        ]}
        value={locale}
        onChange={value => {
          if (typeof value === 'string') {
            handleChangeLocale(value);
          }
        }}
      />
    </Container>
  );
};

export default Details;
