const ProfileTexts = {
  'pages.profile.userInfo.title': 'Informações do usuário',
  'pages.profile.userInfo.username.label': 'Nome de usuário',
  'pages.profile.userInfo.email.label': 'Email',

  'pages.profile.personalData.title': 'Dados pessoais',
  'pages.profile.personalData.name.label': 'Nome',
  'pages.profile.personalData.gender.label': 'Sexo',
  'pages.profile.personalData.docNumber.label': 'CPF',
  'pages.profile.personalData.phone.label': 'Telefone',

  'pages.profile.changePasswordButton': 'Alterar senha',
  'pages.profile.signOutButton': 'Sair',

  'pages.profile.messages.signOutConfirm.description': 'Deseja realmente sair?',
};

const ChangePasswordTexts = {
  'pages.profile.changePassword.title': 'Alterar senha',

  'pages.profile.changePassword.submitChangePasswordConfirmButton':
    'Atualizar senha',

  /* Form */
  'pages.profile.changePassword.form.password.label': 'Senha atual',
  'pages.profile.changePassword.form.password.placeholder':
    'Digite sua senha atual',
  'pages.profile.changePassword.form.password.validation.required':
    'A senha atual é obrigatória',

  'pages.profile.changePassword.form.newPassword.label': 'Nova senha',
  'pages.profile.changePassword.form.newPassword.placeholder':
    'Digite uma nova senha secreta',
  'pages.profile.changePassword.form.newPassword.validation.min':
    'A nova senha precisa ter mais de 6 dígitos',
  'pages.profile.changePassword.form.newPassword.validation.required':
    'A nova senha é obrigatória',

  'pages.profile.changePassword.form.confirmNewPassword.label':
    'Confirmar nova senha',
  'pages.profile.changePassword.form.confirmNewPassword.placeholder':
    'Confirme sua nova senha secreta',
  'pages.profile.changePassword.form.confirmNewPassword.validation.invalid':
    'A confirmação da nova senha está incorreta',
  'pages.profile.changePassword.form.confirmNewPassword.validation.required':
    'A confirmação da nova senha é obrigatória',

  'pages.profile.changePassword.messages.submitChangePasswordConfirm.title':
    'Deseja realmente alterar sua senha?',
  'pages.profile.changePassword.messages.submitChangePasswordConfirm.description':
    'A senha atual será substituída permanentemente',
  'pages.profile.changePassword.messages.submitChangePasswordSuccess.title':
    'Senha alterada com sucesso!',
  'pages.profile.changePassword.messages.submitChangePasswordSuccess.description':
    'Utilize a nova senha para entrar na plataforma posteriormente',
  'pages.profile.changePassword.messages.submitChangePasswordError.title':
    'Senha atual incorreta!',
  'pages.profile.changePassword.messages.submitChangePasswordError.description':
    'Tente novamente!',
};

const UpdateAvatarTexts = {
  'pages.profile.updateAvatar.title': 'Atualizar avatar',

  'pages.profile.updateAvatar.selectImageButton':
    'Selecionar uma imagem para usar como seu avatar',
  'pages.profile.updateAvatar.updateAvatarButton': 'Atualizar avatar',

  'pages.profile.updateAvatar.messages.unblockPageConfirm.title':
    'Deseja realmente cancelar a atualização do avatar?',
};

export default {
  ...ProfileTexts,
  ...ChangePasswordTexts,
  ...UpdateAvatarTexts,
};
