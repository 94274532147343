import styled from 'styled-components';

interface IScoreText {
  $notScore?: boolean;
}

export const Container = styled.div`
  width: 100%;
  max-width: ${props => props.theme.containerWidth};
  margin: 0 auto;
  padding: 2.5rem 3.5rem;

  @media only screen and (max-width: ${props => props.theme.breakpoints.xl}) {
    padding: 2rem 2rem 3.5rem;
  }

  @media only screen and (max-width: ${props => props.theme.breakpoints.sm}) {
    padding: 1rem 1rem 3.5rem;
  }

  > ul {
    margin: 2rem 0;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1rem;

    @media only screen and (max-width: ${props => props.theme.breakpoints.xl}) {
      grid-template-columns: 1fr 1fr;
    }

    @media only screen and (max-width: ${props => props.theme.breakpoints.md}) {
      grid-template-columns: 1fr;
    }
  }

  > button {
    width: 100%;
    max-width: 30rem;
    margin: 0 auto;
    display: block;
  }
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 2rem;
`;

export const CardInfoContainer = styled.div`
  width: 100%;
  display: flex;
  margin-top: 1rem;

  > div:nth-child(1) {
    flex: 1;
  }

  > div:nth-child(2) {
    margin-left: 1rem;
  }
`;

export const UserInfoContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;

  img {
    width: 40px;
    height: 40px;
    object-fit: cover;
    border-radius: 50%;
    margin-right: 8px;
  }

  div {
    display: flex;
    flex-direction: column;

    small {
      color: ${props => props.theme.colors.light['light-2']};
    }
  }
`;

export const CardDetailsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  small {
    color: ${props => props.theme.colors.light['light-2']};
  }
`;

export const ScoreText = styled.p<IScoreText>`
  color: ${props =>
    !props.$notScore
      ? props.theme.colors.primary.primary0
      : props.theme.colors.danger};
`;
