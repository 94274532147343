import { createIntl, createIntlCache } from 'react-intl';

import English, { IEnglishKeys } from '../lang/en';
import BrazilianPortuguese from '../lang/pt-BR';

export interface IMessage {
  [key: string]: string;
}

export type FormatXMLElementFn<T, R = string | T | Array<string | T>> = (
  parts: Array<string | T>,
) => R;

export type TValues = Record<
  string,
  | string
  | number
  | boolean
  | Date
  | FormatXMLElementFn<string, string>
  | null
  | undefined
>;

// function handleChangeSelectedLocale(locale: string): void {
//   if (locale === 'pt-BR') {
//     messages = BrazilianPortuguese;
//   } else {
//     messages = English;
//   }
// }

// const savedLocale = localStorage.getItem('@LanceFutebol:Locale');
// if (savedLocale) {
//   selectedLocale = savedLocale;
// } else {
//   selectedLocale = navigator.language;
// }

// handleChangeSelectedLocale(selectedLocale);

// window.addEventListener('changeSelectedLocale', ((
//   event: CustomEvent<IChangeSelectedLocaleEventData>,
// ) => {
//   selectedLocale = event.detail.locale;
//   handleChangeSelectedLocale(selectedLocale);
// }) as EventListener);

// const intl = createIntl({
//   locale: selectedLocale,
//   messages,
// });

export function getMessagesByLocale(locale: string): IMessage {
  if (locale === 'pt-BR') {
    return BrazilianPortuguese;
  }
  return English;
}

export function getTranslatedText(id: IEnglishKeys, values?: TValues): string {
  const messages = getMessagesByLocale(window._selectedLocale);

  const cache = createIntlCache();
  const intl = createIntl(
    {
      locale: window._selectedLocale,
      messages,
    },
    cache,
  );

  const defaultMessage = English[id];

  return intl.formatMessage(
    {
      id,
      defaultMessage,
    },
    values,
  );
}
