import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  ChangeEvent,
} from 'react';
import { Link } from 'react-router-dom';
import { Input, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import * as _ from 'lodash';
import * as dateFns from 'date-fns';
import Countdown from 'react-countdown';

import { UploadRequests } from '@/services/api/requests/Upload';
import CustomAntButton from '@/components/CustomAntButton';

import api from '@/services/api';
import { showToast } from '@/hooks/showToast';
import { useIntl } from '@/context/IntlContext';

import {
  Container,
  CardGameItem,
  LoadMoreCardsButton,
  RestTimerContainer,
  FilterItem,
  SearchContainer,
  TabListContainer,
  CardListMessagesContainer,
  CardListWrapper,
} from './styles';
import { ICard } from './types';

const { Search } = Input;
const loadingIcon = <LoadingOutlined style={{ fontSize: 20 }} spin />;
const { parseISO } = dateFns;

const CardList: React.FC = () => {
  const intl = useIntl();

  const [cardsAvailableForPlay, setCardsAvailableForPlay] = useState<ICard[]>(
    [],
  );
  const [cardsAvailableForPlayPagination, setCardsAvailableForPlayPagination] =
    useState({
      limit: 10,
      currentPage: 1,
      totalPages: 0,
    });

  const [runningCards, setRunningCards] = useState<ICard[]>([]);
  const [runningCardsPagination, setRunningCardsPagination] = useState({
    limit: 10,
    currentPage: 1,
    totalPages: 0,
  });

  const [finishedCards, setFinishedCards] = useState<ICard[]>([]);
  const [finishedCardsPagination, setFinishedCardsPagination] = useState({
    limit: 10,
    currentPage: 1,
    totalPages: 0,
  });

  const [loadingCardGames, setLoadingCardGames] = useState(true);
  const [selectedViewer, setSelectedViewer] = useState<
    'availableForPlay' | 'running' | 'finished'
  >('availableForPlay');

  const [foundOnSearchCards, setFoundOnSearchCards] = useState<ICard[]>([]);
  const [foundOnSearchCardsPagination, setFoundOnSearchCardsPagination] =
    useState({
      limit: 10,
      currentPage: 1,
      totalPages: 0,
    });

  const [searchValue, setSearchValue] = useState('');
  const [searching, setSearching] = useState(false);
  const [loadingSearch, setLoadingSearch] = useState(false);

  const getCardsAvailableForPlay = useCallback(
    async (page = 1): Promise<void> => {
      setLoadingCardGames(true);
      try {
        const { data } = await api.get<{
          docs: ICard[];
          page: number;
          pages: number;
        }>('/api/athlete-card', {
          params: {
            cardType: 'new',
            page,
            limit: cardsAvailableForPlayPagination.limit,
          },
        });

        if (page === 1) {
          setCardsAvailableForPlay(data.docs);
        } else {
          setCardsAvailableForPlay(oldState => [...oldState, ...data.docs]);
        }
        setCardsAvailableForPlayPagination(oldState => ({
          ...oldState,
          currentPage: data.page,
          totalPages: data.pages,
        }));
      } catch (error) {
        showToast({
          type: 'error',
          title: intl.getTranslatedText(
            'pages.athletesOfTheWeek.messages.getCards.title',
          ),
          description: intl.getTranslatedText(
            'common.errors.unexpectedError.description',
          ),
        });
      }
      setLoadingCardGames(false);
    },
    [cardsAvailableForPlayPagination.limit, intl],
  );

  const getRunningCards = useCallback(
    async (page = 1): Promise<void> => {
      setLoadingCardGames(true);
      try {
        const { data } = await api.get<{
          docs: ICard[];
          page: number;
          pages: number;
        }>('/api/athlete-card', {
          params: {
            cardType: 'running',
            page,
            limit: runningCardsPagination.limit,
          },
        });

        if (page === 1) {
          setRunningCards(data.docs);
        } else {
          setRunningCards(oldState => [...oldState, ...data.docs]);
        }
        setRunningCardsPagination(oldState => ({
          ...oldState,
          currentPage: data.page,
          totalPages: data.pages,
        }));
      } catch (error) {
        showToast({
          type: 'error',
          title: intl.getTranslatedText(
            'pages.athletesOfTheWeek.messages.getCards.title',
          ),
          description: intl.getTranslatedText(
            'common.errors.unexpectedError.description',
          ),
        });
      }
      setLoadingCardGames(false);
    },
    [intl, runningCardsPagination.limit],
  );

  const getFinishedCards = useCallback(
    async (page = 1): Promise<void> => {
      setLoadingCardGames(true);
      try {
        const { data } = await api.get<{
          docs: ICard[];
          page: number;
          pages: number;
        }>('/api/athlete-card', {
          params: {
            cardType: 'finished',
            page,
            limit: finishedCardsPagination.limit,
          },
        });

        if (page === 1) {
          setFinishedCards(data.docs);
        } else {
          setFinishedCards(oldState => [...oldState, ...data.docs]);
        }
        setFinishedCardsPagination(oldState => ({
          ...oldState,
          currentPage: data.page,
          totalPages: data.pages,
        }));
      } catch (error) {
        showToast({
          type: 'error',
          title: intl.getTranslatedText(
            'pages.athletesOfTheWeek.messages.getCards.title',
          ),
          description: intl.getTranslatedText(
            'common.errors.unexpectedError.description',
          ),
        });
      }
      setLoadingCardGames(false);
    },
    [finishedCardsPagination.limit, intl],
  );

  useEffect(() => {
    if (
      selectedViewer === 'availableForPlay' &&
      cardsAvailableForPlay.length === 0
    ) {
      getCardsAvailableForPlay();
    } else if (selectedViewer === 'running' && runningCards.length === 0) {
      getRunningCards();
    } else if (selectedViewer === 'finished' && finishedCards.length === 0) {
      getFinishedCards();
    }
  }, [
    cardsAvailableForPlay.length,
    finishedCards.length,
    getCardsAvailableForPlay,
    getFinishedCards,
    getRunningCards,
    runningCards.length,
    selectedViewer,
  ]);

  const handleGetCardGames = useCallback(
    async (search: string, page = 1): Promise<void> => {
      setLoadingSearch(true);
      try {
        const { data } = await api.get<{
          docs: ICard[];
          page: number;
          pages: number;
        }>('/api/athlete-card', {
          params: {
            search,
            page,
            limit: foundOnSearchCardsPagination.limit,
          },
        });

        if (page === 1) {
          setFoundOnSearchCards(data.docs);
        } else {
          setFoundOnSearchCards(oldState => [...oldState, ...data.docs]);
        }
        setFoundOnSearchCardsPagination(oldState => ({
          ...oldState,
          currentPage: data.page,
          totalPages: data.pages,
        }));
      } catch (error) {
        showToast({
          type: 'error',
          title: intl.getTranslatedText(
            'pages.athletesOfTheWeek.messages.getCards.title',
          ),
          description: intl.getTranslatedText(
            'common.errors.unexpectedError.description',
          ),
        });
      }
      setLoadingSearch(false);
    },
    [foundOnSearchCardsPagination.limit, intl],
  );

  const cardSearchDebounced = useMemo(() => {
    return _.debounce(handleGetCardGames, 500);
  }, [handleGetCardGames]);

  const handleSearchCardGame = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      cardSearchDebounced.cancel();

      if (!searchValue) {
        setSearching(true);
      } else if (!e.target.value.length) {
        setSearching(false);
      }
      setSearchValue(e.target.value);

      if (e.target.value.length > 3) {
        setFoundOnSearchCardsPagination(oldState => ({
          ...oldState,
          currentPage: 1,
          totalPages: 0,
        }));
        setLoadingSearch(true);
        cardSearchDebounced(e.target.value);
      } else {
        setLoadingSearch(false);
        setFoundOnSearchCards([]);
        setFoundOnSearchCardsPagination(oldState => ({
          ...oldState,
          currentPage: 1,
          totalPages: 0,
        }));
      }
    },
    [searchValue, cardSearchDebounced],
  );

  const cardGamesViewer = useMemo(() => {
    const viewerPaginationOptionsData = {
      availableForPlay: cardsAvailableForPlayPagination,
      running: runningCardsPagination,
      finished: finishedCardsPagination,
    };

    if (
      loadingCardGames &&
      viewerPaginationOptionsData[selectedViewer].totalPages === 0
    ) {
      return (
        <CardListMessagesContainer>
          <div>
            <Spin style={{ lineHeight: 0 }} indicator={loadingIcon} />
            <p>
              {intl.getTranslatedText(
                'pages.athletesOfTheWeek.messages.loadingCards',
              )}
            </p>
          </div>
        </CardListMessagesContainer>
      );
    }

    const viewerOptionsData = {
      availableForPlay: cardsAvailableForPlay,
      running: runningCards,
      finished: finishedCards,
    };

    const loadMoreOptionsData = {
      availableForPlay: getCardsAvailableForPlay,
      running: getRunningCards,
      finished: getFinishedCards,
    };

    if (viewerOptionsData[selectedViewer].length > 0) {
      return (
        <CardListWrapper>
          {viewerOptionsData[selectedViewer]?.map(cardGame => (
            <CardGameItem
              $bg={
                cardGame.banner &&
                UploadRequests.getFileUrl(cardGame.banner?._id)
              }
              to={`cards/${cardGame._id}`}
              key={cardGame._id}
            >
              <div>
                {selectedViewer === 'availableForPlay' && (
                  <Countdown
                    date={parseISO(cardGame.startDate)}
                    renderer={({
                      days,
                      hours,
                      minutes,
                      seconds,
                      completed,
                    }) => {
                      if (completed) {
                        return <></>;
                      }

                      return (
                        <RestTimerContainer>
                          <div>
                            <h6>
                              {days.toLocaleString(undefined, {
                                minimumIntegerDigits: 2,
                                useGrouping: false,
                              })}
                            </h6>
                            <small>
                              {intl.getTranslatedText('common.countDown.days')}
                            </small>
                          </div>
                          <div>
                            <h6>
                              {hours.toLocaleString(undefined, {
                                minimumIntegerDigits: 2,
                                useGrouping: false,
                              })}
                            </h6>
                            <small>
                              {intl.getTranslatedText('common.countDown.hours')}
                            </small>
                          </div>
                          <div>
                            <h6>
                              {minutes.toLocaleString(undefined, {
                                minimumIntegerDigits: 2,
                                useGrouping: false,
                              })}
                            </h6>
                            <small>
                              {intl.getTranslatedText(
                                'common.countDown.minutes',
                              )}
                            </small>
                          </div>
                          <div>
                            <h6>
                              {seconds.toLocaleString(undefined, {
                                minimumIntegerDigits: 2,
                                useGrouping: false,
                              })}
                            </h6>
                            <small>
                              {intl.getTranslatedText(
                                'common.countDown.seconds',
                              )}
                            </small>
                          </div>
                        </RestTimerContainer>
                      );
                    }}
                  />
                )}
                <h6>{cardGame.name}</h6>
              </div>
            </CardGameItem>
          ))}
          {viewerPaginationOptionsData[selectedViewer].currentPage <
            viewerPaginationOptionsData[selectedViewer].totalPages && (
            <LoadMoreCardsButton
              onClick={() => {
                if (loadingCardGames) return;

                loadMoreOptionsData[selectedViewer](
                  viewerPaginationOptionsData[selectedViewer].currentPage + 1,
                );
              }}
              disabled={loadingCardGames}
            >
              <h6>
                {!loadingCardGames
                  ? intl.getTranslatedText('common.buttons.viewMore')
                  : intl.getTranslatedText('common.messages.defaultLoading')}
              </h6>
            </LoadMoreCardsButton>
          )}
        </CardListWrapper>
      );
    }

    const viewerDescription = {
      availableForPlay: intl.getTranslatedText(
        'pages.athletesOfTheWeek.viewersDescriptions.availableForPlay',
      ),
      running: intl.getTranslatedText(
        'pages.athletesOfTheWeek.viewersDescriptions.running',
      ),
      finished: intl.getTranslatedText(
        'pages.athletesOfTheWeek.viewersDescriptions.finished',
      ),
    };

    return (
      <CardListMessagesContainer>
        <h6>
          {intl.getTranslatedTextWithHTML(
            'pages.athletesOfTheWeek.messages.noCards',
            {
              viewerDescription: viewerDescription[selectedViewer],
            },
          )}
        </h6>
      </CardListMessagesContainer>
    );
  }, [
    cardsAvailableForPlay,
    cardsAvailableForPlayPagination,
    finishedCards,
    finishedCardsPagination,
    getCardsAvailableForPlay,
    getFinishedCards,
    getRunningCards,
    intl,
    loadingCardGames,
    runningCards,
    runningCardsPagination,
    selectedViewer,
  ]);

  const searchResultsViewer = useMemo(() => {
    if (searchValue.length <= 3) {
      return (
        <CardListMessagesContainer>
          <h6>{intl.getTranslatedText('common.messages.minCharToSearch')}</h6>
        </CardListMessagesContainer>
      );
    }

    if (loadingSearch && foundOnSearchCardsPagination.totalPages === 1) {
      return (
        <CardListMessagesContainer>
          <div>
            <Spin style={{ lineHeight: 0 }} indicator={loadingIcon} />
            <p>{intl.getTranslatedText('common.messages.defaultLoading')}</p>
          </div>
        </CardListMessagesContainer>
      );
    }

    if (foundOnSearchCards.length > 0) {
      return (
        <CardListWrapper>
          {foundOnSearchCards?.map(cardGame => (
            <CardGameItem
              $bg={
                cardGame.banner &&
                UploadRequests.getFileUrl(cardGame.banner?._id)
              }
              to={`cards/${cardGame._id}`}
              key={cardGame._id}
            >
              <div>
                <h6>{cardGame.name}</h6>
              </div>
            </CardGameItem>
          ))}
          {foundOnSearchCardsPagination.currentPage <
            foundOnSearchCardsPagination.totalPages && (
            <LoadMoreCardsButton
              onClick={() => {
                if (loadingSearch) return;

                handleGetCardGames(
                  searchValue,
                  foundOnSearchCardsPagination.currentPage + 1,
                );
              }}
              disabled={loadingSearch}
            >
              <h6>
                {!loadingSearch
                  ? intl.getTranslatedText('common.buttons.viewMore')
                  : intl.getTranslatedText('common.messages.defaultLoading')}
              </h6>
            </LoadMoreCardsButton>
          )}
        </CardListWrapper>
      );
    }

    return (
      <CardListMessagesContainer>
        <h6>
          {intl.getTranslatedTextWithHTML(
            'pages.athletesOfTheWeek.messages.cardNotFound',
          )}
        </h6>
      </CardListMessagesContainer>
    );
  }, [
    foundOnSearchCards,
    foundOnSearchCardsPagination.currentPage,
    foundOnSearchCardsPagination.totalPages,
    handleGetCardGames,
    intl,
    loadingSearch,
    searchValue,
  ]);

  return (
    <Container>
      <SearchContainer>
        <Search
          placeholder={intl.getTranslatedText(
            'pages.athletesOfTheWeek.searchCardsInput.placeholder',
          )}
          onChange={handleSearchCardGame}
          value={searchValue}
        />
        <Link to="cards/card_games_history">
          <CustomAntButton type="default">
            {intl.getTranslatedText('pages.athletesOfTheWeek.myGamesButton')}
          </CustomAntButton>
        </Link>
      </SearchContainer>
      {!searching ? (
        <>
          <TabListContainer>
            <FilterItem
              disabled={loadingCardGames}
              onClick={() => setSelectedViewer('finished')}
              $selected={selectedViewer === 'finished'}
            >
              <p>
                {intl.getTranslatedText(
                  'pages.athletesOfTheWeek.views.finished',
                )}
              </p>
            </FilterItem>
            <FilterItem
              disabled={loadingCardGames}
              onClick={() => setSelectedViewer('availableForPlay')}
              $selected={selectedViewer === 'availableForPlay'}
            >
              <p>
                {intl.getTranslatedText(
                  'pages.athletesOfTheWeek.views.availableForPlay',
                )}
              </p>
            </FilterItem>
            <FilterItem
              disabled={loadingCardGames}
              onClick={() => setSelectedViewer('running')}
              $selected={selectedViewer === 'running'}
            >
              <p>
                {intl.getTranslatedText(
                  'pages.athletesOfTheWeek.views.running',
                )}
              </p>
            </FilterItem>
          </TabListContainer>
          {cardGamesViewer}
        </>
      ) : (
        <>
          <div />
          {searchResultsViewer}
        </>
      )}
    </Container>
  );
};

export default CardList;
