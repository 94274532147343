import styled, { css } from 'styled-components';
import { darken, rgba, shade } from 'polished';
import { Link } from 'react-router-dom';

interface ITop3ItemProps {
  $disabled: boolean;
}

interface IScoreBorder {
  $notScore?: boolean;
  $notGame?: boolean;
}

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  > div {
    align-self: center;
    width: 100%;
    max-width: 40.5rem;
    margin-bottom: 1rem;

    > small {
      margin-bottom: 1rem;
      text-align: center;
      color: ${props => props.theme.colors.light['light-2']};
    }

    > div {
      width: 100%;
    }
  }

  /* > div:nth-child(2) {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-top: 3.5rem;

    > small {
      width: 100%;
      text-align: center;
      color: ${props => props.theme.colors.light['light-2']};
    }

    > div {
      width: 100%;
      display: flex;
      justify-content: center;

      p {
        margin-left: 1rem;
        color: ${props => props.theme.colors.light['light-2']};
      }
    }
    > h6 {
      text-align: center;

      span {
        color: ${props => props.theme.colors.primary.primary0};
      }
    }
  } */

  > section {
    align-self: center;
    display: flex;
    margin-top: 4rem;
    z-index: 5;
  }

  ul {
    width: 100%;
    max-width: 40.5rem;
    align-self: center;
    z-index: 10;
  }
`;

export const Top3Item = styled(Link)<ITop3ItemProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  max-width: 6.5rem;

  ${props =>
    props.$disabled &&
    css`
      cursor: not-allowed;
    `}

  &:nth-child(2) {
    margin: 0 1rem;
  }

  p:first-child {
    color: ${props => props.theme.colors.light['light-2']};
    margin-bottom: 0.25rem;
  }

  svg {
    color: #f8c700;
    margin-bottom: 0.5rem;
  }

  img {
    width: 5.3125rem;
    height: 5.3125rem;
    object-fit: cover;
    border-radius: 50%;
    border: 3px solid ${props => props.theme.colors.primary.primary0};
    margin-bottom: 0.5rem;
  }

  p {
    text-align: center;
  }

  small {
    text-align: center;
    color: ${props => props.theme.colors.light['light-2']};
  }

  small:last-child {
    color: ${props => props.theme.colors.primary.primary0};
    margin-top: 0.5rem;
  }

  &:nth-child(2) {
    img {
      width: 6.25rem;
      height: 6.25rem;
      box-shadow: 0 0 20px
        ${props => rgba(props.theme.colors.primary.primary0, 0.5)};
    }

    transform: translateY(-3.75rem);
  }
`;

export const RankingPlayerItem = styled(Link)`
  border: 0;
  background: ${props => props.theme.colors.dark.dark0};
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0.5rem 0;
  transition: 0.2s background;

  &:hover {
    background: ${props => darken(0.05, props.theme.colors.dark.dark0)};
  }

  & + button {
    border-top: 1px solid ${props => props.theme.colors.dark['dark+1']};
  }

  > p {
    color: ${props => props.theme.colors.light['light-2']};
    margin-right: 1rem;
  }

  img {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 0.5rem;
  }

  div {
    text-align: start;
    small {
      color: ${props => props.theme.colors.light['light-2']};
    }
  }

  > div:last-child {
    display: flex;
    align-items: center;
    margin-left: auto;

    small:nth-child(2) {
      margin-left: 1rem;
    }
  }

  @media only screen and (min-width: ${props => props.theme.breakpoints.xl}) {
    padding: 0.5rem;
  }
`;

export const SearchPlayerItem = styled.div`
  border: 0;
  /* background: ${props => props.theme.colors.dark.dark0}; */
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0.5rem 0;
  transition: 0.2s background;

  /* &:hover {
    background: ${props => darken(0.05, props.theme.colors.dark.dark0)};
  } */

  & + button {
    border-top: 1px solid ${props => props.theme.colors.dark['dark+1']};
  }

  > p {
    color: ${props => props.theme.colors.light['light-2']};
    margin-right: 1rem;
  }

  img {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 0.5rem;
  }

  div {
    text-align: start;
    small {
      color: ${props => props.theme.colors.light['light-2']};
    }
  }

  @media only screen and (min-width: ${props => props.theme.breakpoints.xl}) {
    padding: 0.5rem;
  }
`;

export const ViewMoreButton = styled.button`
  border: 0;
  background: ${props => props.theme.colors.dark.dark0};
  width: 100%;
  height: 3.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.2s background;

  &:hover {
    background: ${props => darken(0.05, props.theme.colors.dark.dark0)};
  }
  border-top: 1px solid ${props => props.theme.colors.dark['dark+1']};
`;

export const LoadingAndNotFoundContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  > small {
    width: 100%;
    text-align: center;
    color: ${props => props.theme.colors.light['light-2']};
  }

  > div {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 3.5rem;

    p {
      margin-left: 1rem;
      color: ${props => props.theme.colors.light['light-2']};
    }

    h6 {
      text-align: center;

      span {
        color: ${props => props.theme.colors.primary.primary0};
      }
    }
  }
`;

export const UserIsMeIndicator = styled.small`
  padding: 0.125rem 0.5rem;
  background: ${props => props.theme.colors.dark['dark+2']};
  border-radius: 4px;
  margin-left: auto;
`;

export const SearchAutocompleteContentContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0.5rem;

  > small {
    width: 100%;
    text-align: center;
    color: ${props => props.theme.colors.light['light-2']};
  }

  > div {
    width: 100%;
    display: flex;
    justify-content: center;

    p {
      margin-left: 1rem;
      color: ${props => props.theme.colors.light['light-2']};
    }
  }
  > p,
  > h6 {
    text-align: center;

    span {
      color: ${props => props.theme.colors.primary.primary0};
    }
  }
`;

export const SelectedOnSearchUser = styled.div`
  width: 100%;
  padding: 1rem;
  border-radius: 8px;
  background: ${props => props.theme.colors.dark['dark+1']};

  > div:nth-child(1) {
    width: 100%;
    display: flex;
    align-items: center;
    padding-bottom: 0.5rem;
    border-bottom: 1px solid ${props => props.theme.colors.dark['dark+4']};

    > small {
      align-self: flex-start;
    }

    img {
      width: 2.5rem;
      height: 2.5rem;
      border-radius: 50%;
      object-fit: cover;
      margin-right: 0.5rem;
    }

    div {
      display: flex;
      flex-direction: column;

      small {
        color: ${props => props.theme.colors.light['light-2']};
      }
    }
  }

  > div:nth-child(2) {
    padding: 1rem 0;
    width: 100%;

    p {
      width: 100%;
      text-align: center;
    }
  }

  ul {
    margin-bottom: 0;
    a {
      padding: 0.5rem 0;
      display: flex;
      flex-direction: column;
      transition: 0.2s background;

      &:hover {
        background: ${props => props.theme.colors.dark['dark+2']};
      }

      p {
        margin-bottom: 0.5rem;
      }

      small {
        color: ${props => props.theme.colors.light['light-2']};
      }

      & + a {
        border-top: 1px solid ${props => props.theme.colors.dark['dark+2']};
      }
    }

    > button {
      border: 0;
      background: transparent;
      width: 100%;
      border-top: 1px solid ${props => props.theme.colors.dark['dark+2']};
      padding: 0.5rem 0;
      transition: 0.2s background;

      &:hover {
        background: ${props => props.theme.colors.dark['dark+2']};
      }

      p {
        width: 100%;
        text-align: center;
      }
    }
  }
`;

export const MyGamesContainer = styled.div<IScoreBorder>`
  width: 100%;
  margin-bottom: 0.5rem;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${props => props.theme.colors.dark['dark+1']};
  border-radius: 8px;
  cursor: pointer;
  box-shadow: 0 1px 7px rgba(0, 0, 0, 0.25);
  flex-wrap: wrap;
  gap: 0.5rem;
  transition: 0.2s background-color;

  &:hover {
    background-color: ${props => shade(0.1, props.theme.colors.dark['dark+1'])};
  }

  border: 1px solid
    ${props =>
      props.$notScore
        ? props.theme.colors.primary.primary0
        : props.theme.colors.danger};

  ${({ $notGame }) =>
    $notGame &&
    css`
      border: 1px solid ${props => props.theme.colors.dark['dark+3']};
      cursor: initial;

      &:hover {
        background-color: ${props => props.theme.colors.dark['dark+1']};
      }
    `}

  strong {
    color: ${props => props.theme.colors.light['light-2']};
  }
`;
