import styled, { css } from 'styled-components';

interface ContainerProps {
  $hasDescription: boolean;
}

export const Container = styled.div<ContainerProps>`
  ${props =>
    props.$hasDescription &&
    css`
      .Toastify__toast-body {
        margin: 0 0 4px;
      }
    `}

  display: flex;

  svg {
    margin: 0 8px 1px 0;
  }

  > div {
    width: 100%;
    flex: 1;

    p {
      font-size: 14px;
      opacity: 0.8;
      line-height: 20px;
    }
  }
`;
