import React, {
  useRef,
  MouseEvent as ReactMouseEvent,
  TouchEvent as ReactTouchEvent,
} from 'react';

import { Container, BarProgressKnob } from './styles';

interface IMessageAudioBar {
  duration: number;
  curTime: number;
  onTimeUpdate(time: number): void;
}

const MessageAudioBar: React.FC<IMessageAudioBar> = ({
  duration,
  curTime,
  onTimeUpdate,
}) => {
  const curPercentage = (curTime / duration) * 100;

  const barRef = useRef<HTMLDivElement>(null);

  function calcClickedTime(pageX: number): number {
    if (!barRef.current) return 0;

    const clickPositionInPage = pageX;
    const bar = barRef.current;
    const barStart = bar.getBoundingClientRect().left + window.scrollX;
    const barWidth = bar.offsetWidth;
    const clickPositionInBar = clickPositionInPage - barStart;
    const timePerPixel = duration / barWidth;

    return timePerPixel * clickPositionInBar;
  }

  function handleTimeDrag(e: ReactMouseEvent<HTMLDivElement>): void {
    onTimeUpdate(calcClickedTime(e.pageX));

    function updateTimeOnMove(eMove: MouseEvent): void {
      onTimeUpdate(calcClickedTime(eMove.pageX));
    }

    document.addEventListener('mousemove', updateTimeOnMove);

    document.addEventListener('mouseup', () => {
      document.removeEventListener('mousemove', updateTimeOnMove);
    });
  }

  function handleTouchTimeDrag(e: ReactTouchEvent<HTMLDivElement>): void {
    onTimeUpdate(calcClickedTime(e.touches[0].pageX));

    function updateTimeOnTouchMove(eTouchMove: TouchEvent): void {
      const touch = eTouchMove.changedTouches[0];

      onTimeUpdate(calcClickedTime(touch.pageX));
    }

    document.addEventListener('touchmove', updateTimeOnTouchMove);

    document.addEventListener('touchend', () => {
      document.removeEventListener('touchmove', updateTimeOnTouchMove);
    });
  }

  return (
    <Container
      ref={barRef}
      $curPercentage={curPercentage}
      onMouseDown={e => handleTimeDrag(e)}
      onTouchStart={e => handleTouchTimeDrag(e)}
    >
      <BarProgressKnob $curPercentage={curPercentage} />
    </Container>
  );
};

export default MessageAudioBar;
