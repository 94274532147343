import styled, { css } from 'styled-components';

interface IContainerProps {
  $type: 'primary' | 'secondary';
}

export const Container = styled.span<IContainerProps>`
  width: 0.875rem;
  height: 0.875rem;
  border-radius: 50%;
  color: ${props => props.theme.colors.dark.dark0};
  margin-left: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${props => props.theme.typograph.sizes.xs}px;
  font-weight: ${props => props.theme.typograph.weights.bold};
  line-height: 1;
  cursor: default;

  ${props =>
    props.$type === 'primary' &&
    css`
      background: ${props.theme.colors.primary['primary+1']};
    `}

  ${props =>
    props.$type === 'secondary' &&
    css`
      background: ${props.theme.colors.secondary.secondary0};
    `}
`;
