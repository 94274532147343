const forgotPasswordTexts = {
  'pages.forgotPassword.title': 'Reset Your Password',
  /* ------------ Links ------------ */
  'pages.forgotPassword.links.signInLink': 'Back to Sign In',

  /* ------------ Form ------------ */
  /* Email */
  'pages.forgotPassword.form.email.label': 'Email',
  'pages.forgotPassword.form.email.placeholder': 'Enter Your Email',
  'pages.forgotPassword.form.email.validation.required': 'Email is Required.',
  'pages.forgotPassword.form.email.validation.invalid':
    'Please Enter a Valid Email.',

  /* Submit */
  'pages.forgotPassword.form.submitButton': 'Send Email',

  /* ------------ Messages ------------ */
  'pages.forgotPassword.messages.forgotPasswordSubmitSuccess.title':
    'Email Sent!',
  'pages.forgotPassword.messages.forgotPasswordSubmitSuccess.description':
    'Check Your Email to Recover Your Password',
  'pages.forgotPassword.messages.forgotPasswordSubmitError.title':
    'Password Recovery Failed',
  'pages.forgotPassword.messages.forgotPasswordSubmitError.description':
    'An Unexpected Error Occurred While Recovering Your Password. Please Try Again!',
  'pages.forgotPassword.messages.forgotPasswordValidationFailure.title':
    'Invalid Data',
  'pages.forgotPassword.messages.forgotPasswordValidationFailure.description':
    'Please Try Again',
};

export default {
  ...forgotPasswordTexts,
};
