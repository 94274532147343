import React, {
  createContext,
  useContext,
  useCallback,
  useState,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import ChatContainer, {
  IChatContainerRefMethods,
} from '../components/ChatContainer';
import { showToast } from '../hooks/showToast';
import { useIntl } from './IntlContext';

import api from '../services/api';
import socket from '../services/socket';

export interface IChatMessage {
  _id: string;
  status: boolean;
  text?: string;
  voiceMsg?: {
    _id: string;
    filename: string;
  };
  _card?: string;
  _game?: string;
  _user: {
    _id: string;
    name: string;
    username: string;
    email: string;
    photo: string;
  };
  createdAt: string;
}

export interface IChatUrls {
  chat: {
    get: string;
    post: string;
    delete: string;
    newMessageSocket: string;
    deleteMessageSocket: string;
  };
  denounce: {
    post: string;
  };
}

export interface ISendNewMessageData {
  text?: string;
  audio?: File | null;
}

export interface IChatData {
  _resourceId: string /* CardGameId, CoachFriendId... */;
  name: string;
  from: string;
  fromCode: 'cardGames' | 'athletesCard' | 'coachFriend' | 'versus';
}

interface ChatContextData {
  setChat(data: IChatData | null): void;
  handleSubmitMessageReport(_messageId: string, report: string): Promise<void>;
  handleSubmitDeleteMessage(_messageId: string): Promise<void>;
  loadingSendMessageReport: boolean;
  currentPlayAudioMessageId: string;
  setCurrentPlayAudioMessageId: React.Dispatch<React.SetStateAction<string>>;
}

const { confirm } = Modal;

const ChatContext = createContext<ChatContextData>({} as ChatContextData);

const ChatProvider: React.FC = ({ children }) => {
  const intl = useIntl();
  const chatContainerRef = useRef<IChatContainerRefMethods | null>(null);

  const [chatData, setChatData] = useState<IChatData | null>(null);
  const [messages, setMessages] = useState<IChatMessage[]>([]);
  const [loadingMessages, setLoadingMessages] = useState(false);
  const [hasMoreMessages, setHasMoreMessages] = useState(false);
  const [currentPlayAudioMessageId, setCurrentPlayAudioMessageId] =
    useState('');

  const [loadingSendMessageReport, setLoadingSendMessageReport] =
    useState(false);

  const chatUrls = useMemo<IChatUrls | null>(() => {
    if (!chatData) {
      return null;
    }

    const urls = {
      cardGames: {
        chat: {
          get: '/api/chat/card-game',
          post: '/api/chat/card-game',
          delete: `/api/chat/card-game`,
          newMessageSocket: `chat-card-game:${chatData._resourceId}`,
          deleteMessageSocket: `delete-chat-card-game:${chatData._resourceId}`,
        },
        denounce: {
          post: '/api/denounce/chat/card-game',
        },
      },
      athletesCard: {
        chat: {
          get: '/api/chat/card-athlete',
          post: '/api/chat/card-athlete',
          delete: `/api/chat/card-athlete`,
          newMessageSocket: `chat-card-athlete:${chatData._resourceId}`,
          deleteMessageSocket: `delete-chat-card-athlete:${chatData._resourceId}`,
        },
        denounce: {
          post: '/api/denounce/chat/card-athlete',
        },
      },
      coachFriend: {
        chat: {
          get: '/api/chat/coach',
          post: '/api/chat/coach',
          delete: `/api/chat/coach`,
          newMessageSocket: `chat-coach:${chatData._resourceId}`,
          deleteMessageSocket: `delete-chat-coach:${chatData._resourceId}`,
        },
        denounce: {
          post: '/api/denounce/chat/coach',
        },
      },
      versus: {
        chat: {
          get: '/api/chat/vs',
          post: '/api/chat/vs',
          delete: `/api/chat/vs`,
          newMessageSocket: `chat-vs:${chatData._resourceId}`,
          deleteMessageSocket: `delete-chat-vs:${chatData._resourceId}`,
        },
        denounce: {
          post: '/api/denounce/chat/vs',
        },
      },
    };

    return urls[chatData.fromCode];
  }, [chatData]);

  const getChatMessages = useCallback(
    async (lastContent = '') => {
      if (!chatData || !chatUrls) return;
      try {
        setLoadingMessages(true);

        const { data } = await api.get<{
          docs: IChatMessage[];
          page: number;
          pages: number;
        }>(chatUrls.chat.get, {
          params: {
            limit: 20,
            lastContent,
            card: chatData._resourceId,
            game: chatData._resourceId,
          },
        });

        const orderedMessages = [...data.docs];
        orderedMessages.reverse();

        if (!lastContent) {
          setMessages(orderedMessages);
        } else {
          setMessages(oldState => [...orderedMessages, ...oldState]);
        }

        if (data.pages > 1) {
          setHasMoreMessages(true);
        } else {
          setHasMoreMessages(false);
        }
      } catch (error) {
        showToast({
          type: 'error',
          title: intl.getTranslatedText('common.errors.unexpectedError.title'),
          description: intl.getTranslatedText(
            'common.errors.unexpectedError.description',
          ),
        });
      }
      setLoadingMessages(false);
    },
    [chatData, chatUrls, intl],
  );

  const handleSubmitNewMessage = useCallback(
    async ({ text, audio }: ISendNewMessageData): Promise<void> => {
      if ((!text && !audio) || !chatUrls || !chatData) return;
      // if () return;

      try {
        showToast({
          type: 'info',
          title: intl.getTranslatedText(
            'contexts.chatContext.messages.submitNewMessageSuccess.title',
          ),
        });

        if (text) {
          await api.post(chatUrls.chat.post, {
            card: chatData._resourceId,
            game: chatData._resourceId,
            text,
          });
        } else if (audio) {
          const formData = new FormData();
          formData.append('audio', audio);
          formData.append('card', chatData._resourceId);
          formData.append('game', chatData._resourceId);

          await api.post(chatUrls.chat.post, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          });
        }
      } catch (error) {
        showToast({
          type: 'error',
          title: intl.getTranslatedText(
            'contexts.chatContext.messages.submitNewMessageError.title',
          ),
          description: intl.getTranslatedText(
            'common.errors.unexpectedError.description',
          ),
        });
      }
    },
    [chatData, chatUrls, intl],
  );

  const handleSubmitMessageReport = useCallback(
    async (_messageId: string, report: string): Promise<void> => {
      if (!_messageId || !report || !chatUrls) return;

      try {
        await new Promise(resolve => {
          confirm({
            title: intl.getTranslatedText(
              'contexts.chatContext.messages.messageReportSubmitConfirm.title',
            ),
            icon: <ExclamationCircleOutlined />,
            content: intl.getTranslatedText(
              'common.messages.actionCannotBeUndone',
            ),
            cancelText: intl.getTranslatedText('common.buttons.cancel'),
            okText: intl.getTranslatedText(
              'contexts.chatContext.messages.messageReportSubmitConfirm.confirmButton',
            ),
            onOk() {
              resolve(true);
            },
          });
        });

        const body = {
          _id: _messageId,
          denounce: report,
        };
        setLoadingSendMessageReport(true);
        await api.post(chatUrls.denounce.post, body);
        setLoadingSendMessageReport(false);

        showToast({
          type: 'success',
          title: intl.getTranslatedText(
            'contexts.chatContext.messages.messageReportSubmitSuccess.title',
          ),
          description: intl.getTranslatedText(
            'contexts.chatContext.messages.messageReportSubmitSuccess.description',
          ),
        });
        // history.push(`/athletes_of_the_week/${params.card_game_id}`);
      } catch (error) {
        setLoadingSendMessageReport(false);
        showToast({
          type: 'error',
          title: intl.getTranslatedText('common.errors.unexpectedError.title'),
          description: intl.getTranslatedText(
            'common.errors.unexpectedError.description',
          ),
        });
      }
    },
    [chatUrls, intl],
  );

  const handleSubmitDeleteMessage = useCallback(
    async (_messageId: string): Promise<void> => {
      if (!_messageId || !chatUrls || !chatData) return;

      try {
        await new Promise(resolve => {
          confirm({
            title: intl.getTranslatedText(
              'contexts.chatContext.messages.deleteMessageSubmitConfirm.title',
            ),
            icon: <ExclamationCircleOutlined />,
            content: intl.getTranslatedText(
              'common.messages.actionCannotBeUndone',
            ),
            cancelText: intl.getTranslatedText('common.buttons.cancel'),
            okText: intl.getTranslatedText(
              'contexts.chatContext.messages.deleteMessageSubmitConfirm.confirmButton',
            ),
            okButtonProps: {
              danger: true,
            },
            onOk() {
              resolve(true);
            },
          });
        });

        showToast({
          type: 'info',
          title: intl.getTranslatedText(
            'contexts.chatContext.messages.deleteMessageSubmitSuccess.title',
          ),
        });

        await api.delete(`${chatUrls.chat.delete}/${_messageId}`);
      } catch (error) {
        showToast({
          type: 'error',
          title: intl.getTranslatedText(
            'contexts.chatContext.messages.deleteMessageSubmitError.title',
          ),
          description: intl.getTranslatedText(
            'common.errors.unexpectedError.description',
          ),
        });
      }
    },
    [chatData, chatUrls, intl],
  );

  const setChat = useCallback((data: IChatData | null) => {
    setChatData(data);
    if (!data) {
      setMessages([]);
    }
  }, []);

  useEffect(() => {
    if (!!chatData && !!chatUrls) {
      getChatMessages();
    }
  }, [chatData, chatUrls, getChatMessages]);

  useEffect(() => {
    if (chatUrls) {
      socket.on(chatUrls.chat.newMessageSocket, (data: IChatMessage) => {
        setMessages(oldState => [...oldState, data]);
        if (chatContainerRef.current) {
          chatContainerRef.current.scrollMessagesToBottom();
        }
      });
      socket.on(chatUrls.chat.deleteMessageSocket, (data: string) => {
        setMessages(oldState =>
          oldState.filter(message => message._id !== data),
        );
      });
    }

    return () => {
      if (chatUrls) {
        socket.off(chatUrls.chat.newMessageSocket);
        socket.off(chatUrls.chat.deleteMessageSocket);
      }
    };
  }, [chatUrls]);

  return (
    <ChatContext.Provider
      value={{
        setChat,
        handleSubmitMessageReport,
        handleSubmitDeleteMessage,
        loadingSendMessageReport,
        currentPlayAudioMessageId,
        setCurrentPlayAudioMessageId,
      }}
    >
      {children}
      <ChatContainer
        ref={chatContainerRef}
        data={chatData}
        messages={messages}
        loadingMessages={loadingMessages}
        handleSubmitNewMessage={handleSubmitNewMessage}
        hasMoreMessages={hasMoreMessages}
        handleGetMoreMessages={() => {
          getChatMessages(messages[0]._id);
        }}
      />
    </ChatContext.Provider>
  );
};

function useChat(): ChatContextData {
  const context = useContext(ChatContext);

  if (!context) {
    throw new Error('useChat must be used within a ChatProvider');
  }

  return context;
}

export { ChatProvider, useChat };
