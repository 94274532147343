import React, { createContext, useCallback, useContext } from 'react';
import { useIntl as useReactIntl } from 'react-intl';
import { Locale as TDfnsLocale } from 'date-fns';

import EnglishMessages, { IEnglishKeys } from '../lang/en';
import { useInternationalizationWrapper } from './InternationalizationWrapperContext';
import { TValues } from '../utils/intl';

export interface IntlContextData {
  getTranslatedText(id: IEnglishKeys, values?: TValues): string;
  getTranslatedTextWithHTML(
    id: IEnglishKeys,
    values?: TValues,
  ): string | React.ReactNode;
  getDatefnsLocale(): TDfnsLocale;
  getFormattedDecimalNumber(number: number): string;
}

const IntlContext = createContext<IntlContextData>({} as IntlContextData);

const IntlProvider: React.FC = ({ children }) => {
  const intl = useReactIntl();
  const { dfnsLocale } = useInternationalizationWrapper();

  const getTranslatedText = useCallback(
    (id: IEnglishKeys, values?: TValues): string => {
      const message = EnglishMessages[id];

      return intl.formatMessage(
        {
          id,
          defaultMessage: message,
        },
        values,
      );
    },
    [intl],
  );

  const getTranslatedTextWithHTML = useCallback(
    (id: IEnglishKeys, values?: TValues): string | React.ReactNode => {
      const message = EnglishMessages[id];

      return intl.formatMessage(
        {
          id,
          defaultMessage: message,
        },
        {
          ...values,
          strong: word => <strong>{word}</strong>,
          span: word => <span>{word}</span>,
          b: word => <b>{word}</b>,
        },
      );
    },
    [intl],
  );

  const getDatefnsLocale = useCallback((): TDfnsLocale => {
    return dfnsLocale;
  }, [dfnsLocale]);

  const getFormattedDecimalNumber = useCallback(
    (number: number): string => {
      return intl.formatNumber(number, { style: 'decimal' });
    },
    [intl],
  );

  return (
    <IntlContext.Provider
      value={{
        getTranslatedText,
        getTranslatedTextWithHTML,
        getDatefnsLocale,
        getFormattedDecimalNumber,
      }}
    >
      {children}
    </IntlContext.Provider>
  );
};

function useIntl(): IntlContextData {
  const context = useContext(IntlContext);

  if (!context) {
    throw new Error('useIntl be used within an IntlProvider');
  }

  return context;
}

export { useIntl, IntlProvider };
