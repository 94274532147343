/* eslint-disable jsx-a11y/media-has-caption */
import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo,
} from 'react';
import { Dropdown, Menu, Modal } from 'antd';
import { FiFlag, FiCopy, FiPlayCircle, FiPauseCircle } from 'react-icons/fi';
import * as dateFns from 'date-fns';

import { UploadRequests } from '@/services/api/requests/Upload';
import Radio from '../../../Radio';

import { useChat, IChatMessage } from '../../../../context/ChatContext';

import useAudioPlayer from '../../../../hooks/useAudioPlayer';
import { useIntl } from '../../../../context/IntlContext';

import MessageAudioBar from './MessageAudioBar';

import UserAvatar from '../../../UserAvatar';

import {
  Container,
  UserName,
  HourAndMessageStatusContainer,
  SelectedForReportMessageGroupContainer,
  MessageAudioPlayerContainer,
  PlayOrStopButton,
} from './styles';
import { showToast } from '../../../../hooks/showToast';

interface IMessage {
  messagesContentContainerRef: React.RefObject<HTMLDivElement>;
  firstMessageOfGroup: boolean;
  lastMessageOfGroup: boolean;
  message: IChatMessage;
}

const { parseISO, format } = dateFns;

const Message: React.FC<IMessage> = ({
  messagesContentContainerRef,
  firstMessageOfGroup,
  lastMessageOfGroup,
  message,
}) => {
  const intl = useIntl();

  const audioRef = useRef<HTMLAudioElement>(null);

  const {
    curTime,
    duration,
    playing,
    setPlaying,
    setClickedTime,
    setFinishAudioPlay,
  } = useAudioPlayer({
    audioElement: audioRef.current,
  });

  const {
    handleSubmitMessageReport,
    loadingSendMessageReport,
    currentPlayAudioMessageId,
    setCurrentPlayAudioMessageId,
  } = useChat();

  const [dropdownVisible, setDropdownVisible] = useState('');

  const [showReportModal, setShowReportModal] = useState(false);
  const [selectedReasonForReport, setSelectedReasonForReport] = useState('');

  const handleCloseDropdownWhenPageIsScrolled = useCallback(() => {
    setDropdownVisible('');
  }, []);

  const handleCloseReportModal = useCallback(() => {
    setShowReportModal(false);
    setSelectedReasonForReport('');
    setFinishAudioPlay();
    // setPlaying(false);
  }, [setFinishAudioPlay]);

  useEffect(() => {
    const messagesContentContainer = messagesContentContainerRef?.current;

    if (messagesContentContainer) {
      messagesContentContainer.addEventListener(
        'scroll',
        handleCloseDropdownWhenPageIsScrolled,
      );
    }

    return () => {
      if (messagesContentContainer) {
        messagesContentContainer.removeEventListener(
          'scroll',
          handleCloseDropdownWhenPageIsScrolled,
        );
      }
    };
  }, [handleCloseDropdownWhenPageIsScrolled, messagesContentContainerRef]);

  useEffect(() => {
    if (currentPlayAudioMessageId !== message._id && message.voiceMsg) {
      setFinishAudioPlay();
    }
  }, [
    currentPlayAudioMessageId,
    message._id,
    message.voiceMsg,
    setFinishAudioPlay,
  ]);

  const handleCopyMessageToClipboard = useCallback(
    (messageText: string) => {
      const textArea = document.createElement('textarea');
      textArea.value = messageText;

      // Avoid scrolling to bottom
      textArea.style.top = '0';
      textArea.style.left = '0';
      textArea.style.position = 'fixed';

      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();

      document.execCommand('copy');

      document.body.removeChild(textArea);

      showToast({
        type: 'info',
        title: intl.getTranslatedText(
          'components.chatContainer.chatContainerMessage.messages.copyMessageToClipboardSuccess.title',
        ),
      });
    },
    [intl],
  );

  const messageContentViewer = useMemo(() => {
    if (message.text) {
      return <p>{message.text}</p>;
    }

    if (message.voiceMsg) {
      return (
        <>
          <audio
            preload="none"
            loop={false}
            ref={audioRef}
            style={{ display: 'none' }}
            src={UploadRequests.getFileUrl(message.voiceMsg._id)}
          />
          <MessageAudioPlayerContainer>
            <PlayOrStopButton
              onClick={() => {
                setPlaying(oldState => {
                  if (!oldState) {
                    setCurrentPlayAudioMessageId(message._id);
                  }

                  return !oldState;
                });
              }}
            >
              {!playing ? (
                <FiPlayCircle size={21} />
              ) : (
                <FiPauseCircle size={21} />
              )}
            </PlayOrStopButton>
            <MessageAudioBar
              duration={duration}
              curTime={curTime}
              onTimeUpdate={time => {
                setClickedTime(time);
              }}
            />
          </MessageAudioPlayerContainer>
        </>
      );
    }

    return <></>;
  }, [
    curTime,
    duration,
    message._id,
    message.text,
    message.voiceMsg,
    playing,
    setClickedTime,
    setCurrentPlayAudioMessageId,
    setPlaying,
  ]);

  // return (
  //   <Container key={message._id} $lastMessageOfGroup={lastMessageOfGroup}>
  //     {firstMessageOfGroup && <UserName>{message._user.name}</UserName>}
  //     <p>{message.text}</p>
  //     {/* <audio ref={audioRef} style={{ display: 'none' }}>
  //       <source src="https://file-examples-com.github.io/uploads/2017/11/file_example_MP3_700KB.mp3" />
  //     </audio> */}
  //     {/* <MessageAudioBar
  //       duration={0}
  //       curTime={0}
  //       onTimeUpdate={time => {
  //         setClickedTime(time);
  //       }}
  //     /> */}
  //     <HourAndMessageStatusContainer>
  //       <small>{format(parseISO(message.createdAt), 'HH:mm')}</small>
  //     </HourAndMessageStatusContainer>
  //   </Container>
  // );

  return (
    <>
      <Dropdown
        overlay={
          <Menu>
            {message.text && (
              <>
                <Menu.Item
                  onClick={() => {
                    if (message.text) {
                      handleCopyMessageToClipboard(message.text);
                      setDropdownVisible('');
                    }
                  }}
                  // danger={!showArchivedGames}
                >
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <>
                      <FiCopy style={{ marginRight: 8 }} size={19} />
                      {intl.getTranslatedText(
                        'components.chatContainer.chatContainerMessage.copyMessageButton',
                      )}
                    </>
                  </div>
                </Menu.Item>
                <Menu.Divider />
              </>
            )}
            <Menu.Item
              onClick={() => {
                setShowReportModal(true);
                setDropdownVisible('');
              }}
              // danger={!showArchivedGames}
            >
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <>
                  <FiFlag style={{ marginRight: 8 }} size={19} />
                  {intl.getTranslatedText(
                    'components.chatContainer.chatContainerMessage.reportMessageButton',
                  )}
                </>
              </div>
            </Menu.Item>
          </Menu>
        }
        placement="bottomLeft"
        arrow
        trigger={['contextMenu']}
        visible={dropdownVisible === message._id}
        onVisibleChange={visible => {
          if (visible) {
            setDropdownVisible(message._id);
          } else {
            setDropdownVisible('');
          }
        }}
      >
        <Container key={message._id} $lastMessageOfGroup={lastMessageOfGroup}>
          {firstMessageOfGroup && <UserName>{message._user.name}</UserName>}
          {messageContentViewer}
          <HourAndMessageStatusContainer>
            {/* {message.voiceMsg && (
              <>
                {duration > 0 ? (
                  <MessageAudioDurationAndCurrentTime>
                    {format(
                      addSeconds(new Date(0), parseInt(duration.toString())),
                      'mm:ss',
                    )}
                  </MessageAudioDurationAndCurrentTime>
                ) : (
                  <MessageAudioDurationAndCurrentTime>
                    --:--
                  </MessageAudioDurationAndCurrentTime>
                )}
              </>
            )} */}
            <small>
              {format(parseISO(message.createdAt), 'p', {
                locale: intl.getDatefnsLocale(),
              })}
            </small>
          </HourAndMessageStatusContainer>
        </Container>
      </Dropdown>
      <Modal
        title={intl.getTranslatedText(
          'components.chatContainer.chatContainerMessage.reportMessageModal.title',
        )}
        visible={showReportModal}
        onCancel={handleCloseReportModal}
        okText={intl.getTranslatedText(
          'components.chatContainer.chatContainerMessage.reportMessageModal.sendReportButton',
        )}
        cancelText={intl.getTranslatedText('common.buttons.cancel')}
        okButtonProps={{
          disabled: !selectedReasonForReport,
          loading: loadingSendMessageReport,
        }}
        onOk={async () => {
          if (!loadingSendMessageReport) {
            await handleSubmitMessageReport(
              message._id,
              selectedReasonForReport,
            );
            handleCloseReportModal();
          }
        }}
        // footer={null}
      >
        <SelectedForReportMessageGroupContainer>
          {/* <img
            src={
              message._user.photo
                ? Env.IMAGE_SERVER_URL + message._user.photo
                : DefaultAvatar
            }
            alt={message._user.name}
          /> */}
          <UserAvatar
            // src="https://i.pinimg.com/originals/80/03/82/800382c6338f6e834adca1b2cc66503e.jpg"
            photoId={message._user.photo}
            size={32}
            style={{
              marginRight: 8,
            }}
          />
          <div>
            {/* <Container key={message._id} $lastMessageOfGroup>
              <UserName>{message._user.name}</UserName>
              <p>{message.text}</p>
              <HourAndMessageStatusContainer>
                <small>{format(parseISO(message.createdAt), 'HH:mm')}</small>
              </HourAndMessageStatusContainer>
            </Container> */}
            <Container
              key={message._id}
              $lastMessageOfGroup={lastMessageOfGroup}
            >
              <UserName>{message._user.name}</UserName>
              {messageContentViewer}
              <HourAndMessageStatusContainer>
                <small>
                  {format(parseISO(message.createdAt), 'p', {
                    locale: intl.getDatefnsLocale(),
                  })}
                </small>
              </HourAndMessageStatusContainer>
            </Container>
          </div>
        </SelectedForReportMessageGroupContainer>
        <Radio
          items={[
            {
              value: intl.getTranslatedText(
                'components.chatContainer.chatContainerMessage.reportMessageModal.reportReasonRadio.item1',
              ),
              label: intl.getTranslatedText(
                'components.chatContainer.chatContainerMessage.reportMessageModal.reportReasonRadio.item1',
              ),
            },
            {
              value: intl.getTranslatedText(
                'components.chatContainer.chatContainerMessage.reportMessageModal.reportReasonRadio.item2',
              ),
              label: intl.getTranslatedText(
                'components.chatContainer.chatContainerMessage.reportMessageModal.reportReasonRadio.item2',
              ),
            },
            {
              value: intl.getTranslatedText(
                'components.chatContainer.chatContainerMessage.reportMessageModal.reportReasonRadio.item3',
              ),
              label: intl.getTranslatedText(
                'components.chatContainer.chatContainerMessage.reportMessageModal.reportReasonRadio.item3',
              ),
            },
            {
              value: intl.getTranslatedText(
                'components.chatContainer.chatContainerMessage.reportMessageModal.reportReasonRadio.item4',
              ),
              label: intl.getTranslatedText(
                'components.chatContainer.chatContainerMessage.reportMessageModal.reportReasonRadio.item4',
              ),
            },
            {
              value: intl.getTranslatedText(
                'components.chatContainer.chatContainerMessage.reportMessageModal.reportReasonRadio.item5',
              ),
              label: intl.getTranslatedText(
                'components.chatContainer.chatContainerMessage.reportMessageModal.reportReasonRadio.item5',
              ),
            },
            {
              value: intl.getTranslatedText(
                'components.chatContainer.chatContainerMessage.reportMessageModal.reportReasonRadio.item6',
              ),
              label: intl.getTranslatedText(
                'components.chatContainer.chatContainerMessage.reportMessageModal.reportReasonRadio.item6',
              ),
            },
            {
              value: intl.getTranslatedText(
                'components.chatContainer.chatContainerMessage.reportMessageModal.reportReasonRadio.item7',
              ),
              label: intl.getTranslatedText(
                'components.chatContainer.chatContainerMessage.reportMessageModal.reportReasonRadio.item7',
              ),
            },
          ]}
          label={intl.getTranslatedText(
            'components.chatContainer.chatContainerMessage.reportMessageModal.reportReasonRadio.label',
          )}
          vertical
          value={selectedReasonForReport}
          // error={meta?.touched && meta?.error && meta?.error}
          onChange={e => {
            setSelectedReasonForReport(e.target.value);
          }}
        />
      </Modal>
    </>
  );
};

export default Message;
