import { AthleteShortPositionEnum, EAthletePosition } from './types';

export function getAthleteShortPosition(position: EAthletePosition): string {
  switch (position) {
    case EAthletePosition.GOALKEEPER:
      return AthleteShortPositionEnum.GOALKEEPER;
    case EAthletePosition.DEFENSE:
      return AthleteShortPositionEnum.DEFENSE;
    case EAthletePosition.MIDFIELD:
      return AthleteShortPositionEnum.MIDFIELD;
    case EAthletePosition.ATTACK:
      return AthleteShortPositionEnum.ATTACK;
    case EAthletePosition.RIGHT_BACK:
      return AthleteShortPositionEnum.RIGHT_BACK;
    case EAthletePosition.LEFT_BACK:
      return AthleteShortPositionEnum.LEFT_BACK;
    case EAthletePosition.ATP:
      return AthleteShortPositionEnum.ATP;
    case EAthletePosition.WTA:
      return AthleteShortPositionEnum.WTA;
    default:
      return '';
  }
}
