import Common from './common';
import Pages from './pages';
import Utils from './utils';
import Services from './services';
import Routes from './routes';
import Contexts from './context';
import Components from './components';

const BrazilianPortugueseTranslation = {
  ...Common,
  ...Pages,
  ...Utils,
  ...Services,
  ...Routes,
  ...Contexts,
  ...Components,
};

export default BrazilianPortugueseTranslation;
