import React from 'react';
import { IconType } from 'react-icons';

import { FaCoins } from 'react-icons/fa';
import { RiInboxArchiveFill } from 'react-icons/ri';
import { BiTransferAlt } from 'react-icons/bi';

import Pack1 from '@/assets/coins-packs/pack-1.jpg';
import Pack2 from '@/assets/coins-packs/pack-2.jpg';
import Pack3 from '@/assets/coins-packs/pack-3.jpg';

import fvsFIconImg from '@/assets/games-icons/fvsf-icon.svg';
import vsIconImg from '@/assets/games-icons/vs-icon.svg';
import vsVipIconImg from '@/assets/games-icons/vs-vip-icon.svg';
import athletesOfTheWeekIconImg from '@/assets/games-icons/athletes-of-the-week-icon.svg';
import cardGameIcon from '@/assets/games-icons/card-game-icon.svg';
import pushGameIcon from '@/assets/games-icons/push-game-icon.svg';

import { ETransactionModule, ETransactionType } from '../../../bullet.types';

import { Container, PurchaseCoinsImgContainer } from './styles';

interface ITransactionIcon {
  Icon: IconType | string;
  iconType: 'svg' | 'img';
}

interface ITransactionIconProps {
  module: ETransactionModule;
  type: ETransactionType;
}

const TransactionIcon: React.FC<ITransactionIconProps> = ({ module, type }) => {
  function getTransactionIcon(transactionModule: string): ITransactionIcon {
    const transactionIcon: ITransactionIcon = {
      Icon: '',
      iconType: 'img',
    };

    if (transactionModule === ETransactionModule.DEPOSIT) {
      transactionIcon.Icon = FaCoins;
      transactionIcon.iconType = 'svg';
    }
    if (transactionModule === ETransactionModule.WITHDRAW) {
      transactionIcon.Icon = RiInboxArchiveFill;
    }
    if (transactionModule === ETransactionModule.TRANSFER) {
      transactionIcon.Icon = BiTransferAlt;
    }
    if (
      transactionModule === ETransactionModule.COACH_FRIEND ||
      transactionModule === ETransactionModule.CHALLENGER
    ) {
      transactionIcon.Icon = fvsFIconImg;
    }
    if (transactionModule === ETransactionModule.GAME_VS) {
      transactionIcon.Icon = vsIconImg;
    }
    if (transactionModule === ETransactionModule.GAME_VS_VIP) {
      transactionIcon.Icon = vsVipIconImg;
    }
    if (transactionModule === ETransactionModule.CARD_GAME) {
      transactionIcon.Icon = cardGameIcon;
    }
    if (transactionModule === ETransactionModule.ATHLETES_OF_THE_WEEK) {
      transactionIcon.Icon = athletesOfTheWeekIconImg;
    }
    if (transactionModule === ETransactionModule.PUSH_GAME) {
      transactionIcon.Icon = pushGameIcon;
    }

    return transactionIcon;
  }

  function getPurchaseCoinsPackImg(transactionType: ETransactionType): string {
    if (transactionType === ETransactionType.PURCHASE_FIRST_PACK) return Pack1;
    if (transactionType === ETransactionType.PURCHASE_SECOND_PACK) return Pack2;
    if (transactionType === ETransactionType.PURCHASE_THIRD_PACK) return Pack3;

    return '';
  }

  const { Icon, iconType } = getTransactionIcon(module);

  if (module === ETransactionModule.DEPOSIT) {
    const packImg = getPurchaseCoinsPackImg(type);

    return <PurchaseCoinsImgContainer src={packImg} alt="icon" />;
  }

  return (
    <Container>
      {iconType === 'img' && typeof Icon === 'string' ? (
        <img src={Icon} alt="icon" />
      ) : (
        <Icon size={28} />
      )}
    </Container>
  );
};

export default TransactionIcon;
