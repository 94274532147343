/* eslint-disable import/no-duplicates */
import React, {
  createContext,
  useCallback,
  useContext,
  useState,
  useEffect,
} from 'react';
import { IntlProvider } from 'react-intl';
import { Locale as TDfnsLocale } from 'date-fns';
import { ptBR as ptBRDfnsLocale, enUS as enDfnsLocale } from 'date-fns/locale';
// import enLocale from 'date-fns/locale/en-US';

import { getMessagesByLocale, IMessage } from '../utils/intl';

interface InternationalizationWrapperContextData {
  locale: string;
  dfnsLocale: TDfnsLocale;
  handleChangeLocale(newLocale: string): void;
}

const InternationalizationWrapperContext =
  createContext<InternationalizationWrapperContextData>(
    {} as InternationalizationWrapperContextData,
  );

// function handleGlobalSelectedLocated(locale: string): void {
//   // window._selectedLocale = locale;
//   const changeSelectedLocaleEvent = new CustomEvent<IChangeSelectedLocaleEventData>(
//     'changeSelectedLocale',
//     {
//       detail: {
//         locale,
//       },
//     },
//   );
//   window.dispatchEvent(changeSelectedLocaleEvent);
// }

function getDfnsLocaleBySelectedLocale(locale: string): TDfnsLocale {
  if (locale === 'pt-BR') {
    return ptBRDfnsLocale;
  }

  return enDfnsLocale;
}

const InternationalizationWrapperProvider: React.FC = ({ children }) => {
  const [messages, setMessages] = useState<IMessage>({} as IMessage);
  const [dfnsLocale, setDfnsLocale] = useState<TDfnsLocale>({} as Locale);
  const [locale, setLocale] = useState(() => {
    let usedLocale: string;

    const savedLocale = localStorage.getItem('@LanceFutebol:Locale');

    if (savedLocale) {
      usedLocale = savedLocale;
    } else {
      usedLocale = navigator.language;
    }

    setMessages(getMessagesByLocale(usedLocale));
    setDfnsLocale(getDfnsLocaleBySelectedLocale(usedLocale));
    window._selectedLocale = usedLocale;
    return usedLocale;
  });

  useEffect(() => {
    setMessages(getMessagesByLocale(locale));
  }, [locale]);

  const handleChangeLocale = useCallback((newLocale: string): void => {
    setLocale(newLocale);
    localStorage.setItem('@LanceFutebol:Locale', newLocale);
    window._selectedLocale = newLocale;
    setDfnsLocale(getDfnsLocaleBySelectedLocale(newLocale));
  }, []);

  return (
    <InternationalizationWrapperContext.Provider
      value={{
        locale,
        dfnsLocale,
        handleChangeLocale,
      }}
    >
      <IntlProvider locale={locale} messages={messages}>
        {children}
      </IntlProvider>
    </InternationalizationWrapperContext.Provider>
  );
};

function useInternationalizationWrapper(): InternationalizationWrapperContextData {
  const context = useContext(InternationalizationWrapperContext);

  if (!context) {
    throw new Error(
      'useInternationalizationWrapper be used within an InternationalizationWrapperProvider',
    );
  }

  return context;
}

export { useInternationalizationWrapper, InternationalizationWrapperProvider };
