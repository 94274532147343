import styled, { keyframes } from 'styled-components';
import { shade } from 'polished';

import forgotPasswordBackgroundImg from '../../assets/forgot-password-background.png';

export const Container = styled.div`
  height: 100vh;

  display: flex;
  align-items: stretch;
`;
export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 2.5rem 0;
  overflow: auto;

  width: 100%;
  max-width: 43.75rem;

  @media only screen and (max-width: ${props => props.theme.breakpoints.xl}) {
    max-width: 100%;
  }
`;

const appearFromRight = keyframes`
  from {
    opacity: 0;
    transform: translateX(3.125rem);
  }
  to {
    opacity: 1;
    transform: translateX(0px);
  }
`;

export const AnimationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  margin: auto 0;

  animation: ${appearFromRight} 1s;

  @media only screen and (max-width: ${props => props.theme.breakpoints.sm}) {
    width: 100%;

    form {
      width: 100% !important;
      padding: 0 1rem !important;
    }
  }

  img {
    width: 11.125rem;
  }

  form {
    margin: 2.5rem 0 0;
    text-align: center;
    width: 23.875rem;

    h1 {
      margin-bottom: 1.5rem;
    }

    h5 {
      margin-bottom: 2.5rem;
    }

    button {
      width: 100%;
      margin-top: 0.5rem;
    }

    a {
      color: #f4ede8;
      display: block;
      margin-top: 1.5rem;
      text-decoration: none;
      transition: color 0.2s;

      &:hover {
        color: ${shade(0.2, '#f4ede8')};
      }
    }
  }

  > a {
    color: ${props => props.theme.colors.primary['primary+1']};
    margin-top: 2.5rem;
    text-decoration: none;
    transition: color 0.2s;

    display: flex;
    align-items: center;

    svg {
      margin-right: 1rem;
    }

    &:hover {
      color: ${props => shade(0.2, props.theme.colors.primary['primary+1'])};
    }
  }
`;

export const Background = styled.div`
  flex: 1;
  background: url(${forgotPasswordBackgroundImg}) no-repeat center;
  background-size: cover;

  @media only screen and (max-width: ${props => props.theme.breakpoints.xl}) {
    display: none;
  }
`;
