import { TUtilsTexts } from '../../en/utils';

import Formatters from './formatters';
import Validate from './validate';

const utilsTexts: TUtilsTexts = {
  ...Formatters,
  ...Validate,
};

export default utilsTexts;
