const ValidationTexts = {
  'utils.validate.yupCustomValidationMethods.objectId.invalid':
    'Insira um ObjectId válido',
  'utils.validate.cnpj.invalid': 'O CNPJ informado não é válido',
  'utils.validate.cpf.invalid': 'O CPF informado não é válido',
};

export default {
  ...ValidationTexts,
};
