/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { Radio as RadioAnt } from 'antd';
import { RadioGroupProps } from 'antd/lib/radio';

import { Container } from './styles';

interface IRadioItem {
  label: string;
  value: unknown;
}

interface IRadioProps extends RadioGroupProps {
  label?: string;
  items: IRadioItem[];
  error?: string | false;
  vertical?: boolean;
}

function getRadioStyle(vertical: boolean): { [key: string]: string } {
  if (vertical) {
    return {
      display: 'block',
      height: '30px',
      lineHeight: '30px',
    };
  }

  return {};
}

const Radio: React.FC<IRadioProps> = ({
  error,
  label,
  items,
  vertical,
  ...rest
}) => {
  return (
    <Container error={!!error}>
      {label && <label>{label}</label>}
      <RadioAnt.Group {...rest}>
        {items.map(item => (
          <RadioAnt
            style={getRadioStyle(!!vertical)}
            key={`${item.value}`}
            value={item.value}
          >
            {item.label}
          </RadioAnt>
        ))}
      </RadioAnt.Group>
      {error && <span>{error}</span>}
    </Container>
  );
};

export default Radio;
