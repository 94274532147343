import AuthContext from './AuthContext';
import ChatContext from './ChatContext';
import ChatP2PContext from './ChatP2PContext';

const contextTexts = {
  ...AuthContext,
  ...ChatContext,
  ...ChatP2PContext,
};

export type TContextTexts = typeof contextTexts;

export default contextTexts;
