import styled from 'styled-components';

interface ITransferAvailabilityBarProgressProps {
  $progress: number;
}

export const Container = styled.div`
  width: 100%;
  min-height: 0;
  overflow-y: auto;
  padding: 32px 24px;
  flex: 1;

  @media only screen and (max-width: 600px) {
    padding: 32px 16px;
  }
`;

export const Content = styled.div`
  width: 100%;

  form {
    width: 100%;
    max-width: 504px;
    margin: 0 auto;

    @media only screen and (max-width: 600px) {
      max-width: 100%;
    }

    > h6 {
      font-weight: ${props => props.theme.typograph.weights.light};
      line-height: 120%;
      margin-top: 3.5rem;
      text-align: center;

      span {
        font-weight: ${props => props.theme.typograph.weights.medium};
      }
    }

    > p {
      margin: 0;
      width: 100%;
      text-align: center;
      color: ${props => props.theme.colors.light['light-2']};
      line-height: 120%;

      @media only screen and (max-width: 600px) {
        font-size: ${props => props.theme.typograph.sizes.small}px;
      }
    }

    > strong {
      margin: 0.5rem 0 2.5rem;
      width: 100%;
      color: ${props => props.theme.colors.light.light0};
      line-height: 120%;
      display: block;

      span {
        font-weight: ${props => props.theme.typograph.weights.medium};
      }

      @media only screen and (max-width: 600px) {
        font-size: ${props => props.theme.typograph.sizes.small}px;
      }
    }

    > button {
      width: 100%;
    }
  }
`;

export const TransferAvailabilityContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid ${props => props.theme.colors.dark['dark+1']};
  margin-bottom: 1.75rem;
`;

export const TransferAvailabilityTitle = styled.small`
  display: flex;
  font-size: ${props => props.theme.typograph.sizes.small}px;
  font-weight: ${props => props.theme.typograph.weights.light};
  color: ${props => props.theme.colors.light['light-2']};

  @media only screen and (max-width: 600px) {
    font-size: ${props => props.theme.typograph.sizes.xs}px;
  }

  span {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: ${props => props.theme.colors.primary.primary0};
    color: ${props => props.theme.colors.dark.dark0};
    margin-left: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: ${props => props.theme.typograph.sizes.xs}px;
    font-weight: ${props => props.theme.typograph.weights.bold};
    cursor: default;
  }
`;

export const TransferAvailabilityBarContainer = styled.div`
  width: 100%;
  height: 0.25rem;
  border-radius: 0.125rem;
  background-color: ${props => props.theme.colors.light['light-2']};
`;

export const TransferAvailabilityBarProgress = styled.div<ITransferAvailabilityBarProgressProps>`
  @keyframes progress {
    from {
      width: 0;
    }

    to {
      width: ${props => `calc(100% - ${100 - props.$progress}%)`};
    }
  }

  height: 0.25rem;
  background: ${props => props.theme.colors.secondary['secondary-1']};
  border-radius: 0.125rem;
  animation: progress ease-in-out 800ms;
  width: ${props => `calc(100% - ${100 - props.$progress}%)`};
`;

export const TransferQuoteValue = styled.small`
  font-size: ${props => props.theme.typograph.sizes.small}px;
  font-weight: ${props => props.theme.typograph.weights.light};
  color: ${props => props.theme.colors.light['light-2']};

  @media only screen and (max-width: 600px) {
    font-size: ${props => props.theme.typograph.sizes.xs}px;
  }

  span {
    font-weight: ${props => props.theme.typograph.weights.medium};
  }
`;

export const InputContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  > h6 {
    font-weight: ${props => props.theme.typograph.weights.light};
    line-height: 120%;
    margin-bottom: 8px;

    span {
      font-weight: ${props => props.theme.typograph.weights.medium};
    }
  }

  > small {
    font-size: ${props => props.theme.typograph.sizes.small}px;
    font-weight: ${props => props.theme.typograph.weights.light};
    line-height: 16px;
  }
`;
