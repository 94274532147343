import {
  ISignUpFormData,
  IAsyncValidationLoadingState,
  IAvailableFieldsState,
} from './types';

export const signUpFormDataInitialValue: ISignUpFormData = {
  name: '',
  username: '',
  docNumber: '',
  gender: '',
  email: '',
  password: '',
  confirmPassword: '',
  phone: '',
  photo: {
    file: null,
    previewUrl: '',
  },
};

export const asyncValidationLoadingInitialValue: IAsyncValidationLoadingState =
  {
    checkingSomeField: false,
    username: false,
    email: false,
    docNumber: false,
  };

export const availableFieldsInitialValue: IAvailableFieldsState = {
  username: true,
  email: true,
  docNumber: true,
};
