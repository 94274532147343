const ChampionshipTexts = {
  'pages.championship.viewersDescriptions.availableForPlay':
    'disponíveis para jogar',
  'pages.championship.viewersDescriptions.running': 'em execução',
  'pages.championship.viewersDescriptions.finished': 'finalizadas',

  'pages.championship.views.finished': 'Finalizadas',
  'pages.championship.views.availableForPlay': 'Disponíveis para jogar',
  'pages.championship.views.running': 'Em execução',

  'pages.championship.searchCardsInput.placeholder': 'Pesquisar cartela',

  // /* ------------ Buttons ------------ */
  'pages.championship.goToHomeLink': 'Voltar para o início',
  'pages.championship.myGamesButton': 'Meus jogos',
  /* ------------ Messages ------------ */
  'pages.championship.messages.getChampionship.title':
    'Ocorreu um erro ao carregar os dados do campeonato',
  'pages.championship.messages.getCards.title':
    'Ocorreu um erro ao carregar os jogos de cartela',
  'pages.championship.messages.loadingCards': 'Carregando cartelas...',
  'pages.championship.messages.noCards':
    'Esse <span>campeonato</span> não possuí cartelas <span>{viewerDescription}</span>',
  'pages.championship.messages.cardNotFound':
    'Essa <span>cartela</span> não foi encontrada',
  'pages.championship.messages.championshipNotFound':
    'Esse <span>campeonato</span> não foi encontrado',
};

const CardGamesHistoryTexts = {
  'pages.championship.cardGamesHistory.title': 'Histórico de cartelas',

  'pages.championship.cardGamesHistory.goToChampionshipDetailsLink':
    'Voltar para os detalhes do campeonato',
  'pages.championship.cardGamesHistory.showActiveGamesButton':
    'Mostrar jogos ativos',
  'pages.championship.cardGamesHistory.showArchivedGamesButton':
    'Mostrar jogos arquivados',

  /* ------------ Messages ------------ */
  'pages.championship.cardGamesHistory.messages.getPlayedCardGamesError.title':
    'Erro ao carregar o histórico de jogos',
  'pages.championship.cardGamesHistory.messages.archiveGameConfirm.description':
    'Deseja realmente arquivar esse jogo?',
  'pages.championship.cardGamesHistory.messages.archiveGameSuccess.title':
    'Jogo arquivado!',
  'pages.championship.cardGamesHistory.messages.unarchiveGameConfirm.description':
    'Deseja realmente tornar esse jogo ativo?',
  'pages.championship.cardGamesHistory.messages.unarchiveGameSuccess.title':
    'Esse jogo está ativo!',
  'pages.championship.cardGamesHistory.messages.noGames':
    'Você não possui nenhum <span>jogo</span> nesse campeonato',
  'pages.championship.cardGamesHistory.messages.viewingArchivedGames':
    'Você está visualizando seus jogos arquivados',
  'pages.championship.cardGamesHistory.messages.noArchivedGames':
    'Você não possui nenhum <span>jogo arquivado</span> nesse campeonato',
};

const MyCardGameDetailsTexts = {
  'pages.championship.myCardGameDetails.title':
    'Detalhes do meu jogo de cartela',

  'pages.championship.myCardGameDetails.goToGamesHistoryLink':
    'Voltar para o histórico de jogos',

  /* ------------ Messages ------------ */
  'pages.championship.myCardGameDetails.messages.noGame':
    'Esse <span>jogo</span> não foi encontrado',

  /* PlayedCardGameMatch */
  ...{
    'pages.championship.myCardGameDetails.playedCardGameMatch.totalScore':
      '{score} pontos',
    'pages.championship.myCardGameDetails.playedCardGameMatch.selectedChampion.label':
      'Campeão selecionado',
    'pages.championship.myCardGameDetails.playedCardGameMatch.selectedChampion.draw':
      'Empate',
    'pages.championship.myCardGameDetails.playedCardGameMatch.selectedPlayers.label':
      'Jogadores selecionados',
  },
};

const CardGameTexts = {
  'pages.championship.cardGame.tabs.ranking': 'Ranking',
  'pages.championship.cardGame.tabs.results': 'Resultados',
  'pages.championship.cardGame.tabs.myGames': 'Meus jogos',

  'pages.championship.cardGame.goToPlayConfirmButton': 'Jogar',
  'pages.championship.cardGame.playNowButton': 'Jogar agora',
  'pages.championship.cardGame.goToChampionshipDetails':
    'Voltar para os detalhes do campeonato',

  'pages.championship.cardGame.messages.getRankingError.title':
    'Ocorreu um erro ao carregar o ranking desse campeonato',
  'pages.championship.cardGame.messages.getMyGamesError.title':
    'Erro ao carregar os seus jogos',
  'pages.championship.cardGame.messages.goToPlayConfirm.title':
    'Deseja jogar nessa cartela?',
  'pages.championship.cardGame.messages.goToPlayConfirm.description':
    'Esse jogo custa R$ {price}',
  'pages.championship.cardGame.messages.noCardGame':
    'Esse <span>jogo de cartela</span> não foi encontrado',

  /* My Games */
  ...{
    'pages.championship.cardGame.myGames.loadingMyGames':
      'Carregando seus jogos...',
    'pages.championship.cardGame.myGames.noGames':
      'Você <span>não</span> realizou nenhum <span>jogo</span> nessa cartela',
  },
  /* Play */
  ...{
    'pages.championship.cardGame.play.title': 'Jogar',

    'pages.championship.cardGame.play.submitNewGameConfirmButton':
      'Finalizar jogo',
    'pages.championship.cardGame.play.goToChampionshipDetailsLink':
      'Voltar para os detalhes do campeonato',

    'pages.championship.cardGame.play.messages.unblockPageConfirm.title':
      'Deseja realmente sair do jogo?',
    'pages.championship.cardGame.play.messages.submitNewGameValidationError.title':
      'Ops...',
    'pages.championship.cardGame.play.messages.submitNewGameValidationError.description':
      'Selecione todas as opções disponíveis antes de continuar',
    'pages.championship.cardGame.play.messages.submitNewGameConfirm.title':
      'Deseja realmente jogar nessa cartela?',
    'pages.championship.cardGame.play.messages.submitNewGameSuccess.title':
      'Jogo de cartela realizado com sucesso!',
    'pages.championship.cardGame.play.messages.noCard':
      'Esse <span>jogo de cartela</span> não foi encontrado',

    /* MatchCard */
    ...{
      'pages.championship.cardGame.play.matchCard.selectPlayerModal.title':
        'Selecionar jogador do {teamAbbrev}',
      'pages.championship.cardGame.play.matchCard.selectPlayerModal.playerNumber':
        'Camisa {playerNumber}',

      'pages.championship.cardGame.play.matchCard.selectTeamPlayerButton':
        'Selecione um jogador do {teamAbbrev}',
    },
  },
  /* Ranking */
  ...{
    'pages.championship.cardGame.ranking.searchUserInput.placeholder':
      'Pesquisar usuário',

    'pages.championship.cardGame.ranking.messages.userNotFound':
      'Esse <span>usuário</span> não foi encontrado',
    'pages.championship.cardGame.ranking.messages.userNotHasGameOnThisCard':
      'Esse usuário não realizou nenhum jogo nessa cartela',
    'pages.championship.cardGame.ranking.messages.noRanking':
      'O <span>ranking</span> dessa cartela não está disponível',
    'pages.championship.cardGame.ranking.messages.loadingRanking':
      'Carregando ranking...',
    'pages.championship.cardGame.ranking.messages.loadingUserGames':
      'Carregando jogos do usuário...',
    'pages.championship.cardGame.ranking.messages.partialInfo':
      'As informações abaixo são parciais',
  },
  /* Results */
  ...{
    'pages.championship.cardGame.results.messages.partialInfo':
      'As informações abaixo são parciais',

    /* MatchCard */
    ...{
      'pages.championship.cardGame.results.matchCard.firstGoal':
        'Primeiro gol feito por: <b>{playerName}</b>',
      'pages.championship.cardGame.results.matchCard.noGoals':
        'Esse time não realizou gols',
      'pages.championship.cardGame.results.matchCard.matchDidNotHappen':
        'Essa partida ainda não aconteceu',
    },
  },
  /* UserCardGameDetails */
  ...{
    'pages.championship.cardGame.userCardGameDetails.title':
      'Detalhes do jogo de cartela',

    'pages.championship.cardGame.userCardGameDetails.goToCardDetailsLink':
      'Voltar para os detalhes da cartela',

    /* ------------ Messages ------------ */
    'pages.championship.cardGame.userCardGameDetails.messages.noGame':
      'Esse <span>jogo</span> não foi encontrado',

    /* PlayedCardGameMatch */
    ...{
      'pages.championship.cardGame.userCardGameDetails.playedCardGameMatch.totalScore':
        '{score} pontos',
      'pages.championship.cardGame.userCardGameDetails.playedCardGameMatch.selectedChampion.label':
        'Campeão selecionado',
      'pages.championship.cardGame.userCardGameDetails.playedCardGameMatch.selectedChampion.draw':
        'Empate',
      'pages.championship.cardGame.userCardGameDetails.playedCardGameMatch.selectedPlayers.label':
        'Jogadores selecionados',
    },
  },
};

export default {
  ...ChampionshipTexts,
  ...CardGamesHistoryTexts,
  ...MyCardGameDetailsTexts,
  ...CardGameTexts,
};
