import { darken } from 'polished';
import styled, { css, keyframes } from 'styled-components';

interface ISelectButtonProps {
  $isOpened: boolean;
  $isFirstRender: boolean;
}

interface ISelectDropdownWrapperProps {
  $isOpened: boolean;
  $isFirstRender: boolean;
}

const openIcon = keyframes`
  100% {
    transform: rotate(180deg);
  }
`;

const closeIcon = keyframes`
  0% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(0deg);
  }
`;

const openDropdown = keyframes`
  0% {
    display: block;
    height: unset;
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const closeDropdown = keyframes`
  0% {
    opacity: 1;
  }
  100% {
    display: none;
    height: 0;
    opacity: 0;
  }
`;

export const Container = styled.div`
  width: 100%;
  position: relative;
`;

export const SelectButton = styled.button<ISelectButtonProps>`
  width: 100%;
  min-height: 3.25rem;
  padding: 0.5rem 1rem;
  background-color: ${props => props.theme.colors.dark['dark+2']};
  border: 1px solid ${props => props.theme.colors.dark['dark+3']};
  border-radius: 4px;
  display: flex;
  gap: 1rem;
  align-items: center;
  transition: 0.2s background-color;

  &:hover {
    background-color: ${props =>
      darken(0.02, props.theme.colors.dark['dark+2'])};
  }

  p {
    flex: 1;
    font-size: ${props => props.theme.typograph.sizes.small}px;
    font-weight: ${props => props.theme.typograph.weights.bold};
    color: ${props => props.theme.colors.light.light0};
    margin: 0;
    text-align: start;

    span {
      display: block;
      font-size: ${props => props.theme.typograph.sizes.small}px;
      font-weight: ${props => props.theme.typograph.weights.medium};
      color: ${props => props.theme.colors.light['light-2']};
    }
  }

  svg {
    color: ${props => props.theme.colors.primary['primary+1']};

    ${props =>
      props.$isOpened &&
      css`
        animation: ${openIcon} 200ms ease-out;
        animation-fill-mode: forwards;
      `}
    ${props =>
      !props.$isOpened &&
      !props.$isFirstRender &&
      css`
        animation: ${closeIcon} 200ms ease-out;
        animation-fill-mode: forwards;
      `}
  }
`;

export const SelectDropdownWrapper = styled.div<ISelectDropdownWrapperProps>`
  width: 100%;
  position: absolute;
  z-index: 100;
  top: calc(100% + 0.5rem);
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.2);
  background-color: ${props => props.theme.colors.dark['dark+2']};
  border-radius: 4px;

  ${props =>
    props.$isOpened &&
    css`
      animation: ${openDropdown} 200ms ease-out;
      animation-fill-mode: forwards;
    `}
  ${props =>
    !props.$isOpened &&
    !props.$isFirstRender &&
    css`
      animation: ${closeDropdown} 200ms ease-out;
      animation-fill-mode: forwards;
    `}
  ${props =>
    !props.$isOpened &&
    props.$isFirstRender &&
    css`
      display: none;
      height: 0;
      opacity: 0;
    `}
`;

export const SelectDropdown = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin: 0;
  max-height: 12rem;
  overflow-y: auto;
  border-radius: 4px;

  &::-webkit-scrollbar {
    width: 7px;
  }
  &::-webkit-scrollbar-track {
    background: ${props => props.theme.colors.dark['dark+3']};
    border-radius: 1rem;
  }

  &::-webkit-scrollbar-thumb {
    background: ${props => props.theme.colors.dark['dark+4']};
    border-radius: 1rem;
  }
`;

export const SelectOption = styled.button`
  width: 100%;
  padding: 0.5rem 1rem;
  background-color: ${props => props.theme.colors.dark['dark+2']};
  border: 0;
  border-bottom: 1px solid ${props => props.theme.colors.dark['dark+3']};
  display: flex;
  gap: 1rem;
  align-items: center;
  transition: 0.2s background-color;

  &:hover {
    background-color: ${props =>
      darken(0.02, props.theme.colors.dark['dark+2'])};
  }

  p {
    flex: 1;
    font-size: ${props => props.theme.typograph.sizes.small}px;
    font-weight: ${props => props.theme.typograph.weights.bold};
    color: ${props => props.theme.colors.light.light0};
    margin: 0;
    text-align: start;

    span {
      display: block;
      font-size: ${props => props.theme.typograph.sizes.small}px;
      font-weight: ${props => props.theme.typograph.weights.medium};
      color: ${props => props.theme.colors.light['light-2']};
    }
  }

  svg {
    color: ${props => props.theme.colors.primary['primary+1']};
  }

  &:first-child {
    border-radius: 4px 4px 0 0;
  }

  &:last-child {
    border-radius: 0 0 4px 4px;
    border-bottom: none;
  }
`;
