const AthletesOfTheWeekTexts = {
  'pages.athletesOfTheWeek.viewersDescriptions.availableForPlay':
    'disponíveis para jogar',
  'pages.athletesOfTheWeek.viewersDescriptions.running': 'em execução',
  'pages.athletesOfTheWeek.viewersDescriptions.finished': 'finalizadas',

  'pages.athletesOfTheWeek.views.finished': 'Finalizadas',
  'pages.athletesOfTheWeek.views.availableForPlay': 'Disponíveis para jogar',
  'pages.athletesOfTheWeek.views.running': 'Em execução',

  'pages.athletesOfTheWeek.searchCardsInput.placeholder': 'Pesquisar cartela',

  // /* ------------ Buttons ------------ */
  'pages.athletesOfTheWeek.myGamesButton': 'Meus jogos',
  /* ------------ Messages ------------ */
  'pages.athletesOfTheWeek.messages.getCards.title':
    'Ocorreu um erro ao carregar os jogos de cartela',
  'pages.athletesOfTheWeek.messages.loadingCards': 'Carregando cartelas...',
  'pages.athletesOfTheWeek.messages.noCards':
    'Não existem cartelas <span>{viewerDescription}</span>',
  'pages.athletesOfTheWeek.messages.cardNotFound':
    'Essa <span>cartela</span> não foi encontrada',
  'pages.athletesOfTheWeek.messages.championshipNotFound':
    'Esse <span>campeonato</span> não foi encontrado',
};

const CardGamesHistoryTexts = {
  'pages.athletesOfTheWeek.cardGamesHistory.title': 'Histórico de cartelas',

  'pages.athletesOfTheWeek.cardGamesHistory.goToAthletesOfTheWeekLink':
    'Voltar para o hat-trick',
  'pages.athletesOfTheWeek.cardGamesHistory.showActiveGamesButton':
    'Mostrar jogos ativos',
  'pages.athletesOfTheWeek.cardGamesHistory.showArchivedGamesButton':
    'Mostrar jogos arquivados',

  /* ------------ Messages ------------ */
  'pages.athletesOfTheWeek.cardGamesHistory.messages.getPlayedCardGamesError.title':
    'Erro ao carregar o histórico de jogos',
  'pages.athletesOfTheWeek.cardGamesHistory.messages.archiveGameConfirm.description':
    'Deseja realmente arquivar esse jogo?',
  'pages.athletesOfTheWeek.cardGamesHistory.messages.archiveGameSuccess.title':
    'Jogo arquivado!',
  'pages.athletesOfTheWeek.cardGamesHistory.messages.unarchiveGameConfirm.description':
    'Deseja realmente tornar esse jogo ativo?',
  'pages.athletesOfTheWeek.cardGamesHistory.messages.unarchiveGameSuccess.title':
    'Esse jogo está ativo!',
  'pages.athletesOfTheWeek.cardGamesHistory.messages.noGames':
    'Você não possui nenhum <span>jogo</span>',
  'pages.athletesOfTheWeek.cardGamesHistory.messages.viewingArchivedGames':
    'Você está visualizando seus jogos arquivados',
  'pages.athletesOfTheWeek.cardGamesHistory.messages.noArchivedGames':
    'Você não possui nenhum <span>jogo arquivado</span>',
};

const CardGameDetailsTexts = {
  'pages.athletesOfTheWeek.myCardGameDetails.title':
    'Detalhes do jogo de cartela',

  'pages.athletesOfTheWeek.myCardGameDetails.goToHome': 'Voltar para a home',

  /* ------------ Messages ------------ */
  'pages.athletesOfTheWeek.myCardGameDetails.messages.noGame':
    'Esse <span>jogo</span> não foi encontrado',

  /* PlayedCardGameAthlete */
  ...{
    'pages.athletesOfTheWeek.myCardGameDetails.playedCardGameAthlete.playedAthleteNumber':
      'Camisa - {playerNumber} · {playerPosition}',
  },
};

const CardTexts = {
  'pages.athletesOfTheWeek.card.tabs.ranking': 'Ranking',
  'pages.athletesOfTheWeek.card.tabs.results': 'Estatísticas',
  'pages.athletesOfTheWeek.card.tabs.myGames': 'Meus jogos',

  'pages.athletesOfTheWeek.card.goToPlayConfirmButton': 'Jogar',
  'pages.athletesOfTheWeek.card.playNowButton': 'Jogar agora',
  'pages.athletesOfTheWeek.card.goToAthletesOfTheWeekLink':
    'Voltar para o hat-trick',

  'pages.athletesOfTheWeek.amount': 'Total: R$ {price}',
  'pages.athletesOfTheWeek.placement.1': '1º colocado: R$ {price}',
  'pages.athletesOfTheWeek.placement.2': '2º colocado: R$ {price}',
  'pages.athletesOfTheWeek.placement.3': '3º colocado: R$ {price}',

  'pages.athletesOfTheWeek.card.messages.getRankingError.title':
    'Ocorreu um erro ao carregar o ranking dessa cartela',
  'pages.athletesOfTheWeek.card.messages.getMyGamesError.title':
    'Erro ao carregar os seus jogos',
  'pages.athletesOfTheWeek.card.messages.goToPlayConfirm.title':
    'Deseja jogar nessa cartela?',
  'pages.athletesOfTheWeek.card.messages.goToPlayConfirm.description':
    'Esse jogo custa R$ {price}',
  'pages.athletesOfTheWeek.card.messages.goToPlayConfirm.freeCardDescription':
    'Esse jogo é gratuito',
  'pages.athletesOfTheWeek.card.messages.noCardGame':
    'Esse <span>jogo de cartela</span> não foi encontrado',

  /* My Games */
  ...{
    'pages.athletesOfTheWeek.card.myGames.loadingMyGames':
      'Carregando seus jogos...',
    'pages.athletesOfTheWeek.card.myGames.noGames':
      'Você <span>não</span> realizou nenhum <span>jogo</span> nessa cartela',
  },
  /* Play */
  ...{
    'pages.athletesOfTheWeek.card.play.title': 'Jogar',
    'pages.athletesOfTheWeek.searchInfluencerCodeInput.placeholder':
      'Digite o código do Influenciador',

    'pages.athletesOfTheWeek.card.play.submitNewGameConfirmButton':
      'Finalizar jogo',
    'pages.athletesOfTheWeek.card.play.goToAthletesOfTheWeekLink':
      'Voltar para o hat-trick',
    'pages.athletesOfTheWeek.card.play.checkInfluencerCode': 'Aplicar',
    'pages.athletesOfTheWeek.card.play.removeInfluencerCode': 'Remover',

    'pages.athletesOfTheWeek.card.play.messages.unblockPageConfirm.title':
      'Deseja realmente sair do jogo?',
    'pages.athletesOfTheWeek.card.play.messages.submitNewGameConfirm.title':
      'Deseja realmente jogar nessa cartela?',
    'pages.athletesOfTheWeek.card.play.messages.submitNewGameSuccess.title':
      'Jogo de cartela realizado com sucesso!',
    'pages.athletesOfTheWeek.card.play.messages.noCard':
      'Esse <span>jogo de cartela</span> não foi encontrado',
    'pages.athletesOfTheWeek.card.play.messages.influencerCode.title':
      'Você possui o <span>código</span> do influenciador?',
    'pages.athletesOfTheWeek.card.play.messages.influencerCodeSuccess.title':
      'Código aplicado com sucesso!',
    'pages.athletesOfTheWeek.card.play.messages.influencerCodeError.title':
      'Código não disponível',

    /* AthleteCard */
    ...{
      'pages.athletesOfTheWeek.card.play.athleteCard.athleteNumber':
        'Camisa - {playerNumber} · {playerPosition}',
    },
  },
  /* Ranking */
  ...{
    'pages.athletesOfTheWeek.card.ranking.searchUserInput.placeholder':
      'Pesquisar usuário',

    'pages.athletesOfTheWeek.card.ranking.messages.userNotFound':
      'Esse <span>usuário</span> não foi encontrado',
    'pages.athletesOfTheWeek.card.ranking.messages.userNotHasGameOnThisCard':
      'Esse usuário não realizou nenhum jogo nessa cartela',
    'pages.athletesOfTheWeek.card.ranking.messages.noRanking':
      'O <span>ranking</span> dessa cartela não está disponível',
    'pages.athletesOfTheWeek.card.ranking.messages.loadingRanking':
      'Carregando ranking...',
    'pages.athletesOfTheWeek.card.ranking.messages.loadingUserGames':
      'Carregando jogos do usuário...',
    'pages.athletesOfTheWeek.card.ranking.messages.partialInfo':
      'As informações abaixo são parciais',
    'pages.athletesOfTheWeek.card.ranking.messages.preparingStage':
      'A cartela está em fase de preparação.',
    'pages.athletesOfTheWeek.card.ranking.messages.myGamesNotFound':
      'Você não está participando desta rodada',
    'pages.athletesOfTheWeek.card.ranking.messages.myGamesPlayedOnce':
      'Você possui <strong>{totalScore}</strong> ',
    'pages.athletesOfTheWeek.card.ranking.messages.myGamesPlayedOnce.noScore':
      'Você não pontuou ainda',
    'pages.athletesOfTheWeek.card.ranking.messages.myGamesPlayedManyTimes':
      'Você pontuou em um dos seus jogos',
    'pages.athletesOfTheWeek.card.ranking.messages.myGamesPlayedManyTimes.noScore':
      'Você não pontuou em nenhum dos seus jogos',
    'pages.athletesOfTheWeek.card.ranking.messages.myGamesPlayedManyTimes.scoreAll':
      'Você pontuou em todos os jogos',
  },
  /* Results */
  ...{
    'pages.athletesOfTheWeek.card.results.messages.partialInfo':
      'As informações abaixo são parciais',

    /* AthleteCard */
    ...{
      'pages.athletesOfTheWeek.card.results.athleteCard.athleteNumber':
        'Camisa - {playerNumber} · {playerPosition}',
    },
  },
  /* UserCardGameDetails */
  ...{
    'pages.athletesOfTheWeek.card.userCardGameDetails.title':
      'Detalhes do jogo de cartela',

    'pages.athletesOfTheWeek.card.userCardGameDetails.goToCardDetailsLink':
      'Voltar para os detalhes da cartela',

    /* ------------ Messages ------------ */
    'pages.athletesOfTheWeek.card.userCardGameDetails.messages.noGame':
      'Esse <span>jogo</span> não foi encontrado',

    /* AthleteCard */
    ...{
      'pages.athletesOfTheWeek.card.userCardGameDetails.playedCardAthlete.athleteNumber':
        'Camisa - {playerNumber} · {playerPosition}',
    },
  },
};

export default {
  ...AthletesOfTheWeekTexts,
  ...CardGamesHistoryTexts,
  ...CardGameDetailsTexts,
  ...CardTexts,
};
