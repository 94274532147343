const ChatP2PContextTexts = {
  /* ---------- Messages ---------- */
  /* SubmitNewMessage */
  'contexts.chatP2PContext.messages.submitNewMessageSuccess.title':
    'Mensagem enviada!',
  'contexts.chatP2PContext.messages.submitNewMessageError.title':
    'Erro ao enviar mensagem',
  /* SubmitMessageReport */
  'contexts.chatP2PContext.messages.messageReportSubmitConfirm.title':
    'Deseja realmente enviar essa denúncia?',
  'contexts.chatP2PContext.messages.messageReportSubmitConfirm.confirmButton':
    'Enviar denúncia',
  'contexts.chatP2PContext.messages.messageReportSubmitSuccess.title':
    'Denúncia enviada!',
  'contexts.chatP2PContext.messages.messageReportSubmitSuccess.description':
    'Sua denúncia foi enviada e será analisada pelo suporte da plataforma Titan369',
  /* SubmitDeleteMessage */
  'contexts.chatP2PContext.messages.deleteMessageSubmitConfirm.title':
    'Deseja realmente excluir essa mensagem?',
  'contexts.chatP2PContext.messages.deleteMessageSubmitConfirm.confirmButton':
    'Excluir mensagem',
  'contexts.chatP2PContext.messages.deleteMessageSubmitSuccess.title':
    'Mensagem excluída!',
  'contexts.chatP2PContext.messages.deleteMessageSubmitError.title':
    'Erro ao excluir mensagem',
};

export default {
  ...ChatP2PContextTexts,
};
