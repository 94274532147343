export enum ETransactionModule {
  DEPOSIT = 'deposit',
  WITHDRAW = 'withdraw',
  TRANSFER = 'transfer',
  COACH_FRIEND = 'coach-friend',
  CHALLENGER = 'challenger',
  GAME_VS = 'game-vs',
  GAME_VS_VIP = 'game-vs-vip',
  CARD_GAME = 'card-game',
  ATHLETES_OF_THE_WEEK = 'athletes-of-the-week',
  PUSH_GAME = 'push-game',
}

export enum ETransactionType {
  PURCHASE_FIRST_PACK = 'purchase-1st-package',
  PURCHASE_SECOND_PACK = 'purchase-2nd-package',
  PURCHASE_THIRD_PACK = 'purchase-3rd-package',
  PURCHASE_FOURTH_PACK = 'purchase-4th-package',
  NEW_GAME = 'new-game',
  CANCEL_GAME = 'cancel-game',
  WINNER_PAYMENT = 'winner-payment',
}

export enum ETransactionStatus {
  WAITING = 'waiting',
  PENDING = 'pending',
  SUCCESS = 'success',
  PAID = 'paid',
  FAILED = 'failed',
  CANCELED = 'canceled',
  REFUNDED = 'refunded',
  REVERSED = 'reversed',
  WITHDRAW_PENDING = 'withdraw-pending',
  WITHDRAW_PAID = 'withdraw-paid',
  WITHDRAW = 'withdraw',
  DEPOSIT = 'deposit',
  TRANSFER = 'transfer',
}

export enum ETransactionCurrency {
  BRL = 'BRL',
}

export enum ETransactionPaymentType {
  CREDIT_CARD = 'credit_card',
  BANK_TRANSFER = 'bank_transfer',
  BOLETO = 'boleto',
  PIX = 'pix',
  PLATFORM = 'platform',
}

export interface ITransaction {
  details: {
    module: ETransactionModule;
    type: ETransactionType;
    title: string;
    description: string;
  };
  tax: number;
  status: ETransactionStatus;
  currency: ETransactionCurrency;
  paymentType: ETransactionPaymentType;
  _id: string;
  sender: {
    altName: string;
    _id: string;
    username: string;
    name: string;
  };
  receiver: {
    altName: string;
    _id: string;
    username: string;
    name: string;
  };
  amount: number;
  id: string;
  createdAt: string;
  updatedAt: string;
}
