const RankingTexts = {
  'pages.ranking.globalRanking': 'Global',

  'pages.ranking.searchUserInput.placeholder': 'Pesquisar usuário',

  'pages.ranking.coachFriend.general': 'Geral',
  'pages.ranking.coachFriend.scoreboard': 'Placar',
  'pages.ranking.coachFriend.minuteOfFirstGoal': 'Minuto do primeiro Gol',
  'pages.ranking.coachFriend.players': 'Jogadores',
  'pages.ranking.coachFriend.corner': 'Escanteios',
  'pages.ranking.coachFriend.offside': 'Impedimentos',
  'pages.ranking.coachFriend.cards': 'Cartões',

  'pages.ranking.messages.getRankingError.title':
    'Ocorreu um erro ao carregar esse ranking',
  'pages.ranking.messages.userNotFound':
    'Esse <span>usuário</span> não foi encontrado',
  'pages.ranking.messages.noRanking':
    'Esse <span>ranking</span> não está disponível',
  'pages.ranking.messages.loadingRanking': 'Carregando ranking...',
  'pages.ranking.messages.loadingUserGames': 'Carregando jogos do usuário...',
};

export default {
  ...RankingTexts,
};
