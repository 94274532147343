import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  background-color: ${props => props.theme.colors.secondary.secondary0};
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
`;

export const AthleteInfoContainer = styled.div`
  width: 100%;
  padding: 1.5rem 1rem;
  display: flex;
  justify-content: center;
  gap: 3rem;

  > div:first-child {
    flex: 1;
    display: flex;
    justify-content: flex-end;

    > h4 {
      font-size: ${props => props.theme.typograph.sizes.h4}px;
      font-weight: ${props => props.theme.typograph.weights.bold};
      line-height: 1.2;
      color: ${props => props.theme.colors.dark.dark0};
      margin: 0;
      text-align: center;

      span {
        display: block;
        font-size: ${props => props.theme.typograph.sizes.p}px;
      }
    }
  }

  > img {
    width: 6rem;
    height: 6rem;
    border-radius: 50%;
    border: 3px solid ${props => props.theme.colors.dark['dark+2']};
    object-fit: cover;
  }

  > div:last-child {
    flex: 1;
    display: flex;
    justify-content: flex-start;

    div {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 0.25rem;

      img {
        width: 2.5rem;
        height: 2.5rem;
        object-fit: cover;
      }

      p {
        font-weight: ${props => props.theme.typograph.weights.bold};
        line-height: 1.2;
        color: ${props => props.theme.colors.dark.dark0};
        margin: 0;
      }
    }
  }
`;

export const AthleteNameContainer = styled.div`
  width: 100%;
  background-color: ${props => props.theme.colors.dark['dark+1']};
  padding: 0.5rem 1rem;
  border-bottom: 1px solid ${props => props.theme.colors.dark['dark+2']};

  > h6 {
    width: 100%;
    font-weight: ${props => props.theme.typograph.weights.bold};
    line-height: 1.2;
    color: ${props => props.theme.colors.light.light0};
    text-transform: uppercase;
    margin: 0;
    text-align: center;
  }
`;

export const CardOptionsContainer = styled.div`
  flex: 1;
  width: 100%;
  background-color: ${props => props.theme.colors.dark['dark+1']};
  padding: 1rem;
  border-radius: 0 0 0.5rem 0.5rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  > div {
    > strong {
      font-size: ${props => props.theme.typograph.sizes.p}px;
      font-weight: ${props => props.theme.typograph.weights.bold};
      line-height: 1.2;
      color: ${props => props.theme.colors.secondary['secondary-1']};
      margin: 0 0 0.25rem;
      display: block;
    }

    > small {
      color: ${props => props.theme.colors.light['light-2']};
    }

    ul {
      margin: 0;
      margin-left: 1.5rem;

      li {
        font-size: ${props => props.theme.typograph.sizes.small}px;
        font-weight: ${props => props.theme.typograph.weights.medium};
        color: ${props => props.theme.colors.light.light0};
        line-height: 1.2;
      }
    }

    > h6 {
      line-height: 1.2;
    }
  }
`;
