import styled, { css } from 'styled-components';
import { shade } from 'polished';
import { Link } from 'react-router-dom';

interface ILastMessageInfoContainerProps {
  $isRead: boolean;
}

interface ILastMessageTimeProps {
  $hasNewMessages: boolean;
}

export const Container = styled.div``;

export const Chat = styled(Link)`
  width: 100%;
  display: flex;
  align-items: center;
  background: ${props => props.theme.colors.dark.dark0};
  text-align: start;
  border: 0;
  transition: 0.2s background;
  padding: 8px 0;

  &:hover {
    background: ${props => shade(0.1, props.theme.colors.dark.dark0)};
  }

  & + button {
    border-top: 1px solid ${props => props.theme.colors.dark['dark+1']};
  }

  > div:nth-child(2) {
    /* Hack for prevent flex overflow */
    min-width: 0;
    flex: 1;
    margin-left: 16px;
    align-self: stretch;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    /* p {
      margin-bottom: 4px;
    } */
  }

  > div:nth-child(3) {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
  }
`;

export const LastMessageInfoContainer = styled.div<ILastMessageInfoContainerProps>`
  display: flex;
  align-items: center;

  svg {
    color: ${props =>
      !props.$isRead
        ? props.theme.colors.light['light-2']
        : props.theme.colors.secondary['secondary+1']};
    margin-right: 4px;
  }

  small {
    color: ${props => props.theme.colors.light['light-2']};
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
  }
`;

export const LastMessageTime = styled.small<ILastMessageTimeProps>`
  align-self: flex-start;
  color: ${props =>
    !props.$hasNewMessages
      ? props.theme.colors.dark['dark+4']
      : props.theme.colors.secondary.secondary0};
  /* color: ${props => props.theme.colors.dark['dark+4']}; */
  margin-left: 8px;
`;

export const UnreadMessagesIndicator = styled.div`
  width: 7px;
  height: 7px;
  background-color: ${props => props.theme.colors.secondary.secondary0};
  border-radius: 50%;

  margin-left: auto;
`;

export const LoadingAndNotFoundContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  > small {
    width: 100%;
    text-align: center;
    color: ${props => props.theme.colors.light['light-2']};
  }

  > div {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 104px;

    p {
      margin-left: 16px;
      color: ${props => props.theme.colors.light['light-2']};
    }

    h6 {
      text-align: center;

      span {
        color: ${props => props.theme.colors.primary.primary0};
      }
    }
  }
`;

export const LoadMoreChatsButton = styled.button`
  border: 0;
  width: 80%;
  max-width: 648px;
  padding: 8px;
  background: ${props => props.theme.colors.dark['dark+1']};
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.25);
  border-radius: 50px;
  color: ${props => props.theme.colors.light['light-2']};
  transition: 0.2s all;
  margin: 32px auto 16px;
  display: block;

  &:hover {
    background: ${props => shade(0.1, props.theme.colors.dark['dark+1'])};
  }

  ${props =>
    props.disabled &&
    css`
      opacity: 0.8;
    `}
`;
