const MyVersusRoomsTexts = {
  'pages.versus.myRooms.title': 'Minhas salas',

  // /* ------------ Buttons ------------ */
  'pages.versus.myRooms.submitArchiveRoomConfirmButton': 'Arquivar',
  'pages.versus.myRooms.submitUnarchiveRoomConfirmButton': 'Tornar ativa',
  'pages.versus.myRooms.goToHomeLink': 'Voltar para o início',
  'pages.versus.myRooms.goToActiveRoomsButton': 'Voltar para as salas ativas',
  'pages.versus.myRooms.showArchivedRoomsButton': 'Mostrar salas arquivadas',
  'pages.versus.myRooms.showActiveRoomsButton': 'Mostrar salas ativas',
  /* ------------ Messages ------------ */
  'pages.versus.myRooms.messages.getRoomsError.title':
    'Erro ao carregar as salas',
  'pages.versus.myRooms.messages.getArchivedRoomsError.title':
    'Erro ao carregar as salas ocultas',
  'pages.versus.myRooms.messages.submitArchiveRoomConfirm.description':
    'Deseja realmente arquivar esse sala?',
  'pages.versus.myRooms.messages.submitArchiveRoomSuccess.title':
    'Sala arquivada!',
  'pages.versus.myRooms.messages.submitUnarchiveRoomConfirm.description':
    'Deseja realmente tornar essa sala ativa?',
  'pages.versus.myRooms.messages.submitUnarchiveRoomSuccess.title':
    'Esse sala está ativa!',
  'pages.versus.myRooms.messages.noRooms':
    'Você não está participando de nenhuma <span>sala</span>',
  'pages.versus.myRooms.messages.viewingArchivedRooms':
    'Você está visualizando suas salas arquivadas',
  'pages.versus.myRooms.messages.noArchivedRooms':
    'Você não possui nenhuma <span>sala arquivada</span>',
};

const PublicVersusRoomsTexts = {
  'pages.versus.publicRooms.title': 'Salas públicas',
  'pages.versus.publicRooms.searchInput.placeholder':
    'Pesquisar pelo nome da sala, times ou usuário',

  /* ------------ Messages ------------ */
  'pages.versus.publicRooms.messages.viewingFindedOnSearchRooms':
    'Mostrando salas encontradas na busca',
  'pages.versus.publicRooms.messages.noPublicRooms':
    'Nenhuma sala pública do <span>Versus</span> disponível',
  'pages.versus.publicRooms.messages.noRooms': 'Nenhuma sala encontrada',
};

const NewVersusRoomTexts = {
  'pages.versus.newRoom.title': 'Criar nova sala',
  'pages.versus.newRoom.step1': 'Sala',
  'pages.versus.newRoom.step2': 'Partida',
  'pages.versus.newRoom.step3': 'Confirmar',

  'pages.versus.newRoom.submitNewRoomConfirmButton': 'Criar sala',

  'pages.versus.newRoom.form.roomType.validation.invalid':
    'O tipo da sala é inválido',
  'pages.versus.newRoom.form.roomType.validation.required':
    'O tipo da sala é obrigatório',
  'pages.versus.newRoom.form.name.validation.required':
    'O nome da sala é obrigatório',
  'pages.versus.newRoom.form.price.validation.required':
    'O valor do jogo é obrigatório',

  'pages.versus.newRoom.messages.blockPage.title':
    'Deseja realmente sair da criação da sala?',
  'pages.versus.newRoom.messages.getChampionshipsError.title':
    'Ocorreu um erro ao carregar os campeonatos',
  'pages.versus.newRoom.messages.getMatchsOfChampionshipError.title':
    'Ocorreu um erro ao carregar os jogos desse campeonato',
  'pages.versus.newRoom.messages.submitNewRoomConfirm.title':
    'Deseja realmente criar essa sala?',
  'pages.versus.newRoom.messages.submitNewRoomSuccess.title':
    'Sala criada com sucesso!',
  'pages.versus.newRoom.messages.submitNewRoomSuccess.description':
    'Você já pode convidar seus amigos e realizar seu jogo',
  /* Room */
  ...{
    /* roomType - form */
    'pages.versus.newRoom.form.roomType.label': 'Tipo da sala',
    'pages.versus.newRoom.form.roomType.privateRoomTitle': 'Sala particular',
    'pages.versus.newRoom.form.roomType.privateRoomDescription':
      'Apenas seus amigos e convidados podem entrar',
    'pages.versus.newRoom.form.roomType.publicRoomTitle': 'Sala pública',
    'pages.versus.newRoom.form.roomType.publicRoomDescription':
      'Todos na rede podem entrar',
    /* name - form */
    'pages.versus.newRoom.form.name.label': 'Nome da sala',
    'pages.versus.newRoom.form.name.placeholder': 'Digite o nome da sala',
    /* price - form */
    'pages.versus.newRoom.form.price.label': 'Valor do jogo',
    'pages.versus.newRoom.form.price.placeholder': 'ex.: 99',
  },
  /* Match */
  ...{
    'pages.versus.newRoom.form.selectedChampionship.label': 'Campeonato',
    'pages.versus.newRoom.form.selectedMatch.label': 'Partida',

    'pages.versus.newRoom.form.matchInfo.date.label': 'Data e hora',
    'pages.versus.newRoom.form.matchInfo.place.label': 'Local',

    'pages.versus.newRoom.messages.selectChampionship':
      'Selecione um campeonato',
    'pages.versus.newRoom.messages.loadingChampionships':
      'Carregando campeonatos...',
    'pages.versus.newRoom.messages.selectMatch': 'Selecione uma partida',
    'pages.versus.newRoom.messages.loadingMatchs':
      'Carregando partidas do campeonato...',
    'pages.versus.newRoom.messages.noChampionshipMatchs':
      'Esse campeonato não possui <span>partidas</span> disponíveis',
  },
};

const VersusRoomTexts = {
  'pages.versus.room.submitRejectInviteForThisRoomConfirmButton': 'Recusar',
  'pages.versus.room.submitAcceptInviteForThisRoomConfirmButton': 'Aceitar',
  'pages.versus.room.submitGetInOnPublicRoomConfirmButton':
    'Participar da sala',
  'pages.versus.room.submitDeleteRoomConfirmButton': 'Excluir sala',
  'pages.versus.room.submitLogOutForThisRoomConfirmButton': 'Sair da sala',
  'pages.versus.room.submitRemoveOpponentConfirmButton': 'Remover participante',
  'pages.versus.room.acceptInviteButton': 'Aceitar convite',
  'pages.versus.room.rejectInviteButton': 'Recusar',
  'pages.versus.room.goToListRoomsLink': 'Voltar para a listagem de salas',

  'pages.versus.room.messages.submitRejectInviteForThisRoomConfirm.title':
    'Deseja realmente recusar a solicitação de ingresso nessa sala?',
  'pages.versus.room.messages.submitAcceptInviteForThisRoomConfirm.title':
    'Deseja realmente aceitar a solicitação de ingresso nessa sala?',
  'pages.versus.room.messages.submitGetInOnPublicRoomConfirm.title':
    'Deseja realmente participar dessa sala?',
  'pages.versus.room.messages.submitDeleteRoomConfirm.title':
    'Deseja realmente excluir essa sala?',
  'pages.versus.room.messages.submitDeleteRoomLoading': 'Excluindo sala...',
  'pages.versus.room.messages.submitDeleteRoomSuccess.title':
    'Essa sala foi excluída!',
  'pages.versus.room.messages.submitLogOutForThisRoomConfirm.title':
    'Deseja realmente sair dessa sala?',
  'pages.versus.room.messages.submitLogOutForThisRoomLoading':
    'Saindo da sala...',
  'pages.versus.room.messages.submitLogOutForThisRoomSuccess.title':
    'Você saiu da sala!',
  'pages.versus.room.messages.submitLogOutForThisRoomSuccess.description':
    'Você não é mais participante dessa sala',
  'pages.versus.room.messages.submitRemoveOpponentConfirm.title':
    'Deseja realmente remover o adversário da sala?',
  'pages.versus.room.messages.submitRemoveOpponentLoading':
    'Removendo adversário...',
  'pages.versus.room.messages.submitRemoveOpponentSuccess.title':
    'Adversário removido!',
  'pages.versus.room.messages.submitRemoveOpponentSuccess.description':
    'Esse usuário não faz mais parte dessa sala',
  'pages.versus.room.messages.inviteDescription':
    '{playerName}({playerUsername}) lhe convidou para jogar com ele por R$ {price}',
  'pages.versus.room.messages.itsNotPossibleToEnterTheRoom':
    'Não é mais possível entrar nessa sala',
  'pages.versus.room.messages.youIsNotOnRoom':
    'Você não está participando dessa sala',
  'pages.versus.room.messages.roomNotFound':
    'Essa <span>sala</span> não foi encontrada',
  'pages.versus.room.messages.playerIsTheFirst':
    '<strong>{playerUsername}</strong> é o primeiro!',

  /* ChooseOpponent */
  ...{
    'pages.versus.room.chooseOpponent.searchUserInput.placeholder':
      'Pesquisar usuário',

    'pages.versus.room.chooseOpponent.submitInviteUserConfirmButton.title':
      'Enviar convite',

    'pages.versus.room.chooseOpponent.messages.searchUserError.title':
      'Ocorreu um erro ao carregar os usuários',
    'pages.versus.room.chooseOpponent.messages.submitInviteUserConfirm.title':
      'Deseja realmente enviar um convite para esse usuário?',
    'pages.versus.room.chooseOpponent.messages.submitInviteUserLoading':
      'Enviando convite...',
    'pages.versus.room.chooseOpponent.messages.submitInviteUserSuccess.title':
      'Solicitação enviada com sucesso!',
    'pages.versus.room.chooseOpponent.messages.submitInviteUserSuccess.description':
      'A solicitação de ingresso nessa sala foi enviada para o usuário selecionado',
    'pages.versus.room.chooseOpponent.messages.userNotFound':
      'Esse <span>usuário</span> não foi encontrado',
    'pages.versus.room.chooseOpponent.messages.waitForYourGameToBeAccept':
      'Aguarde um jogador aceitar sua proposta de jogo',
  },

  /* Game details */
  ...{
    'pages.versus.room.gameDetails.tabs.details': 'Detalhes',
    'pages.versus.room.gameDetails.tabs.plays': 'Jogadas',

    'pages.versus.room.gameDetails.adversary': 'Adversário',
    'pages.versus.room.gameDetails.amount': 'Acumulado',
    'pages.versus.room.gameDetails.status': 'Situação',
    'pages.versus.room.gameDetails.status.waitingFinish':
      'Aguardando finalização da partida oficial',
    'pages.versus.room.gameDetails.status.finished':
      'Partida oficial finalizada',

    'pages.versus.room.gameDetails.messages.winnerInformationIsPartial':
      'As informações do vencedor são parciais',
    'pages.versus.room.gameDetails.messages.playersDidNotScorePoints':
      'Nenhum</span> dos 2 jogadores realizaram <span>pontos',
  },

  /* Lobby */
  ...{
    'pages.versus.room.lobby.amount': 'Acumulado',
    'pages.versus.room.lobby.adversary': 'Adversário',

    'pages.versus.room.lobby.startGameConfirmButton': 'Iniciar jogo',

    'pages.versus.room.lobby.messages.startGameConfirm.title':
      'Deseja realmente iniciar o jogo?',
    'pages.versus.room.lobby.messages.startGameSuccess.title':
      'O jogo foi iniciado!',
    'pages.versus.room.lobby.messages.waitUserAcceptInvite':
      'Aguarde enquanto a solicitação enviada é correspondida',
    'pages.versus.room.lobby.messages.waitOwnerToStartGame':
      'Aguarde o dono da sala iniciar a partida',
  },

  /* Play */
  ...{
    'pages.versus.room.play.tabs.plays': 'Jogadas',

    'pages.versus.room.play.submitNewGameConfirmButton': 'Adicionar lance',
    'pages.versus.room.play.playButton': 'Jogar lance!',

    'pages.versus.room.play.messages.submitNewGameWarn.title':
      'Esse lance já foi adicionado!',
    'pages.versus.room.play.messages.submitNewGameWarn.description':
      'Escolha outro placar e tente novamente!',
    'pages.versus.room.play.messages.submitNewGameConfirm.title':
      'Deseja realmente adicionar esse lance?',
    'pages.versus.room.play.messages.submitNewGameSuccess.title':
      'Lance adicionado!',
    'pages.versus.room.play.messages.meTurnToPlay': 'É a sua vez de jogar!',
    'pages.versus.room.play.messages.adversaryTurnToPlay':
      'É a vez de {adversaryName} jogar!',
  },
};

export default {
  ...MyVersusRoomsTexts,
  ...PublicVersusRoomsTexts,
  ...NewVersusRoomTexts,
  ...VersusRoomTexts,
};
