const ChatContainerTexts = {
  'components.chatContainer.title': 'Chat',
  'components.chatContainer.messageTextarea.placeholder': 'Digite uma mensagem',
  'components.chatContainer.loadingMessages': 'Carregando messagens...',
  /* Buttons */
  'components.chatContainer.loadMoreMessagesButton': 'Carregar mais mensagens',
  /* Messages */
  'components.chatContainer.messages.startVoiceMessageRecordError.title':
    'Permissão de acesso ao Microfone bloqueada',
  'components.chatContainer.messages.startVoiceMessageRecordError.description':
    'Adicione a permissão de acesso ao Microfone e atualize a página para enviar mensagens de áudio',
};

const ChatContainerMessageTexts = {
  /* ReportMessageModal */
  'components.chatContainer.chatContainerMessage.reportMessageModal.title':
    'Denunciar mensagem',
  'components.chatContainer.chatContainerMessage.reportMessageModal.sendReportButton':
    'Enviar denúncia',
  'components.chatContainer.chatContainerMessage.reportMessageModal.reportReasonRadio.label':
    'Motivo da denúncia',
  'components.chatContainer.chatContainerMessage.reportMessageModal.reportReasonRadio.item1':
    'Conteúdo violento ou repulsivo',
  'components.chatContainer.chatContainerMessage.reportMessageModal.reportReasonRadio.item2':
    'Conteúdo de incitação ao ódio ou abusivo',
  'components.chatContainer.chatContainerMessage.reportMessageModal.reportReasonRadio.item3':
    'Atos perigosos ou nocivos',
  'components.chatContainer.chatContainerMessage.reportMessageModal.reportReasonRadio.item4':
    'Abuso infantil',
  'components.chatContainer.chatContainerMessage.reportMessageModal.reportReasonRadio.item5':
    'Promove terrorísmo',
  'components.chatContainer.chatContainerMessage.reportMessageModal.reportReasonRadio.item6':
    'Spam ou enganoso',
  'components.chatContainer.chatContainerMessage.reportMessageModal.reportReasonRadio.item7':
    'Viola meus direitos',

  /* Buttons */
  'components.chatContainer.chatContainerMessage.copyMessageButton': 'Copiar',
  'components.chatContainer.chatContainerMessage.reportMessageButton':
    'Denunciar',
  'components.chatContainer.chatContainerMessage.deleteMessageButton':
    'Excluir',
  /* Messages */
  'components.chatContainer.chatContainerMessage.messages.copyMessageToClipboardSuccess.title':
    'Mensagem copiada para área de transferência',
};

export default {
  ...ChatContainerTexts,
  ...ChatContainerMessageTexts,
};
