import styled from 'styled-components';
import { shade } from 'polished';
import { Link } from 'react-router-dom';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const UserInfo = styled(Link)`
  width: 100%;
  display: flex;
  align-items: center;
  background: ${props => props.theme.colors.dark.dark0};
  text-align: start;
  border: 0;
  transition: 0.2s background;
  padding: 8px 0;
  border-bottom: 1px solid ${props => props.theme.colors.dark['dark+1']};
  box-shadow: 0px 5px 5px -5px #00000050;
  z-index: 0;

  &:hover {
    background: ${props => shade(0.1, props.theme.colors.dark.dark0)};
  }

  > svg {
    color: ${props => props.theme.colors.light['light-2']};
    margin-right: 4px;
  }

  div:nth-child(3) {
    /* Hack for prevent flex overflow */
    min-width: 0;
    flex: 1;
    margin-left: 16px;

    p {
      margin-bottom: 4px;
    }

    small {
      color: ${props => props.theme.colors.light['light-2']};
      white-space: nowrap;
      overflow-x: hidden;
      text-overflow: ellipsis;
    }
  }
`;

export const LoadingAndNotFoundContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  > small {
    width: 100%;
    text-align: center;
    color: ${props => props.theme.colors.light['light-2']};
  }

  > div {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 104px;

    p {
      margin-left: 16px;
      color: ${props => props.theme.colors.light['light-2']};
    }

    h6 {
      text-align: center;

      span {
        color: ${props => props.theme.colors.primary.primary0};
      }
    }
  }
`;
