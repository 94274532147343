import React from 'react';

import Bullet from '@/components/Bullet';
import AthleteCardAdModal from '@/components/AthleteCardAdModal';
import LoadingUserData from '@/components/LoadingUserData';
import Header from '@/components/Header';
import LoadingWithMask from '@/components/LoadingWithMask';
import PushGameModal from '@/components/PushGameModal';

import { useAuth } from '@/context/AuthContext';
import { useLoadingMasked } from '@/context/LoadingMaskedContext';

import { Container, ContentWrapper } from './styles';

const Default: React.FC = ({ children }) => {
  const { loadingUserData } = useAuth();
  const { loadingData } = useLoadingMasked();

  if (loadingUserData) {
    return <LoadingUserData />;
  }

  return (
    <>
      {loadingData.shouldShow && (
        <LoadingWithMask description={loadingData.description} />
      )}
      <PushGameModal />
      <AthleteCardAdModal />
      <Bullet />
      <Container>
        <Header />
        <ContentWrapper>{children}</ContentWrapper>
      </Container>
    </>
  );
};

export default Default;
