const forgotPasswordTexts = {
  'pages.forgotPassword.title': 'Recuperar senha',
  /* ------------ Links ------------ */
  'pages.forgotPassword.links.signInLink': 'Voltar para o login',

  /* ------------ Form ------------ */
  /* Email */
  'pages.forgotPassword.form.email.label': 'E-mail',
  'pages.forgotPassword.form.email.placeholder': 'Digite o seu e-mail',
  'pages.forgotPassword.form.email.validation.required':
    'O e-mail é obrigatório.',
  'pages.forgotPassword.form.email.validation.invalid':
    'O e-mail informado não é válido',

  /* Submit */
  'pages.forgotPassword.form.submitButton': 'Enviar e-mail',

  /* ------------ Messages ------------ */
  'pages.forgotPassword.messages.forgotPasswordSubmitSuccess.title':
    'E-mail enviado!',
  'pages.forgotPassword.messages.forgotPasswordSubmitSuccess.description':
    'Acesse seu e-mail para recuperar senha',
  'pages.forgotPassword.messages.forgotPasswordSubmitError.title':
    'Recuperação de senha falhou',
  'pages.forgotPassword.messages.forgotPasswordSubmitError.description':
    'Ocorreu um erro ao recuperar a senha, tente novamente.',
  'pages.forgotPassword.messages.forgotPasswordValidationFailure.title':
    'Dados inválidos.',
  'pages.forgotPassword.messages.forgotPasswordValidationFailure.description':
    'Tente novamente',
};

export default {
  ...forgotPasswordTexts,
};
