import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  padding: 16px;
  background-color: ${props => props.theme.colors.dark['dark+2']};
  border: 1px solid ${props => props.theme.colors.dark['dark+4']};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  > p {
    margin: 0;
  }

  > div {
    width: 100%;
    display: flex;
    gap: 8px;
    flex-wrap: wrap;

    button {
      flex: 1;

      @media only screen and (max-width: 600px) {
        width: 100%;
        flex: auto;
      }
    }
  }
`;
