import React, { useEffect, useRef } from 'react';
import lottie from 'lottie-web';
import { Link } from 'react-router-dom';

import notFoundAnimation from '../../assets/404-not-found-animation.json';
import CustomAntButton from '../../components/CustomAntButton';
import { useIntl } from '../../context/IntlContext';

import { Container, Content, AnimationContainer } from './styles';

const NotFound: React.FC = () => {
  const intl = useIntl();

  const animationContainer = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (animationContainer.current) {
      lottie.loadAnimation({
        container: animationContainer.current,
        autoplay: true,
        loop: true,
        animationData: notFoundAnimation,
      });
    }
  }, []);

  return (
    <Container>
      <Content>
        <div>
          <AnimationContainer ref={animationContainer} />
          <h4>{intl.getTranslatedText('pages.notFound.title')}</h4>
          <h6>{intl.getTranslatedText('pages.notFound.description')}</h6>
          <Link to="/">
            <CustomAntButton type="primary">
              {intl.getTranslatedText('pages.notFound.goToHomeLink')}
            </CustomAntButton>
          </Link>
        </div>
      </Content>
    </Container>
  );
};

export default NotFound;
