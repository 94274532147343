import Formatters from './formatters';
import Validate from './validate';

const utilsTexts = {
  ...Formatters,
  ...Validate,
};

export type TUtilsTexts = typeof utilsTexts;

export default utilsTexts;
