import styled from 'styled-components';

interface IContainerProps {
  $curPercentage: number;
}

interface IBarProgressKnobProps {
  $curPercentage: number;
}

function getKnobPosition(curPercentage: number): string {
  if (curPercentage < 25) {
    return `${curPercentage}%`;
    // curPercentage < 50 ? curPercentage : curPercentage - 4
  }
  if (curPercentage < 50) {
    return `${curPercentage - 2}%`;
    // curPercentage < 50 ? curPercentage : curPercentage - 4
  }
  if (curPercentage < 75) {
    return `${curPercentage - 3}%`;
    // curPercentage < 50 ? curPercentage : curPercentage - 4
  }
  return `${curPercentage - 4}%`;
}

export const Container = styled.div<IContainerProps>`
  flex: 1;
  border-radius: 5px;
  margin-left: 8px;
  /* margin: 0 20px; */
  /* height: 10px; */
  /* width: 144px; */
  height: 2px;
  background: #fff;
  display: flex;
  align-items: center;
  cursor: pointer;

  background: linear-gradient(
    to right,
    ${props => props.theme.colors.secondary['secondary+1']}
      ${props => props.$curPercentage}%,
    white 0
  );
`;

export const BarProgressKnob = styled.span<IBarProgressKnobProps>`
  position: relative;
  /* height: 16px;
  width: 16px; */
  height: 10px;
  width: 10px;
  border: 1.5px solid white;
  border-radius: 50%;
  background-color: ${props => props.theme.colors.secondary['secondary+1']};
  /* transition: 1s left; */

  /* left: ${props => getKnobPosition(props.$curPercentage)}; */
  left: calc(${props => `${props.$curPercentage}%`} - 3px);
`;
