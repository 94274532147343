import React, { useCallback } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { FiLogIn } from 'react-icons/fi';
import { Formik, Field, FieldProps } from 'formik';
import * as Yup from 'yup';

import { removeAccentsFromText } from '@/utils/formatters';
import logoImg from '../../assets/logo-vertical.svg';

import Input from '../../components/Input';
import CustomAntButton from '../../components/CustomAntButton';

import { useAuth } from '../../context/AuthContext';
import { useInternationalizationWrapper } from '../../context/InternationalizationWrapperContext';

import { showToast } from '../../hooks/showToast';
import { useIntl } from '../../context/IntlContext';

import { Container, Content, AnimationContainer, Background } from './styles';
import Select from '../../components/Select';

interface ISignInFormData {
  username: string;
  password: string;
}

const SignIn: React.FC = () => {
  const history = useHistory();
  const { getTranslatedText } = useIntl();
  const { locale, handleChangeLocale } = useInternationalizationWrapper();
  const signInFormData: ISignInFormData = {
    username: '',
    password: '',
  };

  const { signIn } = useAuth();

  const handleSubmit = useCallback(
    async (data: ISignInFormData) => {
      try {
        await signIn({ username: data.username, password: data.password });

        const pathName = localStorage.getItem(
          '@LanceFutebol:TargetRoutePathName',
        );
        localStorage.removeItem('@LanceFutebol:TargetRoutePathName');

        history.push(pathName || '/home');
      } catch (err) {
        showToast({
          type: 'error',
          title: getTranslatedText('pages.signIn.signInSubmitError.title'),
          description: getTranslatedText(
            'pages.signIn.signInSubmitError.description',
          ),
        });
      }

      return '';
    },
    [getTranslatedText, history, signIn],
  );

  return (
    <Container>
      <Content>
        <AnimationContainer>
          <img src={logoImg} alt="Titan369" />

          <Formik
            initialValues={signInFormData}
            validationSchema={Yup.object().shape({
              username: Yup.string().required(
                getTranslatedText('pages.signIn.form.username.validation'),
              ),
              password: Yup.string().required(
                getTranslatedText('pages.signIn.form.password.validation'),
              ),
            })}
            onSubmit={handleSubmit}
          >
            {formikProps => (
              <form onSubmit={formikProps.handleSubmit}>
                <h5>{getTranslatedText('pages.signIn.title')}</h5>
                <Field name="username">
                  {({ field, meta }: FieldProps) => {
                    return (
                      <Input
                        {...field}
                        label={getTranslatedText(
                          'pages.signIn.form.username.label',
                        )}
                        placeholder={getTranslatedText(
                          'pages.signIn.form.username.placeholder',
                        )}
                        onChange={e => {
                          const value = removeAccentsFromText(e.target.value);

                          e.target.value = value;
                          field.onChange(e);
                        }}
                        id="SignInUsername"
                        type="text"
                        error={meta?.touched && meta?.error && meta?.error}
                      />
                    );
                  }}
                </Field>
                <Field name="password">
                  {({ field, meta }: FieldProps) => {
                    return (
                      <Input
                        {...field}
                        label={getTranslatedText(
                          'pages.signIn.form.password.label',
                        )}
                        placeholder={getTranslatedText(
                          'pages.signIn.form.password.placeholder',
                        )}
                        id="SignInPassword"
                        type="password"
                        error={meta?.touched && meta?.error && meta?.error}
                      />
                    );
                  }}
                </Field>
                <CustomAntButton
                  type="primary"
                  htmlType="submit"
                  disabled={formikProps.isSubmitting}
                >
                  {!formikProps.isSubmitting
                    ? getTranslatedText('pages.signIn.form.submitButton')
                    : getTranslatedText(
                        'pages.signIn.form.submitButtonLoading',
                      )}
                </CustomAntButton>

                <Link to="/forgot">
                  {getTranslatedText('pages.signIn.forgotPasswordLink')}
                </Link>
              </form>
            )}
          </Formik>

          <Link to="/signup">
            <FiLogIn />
            {getTranslatedText('pages.signIn.signUpLink')}
          </Link>

          <Select
            className="ant-select-language"
            id="language"
            options={[
              {
                label: `🇧🇷   ${getTranslatedText(
                  'common.selectLanguage.pt-BR',
                )}`,
                value: 'pt-BR',
              },
              {
                label: `🇺🇸   ${getTranslatedText('common.selectLanguage.en')}`,
                value: 'en',
              },
            ]}
            value={locale}
            onChange={value => {
              if (typeof value === 'string') {
                handleChangeLocale(value);
              }
            }}
          />
        </AnimationContainer>
      </Content>
      <Background />
    </Container>
  );
};

export default SignIn;
