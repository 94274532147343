const RoutesListTexts = {
  'routes.routesList.signIn.name': 'Login',
  'routes.routesList.signUp.name': 'Register',
  'routes.routesList.forgotPassword.name': 'Forgot Password',
  'routes.routesList.home.name': 'Home',
  'routes.routesList.championships.name': 'Championship',
  'routes.routesList.championship.name': 'Championship details',
  'routes.routesList.cardGame.name': 'Card details',
  'routes.routesList.playCardGame.name': 'Play card',
  'routes.routesList.cardGameDetails.name': 'My card game details',
  'routes.routesList.userCardGameDetails.name': 'Card game details',
  'routes.routesList.cardGamesHistory.name': 'Cards history',
  'routes.routesList.coachFriend.name': 'Coach Friend',
  'routes.routesList.coachFriendNewRoom.name': 'New room',
  'routes.routesList.coachFriendRoom.name': 'Room details',
  'routes.routesList.coachFriendRoomNewGame.name': 'New game',
  'routes.routesList.coachFriendRoomMyGameDetails.name': 'My game details',
  'routes.routesList.coachFriendPublicRooms.name': 'Search public room',
  'routes.routesList.coachFriendMyRooms.name': 'My rooms',
  'routes.routesList.versus.name': 'Versus',
  'routes.routesList.versusNewRoom.name': 'New room',
  'routes.routesList.versusRoom.name': 'Room details',
  'routes.routesList.versusGame.name': 'New game',
  'routes.routesList.versusPublicRooms.name': 'Search public room',
  'routes.routesList.versusMyRooms.name': 'My rooms',
  'routes.routesList.versusVIP.name': 'Versus VIP',
  'routes.routesList.versusVIPNewRoom.name': 'New room',
  'routes.routesList.versusVIPGame.name': 'New game',
  'routes.routesList.versusVIPPublicRooms.name': 'Search public room',
  'routes.routesList.versusVIPMyRooms.name': 'My rooms',
  'routes.routesList.athletesOfTheWeek.name': 'Hat-trick',
  'routes.routesList.athleteCardGame.name': 'Card details',
  'routes.routesList.playAthleteCardGame.name': 'Play card',
  'routes.routesList.athleteCardGamesHistory.name': 'Cards history',
  'routes.routesList.athleteCardGameDetails.name': 'My card game details',
  'routes.routesList.athleteUserCardGameDetails.name': 'Card game details',
  'routes.routesList.pushGamesHistory.name': 'Push Games',
  'routes.routesList.profile.name': 'Profile',
  'routes.routesList.globalRanking.name': 'Ranking - Global',
  'routes.routesList.coachFriendRanking.name': 'Ranking - Family Vs Friends',
  'routes.routesList.versusRanking.name': 'Ranking - Versus',
  'routes.routesList.versusVIPRanking.name': 'Ranking - Versus VIP',
  'routes.routesList.cardGamesRanking.name': 'Ranking - Championship card',
  'routes.routesList.athletesOfTheWeekRanking.name': 'Ranking - Hat-trick',
  'routes.routesList.pushGamesRanking.name': 'Ranking - Push Games',
  'routes.routesList.chats.name': 'Chat',
};

const routesTexts = {
  ...RoutesListTexts,
};

export type TRoutesTexts = typeof routesTexts;

export default routesTexts;
