/* eslint-disable jsx-a11y/media-has-caption */
import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo,
} from 'react';
import { Dropdown, Menu } from 'antd';
import { FiCopy, FiPlayCircle, FiPauseCircle, FiTrash2 } from 'react-icons/fi';
import { BiCheckDouble } from 'react-icons/bi';
import * as dateFns from 'date-fns';

import { UploadRequests } from '@/services/api/requests/Upload';
import {
  IChatMessage,
  useChatP2P,
} from '../../../../../../context/ChatP2PContext';
import { useIntl } from '../../../../../../context/IntlContext';

import useAudioPlayer from '../../../../../../hooks/useAudioPlayer';

import MessageAudioBar from './MessageAudioBar';

import {
  Container,
  HourAndMessageStatusContainer,
  MessageAudioPlayerContainer,
  PlayOrStopButton,
} from './styles';
import { showToast } from '../../../../../../hooks/showToast';

interface IMessage {
  messagesContentContainerRef: React.RefObject<HTMLDivElement>;
  lastMessageOfGroup: boolean;
  message: IChatMessage;
}

const { parseISO, format } = dateFns;

const Message: React.FC<IMessage> = ({
  messagesContentContainerRef,
  lastMessageOfGroup,
  message,
}) => {
  const audioRef = useRef<HTMLAudioElement>(null);

  const intl = useIntl();
  const {
    currentPlayAudioMessageId,
    setCurrentPlayAudioMessageId,
    handleSubmitDeleteMessage,
  } = useChatP2P();

  const {
    curTime,
    duration,
    playing,
    setPlaying,
    setClickedTime,
    setFinishAudioPlay,
  } = useAudioPlayer({
    audioElement: audioRef.current,
  });
  const [dropdownVisible, setDropdownVisible] = useState('');

  const handleCloseDropdownWhenPageIsScrolled = useCallback(() => {
    setDropdownVisible('');
  }, []);

  useEffect(() => {
    const messagesContentContainer = messagesContentContainerRef?.current;

    if (messagesContentContainer) {
      messagesContentContainer.addEventListener(
        'scroll',
        handleCloseDropdownWhenPageIsScrolled,
      );
    }

    return () => {
      if (messagesContentContainer) {
        messagesContentContainer.removeEventListener(
          'scroll',
          handleCloseDropdownWhenPageIsScrolled,
        );
      }
    };
  }, [handleCloseDropdownWhenPageIsScrolled, messagesContentContainerRef]);

  useEffect(() => {
    if (currentPlayAudioMessageId !== message._id && message.voiceMsg) {
      setFinishAudioPlay();
    }
  }, [
    currentPlayAudioMessageId,
    message._id,
    message.voiceMsg,
    setFinishAudioPlay,
  ]);

  const handleCopyMessageToClipboard = useCallback(
    (messageText: string) => {
      const textArea = document.createElement('textarea');
      textArea.value = messageText;

      // Avoid scrolling to bottom
      textArea.style.top = '0';
      textArea.style.left = '0';
      textArea.style.position = 'fixed';

      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();

      document.execCommand('copy');

      document.body.removeChild(textArea);

      showToast({
        type: 'info',
        title: intl.getTranslatedText(
          'pages.chats.chat.chatMessage.messages.copyMessageToClipboardSuccess.title',
        ),
      });
    },
    [intl],
  );

  const messageContentViewer = useMemo(() => {
    if (message.text) {
      return <p>{message.text}</p>;
    }

    if (message.voiceMsg) {
      return (
        <>
          <audio
            preload="none"
            loop={false}
            ref={audioRef}
            style={{ display: 'none' }}
            src={UploadRequests.getFileUrl(message.voiceMsg._id)}
          />
          <MessageAudioPlayerContainer>
            <PlayOrStopButton
              onClick={() => {
                setPlaying(oldState => {
                  if (!oldState) {
                    setCurrentPlayAudioMessageId(message._id);
                  }

                  return !oldState;
                });
              }}
            >
              {!playing ? (
                <FiPlayCircle size={21} />
              ) : (
                <FiPauseCircle size={21} />
              )}
            </PlayOrStopButton>
            <MessageAudioBar
              duration={duration}
              curTime={curTime}
              onTimeUpdate={time => {
                setClickedTime(time);
              }}
            />
          </MessageAudioPlayerContainer>
        </>
      );
    }

    return <></>;
  }, [
    curTime,
    duration,
    message._id,
    message.text,
    message.voiceMsg,
    playing,
    setClickedTime,
    setCurrentPlayAudioMessageId,
    setPlaying,
  ]);

  return (
    <Dropdown
      overlay={
        <Menu>
          {message.text && (
            <>
              <Menu.Item
                onClick={() => {
                  if (message.text) {
                    handleCopyMessageToClipboard(message.text);
                    setDropdownVisible('');
                  }
                }}
                // danger={!showArchivedGames}
              >
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <>
                    <FiCopy style={{ marginRight: 8 }} size={19} />
                    {intl.getTranslatedText(
                      'pages.chats.chat.chatMessage.copyMessageButton',
                    )}
                  </>
                </div>
              </Menu.Item>
              <Menu.Divider />
            </>
          )}
          <Menu.Item
            onClick={() => {
              handleSubmitDeleteMessage(message._id);
              setDropdownVisible('');
            }}
            danger
          >
            <div
              style={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <>
                <FiTrash2 style={{ marginRight: 8 }} size={19} />
                {intl.getTranslatedText(
                  'pages.chats.chat.chatMessage.deleteMessageButton',
                )}
              </>
            </div>
          </Menu.Item>
        </Menu>
      }
      placement="bottomRight"
      arrow
      trigger={['contextMenu']}
      visible={dropdownVisible === message._id}
      onVisibleChange={visible => {
        if (visible) {
          setDropdownVisible(message._id);
        } else {
          setDropdownVisible('');
        }
      }}
    >
      <Container key={message._id} $lastMessageOfGroup={lastMessageOfGroup}>
        {messageContentViewer}
        <HourAndMessageStatusContainer $isRead={message.isRead}>
          {/* {message.voiceMsg && (
            <>
              {duration > 0 ? (
                <MessageAudioDurationAndCurrentTime>
                  {format(
                    addSeconds(new Date(0), parseInt(duration.toString())),
                    'mm:ss',
                  )}
                </MessageAudioDurationAndCurrentTime>
              ) : (
                <MessageAudioDurationAndCurrentTime>
                  --:--
                </MessageAudioDurationAndCurrentTime>
              )}
            </>
          )} */}
          <small>
            {format(parseISO(message.createdAt), 'p', {
              locale: intl.getDatefnsLocale(),
            })}
          </small>
          <BiCheckDouble size={18} />
        </HourAndMessageStatusContainer>
      </Container>
    </Dropdown>
  );
};

export default Message;
