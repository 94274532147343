import { shade } from 'polished';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

interface ICardGameItem {
  $archived?: boolean;
}

export const Container = styled.div`
  padding: 40px 56px;

  @media only screen and (max-width: 1199px) {
    padding: 32px 32px 56px;
  }

  @media only screen and (max-width: 600px) {
    padding: 16px 16px 56px;
  }

  > div:nth-child(3),
  > div:nth-child(4) {
    margin: 40px 0 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h6 {
      text-align: center;
      margin-bottom: 16px;

      span {
        color: ${props => props.theme.colors.primary.primary0};
      }
    }

    a {
      font-size: ${props => props.theme.typograph.sizes.p}px;
      color: ${props => props.theme.colors.light['light-2']};
      text-align: center;
      display: flex;
      align-items: center;

      &:hover {
        color: ${props => shade(0.2, props.theme.colors.light['light-2'])};
      }

      svg {
        margin-right: 8px;
      }
    }

    button {
      width: 100%;
      max-width: 400px;
    }
  }

  /* > button {
    width: 100%;
    max-width: 480px;
    margin: 0 auto;
    display: block;
  } */
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 32px;
`;

export const CardGameList = styled.ul`
  margin: 16px 0 32px;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const CardGameItem = styled(Link)<ICardGameItem>`
  width: 33%;
  margin-right: 0.33%;
  margin-bottom: 8px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${props =>
    !props.$archived
      ? props.theme.colors.dark['dark+1']
      : props.theme.colors.dark['dark+3']};
  border-radius: 8px;
  position: relative;

  button {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 1000;
  }

  @media only screen and (min-width: 1400px) {
    width: 24.5%;
    margin-right: 0.5%;
  }

  @media only screen and (max-width: 1199px) {
    width: 49%;
    margin-right: 0.5%;
  }

  @media only screen and (max-width: 600px) {
    width: 100%;
    margin-right: 0;
  }

  strong {
    color: ${props => props.theme.colors.light['light-2']};
    font-size: ${props => props.theme.typograph.sizes.p};
    margin-bottom: 24px;
  }
`;

export const LoadMoreCardsButton = styled.button`
  border: 0;
  width: 33%;
  margin-right: 0.33%;
  margin-bottom: 8px;
  padding: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.theme.colors.dark['dark+1']};
  border-radius: 8px;
  transition: 0.2s background;
  align-self: stretch;

  &:hover {
    background-color: ${props => shade(0.1, props.theme.colors.dark['dark+1'])};
  }

  ${props =>
    props.disabled &&
    css`
      cursor: not-allowed;
      opacity: 0.8;
      &:hover {
        background-color: ${props.theme.colors.dark['dark+1']};
      }
    `}

  @media only screen and (min-width: 1400px) {
    width: 24.5%;
    margin-right: 0.5%;
  }

  @media only screen and (max-width: 1199px) {
    width: 49%;
    margin-right: 0.5%;
  }

  @media only screen and (max-width: 600px) {
    width: 100%;
    margin-right: 0;
  }

  p {
    color: ${props => props.theme.colors.light['light-2']};
    text-align: center;
    align-self: stretch;
  }
`;
