import styled from 'styled-components';
import { lighten } from 'polished';

import { TSocial } from '.';

interface IShareButton {
  $social?: TSocial;
}

function getIconColor(defaultColor: string, social?: TSocial): string {
  let color = defaultColor;
  switch (social) {
    case 'facebook':
      color = 'rgb(59, 89, 152)';
      break;
    case 'twitter':
      color = 'rgb(55, 174, 226)';

      break;
    case 'whatsapp':
      color = 'rgb(37, 211, 102);';

      break;
    case 'telegram':
      color = 'rgb(55, 174, 226);';

      break;
    default:
      break;
  }
  return color;
}

export const ModalContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  > div:nth-child(1) {
    display: grid;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
    margin-bottom: 20px;
    width: 100%;
  }
  > div:nth-child(2) {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    border-radius: 4px;
    background-color: ${props => lighten(0.03, props.theme.colors.dark.dark0)};
    width: 100%;

    div {
      margin-right: 16px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: ${props => props.theme.colors.dark['dark+4']};
    }
  }
`;

export const ShareButton = styled.button<IShareButton>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: auto;
  color: ${props => props.theme.colors.light.light0};
  text-align: center;
  font-size: 16px;
  padding: 8px 16px;
  white-space: nowrap;
  border-radius: 4px;
  border: 1px solid ${props => props.theme.colors.dark['dark+1']};
  cursor: pointer;
  transition: 0.2s all;
  background: ${props => props.theme.colors.dark['dark+1']};

  &:hover {
    opacity: 0.8;
  }

  svg {
    margin-right: 8px;
    color: ${props =>
      getIconColor(props.theme.colors.light.light0, props.$social)};
  }
`;
