import React from 'react';
import Breadcrumbs from '../Breadcrumbs';

import { Container, Content } from './styles';

interface INotFoundProps {
  showBreadcrumbs?: boolean;
}

const NotFound: React.FC<INotFoundProps> = ({
  children,
  showBreadcrumbs = true,
}) => {
  return (
    <Container>
      {showBreadcrumbs && <Breadcrumbs />}
      <Content>
        <div>{children}</div>
      </Content>
    </Container>
  );
};

export default NotFound;
