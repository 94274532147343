import React, { useMemo } from 'react';
import {
  useRouteMatch,
  useLocation,
  useHistory,
  Switch,
  Route,
} from 'react-router-dom';

import { UploadRequests } from '@/services/api/requests/Upload';
import {
  Container,
  Header,
  ContentWrapper,
  Content,
  ImageWrapper,
  UserInformationsHeader,
} from './styles';

import { useAuth } from '../../context/AuthContext';
import { useIntl } from '../../context/IntlContext';

import DefaultAvatar from '../../assets/DefaultAvatar.svg';

import Details from './Details';
import ChangePassword from './ChangePassword';
import UpdateAvatar from './UpdateAvatar';

const Profile: React.FC = () => {
  const routeMatch = useRouteMatch();
  const location = useLocation();
  const history = useHistory();

  const intl = useIntl();
  const { user: me } = useAuth();

  const currentLocation = useMemo(() => {
    const page = location.pathname;

    let currentLocationName = 'main';

    if (page.includes('/update_avatar')) {
      currentLocationName = 'updateAvatar';
    } else if (
      page.includes('/edit_user_informations') ||
      page.includes('/edit_personal_data')
    ) {
      currentLocationName = 'edit';
    }

    return currentLocationName;
  }, [location.pathname]);

  return (
    <Container>
      <Header>
        <div>
          <ImageWrapper
            onClick={() => {
              if (currentLocation === 'main') {
                history.push('/profile/update_avatar');
              }
            }}
            clickEneabled={currentLocation === 'main'}
          >
            <div>
              <strong>
                {intl.getTranslatedText('common.buttons.editPhotoMaskLabel')}
              </strong>
            </div>
            <img
              src={
                me?.photo ? UploadRequests.getFileUrl(me?.photo) : DefaultAvatar
              }
              alt={me?.name || 'Avatar'}
            />
          </ImageWrapper>
          <UserInformationsHeader>
            <div>
              <div>
                <h5>{me?.name}</h5>
              </div>
              <p>{me?.username}</p>
              <p>{me?.email}</p>
            </div>
          </UserInformationsHeader>
        </div>
      </Header>
      <ContentWrapper>
        <Content>
          <Switch>
            <Route exact path={routeMatch.path}>
              <Details />
            </Route>
            <Route path={`${routeMatch.path}/change_password`}>
              <ChangePassword />
            </Route>
            <Route path={`${routeMatch.path}/update_avatar`}>
              <UpdateAvatar />
            </Route>
            <Route path={`${routeMatch.path}/edit_user_informations`}>
              {/* <EditUserInformations
              user={user}
              setUser={setUser}
              parentRouteMatch={routeMatch}
            /> */}
            </Route>
            <Route path={`${routeMatch.path}/edit_personal_data`}>
              {/* <EditPersonalData
              user={user}
              setUser={setUser}
              parentRouteMatch={routeMatch}
            /> */}
            </Route>
          </Switch>
        </Content>
      </ContentWrapper>
    </Container>
  );
};

export default Profile;
