import React from 'react';
import { Field, FieldProps } from 'formik';

import Input from '@/components/Input';
import InputMask from '@/components/InputMask';

import { TPixKeyType } from '..';

interface IPixKeyInputProps {
  pixKeyType: TPixKeyType;
}

const PixKeyInput: React.FC<IPixKeyInputProps> = ({ pixKeyType }) => {
  if (pixKeyType === 'CPF') {
    return (
      <Field name="pixKey">
        {({ field, meta }: FieldProps) => {
          return (
            <div>
              <h6>
                Confira o <span>CPF</span>
              </h6>
              <InputMask
                {...field}
                mask="999.999.999-99"
                placeholder="999.999.999-99"
                id="pixKeyCpfInput"
                type="text"
                error={meta?.touched && meta?.error && meta?.error}
                disabled
              />
              <small>Só são permitidos saques para o seu próprio CPF</small>
            </div>
          );
        }}
      </Field>
    );
  }

  if (pixKeyType === 'EMAIL') {
    return (
      <Field name="pixKey">
        {({ field, meta }: FieldProps) => {
          return (
            <div>
              <h6>
                Digite o seu <span>email</span>
              </h6>
              <Input
                {...field}
                placeholder="email@example.com"
                id="pixKeyEmailInput"
                type="email"
                error={meta?.touched && meta?.error && meta?.error}
              />
            </div>
          );
        }}
      </Field>
    );
  }

  if (pixKeyType === 'PHONE') {
    return (
      <Field name="pixKey">
        {({ field, meta }: FieldProps) => {
          return (
            <div>
              <h6>
                Digite o seu <span>celular</span>
              </h6>
              <InputMask
                {...field}
                mask="(99)99999-9999"
                id="pixKeyPhoneInput"
                placeholder="(99)99999-9999"
                type="text"
                error={meta?.touched && meta?.error && meta?.error}
              />
            </div>
          );
        }}
      </Field>
    );
  }

  return (
    <Field name="pixKey">
      {({ field, meta }: FieldProps) => {
        return (
          <div>
            <h6>
              Digite a sua <span>chave aleatória</span>
            </h6>
            <Input
              {...field}
              placeholder="Digite a chave"
              id="pixKeyRandomInput"
              type="text"
              error={meta?.touched && meta?.error && meta?.error}
            />
          </div>
        );
      }}
    </Field>
  );
};

export default PixKeyInput;
