import React from 'react';
import {
  FiInfo,
  FiAlertCircle,
  FiCheckCircle,
  FiAlertTriangle,
} from 'react-icons/fi';

import { Container } from './styles';

const icons = {
  info: <FiInfo size={20} />,
  success: <FiCheckCircle size={20} />,
  error: <FiAlertCircle size={20} />,
  warn: <FiAlertTriangle size={20} />,
};

export interface IToastContent {
  type?: 'success' | 'error' | 'warn' | 'info';
  title: string;
  description?: string;
}

const ToastContent: React.FC<IToastContent> = ({
  type,
  title,
  description,
}) => {
  return (
    <Container $hasDescription={!!description}>
      {icons[type || 'info']}
      <div>
        <strong>{title}</strong>
        {description && <p>{description}</p>}
      </div>
    </Container>
  );
};

export default ToastContent;
