import styled from 'styled-components';
import { QRCodeSVG } from 'qrcode.react';

export const Container = styled.div`
  width: 100%;
  min-height: 0;
  overflow-y: scroll;
  padding: 32px 24px;

  @media only screen and (max-width: 600px) {
    padding: 32px 16px;
  }

  > p {
    margin: 0;
    width: 100%;
    text-align: center;
    color: ${props => props.theme.colors.light['light-2']};
    line-height: 120%;

    @media only screen and (max-width: 600px) {
      font-size: ${props => props.theme.typograph.sizes.small}px;
    }
  }

  ul {
    list-style: none;
    margin: 40px auto 32px;
    /* padding-bottom: 32px; */
    width: 100%;
    max-width: 504px;
    display: flex;
    flex-direction: column;
    gap: 24px;

    @media only screen and (max-width: 600px) {
      margin: 32px auto 24px;
      /* padding-bottom: 24px; */
      max-width: 100%;
      gap: 16px;
    }
  }

  hr {
    /* border-bottom: 1px solid  */
    background-color: ${props => props.theme.colors.dark['dark+3']};
    height: 1px;
    border: none;
  }
`;

export const TutorialStepContainer = styled.li`
  display: flex;
  column-gap: 8px;
  flex-wrap: wrap;

  > span {
    background-color: ${props => props.theme.colors.light['light-2']};
    width: 24px;
    height: 24px;
    border-radius: 50%;
    color: ${props => props.theme.colors.dark.dark0};
    font-size: ${props => props.theme.typograph.sizes.small}px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  > small {
    flex: 1;
    line-height: 120%;
    color: ${props => props.theme.colors.light.light0};
    text-align: start;

    @media only screen and (max-width: 600px) {
      font-size: ${props => props.theme.typograph.sizes.xs};
    }
  }

  > div {
    margin: 32px auto 8px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;

    > svg {
      margin: 0 auto;
    }
    > button {
      width: 200px;
      margin: 0 auto;
    }

    @media only screen and (max-width: 600px) {
      margin: 24px auto 8px;
    }
  }
`;

export const PurchaseContainer = styled.div`
  width: 100%;
  max-width: 456px;
  margin-top: 24px;

  @media only screen and (max-width: 600px) {
    max-width: 100%;
  }

  h5 {
    margin: 0;
    margin-bottom: 16px;
    line-height: 120%;
    color: ${props => props.theme.colors.light.light0};
    font-weight: ${props => props.theme.typograph.weights.light};

    @media only screen and (max-width: 600px) {
      font-size: ${props => props.theme.typograph.sizes.h6}px;
    }
  }

  ul {
    list-style: none;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin: 0;
  }
`;

export const PurchaseDetailsContainer = styled.div`
  width: 100%;
  background-color: ${props => props.theme.colors.dark['dark+1']};
  border: 1px solid ${props => props.theme.colors.dark['dark+4']};
  border-radius: 8px;
  padding: 8px 16px;
  display: flex;
  align-items: center;
  transition: 0.2s all;
  box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.25);

  img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 16px;
  }

  > div {
    text-overflow: ellipsis;
    overflow-x: hidden;
    white-space: nowrap;
    margin-right: 8px;

    strong {
      font-weight: ${props => props.theme.colors.dark['dark+4']};
      color: ${props => props.theme.colors.light.light0};
    }

    small {
      font-weight: ${props => props.theme.typograph.weights.light};
      text-align: start;
    }
  }
`;

export const QRCode = styled(QRCodeSVG).attrs(props => ({
  height: 200,
  width: 200,
  bgColor: props.theme.colors.dark.dark0,
  fgColor: props.theme.colors.light.light0,
}))``;
