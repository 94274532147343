import styled, { css } from 'styled-components';

interface IHeaderProps {
  $isOnMain?: boolean;
}

export const Wrapper = styled.div`
  width: 100%;
  height: -webkit-fill-available;
  position: fixed;
  z-index: 100;
`;

export const BackgroundMask = styled.div`
  width: 100%;
  height: -webkit-fill-available;
  background: rgba(0, 0, 0, 0.75);
  cursor: pointer;
`;

export const Container = styled.div`
  width: 100%;
  max-width: 800px;
  height: 85%;
  max-height: 768px;
  background-color: ${props => props.theme.colors.dark.dark0};
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  display: flex;
  flex-direction: column;

  @media only screen and (max-width: 600px) {
    height: 75vh;
  }
`;

export const Header = styled.div<IHeaderProps>`
  width: 100%;
  padding: 24px 24px 0px;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${props =>
    !props.$isOnMain &&
    css`
      padding: 24px 24px 16px;
      border-bottom: 1px solid ${props.theme.colors.dark['dark+1']};
    `}

  > div {
    display: flex;
    align-items: center;

    h6 {
      margin-left: 16px;
      font-weight: ${props => props.theme.typograph.weights.medium};
      line-height: 120%;

      @media only screen and (max-width: 600px) {
        font-size: ${props => props.theme.typograph.sizes.p}px;
      }
    }
  }

  @media only screen and (max-width: 600px) {
    padding: 16px 16px 0px;

    ${props =>
      !props.$isOnMain &&
      css`
        padding: 16px;
      `}
  }
`;

export const LoadingContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  p {
    margin: 0px 0px 4px 16px;
  }
`;
