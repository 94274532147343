const FormattersTexts = {
  /* Gender */
  'utils.formatters.gender.male': 'Male',
  'utils.formatters.gender.female': 'Female',
  'utils.formatters.gender.other': 'Others',
  'utils.formatters.gender.invalid': 'Invalid gender',
  /* Soccer Players */
  'utils.formatters.soccerPlayer.number.notFound': 'No.', // Sem número
};

export default {
  ...FormattersTexts,
};
