import styled, { keyframes } from 'styled-components';
import { shade } from 'polished';

import signInBackgroundImg from '../../assets/sign-in-background.png';

export const Container = styled.div`
  height: 100vh;

  display: flex;
  align-items: stretch;
`;
export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  place-content: center;
  width: 100%;
  max-width: 700px;

  @media only screen and (max-width: 1199px) {
    max-width: 100%;
  }
`;

const appearFromLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(-50px);
  }
  to {
    opacity: 1;
    transform: translateX(0px);
  }
`;

export const AnimationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  animation: ${appearFromLeft} 1s;

  @media only screen and (max-width: 600px) {
    width: 100%;

    form {
      width: 100% !important;
      padding: 0 16px !important;
    }
  }

  img {
    width: 178px;
  }

  form {
    margin: 104px 0 16px;
    width: 382px;
    text-align: center;

    h5 {
      margin-bottom: 40px;
    }

    button {
      width: 100%;
      margin-top: 8px;
    }

    a {
      color: #f4ede8;
      display: block;
      margin-top: 24px;
      text-decoration: none;
      transition: color 0.2s;

      &:hover {
        color: ${shade(0.2, '#f4ede8')};
      }
    }
  }

  > a {
    color: ${props => props.theme.colors.primary['primary+1']};
    text-decoration: none;
    transition: color 0.2s;
    margin-bottom: 40px;

    display: flex;
    align-items: center;

    svg {
      margin-right: 16px;
    }

    &:hover {
      color: ${props => shade(0.2, props.theme.colors.primary['primary+1'])};
    }
  }
`;

export const Background = styled.div`
  flex: 1;
  background: url(${signInBackgroundImg}) no-repeat center;
  background-size: cover;

  @media only screen and (max-width: 1199px) {
    display: none;
  }
`;
