import React from 'react';

import { useIntl } from '../../../../context/IntlContext';

import { Container } from './styles';
import { IResultsProps } from './types';
import Card from './Card';
import { ECardTypeEnum } from '../../types';

const Results: React.FC<IResultsProps> = ({ cardGame }) => {
  const intl = useIntl();
  const { cardType, athletes } = cardGame;

  return (
    <Container>
      {cardType === ECardTypeEnum.RUNNING && (
        <small>
          {intl.getTranslatedText(
            'pages.athletesOfTheWeek.card.results.messages.partialInfo',
          )}
        </small>
      )}
      <ul>
        {athletes.map(athlete => (
          <Card
            key={athlete._athlete._id}
            athlete={athlete}
            cardGame={cardGame}
          />
        ))}
      </ul>
    </Container>
  );
};

export default Results;
