import React, { useState, useMemo, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'antd';

import {
  Container,
  TitlePageContainer,
  AvatarCropperContainer,
  ButtonsContainer,
} from './styles';

import AvatarCropper from '../../../components/AvatarCropper';
import useBeforeUnload from '../../../hooks/useBeforeUnload';
import { useAuth } from '../../../context/AuthContext';
import { useIntl } from '../../../context/IntlContext';

interface INewAvatar {
  file: File | null;
  previewUrl: string;
}

const UpdateAvatar: React.FC = () => {
  const history = useHistory();

  const intl = useIntl();
  const { updateAvatar, loadingUpdateAvatar } = useAuth();

  const [newAvatar, setNewAvatar] = useState<INewAvatar>({
    file: null,
    previewUrl: '',
  });
  const [haveChanges, setHaveChanges] = useState(false);

  useBeforeUnload({
    when: haveChanges,
    message: 'Deseja realmente sair? As alterações serão perdidas',
  });

  const unblockPage = useMemo(() => {
    const messageComponents = {
      title: intl.getTranslatedText(
        'pages.profile.updateAvatar.messages.unblockPageConfirm.title',
      ),
      content: intl.getTranslatedText('common.messages.changesWillBeLost'),
      cancelText: intl.getTranslatedText('common.buttons.goBack'),
      okText: intl.getTranslatedText('common.buttons.cancel'),
    };

    if (haveChanges) {
      return history.block(JSON.stringify(messageComponents));
    }
    return () => {
      // console.log()
    };
  }, [haveChanges, history, intl]);

  useEffect(() => {
    return () => {
      unblockPage();
    };
  }, [unblockPage]);

  return (
    <Container>
      <TitlePageContainer>
        <h5>{intl.getTranslatedText('pages.profile.updateAvatar.title')}</h5>
        <Button onClick={() => history.push('/profile')} danger type="text">
          {intl.getTranslatedText('common.buttons.cancel')}
        </Button>
      </TitlePageContainer>
      <AvatarCropperContainer>
        <AvatarCropper
          croppedAvatar={newAvatar}
          setCroppedAvatar={setNewAvatar}
          setHaveChanges={setHaveChanges}
          required
          selectImageButtonLabel={intl.getTranslatedText(
            'pages.profile.updateAvatar.selectImageButton',
          )}
        />
        {newAvatar?.previewUrl && (
          <ButtonsContainer>
            <Button
              onClick={async () => {
                await updateAvatar(newAvatar.file);
                unblockPage();
                history.push('/profile');
              }}
              disabled={loadingUpdateAvatar}
              loading={loadingUpdateAvatar}
              type="primary"
            >
              {intl.getTranslatedText(
                'pages.profile.updateAvatar.updateAvatarButton',
              )}
            </Button>
          </ButtonsContainer>
        )}
      </AvatarCropperContainer>
    </Container>
  );
};

export default UpdateAvatar;
