import styled from 'styled-components';

export const Container = styled.div`
  /* @media only screen and (max-width: 1199px) {
  } */
`;

export const ContentWrapper = styled.div`
  /* min-height: calc(100vh - 72px); */
  transform: translateY(72px);

  @media only screen and (max-width: 1199px) {
    /* min-height: calc(100vh - 61px); */
    transform: translateY(61px);
  }
  /* SCROLL ONLY CONTENT AND FIX HEADER */
  /* @media only screen and (max-width: 1024px) {
    display: flex;
    flex-wrap: wrap;
    max-width: 100vw;
  } */
`;
