import React from 'react';
import { Select as SelectAnt } from 'antd';
import { SelectProps as AntSelectProps, SelectValue } from 'antd/lib/select';

import { Container } from './styles';

const { Option } = SelectAnt;

export interface ISelectOption {
  label: string;
  value: string;
}

// interface SelectProps extends Omit<SelectHTMLAttributes<HTMLSelectElement>, 'size'> {
//   label: string;
//   id: string;
//   error?: string | false;
//   bordered?: boolean;
//   options: ISelectOption[];
// }

interface SelectProps extends AntSelectProps<SelectValue> {
  label?: string;
  id: string;
  error?: string | false;
  bordered?: boolean;
  options: ISelectOption[];
}

const Select: React.FC<SelectProps> = ({
  label,
  id,
  options,
  error,
  bordered,
  ...rest
}) => {
  return (
    <Container error={!!error}>
      {label && <label htmlFor={id}>{label}</label>}
      <SelectAnt id={id} bordered={bordered} {...rest}>
        {options.map(({ label: optionLabel, ...restOptionProps }) => (
          <Option key={restOptionProps.value} {...restOptionProps}>
            {optionLabel}
          </Option>
        ))}
      </SelectAnt>
      {error && <span>{error}</span>}
    </Container>
  );
};

export default Select;
