const SignInTexts = {
  'pages.signIn.title': 'Faça seu login',
  /* ------------ Form ------------ */
  /* Username */
  'pages.signIn.form.username.label': 'Nome de usuário, email ou CPF',
  'pages.signIn.form.username.placeholder':
    'Digite seu nome de usuário, email ou CPF',
  'pages.signIn.form.username.validation': 'O usuário é obrigatório',
  /* Password */
  'pages.signIn.form.password.label': 'Senha',
  'pages.signIn.form.password.placeholder': 'Digite sua senha secreta',
  'pages.signIn.form.password.validation': 'A senha é obrigatória',
  'pages.signIn.form.submitButton': 'Entrar',
  'pages.signIn.form.submitButtonLoading': 'Carregando...',

  /* ------------ Links ------------ */
  'pages.signIn.forgotPasswordLink': 'Esqueci minha senha',
  'pages.signIn.signUpLink': 'Criar conta',

  /* ------------ Messages ------------ */
  'pages.signIn.signInSubmitError.title': 'Erro de autenticação',
  'pages.signIn.signInSubmitError.description':
    'Ocorreu um erro ao fazer o login, cheque as credenciais',
};

export default {
  ...SignInTexts,
};
