const ChampionshipTexts = {
  'pages.championship.viewersDescriptions.availableForPlay':
    'available to play',
  'pages.championship.viewersDescriptions.running': 'running',
  'pages.championship.viewersDescriptions.finished': 'finished',

  'pages.championship.views.finished': 'Finished',
  'pages.championship.views.availableForPlay': 'Available to play',
  'pages.championship.views.running': 'Running',

  'pages.championship.searchCardsInput.placeholder': 'Search card',

  // /* ------------ Buttons ------------ */
  'pages.championship.goToHomeLink': 'Back to home',
  'pages.championship.myGamesButton': 'My game',
  /* ------------ Messages ------------ */
  'pages.championship.messages.getChampionship.title':
    'Unexpected error to load championship data',
  'pages.championship.messages.getCards.title':
    'Unexpected error to load card game',
  'pages.championship.messages.loadingCards': 'Loading cards...',
  'pages.championship.messages.noCards':
    '<span>Championship</span> has no cards <span>{viewerDescription}</span>',
  'pages.championship.messages.cardNotFound': '<span>Card</span> not found',
  'pages.championship.messages.championshipNotFound':
    '<span>Championship</span> not found',
};

const CardGamesHistoryTexts = {
  'pages.championship.cardGamesHistory.title': 'Cards history',

  'pages.championship.cardGamesHistory.goToChampionshipDetailsLink':
    'Back to championship details',
  'pages.championship.cardGamesHistory.showActiveGamesButton':
    'Show active games',
  'pages.championship.cardGamesHistory.showArchivedGamesButton':
    'Show archived games',

  /* ------------ Messages ------------ */
  'pages.championship.cardGamesHistory.messages.getPlayedCardGamesError.title':
    'Error to load game history',
  'pages.championship.cardGamesHistory.messages.archiveGameConfirm.description':
    'Do you want archive this game?',
  'pages.championship.cardGamesHistory.messages.archiveGameSuccess.title':
    'Archived game!',
  'pages.championship.cardGamesHistory.messages.unarchiveGameConfirm.description':
    'Do you really want to make this game active?',
  'pages.championship.cardGamesHistory.messages.unarchiveGameSuccess.title':
    'This game are active!',
  'pages.championship.cardGamesHistory.messages.noGames':
    "You don't have <span>game</span> in this championship",
  'pages.championship.cardGamesHistory.messages.viewingArchivedGames':
    'You are seeing yours archived championship',
  'pages.championship.cardGamesHistory.messages.noArchivedGames':
    "You don't have <span>archived game</span> in this championship",
};

const MyCardGameDetailsTexts = {
  'pages.championship.myCardGameDetails.title': 'My card game details',

  'pages.championship.myCardGameDetails.goToGamesHistoryLink':
    'Back to games history',

  /* ------------ Messages ------------ */
  'pages.championship.myCardGameDetails.messages.noGame':
    '<span>Game</span> not found',

  /* PlayedCardGameMatch */
  ...{
    'pages.championship.myCardGameDetails.playedCardGameMatch.totalScore':
      '{score} score',
    'pages.championship.myCardGameDetails.playedCardGameMatch.selectedChampion.label':
      'Selected champion',
    'pages.championship.myCardGameDetails.playedCardGameMatch.selectedChampion.draw':
      'Draw!',
    'pages.championship.myCardGameDetails.playedCardGameMatch.selectedPlayers.label':
      'Players selected',
  },
};

const CardGameTexts = {
  'pages.championship.cardGame.tabs.ranking': 'Ranking',
  'pages.championship.cardGame.tabs.results': 'Results',
  'pages.championship.cardGame.tabs.myGames': 'My game',

  'pages.championship.cardGame.goToPlayConfirmButton': 'Play',
  'pages.championship.cardGame.playNowButton': 'Play now',
  'pages.championship.cardGame.goToChampionshipDetails':
    'Back to championship details',

  'pages.championship.cardGame.messages.getRankingError.title':
    'Unexpected error to load this championship ranking',
  'pages.championship.cardGame.messages.getMyGamesError.title':
    'Unexpected error to load your games',
  'pages.championship.cardGame.messages.goToPlayConfirm.title':
    'Do you want to play this card game?',
  'pages.championship.cardGame.messages.goToPlayConfirm.description':
    'This game cost R$ {price}',
  'pages.championship.cardGame.messages.noCardGame':
    '<span>Card game</span> not found',

  /* My Games */
  ...{
    'pages.championship.cardGame.myGames.loadingMyGames':
      'Loading your game...',
    'pages.championship.cardGame.myGames.noGames':
      "You <span>haven't</span> <span>played</span> this card yet",
  },
  /* Play */
  ...{
    'pages.championship.cardGame.play.title': 'Play',

    'pages.championship.cardGame.play.submitNewGameConfirmButton': 'End game',
    'pages.championship.cardGame.play.goToChampionshipDetailsLink':
      'Back to championship details',

    'pages.championship.cardGame.play.messages.unblockPageConfirm.title':
      'Do you want quit this game?',
    'pages.championship.cardGame.play.messages.submitNewGameValidationError.title':
      'Ops...',
    'pages.championship.cardGame.play.messages.submitNewGameValidationError.description':
      'Select all options available before to play',
    'pages.championship.cardGame.play.messages.submitNewGameConfirm.title':
      'Do you really want to play in this card?',
    'pages.championship.cardGame.play.messages.submitNewGameSuccess.title':
      'Card game successfully done!',
    'pages.championship.cardGame.play.messages.noCard':
      '<span>Card game</span> not found',

    /* MatchCard */
    ...{
      'pages.championship.cardGame.play.matchCard.selectPlayerModal.title':
        'Select a player {teamAbbrev}',
      'pages.championship.cardGame.play.matchCard.selectPlayerModal.playerNumber':
        'Shirt {playerNumber}',

      'pages.championship.cardGame.play.matchCard.selectTeamPlayerButton':
        'Select a player of {teamAbbrev}',
    },
  },
  /* Ranking */
  ...{
    'pages.championship.cardGame.ranking.searchUserInput.placeholder':
      'Search user',

    'pages.championship.cardGame.ranking.messages.userNotFound':
      '<span>User</span> not found',
    'pages.championship.cardGame.ranking.messages.userNotHasGameOnThisCard':
      "This user hasn't played on this card yet",
    'pages.championship.cardGame.ranking.messages.noRanking':
      '<span>Ranking</span> of this card is not available',
    'pages.championship.cardGame.ranking.messages.loadingRanking':
      'Loading ranking...',
    'pages.championship.cardGame.ranking.messages.loadingUserGames':
      "Loading user's games...",
    'pages.championship.cardGame.ranking.messages.partialInfo':
      'The information below is partial',
  },
  /* Results */
  ...{
    'pages.championship.cardGame.results.messages.partialInfo':
      'The information below is partial',

    /* MatchCard */
    ...{
      'pages.championship.cardGame.results.matchCard.firstGoal':
        'First goal made by: <b>{playerName}</b>',
      'pages.championship.cardGame.results.matchCard.noGoals':
        "This team hasn't made goal yet",
      'pages.championship.cardGame.results.matchCard.matchDidNotHappen':
        'This match no start yet',
    },
  },
  /* UserCardGameDetails */
  ...{
    'pages.championship.cardGame.userCardGameDetails.title':
      'Card game details',

    'pages.championship.cardGame.userCardGameDetails.goToCardDetailsLink':
      'Back to game details',

    /* ------------ Messages ------------ */
    'pages.championship.cardGame.userCardGameDetails.messages.noGame':
      '<span>Game</span> not found',

    /* PlayedCardGameMatch */
    ...{
      'pages.championship.cardGame.userCardGameDetails.playedCardGameMatch.totalScore':
        '{score} score',
      'pages.championship.cardGame.userCardGameDetails.playedCardGameMatch.selectedChampion.label':
        'Champion selected',
      'pages.championship.cardGame.userCardGameDetails.playedCardGameMatch.selectedChampion.draw':
        'Draw!',
      'pages.championship.cardGame.userCardGameDetails.playedCardGameMatch.selectedPlayers.label':
        'Players selected',
    },
  },
};

export default {
  ...ChampionshipTexts,
  ...CardGamesHistoryTexts,
  ...MyCardGameDetailsTexts,
  ...CardGameTexts,
};
