import styled from 'styled-components';
import { shade } from 'polished';

import notFoundBackground from '../../assets/not-found-background.jpg';

export const Container = styled.div`
  width: 100%;
  height: calc(100vh - 72px);
  background: linear-gradient(
      0deg,
      rgba(43, 43, 43, 0.95),
      rgba(43, 43, 43, 0.95)
    ),
    url(${notFoundBackground}) no-repeat center;
  background-size: cover;

  display: flex;
  flex-direction: column;

  padding: 40px 56px;

  @media only screen and (max-width: 600px) {
    padding: 16px 16px 56px;
  }

  @media only screen and (max-width: 1199px) {
    height: calc(100vh - 61px);
    padding: 32px 32px 56px;
  }
`;

export const Content = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;

  div {
    display: flex;
    align-items: center;
    flex-direction: column;

    h4 {
      text-align: center;

      span {
        color: ${props => props.theme.colors.primary.primary0};
      }
    }

    a {
      font-size: ${props => props.theme.typograph.sizes.p}px;
      color: ${props => props.theme.colors.light['light-2']};
      text-align: center;
      display: flex;
      align-items: center;

      &:hover {
        color: ${props => shade(0.2, props.theme.colors.light['light-2'])};
      }

      svg {
        margin-right: 8px;
      }
    }
  }
`;
