const ValidationTexts = {
  'utils.validate.yupCustomValidationMethods.objectId.invalid':
    'Insert a valid ObjectId',
  'utils.validate.cnpj.invalid': 'Invalid business document number',
  'utils.validate.cpf.invalid': 'Invalid document number',
};

export default {
  ...ValidationTexts,
};
