import styled from 'styled-components';
import { animated } from 'react-spring';

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: 100;
`;

export const BackgroundMask = styled.div`
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.75);
`;

export const PushGameContainer = styled(animated.div)`
  width: calc(100% - 2rem);
  max-width: 43rem;
  position: absolute;
  z-index: 105;
  left: 0;
  right: 0;
  margin: 0 auto;
  background: ${props => props.theme.colors.dark.dark0};
  border: 2px solid ${props => props.theme.colors.primary['primary-1']};
  border-radius: 8px;
  box-shadow: 0px 0px 20px rgba(0, 148, 32, 0.5);
  padding: 1rem;

  > img {
    width: 100%;
  }

  > small {
    margin-top: 0.5rem;
  }
`;

export const Header = styled.div`
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;

  > strong {
    flex: 1;
    font-size: ${props => props.theme.typograph.sizes.xmd}px;
  }
`;

export const YoutubeVideoContainer = styled.div`
  width: 100%;
  padding-top: 56.25%;
  position: relative;

  iframe {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
`;

export const UrlLink = styled.a`
  display: flex;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  background-color: ${props => props.theme.colors.dark['dark+2']};
  font-size: ${props => props.theme.typograph.sizes.small}px;
  font-weight: ${props => props.theme.typograph.weights.medium};
  color: ${props => props.theme.colors.light.light0};
  width: fit-content;
  margin: 0.5rem auto 0;
`;
