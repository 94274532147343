const ChatP2PContextTexts = {
  /* ---------- Messages ---------- */
  /* SubmitNewMessage */
  'contexts.chatP2PContext.messages.submitNewMessageSuccess.title':
    'Message sent!',
  'contexts.chatP2PContext.messages.submitNewMessageError.title':
    'Error to send message',
  /* SubmitMessageReport */
  'contexts.chatP2PContext.messages.messageReportSubmitConfirm.title':
    'Do you want to submit this report?',
  'contexts.chatP2PContext.messages.messageReportSubmitConfirm.confirmButton':
    'Send report',
  'contexts.chatP2PContext.messages.messageReportSubmitSuccess.title':
    'Report sent!',
  'contexts.chatP2PContext.messages.messageReportSubmitSuccess.description':
    'Your report has been sent and will be analyzed by our team.',
  /* SubmitDeleteMessage */
  'contexts.chatP2PContext.messages.deleteMessageSubmitConfirm.title':
    'Do you want remove this message?',
  'contexts.chatP2PContext.messages.deleteMessageSubmitConfirm.confirmButton':
    'Remove message',
  'contexts.chatP2PContext.messages.deleteMessageSubmitSuccess.title':
    'Message removed!',
  'contexts.chatP2PContext.messages.deleteMessageSubmitError.title':
    'Error to remove message',
};

export default {
  ...ChatP2PContextTexts,
};
