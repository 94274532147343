const AvatarCropperTexts = {
  'components.avatarCropper.selectImageButton': 'Select an image',
  'components.avatarCropper.requiredImage': '(required)',
  'components.avatarCropper.previewContainer.removeImageButton': 'Remove image',
  'components.avatarCropper.previewContainer.editImageButton': 'Edit image',
  'components.avatarCropper.cropContainer.removeImageButton': 'Remove',
  'components.avatarCropper.cropContainer.changeImageButton': 'Change image',
  'components.avatarCropper.cropContainer.finishButton': 'Send',
};

export default {
  ...AvatarCropperTexts,
};
