import styled, { css } from 'styled-components';

interface IHeaderProps {
  $bg?: string;
}

interface IItemPriceDropdownProps {
  $medalType?: string;
}

export const Container = styled.div`
  padding: 0 0 3.5rem;
`;

export const Header = styled.div<IHeaderProps>`
  width: 100%;
  height: 14.5rem;
  background: linear-gradient(
      180deg,
      rgba(43, 43, 43, 0.95) 0%,
      rgba(43, 43, 43, 0.9) 26.04%,
      rgba(43, 43, 43, 0.7) 51.04%,
      rgba(43, 43, 43, 0.9) 69.73%,
      rgba(43, 43, 43, 0.95) 100%
    ),
    url(${props =>
        props.$bg ||
        'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSBP2zNNRqebdTAs6101-g1ZP-AENruAakJhA&usqp=CAU'})
      no-repeat center;
  background-size: cover;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  cursor: default;
  padding: 1rem 3.5rem;

  @media only screen and (max-width: ${props => props.theme.breakpoints.xl}) {
    padding: 1rem 2rem;
  }

  @media only screen and (max-width: ${props => props.theme.breakpoints.md}) {
    padding: 1rem;
  }

  > button {
    position: absolute;
    top: 1rem;
    right: 3.5rem;

    @media only screen and (max-width: ${props => props.theme.breakpoints.xl}) {
      right: 2rem;
    }

    @media only screen and (max-width: ${props => props.theme.breakpoints.md}) {
      right: 1rem;
    }
  }

  p {
    margin-top: 0.5rem;
    color: ${props => props.theme.colors.light['light-2']};
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }

  > div:last-child {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 0.5rem;
    align-items: start;
    width: 100%;
  }

  > div:last-child > div:last-child {
    display: flex;
    align-items: center;
    column-gap: 1rem;
    row-gap: 0.5rem;
  }

  @media only screen and (max-width: ${props => props.theme.breakpoints.sm}) {
    > div:last-child {
      button {
        display: none;
      }
    }
  }
`;

export const AccumulatedValueContainer = styled.div`
  padding: 0.5rem 1rem;
  background: ${props => props.theme.colors.dark['dark+1']};
  border-radius: 0.5rem;
  border: 1px solid ${props => props.theme.colors.primary.primary0};
  display: flex;
  align-items: center;
  width: fit-content;

  svg {
    color: #e4ce00;
  }

  small {
    margin-left: 0.5rem;
  }
`;

export const RestTimerContainer = styled.div`
  display: flex;
  position: absolute;
  top: 1rem;

  div {
    display: flex;
    flex-direction: column;

    & + div {
      margin-left: 1rem;
    }

    h6 {
      color: ${props => props.theme.colors.primary.primary0};
    }
    small {
      color: ${props => props.theme.colors.light['light-2']};
    }
  }
`;

export const Content = styled.div`
  width: 100%;
  padding: 0 3.5rem;

  > div:first-child {
    width: 100%;
    display: flex;
    justify-content: center;

    button {
      margin-top: 1rem;
      margin-bottom: 0.25rem;
    }

    @media only screen and (min-width: 601px) {
      display: none;
    }
  }

  @media only screen and (max-width: ${props => props.theme.breakpoints.xl}) {
    padding: 0 2rem;
  }

  @media only screen and (max-width: ${props => props.theme.breakpoints.md}) {
    padding: 0 1rem;
  }
`;

export const ContentTab = styled.div`
  margin-top: 1rem;
`;

export const ItemPriceDropdown = styled.div<IItemPriceDropdownProps>`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.25rem 0.75rem;
  cursor: 'default';

  &:hover {
    background-color: ${props => props.theme.colors.dark.dark0};
  }

  ${({ $medalType }) =>
    $medalType === 'gold' &&
    css`
      svg {
        color: ${props => props.theme.colors.gold};
      }
    `}

  ${({ $medalType }) =>
    $medalType === 'silver' &&
    css`
      svg {
        color: ${props => props.theme.colors.silver};
      }
    `}

    ${({ $medalType }) =>
    $medalType === 'bronze' &&
    css`
      svg {
        color: ${props => props.theme.colors.bronze};
      }
    `}
`;
