import React from 'react';
import { Input as InputAnt, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import InputMaskLib, { Props as InputMaskLibProps } from 'react-input-mask';

import { Container } from './styles';

interface IInputMask extends InputMaskLibProps {
  id: string;
  label?: string;
  error?: string | false;
  showloading?: boolean;
}

const InputMask: React.FC<IInputMask> = ({
  mask,
  id,
  label,
  error,
  showloading,
  ...rest
}) => {
  const antIcon = (
    <LoadingOutlined style={{ fontSize: 16, color: '#009420' }} spin />
  );

  return (
    <Container error={!!error}>
      <label htmlFor={id}>{label}</label>
      <div>
        <InputMaskLib mask={mask} maskPlaceholder="" {...rest}>
          <InputAnt id={id} />
        </InputMaskLib>
        {showloading && <Spin indicator={antIcon} />}
      </div>
      {error && <span>{error}</span>}
    </Container>
  );
};

export default InputMask;
