import { darken } from 'polished';
import styled, { css } from 'styled-components';

interface IChangeGolAmountButtonProps {
  $action?: 'increase' | 'decrease';
}

export const Container = styled.div`
  width: 100%;
  background-color: ${props => props.theme.colors.secondary.secondary0};
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
`;

export const AthleteInfoContainer = styled.div`
  width: 100%;
  padding: 1.5rem 1rem;
  display: flex;
  justify-content: center;
  gap: 3rem;

  > div:first-child {
    flex: 1;
    display: flex;
    justify-content: flex-end;

    > h4 {
      font-size: ${props => props.theme.typograph.sizes.h4}px;
      font-weight: ${props => props.theme.typograph.weights.bold};
      line-height: 1.2;
      color: ${props => props.theme.colors.dark.dark0};
      margin: 0;
      text-align: center;

      span {
        display: block;
        font-size: ${props => props.theme.typograph.sizes.p}px;
      }
    }
  }

  > img {
    width: 6rem;
    height: 6rem;
    border-radius: 50%;
    border: 3px solid ${props => props.theme.colors.dark['dark+2']};
    object-fit: cover;
  }

  > div:last-child {
    flex: 1;
    display: flex;
    justify-content: flex-start;

    div {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 0.25rem;

      img {
        width: 2.5rem;
        height: 2.5rem;
        object-fit: cover;
      }

      p {
        font-weight: ${props => props.theme.typograph.weights.bold};
        line-height: 1.2;
        color: ${props => props.theme.colors.dark.dark0};
        margin: 0;
      }
    }
  }
`;

export const AthleteNameContainer = styled.div`
  width: 100%;
  background-color: ${props => props.theme.colors.dark['dark+1']};
  padding: 0.5rem 1rem;
  border-bottom: 1px solid ${props => props.theme.colors.dark['dark+2']};

  > h6 {
    width: 100%;
    font-weight: ${props => props.theme.typograph.weights.bold};
    line-height: 1.2;
    color: ${props => props.theme.colors.light.light0};
    text-transform: uppercase;
    margin: 0;
    text-align: center;
  }
`;

export const CardOptionsContainer = styled.div`
  flex: 1;
  width: 100%;
  background-color: ${props => props.theme.colors.dark['dark+1']};
  padding: 1rem;
  border-radius: 0 0 0.5rem 0.5rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  > div {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    > small {
      width: 100%;
      color: ${props => props.theme.colors.light['light-2']};
    }
  }
`;

export const SelectGolAmountContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 0.5rem;

  > div {
    display: flex;
    align-items: center;

    h6 {
      color: ${props => props.theme.colors.light.light0};
      margin: 0 1rem;
    }
  }

  > small {
    color: ${props => props.theme.colors.dark['dark+4']};
  }
`;

export const ChangeGolAmountButton = styled.button<IChangeGolAmountButtonProps>`
  width: 2rem;
  height: 2rem;
  background-color: ${props => props.theme.colors.dark['dark+2']};
  border: 1px solid ${props => props.theme.colors.dark['dark+3']};
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 2px 5px #00000040;
  transition: 0.2s background-color;

  &:hover {
    ${props =>
      !props.disabled &&
      css`
        background-color: ${darken(0.02, props.theme.colors.dark['dark+2'])};
      `}
  }

  ${props =>
    props.disabled &&
    css`
      opacity: 0.6;
      cursor: not-allowed;
    `}

  svg {
    ${props =>
      props.$action === 'increase' &&
      css`
        color: ${props.theme.colors.primary.primary0};
      `}
    ${props =>
      props.$action === 'decrease' &&
      css`
        color: ${props.theme.colors.danger};
      `}
  }
`;

export const ChangeGoalAmountInput = styled.input`
  background: transparent;
  color: ${props => props.theme.colors.light.light0};
  margin: 0 1rem;
  font-size: ${props => props.theme.typograph.sizes.h6}px;
  font-weight: ${props => props.theme.typograph.weights.bold};
  border: 0;
  width: 1ch;
  min-width: 1ch;
`;
