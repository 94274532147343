import { Alert } from 'antd';
import styled, { css } from 'styled-components';

interface IKeyTypeSelectProps {
  $selected?: boolean;
}

export const Container = styled.div`
  width: 100%;
  min-height: 0;
  overflow-y: auto;
  padding: 32px 24px;
  flex: 1;

  @media only screen and (max-width: 600px) {
    padding: 32px 16px;
  }
`;

export const Content = styled.div`
  width: 100%;

  form {
    width: 100%;
    max-width: 504px;
    margin: 0 auto;

    @media only screen and (max-width: 600px) {
      max-width: 100%;
    }

    > p {
      margin: 0;
      width: 100%;
      text-align: center;
      color: ${props => props.theme.colors.light['light-2']};
      line-height: 120%;

      @media only screen and (max-width: 600px) {
        font-size: ${props => props.theme.typograph.sizes.small}px;
      }
    }

    > strong {
      margin: 0 0 40px;
      width: 100%;
      color: ${props => props.theme.colors.light.light0};
      line-height: 120%;
      display: block;

      span {
        font-weight: ${props => props.theme.typograph.weights.medium};
      }

      @media only screen and (max-width: 600px) {
        font-size: ${props => props.theme.typograph.sizes.small}px;
      }
    }

    > button {
      width: 100%;
    }
  }
`;

export const InputsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 32px;
  margin-bottom: 8px;

  > div {
    width: 100%;
    display: flex;
    flex-direction: column;

    > h6 {
      font-weight: ${props => props.theme.typograph.weights.light};
      line-height: 120%;
      margin-bottom: 8px;

      span {
        font-weight: ${props => props.theme.typograph.weights.medium};
      }
    }

    > small {
      font-size: ${props => props.theme.typograph.sizes.small}px;
      font-weight: ${props => props.theme.typograph.weights.light};
      line-height: 16px;
    }
  }
`;

export const KeyTypesSelectContainer = styled.div`
  width: 100%;
  height: 72px;
  display: flex;
  margin: 40px 0px 32px;

  @media only screen and (max-width: 600px) {
    margin: 32px 0px 16px;
  }
`;

export const KeyTypeSelect = styled.button<IKeyTypeSelectProps>`
  width: 25%;
  background-color: ${props => props.theme.colors.dark['dark+1']};
  border: 1px solid ${props => props.theme.colors.dark['dark+2']};
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  transition: 0.2s all;

  svg,
  small {
    color: ${props => props.theme.colors.light.light0};
  }

  &:hover {
    opacity: 0.8;
  }

  ${props =>
    props.$selected &&
    css`
      background-color: ${props.theme.colors.dark.dark0};
      border-color: ${props.theme.colors.secondary.secondary0};

      svg,
      small {
        color: ${props.theme.colors.secondary.secondary0};
      }
    `}

  &:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }
  &:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }
`;

export const TransferAlert = styled(Alert)`
  margin-bottom: 24px;
`;
