import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  min-height: 0;
  overflow-y: scroll;
  padding: 32px 24px;
  display: flex;
  flex-direction: column;

  @media only screen and (max-width: 600px) {
    padding: 32px 16px;
  }
`;

export const Content = styled.div`
  width: 100%;

  > p {
    margin: 0;
    width: 100%;
    text-align: center;
    color: ${props => props.theme.colors.light['light-2']};
    line-height: 120%;

    @media only screen and (max-width: 600px) {
      font-size: ${props => props.theme.typograph.sizes.small}px;
    }
  }

  ul {
    list-style: none;
    margin: 40px auto 0px;
    width: 100%;
    max-width: 504px;
    display: flex;
    flex-direction: column;
    gap: 16px;

    @media only screen and (max-width: 600px) {
      margin: 32px auto 0px;
      max-width: 100%;
    }
  }
`;

export const CoinsPackContainer = styled.li`
  width: 100%;
  padding: 16px;
  background-color: ${props => props.theme.colors.dark['dark+1']};
  border: 1px solid ${props => props.theme.colors.dark['dark+4']};
  border-radius: 8px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  position: relative;

  > button {
    width: 100%;
  }

  > small {
    padding: 2px 16px;
    line-height: 120%;
    color: ${props => props.theme.colors.light.light0};
    background-color: ${props => props.theme.colors.secondary.secondary0};
    border-radius: 4px;
    position: absolute;
    top: -8px;
    right: 16px;
    width: initial;
    cursor: initial;
  }

  .ant-input-group-addon,
  .ant-input {
    background-color: ${props => props.theme.colors.dark['dark+2']};
  }
`;

export const CoinsPackDetails = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto 1fr;
  grid-template-areas:
    'pack_image pack_title'
    'pack_image pack_price';
  margin-bottom: 16px;

  img {
    width: 64px;
    height: 64px;
    border-radius: 50%;
    margin-right: 8px;
    grid-area: pack_image;
  }

  h6 {
    color: ${props => props.theme.colors.light.light0};
    margin-bottom: 8px;
    grid-area: pack_title;
    margin: 0;
    line-height: 120%;
    margin-bottom: 8px;
  }

  p {
    margin: 0;
    font-weight: ${props => props.theme.typograph.weights.light};
    color: ${props => props.theme.colors.light['light-2']};
    grid-area: pack_price;
  }
`;
