import React, { useCallback, useState, useEffect, useMemo } from 'react';
import { FiX } from 'react-icons/fi';
import { useTransition } from 'react-spring';
import { MdInsertLink } from 'react-icons/md';

import { UploadRequests } from '@/services/api/requests/Upload';
import { getYouTubeVideoId } from '@/utils/validate';
import { useAthleteCardAds } from '@/context/AthleteCardAdsContext';
import CustomAntButton from '../CustomAntButton';

import api from '../../services/api';
import socket from '../../services/socket';

import {
  Container,
  BackgroundMask,
  PushGameContainer,
  Header,
  YoutubeVideoContainer,
  UrlLink,
} from './styles';
import { AdsTypeEnum, ICardGameAd } from './types';

const AthleteCardAdModal: React.FC = () => {
  const { selectedAdOnNotificationList, setSelectedAdOnNotificationList } =
    useAthleteCardAds();

  const [ad, setAd] = useState<ICardGameAd | null>(null);

  const transitions = useTransition(!!ad, null, {
    from: {
      bottom: '-100%',
      opacity: 0,
    },
    enter: {
      bottom: '15%',
      opacity: 1,
    },
    leave: {
      bottom: '-100%',
      opacity: 0,
    },
  });

  const getAd = useCallback(async (_cardId: string, _adId: string) => {
    const { data } = await api.get<ICardGameAd>(
      `/api/athlete-card/${_cardId}/view/ad/${_adId}`,
    );
    setAd(data);
  }, []);

  const handleClose = useCallback(async () => {
    setAd(null);
  }, []);

  useEffect(() => {
    socket.on(
      `athlete-ad`,
      async (data: { uri: string; _card: string; _id: string }) => {
        getAd(data._card, data._id);
      },
    );
    return () => {
      socket.off(`athlete-ad`);
    };
  }, [getAd, handleClose]);

  useEffect(() => {
    if (selectedAdOnNotificationList) {
      const { _cardId, _adId } = selectedAdOnNotificationList;
      getAd(_cardId, _adId);
      setSelectedAdOnNotificationList(null);
    }
  }, [getAd, selectedAdOnNotificationList, setSelectedAdOnNotificationList]);

  const youtubeUrl = useMemo(() => {
    if (!ad) return '';
    const adsUrl = ad?.url;

    if (ad.type === AdsTypeEnum.IMAGE) return '';

    const youTubeVideoId = getYouTubeVideoId(adsUrl);
    return `https://www.youtube.com/embed/${youTubeVideoId}?autoplay=1`;
  }, [ad]);

  if (ad === null) {
    return <></>;
  }

  return (
    <Container>
      <BackgroundMask onClick={() => handleClose()} />
      {transitions.map(
        ({ item, key, props }) =>
          item && (
            <PushGameContainer key={key} style={props}>
              <Header>
                <strong>{ad.title}</strong>
                <CustomAntButton
                  type="text"
                  useCustomIcon
                  icon={<FiX size={20} />}
                  onClick={() => handleClose()}
                />
              </Header>
              {ad.type === AdsTypeEnum.IMAGE && (
                <img
                  src={UploadRequests.getFileUrl(ad._upload?._id as string)}
                  alt="Banner"
                />
              )}
              {ad.type === AdsTypeEnum.VIDEO && (
                <YoutubeVideoContainer>
                  <iframe
                    title={youtubeUrl}
                    src={youtubeUrl}
                    allow="autoplay"
                  />
                </YoutubeVideoContainer>
              )}
              <small>{ad.text}</small>
              <UrlLink href={ad.url} target="_blank">
                <MdInsertLink size={20} />
                Ver mais
              </UrlLink>
            </PushGameContainer>
          ),
      )}
    </Container>
  );
};

export default AthleteCardAdModal;
