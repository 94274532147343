const SignUpTexts = {
  'pages.signUp.title': 'Create an account for free',

  /* ------------ Links ------------ */
  'pages.signUp.links.signInLink': 'Back to sign in',

  /* ------------ Form ------------ */
  /* Avatar */
  'pages.signUp.form.photo.selectImageButton': `Select an image to your user avatar
  <strong>(required)</strong>`,
  'pages.signUp.form.photo.changeImageButton':
    'Click here to change your image',
  'pages.signUp.form.photo.validation.required': 'User avatar is required.',
  /* Name */
  'pages.signUp.form.name.label': 'Full name',
  'pages.signUp.form.name.placeholder': 'Type your full name',
  'pages.signUp.form.name.validation.required': 'Full name is required',
  /* username */
  'pages.signUp.form.username.label': 'Username',
  'pages.signUp.form.username.tooltipText':
    "Your username is how you'll be identified by other users on the platform and therefore should be unique.",
  'pages.signUp.form.username.placeholder': 'ex.: your-username',
  'pages.signUp.form.username.validation.invalid':
    'Must only include letters, numbers, or the following special characters: period(.), hyphen(-), and underscore(_)',
  'pages.signUp.form.username.validation.max':
    'The maximum number of characters is 30',
  'pages.signUp.form.username.validation.required': 'Username is required',
  'pages.signUp.form.username.validation.unavailable':
    'Username is already in use.',
  /* docnumber */
  'pages.signUp.form.docNumber.label': 'Document Number',
  'pages.signUp.form.docNumber.placeholder': '999999999999',
  'pages.signUp.form.docNumber.validation.required':
    'Document number is required',
  'pages.signUp.form.docNumber.validation.invalid':
    'Document number is invalid',
  'pages.signUp.form.docNumber.validation.unavailable':
    'Document number is already in use',
  /* email */
  'pages.signUp.form.email.label': 'Email',
  'pages.signUp.form.email.placeholder': 'Type your email',
  'pages.signUp.form.email.validation.required': 'Email is required',
  'pages.signUp.form.email.validation.invalid': 'Invalid email',
  'pages.signUp.form.email.validation.unavailable': 'Email is already in use',
  /* gender */
  'pages.signUp.form.gender.label': 'Gender',
  'pages.signUp.form.gender.male': 'Male',
  'pages.signUp.form.gender.female': 'Female',
  'pages.signUp.form.gender.other': 'Other',
  'pages.signUp.form.gender.validation.required': 'Gender is required',
  'pages.signUp.form.gender.validation.invalid': 'Invalid gender',
  /* password */
  'pages.signUp.form.password.label': 'Password',
  'pages.signUp.form.password.placeholder': 'Type your password',
  'pages.signUp.form.password.validation.required': 'Password is required',
  'pages.signUp.form.password.validation.min':
    'Your password needs 6 digits or more.',
  /* confirmPassword */
  'pages.signUp.form.confirmPassword.label': 'Repeat password',
  'pages.signUp.form.confirmPassword.placeholder': 'Repeat your password',
  'pages.signUp.form.confirmPassword.validation.required':
    'You should repeat your password',
  'pages.signUp.form.confirmPassword.validation.incorrect':
    'You should repeat your password correctly',

  /* phone */
  'pages.signUp.form.phone.label': 'Phone number',
  'pages.signUp.form.phone.placeholder': '+999(99)99999-9999',
  'pages.signUp.form.phone.validation.required': 'Phone Number is required',
  'pages.signUp.form.phone.validation.invalid': 'Invalid phone number',
  /* Submit */
  'pages.signUp.form.submitButton': 'Create account',
  'pages.signUp.form.submitButtonLoading': 'Loading...',

  /* ------------ Messages ------------ */
  'pages.signUp.messages.signUpSubmitAvatarError.title':
    'Error to submit user avatar',
  'pages.signUp.messages.signUpSubmitAvatarError.description':
    'Unexpected error to submit user avatar!',
  'pages.signUp.messages.signUpSubmitSuccess.title': 'Account created!',
  'pages.signUp.messages.signUpSubmitSuccess.description':
    'You can login on Titan369',
  'pages.signUp.messages.signUpSubmitError.title': 'Register fail',
  'pages.signUp.messages.signUpSubmitError.description':
    'Unexpected error to your register. Try again!',
  'pages.signUp.messages.signUpValidationFailure.title': 'Invalid data.',
  'pages.signUp.messages.signUpValidationFailure.description': 'Try again',
};

export default {
  ...SignUpTexts,
};
