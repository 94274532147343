import React, { useMemo } from 'react';
import { Switch } from 'react-router-dom';

import { getRoutesList } from './RoutesList';
import Route from './Route';

import { useIntl } from '../context/IntlContext';

import NotFound from '../pages/NotFound';

const Routes: React.FC = () => {
  const intl = useIntl();

  const routesList = useMemo(() => {
    return getRoutesList(intl);
  }, [intl]);

  return (
    <Switch>
      {routesList.map(route => (
        <Route key={route.path} {...route} />
      ))}
      <Route path="*" component={NotFound} isPrivate />
    </Switch>
  );
};

export default Routes;
