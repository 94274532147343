const NotFoundTexts = {
  'pages.notFound.title': 'Ops... Are you lost?',
  'pages.notFound.description': 'Page not found. Back here in the future!',

  'pages.notFound.goToHomeLink': 'Take me to home page!',
};

export default {
  ...NotFoundTexts,
};
