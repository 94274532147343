import styled from 'styled-components';

export interface IInputContainerProps {
  isCodeAvailable: boolean;
}

export const Container = styled.div`
  width: 100%;
  max-width: ${props => props.theme.containerWidth};
  margin: 0 auto;
  padding: 2.5rem 3.5rem;

  @media only screen and (max-width: ${props => props.theme.breakpoints.xl}) {
    padding: 2rem 2rem 3.5rem;
  }

  @media only screen and (max-width: ${props => props.theme.breakpoints.sm}) {
    padding: 1rem 1rem 3.5rem;
  }

  > ul {
    margin: 2rem 0;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1rem;

    @media only screen and (max-width: ${props => props.theme.breakpoints.xl}) {
      grid-template-columns: 1fr 1fr;
    }

    @media only screen and (max-width: ${props => props.theme.breakpoints.md}) {
      grid-template-columns: 1fr;
    }
  }

  > button {
    width: 100%;
    max-width: 30rem;
    margin: 0 auto;
    display: block;
  }
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 2rem;
`;

export const InfluencerCodeContainer = styled.div<IInputContainerProps>`
  width: 100%;
  max-width: 30.5rem;
  display: flex;
  flex-direction: column;
  background-color: ${props => props.theme.colors.dark['dark+1']};
  padding: 1rem;
  border-radius: 0.5rem;
  margin: 0 auto 2rem;

  @media only screen and (max-width: ${props => props.theme.breakpoints.md}) {
    max-width: 100%;
  }

  > div {
    width: 100%;
    display: flex;
    flex-direction: row;

    > div {
      flex: 1;

      input {
        border: 1px solid ${props => props.theme.colors.dark['dark+3']};
      }
    }

    button {
      border: 1px solid ${props => props.theme.colors.dark['dark+3']};
      margin-left: 0.5rem;
    }
  }

  > small {
    font-size: ${props => props.theme.typograph.sizes.p}px;
    font-weight: ${props => props.theme.typograph.weights.light};
    line-height: 1rem;
    margin-bottom: 0.5rem;

    span {
      font-weight: ${props => props.theme.typograph.weights.medium};
    }
  }

  > span {
    color: ${props => props.theme.colors.primary.primary0};
  }
`;
