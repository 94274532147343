import styled, { css } from 'styled-components';
import { shade } from 'polished';

interface IRecordAudioMessageFooter {
  $mode: 'record' | 'preview';
}

export const Container = styled.div`
  width: 100%;
  display: flex;
  position: realtive;
  flex-direction: column;
  /* flex: 1; */
  background: ${props => props.theme.colors.dark.dark0};
  /* ----------- Many devices ----------- */

  height: calc(100vh - 196px);

  @media only screen and (max-width: 1199px) {
    height: calc(100vh - 61px - 57px - 74px);
  }
  @media only screen and (max-width: 600px) {
    height: calc(100vh - 61px - 57px - 66px);
  }

  /* ----------- Some ANDROID devices ----------- */

  /* Portrait */
  @media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
    max-height: calc(90vh - 61px - 57px - 48px);
  }

  /* Portrait */
  @media only screen and (min-device-width: 414px) and (max-device-width: 736px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: portrait) {
    max-height: calc(90vh - 61px - 57px - 66px);
  }

  /* ----------- iPhone 6, 6S, 7, 8 and some ANDROID ----------- */

  /* Portrait */
  @media only screen and (device-width: 375px) and (device-height: 667px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
    max-height: calc(100vh - 61px - 57px - 10px);
  }

  /* ----------- iPhone 6+, 7+ and 8+ ----------- */

  /* Portrait */
  @media only screen and (device-width: 414px) and (device-height: 736px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: portrait) {
    max-height: calc(100vh - 61px - 57px - 10px);
  }

  /* ----------- iPhone X and XS ----------- */

  /* Portrait */
  @media only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait) {
    max-height: calc(100vh - 61px - 57px);
  }

  /* ----------- iPhone XS MAX ----------- */

  /* Portrait */
  @media only screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait) {
    max-height: calc(100vh - 61px - 57px);
  }

  /* ----------- iPhone XR, 11 ----------- */

  /* Portrait */
  @media only screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait) {
    max-height: calc(100vh - 61px - 57px - 178px);
  }
`;

export const Header = styled.div`
  padding: 16px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${props => props.theme.colors.dark['dark+2']};

  div {
    flex: 1;

    h6 {
      color: ${props => props.theme.colors.light.light0};
    }

    small {
      color: ${props => props.theme.colors.light['light-2']};
    }
  }

  button {
    svg {
      color: ${props => props.theme.colors.light.light0};
    }
  }
`;

export const Content = styled.div`
  flex: 1;
  padding: 0 8px 8px;
  overflow-y: scroll;
  flex-wrap: wrap;

  &::-webkit-scrollbar {
    background: ${props => props.theme.colors.dark.dark0};
    width: 8px;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: ${props => props.theme.colors.dark['dark+1']};
  }
`;

export const Footer = styled.form`
  /* padding: 8px 16px 16px; */
  padding: 8px 0;
  /* padding: 8px; */
  border-top: 1px solid ${props => props.theme.colors.dark['dark+1']};

  display: flex;
  justify-content: center;
  align-items: flex-end;

  background: ${props => props.theme.colors.dark.dark0};
  position: fixed;
  /* top: calc(100vh - 61px - 57px - 66px); */
  width: calc(100% - 56px - 56px);
  margin: auto 56px;
  left: 0;
  padding: 16px;
  textarea {
    flex: 1;
    resize: none;
    border: 1px solid ${props => props.theme.colors.dark['dark+1']};
  }
  button {
    margin-left: 8px;
    flex-shrink: 0;
  }
  /* ----------- Many devices ----------- */

  top: calc(100vh - 140px);

  @media only screen and (max-width: 1199px) {
    top: calc(100vh - 135px);
    width: calc(100% - 32px - 32px);
    margin: auto 32px;
  }
  @media only screen and (max-width: 600px) {
    top: calc(100vh - 61px - 57px - 10px);
    width: calc(100% - 16px - 16px);
    margin: auto 16px;
  }

  /* ----------- Some ANDROID devices ----------- */

  /* Portrait */
  @media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
    /* top: calc(100vh - 61px - 57px - 66px); */
    top: auto;
    bottom: -67px;
  }

  /* Portrait */
  @media only screen and (min-device-width: 414px) and (max-device-width: 736px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: portrait) {
    top: calc(100vh - 61px - 57px - 66px);
    top: auto;
    bottom: -67px;
  }

  /* ----------- iPhone 6, 6S, 7, 8 and some ANDROID ----------- */

  /* Portrait */
  @media only screen and (device-width: 375px) and (device-height: 667px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
    top: calc(100vh - 61px - 57px - 10px);
  }

  /* ----------- iPhone 6+, 7+ and 8+ ----------- */

  /* Portrait */
  @media only screen and (device-width: 414px) and (device-height: 736px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: portrait) {
    top: calc(100vh - 61px - 57px - 10px);
  }

  /* ----------- iPhone X and XS ----------- */

  /* Portrait */
  @media only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait) {
    top: calc(100vh - 61px - 57px - 10px);
  }

  /* ----------- iPhone XS MAX ----------- */

  /* Portrait */
  @media only screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait) {
    top: calc(100vh - 61px - 57px - 10px);
  }

  /* ----------- iPhone XR, 11 ----------- */

  /* Portrait */
  @media only screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait) {
    top: calc(100vh - 61px - 57px - 120px);
  }
`;

export const RecordAudioMessageFooter = styled.form<IRecordAudioMessageFooter>`
  padding: 8px 16px;
  border-top: 1px solid ${props => props.theme.colors.dark['dark+2']};
  display: flex;
  justify-content: center;
  align-items: center;

  ${props =>
    props.$mode === 'record' &&
    css`
      > button {
        margin: 0 32px;
      }
    `}

  ${props =>
    props.$mode === 'preview' &&
    css`
      > button {
        &:nth-child(2) {
          margin-left: 16px;
        }
        &:last-child {
          margin-left: 8px;
        }
      }
    `}

  > span {
    background: ${props => props.theme.colors.danger};
    border-radius: 50%;
    /* margin: 10px; */
    margin-right: 16px;
    height: 14px;
    width: 14px;

    box-shadow: 0 0 0 0 rgba(255, 77, 79, 1);
    transform: scale(1);
    animation: pulse 2s infinite;
  }

  @keyframes pulse {
    0% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(255, 77, 79, 0.7);
    }

    70% {
      transform: scale(1);
      box-shadow: 0 0 0 10px rgba(255, 77, 79, 0);
    }

    100% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(255, 77, 79, 0);
    }
  }
`;

export const NewVoiceMessagePreviewPlayerContainer = styled.div`
  display: flex;
  /* width: 100%; */
  align-items: center;
  flex: 1;
  /* cursor: default; */

  > p {
    margin-left: 8px;
  }
`;
export const PlayOrStopButton = styled.button`
  border: 0;
  background: transparent;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    color: ${props => props.theme.colors.light.light0};
  }
`;

export const LoadMoreMessagesButton = styled.button`
  border: 0;
  width: 80%;
  padding: 8px;
  background: ${props => props.theme.colors.dark['dark+3']};
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.25);
  border-radius: 50px;
  color: ${props => props.theme.colors.light['light-2']};
  transition: 0.2s all;
  margin: 16px auto;
  display: block;

  &:hover {
    background: ${props => shade(0.1, props.theme.colors.dark['dark+2'])};
  }

  ${props =>
    props.disabled &&
    css`
      opacity: 0.8;
    `}
`;

export const LoadingMessagesContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 104px;

  > small {
    width: 100%;
    text-align: center;
    color: ${props => props.theme.colors.light['light-2']};
  }

  > div {
    width: 100%;
    display: flex;
    justify-content: center;
    /* margin-top: 56px; */

    p {
      margin-left: 16px;
      color: ${props => props.theme.colors.light['light-2']};
    }

    h6 {
      text-align: center;

      span {
        color: ${props => props.theme.colors.primary.primary0};
      }
    }
  }
`;
