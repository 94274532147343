import { ICardGameDetails, IPlayedAthlete } from '../types';

export interface ICardProps {
  playedAthlete: IPlayedAthlete;
  cardDetails: ICardGameDetails;
  isCardFinished: boolean;
  isItGameMine: boolean;
  username: string;
}

export enum EOptionStatus {
  WON,
  LOST,
  WAITING,
}
