import styled from 'styled-components';

export const SelectedUserContainer = styled.div`
  width: 100%;
  padding: 1rem;
  background: ${props => props.theme.colors.dark['dark+1']};
  border: 1px solid ${props => props.theme.colors.dark['dark+2']};
  border-radius: 0.25rem;
  display: flex;
  gap: 1.25rem;
  margin-bottom: 1rem;

  > div {
    flex: 1;
  }
`;

export const SelectedUserField = styled.div`
  p + & {
    margin-top: 0.75rem;
  }

  & + & {
    margin-top: 0.5rem;
  }

  small {
    font-size: ${props => props.theme.typograph.sizes.xs}px;
    color: ${props => props.theme.colors.secondary['secondary+1']};
    font-weight: ${props => props.theme.typograph.weights.medium};
    display: block;
  }
  p {
    font-size: ${props => props.theme.typograph.sizes.p}px;
    color: ${props => props.theme.colors.light.light0};
    font-weight: ${props => props.theme.typograph.weights.medium};
    margin: 0;
  }
`;
