import React, { useCallback } from 'react';
import { Modal } from 'antd';
import { useHistory } from 'react-router-dom';
import {
  Formik,
  FastField,
  FormikProps,
  FastFieldProps,
  FormikHelpers,
} from 'formik';
import * as Yup from 'yup';

import {
  Container,
  TitlePageContainer,
  Content,
  ButtonsContainer,
} from './styles';

import api from '../../../services/api';
import { showToast } from '../../../hooks/showToast';
import { useIntl } from '../../../context/IntlContext';

import Input from '../../../components/Input';
import CustomAntButton from '../../../components/CustomAntButton';

interface IChangePasswordFormData {
  password: string;
  newPassword: string;
  confirmNewPassword: string;
}

const { confirm } = Modal;

function eneableSubmitButton({
  errors,
}: FormikProps<IChangePasswordFormData>): boolean {
  if (Object.entries(errors).length > 0) {
    return false;
  }

  return true;
}

const ChangePassword: React.FC = () => {
  const history = useHistory();

  const intl = useIntl();

  const changePasswordFormData: IChangePasswordFormData = {
    password: '',
    newPassword: '',
    confirmNewPassword: '',
  };

  const handleSubmit = useCallback(
    async (
      values: IChangePasswordFormData,
      actions: FormikHelpers<IChangePasswordFormData>,
    ) => {
      await new Promise(resolve => {
        confirm({
          title: intl.getTranslatedText(
            'pages.profile.changePassword.messages.submitChangePasswordConfirm.title',
          ),
          content: intl.getTranslatedText(
            'pages.profile.changePassword.messages.submitChangePasswordConfirm.description',
          ),
          okText: intl.getTranslatedText(
            'pages.profile.changePassword.submitChangePasswordConfirmButton',
          ),
          cancelText: intl.getTranslatedText('common.buttons.cancel'),
          okButtonProps: {
            danger: true,
          },
          onOk() {
            resolve(true);
          },
          onCancel() {
            actions.setSubmitting(false);
          },
        });
      });

      const body = {
        password: values.password,
        newPassword: values.newPassword,
      };

      try {
        await api.put(`/api/update-password/`, body);

        showToast({
          title: intl.getTranslatedText(
            'pages.profile.changePassword.messages.submitChangePasswordSuccess.title',
          ),
          description: intl.getTranslatedText(
            'pages.profile.changePassword.messages.submitChangePasswordSuccess.description',
          ),
          type: 'success',
        });
        history.push('/profile');
      } catch (error) {
        if (error?.response?.status === 403) {
          showToast({
            title: intl.getTranslatedText(
              'pages.profile.changePassword.messages.submitChangePasswordError.title',
            ),
            description: intl.getTranslatedText(
              'pages.profile.changePassword.messages.submitChangePasswordError.description',
            ),
            type: 'error',
          });
          return;
        }
        showToast({
          title: intl.getTranslatedText('common.errors.unexpectedError.title'),
          description: intl.getTranslatedText(
            'common.errors.unexpectedError.description',
          ),
          type: 'error',
        });
      }
    },
    [history, intl],
  );

  return (
    <Container>
      <TitlePageContainer>
        <h5>{intl.getTranslatedText('pages.profile.changePassword.title')}</h5>
        <CustomAntButton
          onClick={() => history.push('/profile')}
          danger
          type="text"
        >
          {intl.getTranslatedText('common.buttons.cancel')}
        </CustomAntButton>
      </TitlePageContainer>
      <Content>
        <Formik
          initialValues={changePasswordFormData}
          validationSchema={Yup.object().shape({
            password: Yup.string().required(
              intl.getTranslatedText(
                'pages.profile.changePassword.form.password.validation.required',
              ),
            ),
            newPassword: Yup.string()
              .min(
                6,
                intl.getTranslatedText(
                  'pages.profile.changePassword.form.newPassword.validation.min',
                ),
              )
              .required(
                intl.getTranslatedText(
                  'pages.profile.changePassword.form.newPassword.validation.required',
                ),
              ),
            confirmNewPassword: Yup.string().when(
              'newPassword',
              (newPassword: string, field: Yup.StringSchema) => {
                return newPassword
                  ? field
                      .required(
                        intl.getTranslatedText(
                          'pages.profile.changePassword.form.confirmNewPassword.validation.required',
                        ),
                      )
                      .oneOf(
                        [Yup.ref('newPassword')],
                        intl.getTranslatedText(
                          'pages.profile.changePassword.form.confirmNewPassword.validation.invalid',
                        ),
                      )
                  : field;
              },
            ),
          })}
          validateOnMount
          onSubmit={handleSubmit}
        >
          {formikProps => (
            <form onSubmit={formikProps.handleSubmit}>
              <FastField name="password">
                {({ field, meta }: FastFieldProps) => {
                  return (
                    <Input
                      {...field}
                      label={intl.getTranslatedText(
                        'pages.profile.changePassword.form.password.label',
                      )}
                      placeholder={intl.getTranslatedText(
                        'pages.profile.changePassword.form.password.placeholder',
                      )}
                      id="password"
                      type="password"
                      error={meta?.touched && meta?.error}
                    />
                  );
                }}
              </FastField>
              <FastField name="newPassword">
                {({ field, meta }: FastFieldProps) => {
                  return (
                    <Input
                      {...field}
                      label={intl.getTranslatedText(
                        'pages.profile.changePassword.form.newPassword.label',
                      )}
                      placeholder={intl.getTranslatedText(
                        'pages.profile.changePassword.form.newPassword.placeholder',
                      )}
                      id="newPassword"
                      type="password"
                      error={meta?.touched && meta?.error}
                    />
                  );
                }}
              </FastField>
              <FastField name="confirmNewPassword">
                {({ field, meta }: FastFieldProps) => {
                  return (
                    <Input
                      {...field}
                      label={intl.getTranslatedText(
                        'pages.profile.changePassword.form.confirmNewPassword.label',
                      )}
                      placeholder={intl.getTranslatedText(
                        'pages.profile.changePassword.form.confirmNewPassword.placeholder',
                      )}
                      id="confirmNewPassword"
                      type="password"
                      error={meta?.touched && meta?.error}
                    />
                  );
                }}
              </FastField>
              <ButtonsContainer>
                <CustomAntButton
                  htmlType="submit"
                  disabled={
                    formikProps.isSubmitting ||
                    !eneableSubmitButton(formikProps)
                  }
                  type="primary"
                >
                  {!formikProps.isSubmitting
                    ? intl.getTranslatedText(
                        'pages.profile.changePassword.submitChangePasswordConfirmButton',
                      )
                    : intl.getTranslatedText('common.messages.defaultLoading')}
                </CustomAntButton>
              </ButtonsContainer>
            </form>
          )}
        </Formik>
      </Content>
    </Container>
  );
};

export default ChangePassword;
