import styled, { css } from 'styled-components';

interface IActionButton {
  $type: 'getCoins' | 'withdraw' | 'transfer';
}

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 0;
`;

export const BalanceContainer = styled.div`
  width: 100%;
  padding: 0 16px;

  > p {
    width: 100%;
    font-size: ${props => props.theme.typograph.sizes.p};
    font-weight: ${props => props.theme.typograph.weights.light};
    color: ${props => props.theme.colors.light.light0};
    text-align: center;
    margin: 0;
    margin-bottom: 4px;
  }

  > h5 {
    width: 100%;
    text-align: center;
    margin: 0;
    color: ${props => props.theme.colors.light.light0};
    font-weight: ${props => props.theme.typograph.weights.medium};

    span {
      font-size: ${props => props.theme.typograph.sizes.h6};
    }
  }

  > div {
    width: 100%;
    max-width: 504px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    margin: 32px auto 0px;
    align-items: stretch;
  }
`;

export const ActionButton = styled.button<IActionButton>`
  flex: 1;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 8px;
  padding: 12px;
  border: 0;
  transition: 0.2s opacity;
  background: ${props => props.theme.colors.light['light-2']};

  ${props =>
    props.$type === 'getCoins' &&
    css`
      background: linear-gradient(
          0deg,
          rgba(0, 173, 38, 0.4),
          rgba(0, 173, 38, 0.4)
        ),
        #ffffff;
    `}
  ${props =>
    props.$type === 'withdraw' &&
    css`
      background: linear-gradient(
          0deg,
          rgba(255, 172, 38, 0.4),
          rgba(255, 172, 38, 0.4)
        ),
        #ffffff;
    `}

  &:hover {
    opacity: 0.8;
  }

  small,
  svg {
    color: ${props => props.theme.colors.dark['dark+1']};
  }

  small {
    display: block;
    font-size: ${props => props.theme.typograph.sizes.xs}px;
    font-weight: ${props => props.theme.typograph.weights.medium};
    text-align: start;
  }
`;

export const TransactionsContainer = styled.div`
  width: 100%;
  margin-top: 24px;
  flex: 1;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  background-color: ${props => props.theme.colors.dark['dark+2']};
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 32px;
  box-shadow: 0px -2px 9px rgba(0, 0, 0, 0.25);
  overflow-y: scroll;

  @media only screen and (max-width: 600px) {
    padding: 16px;
    margin-top: 16px;
  }

  > div {
    width: 100%;
    max-width: 456px;

    @media only screen and (max-width: 600px) {
      max-width: 100%;
    }

    h5 {
      margin: 0;
      margin-bottom: 16px;
      line-height: 120%;
      color: ${props => props.theme.colors.light.light0};
      font-weight: ${props => props.theme.typograph.weights.light};

      @media only screen and (max-width: 600px) {
        font-size: ${props => props.theme.typograph.sizes.h6}px;
      }
    }

    ul {
      list-style: none;
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 8px;
      margin: 0;
    }
  }
`;

export const PendingPurchaseContainer = styled.button`
  width: 100%;
  background-color: ${props => props.theme.colors.dark['dark+1']};
  border: 1px solid ${props => props.theme.colors.dark['dark+4']};
  border-radius: 8px;
  padding: 8px 16px;
  display: flex;
  align-items: center;
  transition: 0.2s all;
  box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.25);

  &:hover {
    opacity: 0.8;
  }

  img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 16px;
  }

  > div {
    text-overflow: ellipsis;
    overflow-x: hidden;
    white-space: nowrap;
    margin-right: 8px;

    strong {
      font-weight: ${props => props.theme.colors.dark['dark+4']};
      color: ${props => props.theme.colors.light.light0};
    }

    small {
      font-weight: ${props => props.theme.typograph.weights.light};
      text-align: start;
    }
  }
`;
