import React from 'react';
import { Router } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { ToastContainer } from 'react-toastify';

import GlobalStyle from './styles/global';
import Theme from './styles/theme';

import Routes from './routes';

import AppProvider from './context';

import history from './services/history';

import { registerYupCustomValidationMethods } from './utils/validate';

registerYupCustomValidationMethods();

const App: React.FC = () => {
  return (
    <ThemeProvider theme={Theme}>
      <Router history={history}>
        <AppProvider>
          <Routes />
          <GlobalStyle />
          <ToastContainer autoClose={5000} />
        </AppProvider>
      </Router>
    </ThemeProvider>
  );
};

export default App;
