const ChatsTexts = {
  'pages.chats.title': 'Conversas',
  'pages.chats.unreadChats': '{unreadChatsCount} não lida(s)',
};

const ChatsListTexts = {
  'pages.chats.chatsList.audioMessage': 'Mensagem de áudio',

  'pages.chats.chatsList.viewMoreChatsButton': 'Ver mais conversas',

  'pages.chats.chatsList.messages.getChatsError.title':
    'Não foi possível carregar as conversas',
  'pages.chats.chatsList.messages.loadingChats': 'Carregando conversas...',
  'pages.chats.chatsList.messages.noChats':
    'Você não possui nenhuma <span>conversa</span> com outros usuários',
};

const ChatTexts = {
  'pages.chats.chat.loadingChat': 'Carregando informações da conversa...',

  'pages.chats.chat.messageTextarea.placeholder': 'Digite uma mensagem',
  'pages.chats.chat.loadingMessages': 'Carregando messagens...',
  /* Buttons */
  'pages.chats.chat.loadMoreMessagesButton': 'Carregar mais mensagens',
  /* Messages */
  'pages.chats.chat.messages.startVoiceMessageRecordError.title':
    'Permissão de acesso ao Microfone bloqueada',
  'pages.chats.chat.messages.startVoiceMessageRecordError.description':
    'Adicione a permissão de acesso ao Microfone e atualize a página para enviar mensagens de áudio',
};

const ChatMessageTexts = {
  /* ReportMessageModal */
  'pages.chats.chat.chatMessage.reportMessageModal.title': 'Denunciar mensagem',
  'pages.chats.chat.chatMessage.reportMessageModal.sendReportButton':
    'Enviar denúncia',
  'pages.chats.chat.chatMessage.reportMessageModal.reportReasonRadio.label':
    'Motivo da denúncia',
  'pages.chats.chat.chatMessage.reportMessageModal.reportReasonRadio.item1':
    'Conteúdo violento ou repulsivo',
  'pages.chats.chat.chatMessage.reportMessageModal.reportReasonRadio.item2':
    'Conteúdo de incitação ao ódio ou abusivo',
  'pages.chats.chat.chatMessage.reportMessageModal.reportReasonRadio.item3':
    'Atos perigosos ou nocivos',
  'pages.chats.chat.chatMessage.reportMessageModal.reportReasonRadio.item4':
    'Abuso infantil',
  'pages.chats.chat.chatMessage.reportMessageModal.reportReasonRadio.item5':
    'Promove terrorísmo',
  'pages.chats.chat.chatMessage.reportMessageModal.reportReasonRadio.item6':
    'Spam ou enganoso',
  'pages.chats.chat.chatMessage.reportMessageModal.reportReasonRadio.item7':
    'Viola meus direitos',

  /* Buttons */
  'pages.chats.chat.chatMessage.copyMessageButton': 'Copiar',
  'pages.chats.chat.chatMessage.reportMessageButton': 'Denunciar',
  'pages.chats.chat.chatMessage.deleteMessageButton': 'Excluir',
  /* Messages */
  'pages.chats.chat.chatMessage.messages.copyMessageToClipboardSuccess.title':
    'Mensagem copiada para área de transferência',
};

export default {
  ...ChatsTexts,
  ...ChatsListTexts,
  ...ChatTexts,
  ...ChatMessageTexts,
};
