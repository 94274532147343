import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useRouteMatch, useHistory, Link, useLocation } from 'react-router-dom';
import { Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { FiChevronLeft } from 'react-icons/fi';

import { useBullet } from '@/context/BulletContext';
import CustomAntButton from '@/components/CustomAntButton';
import NotFound from '@/components/NotFound';
import Loading from '@/components/Loading';
import { showToast } from '@/hooks/showToast';
import api from '@/services/api';
import { useIntl } from '@/context/IntlContext';
import AppError from '@/errors/AppError';

import Input from '@/components/Input';
import { ECardTypeEnum, EGameType, ICardGame } from '../types';

import { Container, Header, InfluencerCodeContainer } from './styles';
import Card from './Card';
import { IRouteParams, IUserGameAthlete } from './types';

const { confirm } = Modal;

const PlayCardGame: React.FC = () => {
  const { search: searchLocation } = useLocation();
  const searchParams = new URLSearchParams(searchLocation);
  const influencerCodeByParams = searchParams.get('influencer_code');

  const { params } = useRouteMatch<IRouteParams>();

  const history = useHistory();

  const intl = useIntl();
  const bullet = useBullet();

  const [cardGame, setCardGame] = useState<ICardGame | null>(null);
  const [loadingCard, setLoadingCard] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [influencerCodeInputValue, setInfluencerCodeInputValue] = useState('');
  const [isInvalidInfluencerCode, setIsInvalidInfluencerCode] = useState(false);
  const [isCheckingCode, setIsCheckingCode] = useState(false);
  const [isCodeAvailable, setIsCodeAvailable] = useState(false);
  const errorCodeMessage = intl.getTranslatedText(
    'pages.athletesOfTheWeek.card.play.messages.influencerCodeError.title',
  );

  const [userGame, setUserGame] = useState<IUserGameAthlete[]>([]);

  const getCardGame = useCallback(async (): Promise<void> => {
    try {
      const { data } = await api.get<{
        doc: Omit<ICardGame, 'amount' | 'cardCount'>;
        amount: number;
        cardCount: number;
      }>(`/api/athlete-card/${params.card_game_id}`);

      if (data.doc.cardType !== ECardTypeEnum.NEW) {
        history.push(`/`);
        return;
      }

      const athletesWithAbbrevOnTeams = data.doc.athletes.map(athlete => ({
        ...athlete,
        _team: {
          ...athlete._team,
          abbrev: athlete._team.name.substring(0, 3).toUpperCase(),
        },
      }));

      setCardGame({
        ...data.doc,
        athletes: athletesWithAbbrevOnTeams,
        amount: data.amount,
        cardCount: data.cardCount,
        goalOptions: data.doc.goalOptions,
        statisticOptions: data.doc.statisticOptions,
      });
      setUserGame(
        athletesWithAbbrevOnTeams.map(
          athlete => ({ athlete, athleteScore: 0 } as IUserGameAthlete),
        ),
      );
    } catch (error) {
      showToast({
        type: 'error',
        title: intl.getTranslatedText('common.errors.unexpectedError.title'),
        description: intl.getTranslatedText(
          'common.errors.unexpectedError.description',
        ),
      });
    }
    setLoadingCard(false);
  }, [history, intl, params.card_game_id]);

  const checkIfInfluencerCodeIsAvailable = useCallback(
    async (influencerCode: string): Promise<void> => {
      try {
        setIsInvalidInfluencerCode(false);
        setIsCheckingCode(true);
        setIsCodeAvailable(false);

        const { data } = await api.get(
          `/api/athlete-card/${params.card_game_id}/check-influencer-code/${influencerCode}`,
        );

        setInfluencerCodeInputValue(influencerCode);
        if (data) {
          setIsCodeAvailable(true);
          setIsCheckingCode(false);
          setIsInvalidInfluencerCode(false);
        } else {
          setIsCodeAvailable(false);
          setIsInvalidInfluencerCode(true);
          setIsCheckingCode(false);
        }
      } catch (error) {
        setIsCheckingCode(false);
        setIsInvalidInfluencerCode(true);
      }
    },
    [params.card_game_id],
  );

  useEffect(() => {
    getCardGame();
    if (influencerCodeByParams) {
      checkIfInfluencerCodeIsAvailable(influencerCodeByParams);
    }
  }, [checkIfInfluencerCodeIsAvailable, getCardGame, influencerCodeByParams]);

  const handleSubmit = useCallback(async () => {
    try {
      const userGameChoices = userGame.map(userGameAthlete => {
        const _athleteGoal =
          cardGame?.gameType === EGameType.OPTIONS
            ? userGameAthlete._athleteGoal
            : null;
        const _athleteStatistic =
          cardGame?.gameType === EGameType.OPTIONS
            ? userGameAthlete._athleteStatistic
            : null;
        const athleteScore =
          cardGame?.gameType === EGameType.CLASSIC
            ? userGameAthlete.athleteScore
            : null;

        return {
          _team: userGameAthlete.athlete._team._id,
          _athlete: userGameAthlete.athlete._athlete._id,
          _athleteGoal,
          _athleteStatistic,
          athleteScore,
        };
      });

      await new Promise((resolve, reject) => {
        confirm({
          title: intl.getTranslatedText(
            'pages.athletesOfTheWeek.card.play.messages.submitNewGameConfirm.title',
          ),
          icon: <ExclamationCircleOutlined />,
          content: intl.getTranslatedText(
            'common.messages.actionCannotBeUndone',
          ),
          cancelText: intl.getTranslatedText('common.buttons.cancel'),
          okText: intl.getTranslatedText(
            'pages.athletesOfTheWeek.card.play.submitNewGameConfirmButton',
          ),
          onOk() {
            resolve(true);
          },
          onCancel() {
            reject(new Error('CANCEL_SEND_GAME'));
          },
        });
      });

      if (cardGame?.price) {
        const hasAvailableBalance = bullet.checkIfBalanceIsAvailableToAction(
          cardGame.price,
        );
        if (!hasAvailableBalance) return;
      }

      const body = {
        _card: cardGame?._id,
        athletes: userGameChoices,
        code: isCodeAvailable ? influencerCodeInputValue : undefined,
      };

      setIsSubmitting(true);
      await api.post('/api/played-athlete', body);
      setIsSubmitting(false);

      showToast({
        type: 'success',
        title: intl.getTranslatedText(
          'pages.athletesOfTheWeek.card.play.messages.submitNewGameSuccess.title',
        ),
      });
      history.push(`/cards/${cardGame?._id}`);
    } catch (error) {
      if (error instanceof AppError && error.options?.type === 'validation') {
        showToast({
          type: 'warn',
          title: error.title,
          description: error.description,
        });
        return;
      }
      setIsSubmitting(false);
    }
  }, [
    bullet,
    cardGame,
    history,
    intl,
    userGame,
    isCodeAvailable,
    influencerCodeInputValue,
  ]);

  const isFinishButtonEnabled = useMemo(() => {
    if (cardGame?.gameType === EGameType.OPTIONS) {
      return userGame.every(
        userGameAthlete =>
          !!userGameAthlete._athleteGoal && !!userGameAthlete._athleteStatistic,
      );
    }
    if (cardGame?.gameType === EGameType.CLASSIC) {
      return true;
    }
    return false;
  }, [cardGame?.gameType, userGame]);

  if (loadingCard) {
    return (
      <Container>
        <Header>
          <h5>
            {intl.getTranslatedText('pages.athletesOfTheWeek.card.play.title')}
          </h5>
        </Header>
        <Loading />
      </Container>
    );
  }

  if (!cardGame) {
    return (
      <NotFound>
        <h4>
          {intl.getTranslatedTextWithHTML(
            'pages.athletesOfTheWeek.card.play.messages.noCard',
          )}
        </h4>
        <Link to="/">
          <FiChevronLeft size={16} />
          {intl.getTranslatedText(
            'pages.athletesOfTheWeek.card.play.goToAthletesOfTheWeekLink',
          )}
        </Link>
      </NotFound>
    );
  }

  return (
    <Container>
      <Header>
        <h5>
          {intl.getTranslatedText('pages.athletesOfTheWeek.card.play.title')} |{' '}
          {cardGame?.name}
        </h5>
        <Link
          to={`/cards/${params.card_game_id}${
            influencerCodeByParams
              ? `?influencer_code=${influencerCodeByParams}`
              : ''
          }`}
        >
          <CustomAntButton type="text" danger>
            {intl.getTranslatedText('common.buttons.cancel')}
          </CustomAntButton>
        </Link>
      </Header>
      <ul>
        {userGame.map(userGameAthlete => (
          <Card
            key={userGameAthlete.athlete._athlete._id}
            userGameAthlete={userGameAthlete}
            goalOptions={cardGame.goalOptions}
            statisticOptions={cardGame.statisticOptions}
            setUserGame={setUserGame}
            gameType={cardGame.gameType}
          />
        ))}
      </ul>

      <InfluencerCodeContainer isCodeAvailable={!!isCodeAvailable}>
        <small>
          {intl.getTranslatedTextWithHTML(
            'pages.athletesOfTheWeek.card.play.messages.influencerCode.title',
          )}
        </small>
        <div>
          <Input
            placeholder={intl.getTranslatedText(
              'pages.athletesOfTheWeek.searchInfluencerCodeInput.placeholder',
            )}
            id="insertInfluencerCode"
            type="text"
            value={influencerCodeInputValue}
            onChange={e => {
              e.target.value = e.target.value.replace(/\s+/g, '').toUpperCase();
              setInfluencerCodeInputValue(e.target.value);
            }}
            disabled={isCodeAvailable || isCheckingCode}
            error={isInvalidInfluencerCode && errorCodeMessage}
          />

          {isCodeAvailable ? (
            <>
              <CustomAntButton
                htmlType="button"
                type="default"
                danger
                onClick={() => setIsCodeAvailable(false)}
              >
                {intl.getTranslatedText(
                  'pages.athletesOfTheWeek.card.play.removeInfluencerCode',
                )}
              </CustomAntButton>
            </>
          ) : (
            <CustomAntButton
              htmlType="button"
              type="default"
              loading={isCheckingCode}
              disabled={!influencerCodeInputValue}
              onClick={() =>
                checkIfInfluencerCodeIsAvailable(influencerCodeInputValue)
              }
            >
              {intl.getTranslatedText(
                'pages.athletesOfTheWeek.card.play.checkInfluencerCode',
              )}
            </CustomAntButton>
          )}
        </div>
        {isCodeAvailable && (
          <span>
            {intl.getTranslatedText(
              'pages.athletesOfTheWeek.card.play.messages.influencerCodeSuccess.title',
            )}
          </span>
        )}
      </InfluencerCodeContainer>
      <CustomAntButton
        onClick={handleSubmit}
        disabled={!isFinishButtonEnabled}
        loading={isSubmitting}
        type="primary"
      >
        {intl.getTranslatedText(
          'pages.athletesOfTheWeek.card.play.submitNewGameConfirmButton',
        )}
      </CustomAntButton>
    </Container>
  );
};

export default PlayCardGame;
