const PushGamesHistoryTexts = {
  'pages.pushGamesHistory.title': 'Histórico dos Push Games',

  'pages.pushGamesHistory.messages.notPushGames':
    'Você não possui nenhum <span>Push Game</span>',
  'pages.pushGamesHistory.messages.notArchivedPushGames':
    'Você não possui nenhum <span>Push Game</span> arquivado',

  'pages.pushGamesHistory.playedPushGameInfo.question.label': 'Pergunta',
  'pages.pushGamesHistory.playedPushGameInfo.price.label': 'Valor do jogo',
  'pages.pushGamesHistory.playedPushGameInfo.response.label': 'Resposta',
  'pages.pushGamesHistory.playedPushGameInfo.result.label': 'Resultado',
  'pages.pushGamesHistory.playedPushGameInfo.result.won': 'Você acertou!',
  'pages.pushGamesHistory.playedPushGameInfo.result.lost': 'Você errou!',
  'pages.pushGamesHistory.playedPushGameInfo.received.label': 'Valor recebido',
  'pages.pushGamesHistory.playedPushGameInfo.winners.label':
    'Quantidade de vencedores',
  'pages.pushGamesHistory.playedPushGameInfo.winners.value':
    '{winnerCount} vencedores',

  'pages.pushGamesHistory.goToHomeLink': 'Voltar para a tela inicial',
  'pages.pushGamesHistory.showActiveGamesButton': 'Mostrar jogos ativos',
  'pages.pushGamesHistory.showUnarchivedGamesButton':
    'Mostrar jogos arquivados',

  'pages.pushGamesHistory.messages.getPlayedPushGamesError.title':
    'Erro ao carregar o histórico de jogos',
  'pages.pushGamesHistory.messages.getArchivedPlayedPushGamesError.title':
    'Erro ao carregar o histórico de jogos arquivados',
  'pages.pushGamesHistory.messages.submitArchivePushGameConfirm.description':
    'Deseja realmente arquivar esse jogo?',
  'pages.pushGamesHistory.messages.submitArchivePushGameSuccess.title':
    'Jogo arquivado!',
  'pages.pushGamesHistory.messages.submitUnarchivePushGameConfirm.description':
    'Deseja realmente tornar esse jogo ativo?',
  'pages.pushGamesHistory.messages.submitUnarchivePushGameSuccess.title':
    'Esse jogo está ativo!',
  'pages.pushGamesHistory.messages.viewingArchivedGames':
    'Você está visualizando seus jogos arquivados',
};

export default {
  ...PushGamesHistoryTexts,
};
