const env = {
  development: {
    BASE_URL: 'http://localhost:3000',
    API_URL: 'http://localhost:8000',
    SOCKET_URL: 'http://localhost:8000',
  },
  production: {
    BASE_URL: 'https://app.exgool.com',
    API_URL: 'https://app.exgool.com/v1',
    SOCKET_URL: 'https://app.exgool.com/v1',
  },
};

export default env;
