import React, { useCallback, useMemo, useState } from 'react';

import Pack1 from '@/assets/coins-packs/pack-1.jpg';
import Pack2 from '@/assets/coins-packs/pack-2.jpg';
import Pack3 from '@/assets/coins-packs/pack-3.jpg';

import CustomAntButton from '@/components/CustomAntButton';
import Input from '@/components/Input';
import api from '@/services/api';
import { showToast } from '@/hooks/showToast';

import { EBulletPagePath, useBullet } from '@/context/BulletContext';
import { useIntl } from '@/context/IntlContext';

import {
  Container,
  Content,
  CoinsPackContainer,
  CoinsPackDetails,
} from './styles';

import ProcessingPayment from '../../ProcessingPayment';
import { ETransactionType } from '../../bullet.types';
import { IPurchaseCoinsPackResponseDTO } from '../deposit.types';
import ConfirmAction from '../../ConfirmAction';

interface IHandleSubmitProps {
  type: ETransactionType;
  amount?: number;
}
interface IPurchaseCoinsPackRequestDTO {
  amount: number;
  details: {
    type: ETransactionType;
    title: string;
    description: string;
  };
}

const ChooseCoinsPack: React.FC = () => {
  const intl = useIntl();
  const bullet = useBullet();

  const [shouldConfirmPurchase, setShouldConfirmPurchase] = useState<
    | ETransactionType.PURCHASE_FIRST_PACK
    | ETransactionType.PURCHASE_SECOND_PACK
    | ETransactionType.PURCHASE_THIRD_PACK
    | null
  >(null);

  const [processingPaymentLoading, setProcessingPaymentLoading] =
    useState(false);

  const packsDetails = useMemo(() => {
    return {
      first: {
        amount: 5,
        title: `Pacote 1 - R$5`,
        description: 'R$5',
      },
      second: {
        amount: 12,
        title: 'Pacote 2 - R$12',
        description: 'R$12',
      },
      third: {
        title: 'Pacote 3 - Custom',
      },
    };
  }, []);

  const [customPackInputValue, setCustomPackInputValue] = useState('20');

  const handleSubmit = useCallback(
    async ({ type, amount }: IHandleSubmitProps) => {
      const body: IPurchaseCoinsPackRequestDTO = {
        amount: 0,
        details: {
          type,
          title: '',
          description: '',
        },
      };

      if (type === ETransactionType.PURCHASE_FIRST_PACK) {
        body.amount = packsDetails.first.amount;
        body.details.title = packsDetails.first.title;
        body.details.description = packsDetails.first.description;
      } else if (type === ETransactionType.PURCHASE_SECOND_PACK) {
        body.amount = packsDetails.second.amount;
        body.details.title = packsDetails.second.title;
        body.details.description = packsDetails.second.description;
      } else if (type === ETransactionType.PURCHASE_THIRD_PACK) {
        body.amount = amount || 0;
        body.details.title = packsDetails.third.title;
        body.details.description = `R$${amount || 0}`;
      }

      try {
        setProcessingPaymentLoading(true);
        const { data: responseData } =
          await api.post<IPurchaseCoinsPackResponseDTO>(
            '/api/payment-deposit',
            body,
          );
        const transactionId = responseData.data.transaction._id;
        setProcessingPaymentLoading(false);

        bullet.navigateTo(EBulletPagePath.PAYMENT, {
          transactionId,
        });
      } catch (error) {
        setProcessingPaymentLoading(false);
        showToast({
          title: intl.getTranslatedText('common.errors.unexpectedError.title'),
          description: intl.getTranslatedText(
            'common.errors.unexpectedError.description',
          ),
          type: 'error',
        });
      }
    },
    [intl, packsDetails, bullet],
  );

  if (processingPaymentLoading) {
    return (
      <Container>
        <ProcessingPayment flow="deposit" />
      </Container>
    );
  }

  return (
    <Container>
      <Content>
        <p>Escolha um pacote de moedas</p>
        <ul>
          <CoinsPackContainer>
            <CoinsPackDetails>
              <img src={Pack1} alt="Pack 1" />
              <h6>{packsDetails.first.title}</h6>
              <p>{packsDetails.first.description}</p>
            </CoinsPackDetails>
            {shouldConfirmPurchase !== ETransactionType.PURCHASE_FIRST_PACK ? (
              <CustomAntButton
                type="primary"
                onClick={() =>
                  setShouldConfirmPurchase(ETransactionType.PURCHASE_FIRST_PACK)
                }
                disabled={!!shouldConfirmPurchase}
              >
                Comprar
              </CustomAntButton>
            ) : (
              <ConfirmAction
                onConfirm={() =>
                  handleSubmit({ type: ETransactionType.PURCHASE_FIRST_PACK })
                }
                onCancel={() => setShouldConfirmPurchase(null)}
              />
            )}
          </CoinsPackContainer>
          <CoinsPackContainer>
            <small>Mais popular</small>
            <CoinsPackDetails>
              <img src={Pack2} alt="Pack 2" />
              <h6>{packsDetails.second.title}</h6>
              <p>{packsDetails.second.description}</p>
            </CoinsPackDetails>
            {shouldConfirmPurchase !== ETransactionType.PURCHASE_SECOND_PACK ? (
              <CustomAntButton
                type="primary"
                onClick={() =>
                  setShouldConfirmPurchase(
                    ETransactionType.PURCHASE_SECOND_PACK,
                  )
                }
                disabled={!!shouldConfirmPurchase}
              >
                Comprar
              </CustomAntButton>
            ) : (
              <ConfirmAction
                onConfirm={() =>
                  handleSubmit({ type: ETransactionType.PURCHASE_SECOND_PACK })
                }
                onCancel={() => setShouldConfirmPurchase(null)}
              />
            )}
          </CoinsPackContainer>
          <CoinsPackContainer>
            <CoinsPackDetails>
              <img src={Pack3} alt="Pack 3" />
              <h6>{packsDetails.third.title}</h6>
              <p>
                R$
                {parseInt(customPackInputValue) >= 20
                  ? customPackInputValue
                  : 0}
              </p>
            </CoinsPackDetails>
            <Input
              id="customPack"
              addonBefore="R$"
              placeholder="Digite um valor a partir de 20"
              value={customPackInputValue}
              onChange={e => {
                const newValue = e.target.value;
                setCustomPackInputValue(newValue.replace(/\D/g, ''));
              }}
              error={
                (!customPackInputValue ||
                  parseInt(customPackInputValue) < 20) &&
                'O valor deve ser no mínimo R$20'
              }
            />
            {shouldConfirmPurchase !== ETransactionType.PURCHASE_THIRD_PACK ? (
              <CustomAntButton
                type="primary"
                disabled={
                  !customPackInputValue ||
                  parseInt(customPackInputValue) < 20 ||
                  !!shouldConfirmPurchase
                }
                onClick={() =>
                  setShouldConfirmPurchase(ETransactionType.PURCHASE_THIRD_PACK)
                }
              >
                Comprar
              </CustomAntButton>
            ) : (
              <ConfirmAction
                onConfirm={() =>
                  handleSubmit({
                    type: ETransactionType.PURCHASE_THIRD_PACK,
                    amount: parseInt(customPackInputValue),
                  })
                }
                onCancel={() => setShouldConfirmPurchase(null)}
              />
            )}
          </CoinsPackContainer>
        </ul>
      </Content>
    </Container>
  );
};

export default ChooseCoinsPack;
