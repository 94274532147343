const NotificationsTexts = {
  'components.notifications.title': 'Notification',
  /* Buttons */
  'components.notifications.markAllAsReadButton': 'Mark all as read',
  /* Messages */
  'components.notifications.messages.getNotificationsError.title':
    'Error to load the notification',
  'components.notifications.messages.newNotification.title':
    'You received a new message',
  'components.notifications.messages.submitDeleteNotificationSuccess.title':
    'Notification removed!',
  'components.notifications.messages.submitMarkWithReadNotificationError.title':
    'Could not mark notification as read',
  'components.notifications.messages.submitMarkWithReadNotificationsError.title':
    'Could not mark notifications as read',
  'components.notifications.messages.submitMarkWithReadNotificationError.description':
    'Unexpected error to mark a notification as read',
  'components.notifications.messages.submitMarkWithReadNotificationsError.description':
    'Unexpected error to mark a notifications as read',
  'components.notifications.messages.noNotifications':
    "You don't have <span>notifications</span>",
};

export default {
  ...NotificationsTexts,
};
