const ChatContextTexts = {
  /* ---------- Messages ---------- */
  /* SubmitNewMessage */
  'contexts.chatContext.messages.submitNewMessageSuccess.title':
    'Message sent!',
  'contexts.chatContext.messages.submitNewMessageError.title':
    'Error to send message',
  /* SubmitMessageReport */
  'contexts.chatContext.messages.messageReportSubmitConfirm.title':
    'Do you want to submit this report?',
  'contexts.chatContext.messages.messageReportSubmitConfirm.confirmButton':
    'Send report',
  'contexts.chatContext.messages.messageReportSubmitSuccess.title':
    'Report sent!',
  'contexts.chatContext.messages.messageReportSubmitSuccess.description':
    'Your report has been sent and will be analyzed by our team.',
  /* SubmitDeleteMessage */
  'contexts.chatContext.messages.deleteMessageSubmitConfirm.title':
    'Do you want remove this message?',
  'contexts.chatContext.messages.deleteMessageSubmitConfirm.confirmButton':
    'Remove message',
  'contexts.chatContext.messages.deleteMessageSubmitSuccess.title':
    'Message removed!',
  'contexts.chatContext.messages.deleteMessageSubmitError.title':
    'Error to remove message',
};

export default {
  ...ChatContextTexts,
};
