import styled, { css } from 'styled-components';

interface ContainerProps {
  error: boolean;
}

interface LabelContainerProps {
  error: boolean;
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 0.5rem;

  > div {
    width: 100%;
    display: flex;
    align-items: center;

    input {
      flex: 1;

      ${props =>
        props.error &&
        css`
          border-color: ${props.theme.colors.danger};

          &:hover {
            border-color: ${props.theme.colors.danger};
            /* box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2); */
          }

          &:focus {
            border-color: ${props.theme.colors.danger};
            box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2);
          }
        `}
    }

    /* Loading Indicator */
    div {
      margin-left: 0.5rem;
    }
  }

  > span {
    color: ${props => props.theme.colors.danger};
    font-size: ${props => props.theme.typograph.sizes.small}px;
    font-weight: ${props => props.theme.typograph.weights.medium};
    text-align: start;
  }
`;

export const LabelContainer = styled.div<LabelContainerProps>`
  display: flex;
  align-items: center;

  label {
    ${props =>
      props.error &&
      css`
        color: ${props.theme.colors.danger};
      `}
  }
`;
