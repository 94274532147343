import styled, { css } from 'styled-components';
import { TTransactionStatusType } from '.';

interface IContainer {
  $hasClickAction?: boolean;
}

interface ITransactionAmount {
  $flow: 'in' | 'out';
}

interface ITransactionAmountStatusContainer {
  $type: TTransactionStatusType;
}

export const Container = styled.button<IContainer>`
  width: 100%;
  background-color: ${props => props.theme.colors.dark['dark+1']};
  border: 1px solid ${props => props.theme.colors.dark['dark+4']};
  border-radius: 8px;
  padding: 8px 16px;
  display: flex;
  align-items: center;
  transition: 0.2s all;
  box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.25);
  cursor: default;

  ${props =>
    props.$hasClickAction &&
    css`
      cursor: pointer;

      &:hover {
        opacity: 0.8;
      }
    `}
`;

export const TransactionDetails = styled.div`
  text-overflow: ellipsis;
  overflow-x: hidden;
  white-space: nowrap;
  margin-right: 8px;

  strong {
    color: ${props => props.theme.colors.light.light0};
    text-align: start;
    width: 100%;
    display: block;
  }

  small {
    font-weight: ${props => props.theme.typograph.weights.light};
    text-align: start;
    width: 100%;
    display: block;
  }
`;

export const TransactionAmount = styled.strong<ITransactionAmount>`
  margin-left: auto;
  color: ${props => props.theme.colors.secondary['secondary+1']};

  ${props =>
    props.$flow === 'in' &&
    css`
      color: ${props.theme.colors.primary['primary+1']};
    `}
  ${props =>
    props.$flow === 'out' &&
    css`
      color: ${props.theme.colors.danger};
    `}
`;

export const TransactionAmountAndStatusContainer = styled.div<ITransactionAmountStatusContainer>`
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: flex-end;
  margin-left: auto;

  small {
    font-size: ${props => props.theme.typograph.sizes.small}px;
    text-align: end;
    color: ${props => props.theme.colors.dark['dark+4']};
    font-weight: ${props => props.theme.typograph.weights.medium};

    &:last-child {
      ${props =>
        props.$type === 'pending' &&
        css`
          color: ${props.theme.colors.secondary.secondary0};
        `}
      ${props =>
        props.$type === 'error' &&
        css`
          color: ${props.theme.colors.danger};
        `}
    }
  }
`;
