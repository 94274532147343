export interface ITeam {
  _id: string;
  name: string;
  abbrev: string;
  image: string;
}

export enum EAthletePosition {
  GOALKEEPER = 'G',
  DEFENSE = 'D',
  MIDFIELD = 'M',
  ATTACK = 'A',
  RIGHT_BACK = 'LD',
  LEFT_BACK = 'LE',
  ATP = 'ATP',
  WTA = 'WTA',
  F1_START = 'F1_START',
  F1_FINISH = 'F1_FINISH',
  POKER__HAND_CHAMPION_SEQUENCE = 'POKER__HAND_CHAMPION_SEQUENCE',
  POKER__ELIMINATION_SEQUENCE = 'POKER__ELIMINATION_SEQUENCE',
  GOAL_MINUTE = 'GOAL_MINUTE',
  FIRST_GOAL_MINUTE = 'FIRST_GOAL_MINUTE',
  SECOND_GOAL_MINUTE = 'SECOND_GOAL_MINUTE',
  FIRST_GOAL_MINUTE_OF_THE_ROUND = 'FIRST_GOAL_MINUTE_OF_THE_ROUND',
  SECOND_GOAL_MINUTE_OF_THE_ROUND = 'SECOND_GOAL_MINUTE_OF_THE_ROUND',
  FIRST_HALF_GOAL_MINUTE = 'FIRST_HALF_GOAL_MINUTE',
  SECOND_HALF_GOAL_MINUTE = 'SECOND_HALF_GOAL_MINUTE',
  GOAL_INSIDE_AREA = 'GOAL_INSIDE_AREA',
  GOAL_OUTSIDE_AREA = 'GOAL_OUTSIDE_AREA',
  GOAL_INSIDE_AREA_OF_THE_ROUND = 'GOAL_INSIDE_AREA_OF_THE_ROUND',
  GOAL_OUTSIDE_AREA_OF_THE_ROUND = 'GOAL_OUTSIDE_AREA_OF_THE_ROUND',
  GOALS_IN_THE_FIRST_HALF = 'GOALS_IN_THE_FIRST_HALF',
  GOALS_IN_THE_SECOND_HALF = 'GOALS_IN_THE_SECOND_HALF',
  GOALS_IN_THE_FIRST_HALF_OF_THE_ROUND = 'GOALS_IN_THE_FIRST_HALF_OF_THE_ROUND',
  GOALS_IN_THE_SECOND_HALF_OF_THE_ROUND = 'GOALS_IN_THE_SECOND_HALF_OF_THE_ROUND',
  FOOTBALL__FIELD_GOAL_AMOUNT = 'FOOTBALL__FIELD_GOAL_AMOUNT',
  FOOTBALL__TOUCHDOWN_AMOUNT = 'FOOTBALL__TOUCHDOWN_AMOUNT',
  FOOTBALL__TWO_POINT_CONVERSION_AMOUNT = 'FOOTBALL__TWO_POINT_CONVERSION_AMOUNT',
  FOOTBALL__EXTRA_KICK_AMOUNT = 'FOOTBALL__EXTRA_KICK_AMOUNT',
  FOOTBALL__SAFETY_AMOUNT = 'FOOTBALL__SAFETY_AMOUNT',
  FOOTBALL__PICK_SIX_TOUCHDOWN_AMOUNT = 'FOOTBALL__PICK_SIX_TOUCHDOWN_AMOUNT',
}

export enum AthleteShortPositionEnum {
  GOALKEEPER = 'GOL',
  DEFENSE = 'DEF',
  MIDFIELD = 'MC',
  ATTACK = 'ATA',
  RIGHT_BACK = 'LD',
  LEFT_BACK = 'LE',
  ATP = 'ATP',
  WTA = 'WTA',
}

export interface IAthlete {
  _team: ITeam;
  _athlete: {
    _id: string;
    status: boolean;
    name: string;
    url: string;
    number: string;
    position: EAthletePosition;
    age: string;
    height: string;
    weight: string;
    from: string;
    updatedAt: string;
    createdAt: string;
    photo: string;
  };
  athleteScore: number;
  classicValue: number;
  _athleteGoals: string[];
  _athleteStatistics: string[];
}

export enum ECardGameOptionTopic {
  GOAL = 'goal',
  STATISTIC = 'statistic',
  OTHERS = 'others',
}

export interface ICardGameOption {
  _id: string;
  _option: string;
  name: string;
  topic: ECardGameOptionTopic;
  value: number;
}

export enum EGameType {
  OPTIONS = 'default',
  CLASSIC = 'classic',
}

export enum ECardTypeEnum {
  NEW = 'new',
  RUNNING = 'running',
  FINISHED = 'finished',
  PREPARING = 'preparing',
}

export interface ICardGame {
  _id: string;
  name: string;
  startDate: string;
  cardType: ECardTypeEnum;
  price: number;
  status: boolean;
  endDate: string;
  finished: boolean;
  banner?: string;
  athletes: IAthlete[];
  goalOptions: ICardGameOption[];
  statisticOptions: ICardGameOption[];
  amount: number;
  cardCount: number;
  gameType: EGameType;
  fixedAmount?: number;
  paymentPercentage: {
    first: number;
    second: number;
    third: number;
    fourth: number;
    fifth: number;
  };
  percentages: {
    homeMin: number;
    home: number;
    influencer: number;
    winner: number;
  };
}
