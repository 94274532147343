import React from 'react';
import { FastField, FastFieldProps, FormikProps } from 'formik';

import CustomAntButton from '@/components/CustomAntButton';
import Input from '@/components/Input';

import { IConfirmPasswordProps } from './searchUser.types';

import { InputContainer } from '../styles';
import { ButtonsContainer } from './styles';
import { ITransferFormData } from '../transfer.types';

function enableConfirmButton(
  formikProps: FormikProps<ITransferFormData>,
): boolean {
  const { touched, errors } = formikProps;

  const noErrors = !errors?.password;
  const allFieldsTouched = touched?.password;

  if (noErrors && allFieldsTouched) return true;
  return false;
}

const ConfirmPassword: React.FC<IConfirmPasswordProps> = ({
  formikProps,
  handleGoBack,
}) => {
  return (
    <>
      <InputContainer>
        <h6>Confirme a sua senha</h6>
        <FastField name="password">
          {({ field, meta }: FastFieldProps) => {
            return (
              <Input
                {...field}
                placeholder="Digite sua senha"
                id="confirmPasswordInput"
                type="password"
                error={meta?.touched && meta?.error && meta?.error}
                disabled={formikProps.isSubmitting}
              />
            );
          }}
        </FastField>
      </InputContainer>
      <ButtonsContainer>
        <CustomAntButton
          htmlType="button"
          type="default"
          onClick={() => handleGoBack()}
        >
          Voltar
        </CustomAntButton>
        <CustomAntButton
          htmlType="submit"
          disabled={!enableConfirmButton(formikProps)}
          loading={formikProps.isSubmitting}
          type="primary"
        >
          Confirmar
        </CustomAntButton>
      </ButtonsContainer>
    </>
  );
};

export default ConfirmPassword;
