import { getTranslatedText } from './intl';

export function formatCpf(cpf: string): string {
  if (cpf && cpf.length === 11) {
    return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
  }
  return cpf;
}

export function formatCnpj(cnpj: string): string {
  if (cnpj && cnpj.length === 14) {
    return cnpj.replace(
      /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
      '$1.$2.$3/$4-$5',
    );
  }
  return cnpj;
}

export function formatGender(
  initialLetterOfGender: 'M' | 'F' | 'O' | string,
): string {
  if (initialLetterOfGender === 'M') {
    return getTranslatedText('utils.formatters.gender.male');
  }
  if (initialLetterOfGender === 'F') {
    return getTranslatedText('utils.formatters.gender.female');
  }
  if (initialLetterOfGender === 'O') {
    return getTranslatedText('utils.formatters.gender.other');
  }

  return getTranslatedText('utils.formatters.gender.invalid');
}

export function formatPhone(phone: string): string {
  if (phone && phone.length === 11) {
    return phone.replace(/(\d{2})(\d{5})(\d{4})/, '($1)$2-$3');
  }
  if (phone && phone.length === 10) {
    return phone.replace(/(\d{2})(\d{4})(\d{4})/, '($1)$2-$3');
  }

  return phone;
}

export function formatMoney(number: number): string {
  const moneyFormatter = new Intl.NumberFormat('pt-BR', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  return moneyFormatter.format(number);
}

export function formatSoccerPlayerNumber(soccerPlayerNumber: string): string {
  const isNumber = /^\d+$/;

  if (isNumber.test(soccerPlayerNumber)) {
    return soccerPlayerNumber;
  }

  return getTranslatedText('utils.formatters.soccerPlayer.number.notFound');
}

export function formatSoccerPlayerPosition(initials: string): string {
  return initials;
}

export function formatNameWithPossessivePronoun(name: string): string {
  if (window._selectedLocale === 'en') {
    if (name.toLocaleLowerCase().endsWith('s')) {
      return `${name}'`;
    }
    return `${name}'s`;
  }

  return name;
}

export function objectToGetParams(object: {
  [key: string]: string | number | undefined | null;
}): string {
  const params = Object.entries(object)
    .filter(([, value]) => value !== undefined && value !== null)
    .map(
      ([key, value]) =>
        `${encodeURIComponent(key)}=${encodeURIComponent(String(value))}`,
    );

  return params.length > 0 ? `?${params.join('&')}` : '';
}

export function getShareSocialLinks(
  social: 'facebook' | 'twitter' | 'whatsapp' | 'telegram',
  url: string,
): string {
  // assert(url, 'facebook.url');

  let link = '#';

  if (social === 'facebook') {
    link = `https://www.facebook.com/sharer/sharer.php${objectToGetParams({
      u: url,
      // quote,
      // hashtag,
    })}`;
  }
  if (social === 'twitter') {
    link = `https://twitter.com/share${objectToGetParams({
      url,
      // quote,
      // hashtag,
    })}`;
  }
  if (social === 'whatsapp') {
    link = `https://api.whatsapp.com/send${objectToGetParams({
      text: `titulo teste ${url}`,
      // quote,
      // hashtag,
    })}`;
  }
  if (social === 'telegram') {
    link = `https://telegram.me/share/url${objectToGetParams({
      url,
      // quote,
      // hashtag,
    })}`;
  }

  return link;
}

export function removeAccentsFromText(text: string): string {
  return text.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
}
