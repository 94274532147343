import React from 'react';

// import UserAvatar from '../../UserAvatar';

import { IGroupMessages } from '..';

import Message from './Message';

import { Container } from './styles';

interface IUserMessagesGroup {
  group: IGroupMessages;
  messagesContentContainerRef: React.RefObject<HTMLDivElement>;
}

const UserMessagesGroup: React.FC<IUserMessagesGroup> = ({
  group,
  messagesContentContainerRef,
}) => {
  return (
    <Container>
      {/* <UserAvatar
        // src="https://i.pinimg.com/originals/80/03/82/800382c6338f6e834adca1b2cc66503e.jpg"
        photoId={group._user.photo}
        size={32}
        user={{
          name: group._user.name,
          username: group._user.username,
          email: group._user.email,
        }}
      /> */}
      {/* <img
        src={
          group._user.photo
            ? Env.IMAGE_SERVER_URL + group._user.photo
            : DefaultAvatar
        }
        alt={group._user.name}
      /> */}
      <div>
        {group.messages.map((message, index) => (
          <Message
            key={message._id}
            message={message}
            lastMessageOfGroup={index === group.messages.length - 1}
            messagesContentContainerRef={messagesContentContainerRef}
          />
        ))}
      </div>
    </Container>
  );
};

export default UserMessagesGroup;
