import React from 'react';

import { useIntl } from '../../context/IntlContext';

import { Container } from './styles';
import logo from '../../assets/logo-vertical.svg';

const LoadingUserData: React.FC = () => {
  const intl = useIntl();

  return (
    <Container>
      <img src={logo} alt="Titan369" />
      <p>{intl.getTranslatedText('common.messages.defaultLoading')}</p>
    </Container>
  );
};

export default LoadingUserData;
