import env from '@/env';

interface IEnvironmentVars {
  BASE_URL: string;
  API_URL: string;
  SOCKET_URL: string;
}

const { NODE_ENV } = process.env as { NODE_ENV: 'development' | 'production' };

const environment: IEnvironmentVars = {} as IEnvironmentVars;

const isUrl = (url?: string): boolean => {
  if (!url) return false;
  // using regex to check if the url is valid
  const regex = new RegExp(
    // eslint-disable-next-line no-useless-escape
    /^(http|https):\/\/[^ "]+$/,
  );
  return regex.test(url);
};

const { BASE_URL, API_URL, SOCKET_URL } = env[NODE_ENV];

if (NODE_ENV === 'development') {
  environment.BASE_URL =
    isUrl(BASE_URL) && BASE_URL ? BASE_URL : 'http://localhost:3000';
  environment.API_URL =
    isUrl(API_URL) && API_URL ? API_URL : 'http://localhost:8000';
  environment.SOCKET_URL =
    isUrl(SOCKET_URL) && SOCKET_URL ? SOCKET_URL : 'http://localhost:8000';
} else if (NODE_ENV === 'production') {
  environment.BASE_URL =
    isUrl(BASE_URL) && BASE_URL ? BASE_URL : 'https://app.titan369.com';
  environment.API_URL =
    isUrl(API_URL) && API_URL ? API_URL : 'https://api.titan369.com';
  environment.SOCKET_URL =
    isUrl(SOCKET_URL) && SOCKET_URL ? SOCKET_URL : 'https://api.titan369.com';
}

export default environment;
