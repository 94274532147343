import styled, { css } from 'styled-components';
import { shade } from 'polished';

interface IRecordAudioMessageFooter {
  $mode: 'record' | 'preview';
}

export const OpenChatButton = styled.button`
  position: fixed;
  bottom: 40px;
  right: 16px;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  background: ${props => props.theme.colors.primary['primary-1']};
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.4);
  border: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    color: ${props => props.theme.colors.light.light0};
  }
`;

export const Container = styled.div`
  width: 100%;
  height: 75vh;
  background: ${props => props.theme.colors.dark['dark+1']};
  box-shadow: 0px -4px 15px 4px rgba(0, 0, 0, 0.25);
  z-index: 95;
  position: fixed;
  bottom: 0;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  display: flex;
  flex-direction: column;

  @media only screen and (min-width: 769px) {
    width: 736px;
    right: 32px;
  }
`;

export const Header = styled.div`
  padding: 16px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${props => props.theme.colors.dark['dark+2']};

  div {
    flex: 1;

    h6 {
      color: ${props => props.theme.colors.light.light0};
    }

    small {
      color: ${props => props.theme.colors.light['light-2']};
    }
  }

  button {
    svg {
      color: ${props => props.theme.colors.light.light0};
    }
  }
`;

export const Content = styled.div`
  flex: 1;
  padding: 0 16px 8px;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    background: ${props => props.theme.colors.dark['dark+1']};
    width: 8px;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: ${props => props.theme.colors.dark['dark+2']};
  }
`;

export const Footer = styled.form`
  /* padding: 8px 16px 16px; */
  padding: 8px 16px;
  border-top: 1px solid ${props => props.theme.colors.dark['dark+2']};

  display: flex;
  justify-content: center;
  align-items: flex-end;

  textarea {
    flex: 1;
    resize: none;
    border: 1px solid ${props => props.theme.colors.dark['dark+2']};
  }
  button {
    margin-left: 8px;
    flex-shrink: 0;
  }
`;

export const RecordAudioMessageFooter = styled.form<IRecordAudioMessageFooter>`
  padding: 8px 16px;
  border-top: 1px solid ${props => props.theme.colors.dark['dark+2']};
  display: flex;
  justify-content: center;
  align-items: center;

  ${props =>
    props.$mode === 'record' &&
    css`
      > button {
        margin: 0 32px;
      }
    `}

  ${props =>
    props.$mode === 'preview' &&
    css`
      > button {
        &:nth-child(2) {
          margin-left: 16px;
        }
        &:last-child {
          margin-left: 8px;
        }
      }
    `}

  > span {
    background: ${props => props.theme.colors.danger};
    border-radius: 50%;
    /* margin: 10px; */
    margin-right: 16px;
    height: 14px;
    width: 14px;

    box-shadow: 0 0 0 0 rgba(255, 77, 79, 1);
    transform: scale(1);
    animation: pulse 2s infinite;
  }

  @keyframes pulse {
    0% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(255, 77, 79, 0.7);
    }

    70% {
      transform: scale(1);
      box-shadow: 0 0 0 10px rgba(255, 77, 79, 0);
    }

    100% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(255, 77, 79, 0);
    }
  }
`;

export const NewVoiceMessagePreviewPlayerContainer = styled.div`
  display: flex;
  /* width: 100%; */
  align-items: center;
  flex: 1;
  /* cursor: default; */

  > p {
    margin-left: 8px;
  }
`;
export const PlayOrStopButton = styled.button`
  border: 0;
  background: transparent;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    color: ${props => props.theme.colors.light.light0};
  }
`;

export const LoadMoreMessagesButton = styled.button`
  border: 0;
  width: 80%;
  padding: 8px;
  background: ${props => props.theme.colors.dark['dark+3']};
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.25);
  border-radius: 50px;
  color: ${props => props.theme.colors.light['light-2']};
  transition: 0.2s all;
  margin: 16px auto;
  display: block;

  &:hover {
    background: ${props => shade(0.1, props.theme.colors.dark['dark+2'])};
  }

  ${props =>
    props.disabled &&
    css`
      opacity: 0.8;
    `}
`;

export const LoadingMessagesContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 104px;

  > small {
    width: 100%;
    text-align: center;
    color: ${props => props.theme.colors.light['light-2']};
  }

  > div {
    width: 100%;
    display: flex;
    justify-content: center;
    /* margin-top: 56px; */

    p {
      margin-left: 16px;
      color: ${props => props.theme.colors.light['light-2']};
    }

    h6 {
      text-align: center;

      span {
        color: ${props => props.theme.colors.primary.primary0};
      }
    }
  }
`;
