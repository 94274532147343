import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

window._selectedLocale = navigator.language;

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);
