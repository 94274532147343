import React, { useEffect, useRef } from 'react';
import lottie from 'lottie-web';

import loadingAnimation from '../../assets/loading-animation.json';

import { useIntl } from '../../context/IntlContext';

import { Container } from './styles';

const Loading: React.FC = () => {
  const intl = useIntl();
  const animationContainer = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (animationContainer.current) {
      lottie.loadAnimation({
        container: animationContainer.current,
        autoplay: true,
        loop: true,
        animationData: loadingAnimation,
      });
    }
  }, []);

  return (
    <Container>
      <div style={{ width: 200 }} ref={animationContainer} />
      <h6>{intl.getTranslatedText('common.messages.defaultLoading')}</h6>
    </Container>
  );
};

export default Loading;
