const HomeTexts = {
  /* ------------ Rankings ------------ */
  'pages.home.rankingsLabel': 'Ranking',
  'pages.home.globalRankingLabel': 'Global',

  /* ------------ Buttons ------------ */
  'pages.home.rankingBannerButtonDescription': 'Click to more details',
  'pages.home.newRoomButton': 'New room',
  'pages.home.publicRoomsButton': 'Public room',
  'pages.home.myRoomsButton': 'My rooms',

  /* ------------ Messages ------------ */
  'pages.home.getHomeDataError.title':
    'Unexpected error to load data on viewport',
  'pages.home.noCoachFriendRooms':
    "You aren't in any <span>room</span> Family vs Friend",
  'pages.home.noVersusRooms': "You aren't in any <span>room</span> Versus",
  'pages.home.noVersusVIPRooms':
    "You aren't in any <span>room</span> Versus VIP",
};

export default {
  ...HomeTexts,
};
