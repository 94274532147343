import React from 'react';
import { toast } from 'react-toastify';
import ToastContent, { IToastContent } from '../components/ToastContent';

const toastTypes = {
  error: toast.error,
  success: toast.success,
  info: toast.info,
  warn: toast.warn,
};

// function showToastFn() {
//   return ({ title, description, type }: IToastContent): React.ReactText =>
//     toastTypes[type || 'info'](
//       <ToastContent type={type} title={title} description={description} />,
//     );
// }

export function showToast({
  title,
  description,
  type,
}: IToastContent): React.ReactText {
  return toastTypes[type || 'info'](
    <ToastContent type={type} title={title} description={description} />,
  );
}
