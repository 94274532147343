const FormattersTexts = {
  /* Gender */
  'utils.formatters.gender.male': 'Masculino',
  'utils.formatters.gender.female': 'Feminino',
  'utils.formatters.gender.other': 'Outro',
  'utils.formatters.gender.invalid': 'Sexo inválido',
  /* Soccer Players */
  'utils.formatters.soccerPlayer.number.notFound': 'SN', // Sem número
};

export default {
  ...FormattersTexts,
};
