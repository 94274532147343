const PushGamesHistoryTexts = {
  'pages.pushGamesHistory.title': 'Push games history',

  'pages.pushGamesHistory.messages.notPushGames':
    'You have no <span>Push Game</span>',
  'pages.pushGamesHistory.messages.notArchivedPushGames':
    'You have no <span>Push Game</span> archived',

  'pages.pushGamesHistory.playedPushGameInfo.question.label': 'Question',
  'pages.pushGamesHistory.playedPushGameInfo.price.label': 'Price game',
  'pages.pushGamesHistory.playedPushGameInfo.response.label': 'Answers',
  'pages.pushGamesHistory.playedPushGameInfo.result.label': 'Result',
  'pages.pushGamesHistory.playedPushGameInfo.result.won': 'You won!',
  'pages.pushGamesHistory.playedPushGameInfo.result.lost': 'You lose!',
  'pages.pushGamesHistory.playedPushGameInfo.received.label': 'Amount received',
  'pages.pushGamesHistory.playedPushGameInfo.winners.label': 'Winner amount',
  'pages.pushGamesHistory.playedPushGameInfo.winners.value':
    '{winnerCount} winners',

  'pages.pushGamesHistory.goToHomeLink': 'Back to home page',
  'pages.pushGamesHistory.showActiveGamesButton': 'Show active games',
  'pages.pushGamesHistory.showUnarchivedGamesButton': 'Show archived games',

  'pages.pushGamesHistory.messages.getPlayedPushGamesError.title':
    'Unexpected error to load game history',
  'pages.pushGamesHistory.messages.getArchivedPlayedPushGamesError.title':
    'Unexpected error to load archived games history',
  'pages.pushGamesHistory.messages.submitArchivePushGameConfirm.description':
    'Do you really want to archive this game?',
  'pages.pushGamesHistory.messages.submitArchivePushGameSuccess.title':
    'Archived game!',
  'pages.pushGamesHistory.messages.submitUnarchivePushGameConfirm.description':
    'Do you really want to unarchive this game?',
  'pages.pushGamesHistory.messages.submitUnarchivePushGameSuccess.title':
    'Active game!',
  'pages.pushGamesHistory.messages.viewingArchivedGames':
    "You're viewing your archived games",
};

export default {
  ...PushGamesHistoryTexts,
};
