import React, { useEffect, useMemo, useRef } from 'react';
import lottie from 'lottie-web';

import processingDepositAnimation from '@/assets/processing-deposit-animation.json';
import processingWithdrawAnimation from '@/assets/processing-withdraw-animation.json';

import { AnimationContainer, Container } from './styles';

interface IProcessingPaymentProps {
  flow: 'deposit' | 'withdraw';
}

const ProcessingPayment: React.FC<IProcessingPaymentProps> = ({ flow }) => {
  const animationContainer = useRef<HTMLDivElement>(null);
  const processingData = useMemo(() => {
    if (flow === 'deposit')
      return {
        animation: processingDepositAnimation,
        title: 'Estamos processando sua compra...',
      };
    return {
      animation: processingWithdrawAnimation,
      title: 'Sua transferência está em processamento...',
      description:
        'Enviamos a solicitação para o seu banco e em alguns minutos sua transação será concluída',
    };
  }, [flow]);

  useEffect(() => {
    if (animationContainer.current) {
      lottie.loadAnimation({
        container: animationContainer.current,
        autoplay: true,
        loop: true,
        animationData: processingData.animation,
      });
    }
  }, [processingData]);

  return (
    <Container>
      <AnimationContainer ref={animationContainer} />
      <h6>{processingData.title}</h6>
      {processingData.description && <p>{processingData.description}</p>}
    </Container>
  );
};

export default ProcessingPayment;
