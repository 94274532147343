import React, { useCallback, useState, useRef, ChangeEvent } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { FiArrowLeft } from 'react-icons/fi';
import {
  Formik,
  FastField,
  FastFieldProps,
  Field,
  FieldProps,
  FormikProps,
} from 'formik';

import { UploadRequests } from '@/services/api/requests/Upload';
import { removeAccentsFromText } from '@/utils/formatters';
import api from '../../services/api';
import { showToast } from '../../hooks/showToast';
import { useIntl } from '../../context/IntlContext';
import logoImg from '../../assets/logo-vertical.svg';
import Input from '../../components/Input';
import InputMask from '../../components/InputMask';
import CustomAntButton from '../../components/CustomAntButton';
import Radio from '../../components/Radio';

import {
  Container,
  Content,
  AnimationContainer,
  Background,
  SelectImageButton,
} from './styles';
import {
  asyncValidationLoadingInitialValue,
  availableFieldsInitialValue,
  signUpFormDataInitialValue,
} from './constants';
import {
  IAsyncValidationLoadingState,
  IAvailableFieldsState,
  ISignUpFormData,
} from './types';
import { getSignUpValidationSchema } from './validators';

const SignUp: React.FC = () => {
  const { getTranslatedText, getTranslatedTextWithHTML } = useIntl();
  const history = useHistory();
  const inputRef = useRef<HTMLInputElement>(null);
  const formikRef = useRef<FormikProps<ISignUpFormData>>(null);

  const [asyncValidationLoading, setAsyncValidationLoading] =
    useState<IAsyncValidationLoadingState>(asyncValidationLoadingInitialValue);
  const [focusedField, setFocusedField] = useState('');
  const [availableFields, setAvailableFields] = useState<IAvailableFieldsState>(
    availableFieldsInitialValue,
  );

  function handleSelectFile(e: ChangeEvent<HTMLInputElement>): void {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () =>
        formikRef.current?.setFieldValue('photo', {
          file: e.target.files && e.target.files[0],
          previewUrl: reader.result,
        }),
      );
      reader.readAsDataURL(e.target.files[0]);
    }
  }

  const handleSubmit = useCallback(
    async (data: ISignUpFormData) => {
      const body = {
        name: data.name,
        username: data.username,
        email: data.email,
        docNumber: data.docNumber.replace(/\./g, '').replace(/-/g, ''),
        gender: data.gender,
        password: data.password,
        phones: [
          data.phone.replace(/\(/g, '').replace(/\)/g, '').replace(/-/g, ''),
        ],
        photo: '',
      };

      if (data.photo.file) {
        try {
          const { data: responseData } = await UploadRequests.upload({
            file: data.photo.file,
            from: 'userAvatar',
          });

          body.photo = responseData._id;
        } catch (error) {
          showToast({
            type: 'error',
            title: getTranslatedText(
              'pages.signUp.messages.signUpSubmitAvatarError.title',
            ),
            description: getTranslatedText(
              'pages.signUp.messages.signUpSubmitAvatarError.description',
            ),
          });

          return;
        }
      }

      try {
        await api.post('/api/user', body);

        showToast({
          type: 'success',
          title: getTranslatedText(
            'pages.signUp.messages.signUpSubmitSuccess.title',
          ),
          description: getTranslatedText(
            'pages.signUp.messages.signUpSubmitSuccess.description',
          ),
        });

        history.push('/');
      } catch (err) {
        showToast({
          type: 'error',
          title: getTranslatedText(
            'pages.signUp.messages.signUpSubmitError.title',
          ),
          description: getTranslatedText(
            'pages.signUp.messages.signUpSubmitError.description',
          ),
        });
      }
    },
    [getTranslatedText, history],
  );

  return (
    <Container>
      <Background />
      <Content>
        <AnimationContainer>
          <img src={logoImg} alt="Titan369" />

          <Link to="/">
            <FiArrowLeft />
            {getTranslatedText('pages.signUp.links.signInLink')}
          </Link>

          <Formik
            innerRef={formikRef}
            initialValues={signUpFormDataInitialValue}
            validationSchema={getSignUpValidationSchema({
              getTranslatedText,
              focusedField,
              setAsyncValidationLoading,
              availableFields,
              setAvailableFields,
            })}
            onSubmit={handleSubmit}
          >
            {formikProps => (
              <form
                onSubmit={e => {
                  e.preventDefault();
                  if (!asyncValidationLoading.checkingSomeField) {
                    if (Object.entries(formikProps.errors).length > 0) {
                      showToast({
                        type: 'warn',
                        title: getTranslatedText(
                          'pages.signUp.messages.signUpValidationFailure.title',
                        ),
                        description: getTranslatedText(
                          'pages.signUp.messages.signUpValidationFailure.description',
                        ),
                      });
                    }
                    formikProps.handleSubmit(e);
                  }
                }}
              >
                <h5>{getTranslatedText('pages.signUp.title')}</h5>
                <input
                  type="file"
                  style={{ display: 'none' }}
                  ref={inputRef}
                  accept="image/*"
                  onChange={handleSelectFile}
                />
                <SelectImageButton
                  type="button"
                  onClick={() => {
                    inputRef.current?.click();
                  }}
                  error={
                    !!formikProps.touched.photo?.previewUrl &&
                    !!formikProps.errors.photo?.previewUrl
                  }
                >
                  {formikProps.values.photo.previewUrl ? (
                    <>
                      <img
                        src={formikProps.values.photo.previewUrl}
                        alt="User"
                      />
                      <p>
                        {getTranslatedText(
                          'pages.signUp.form.photo.changeImageButton',
                        )}
                      </p>
                    </>
                  ) : (
                    <p>
                      {getTranslatedTextWithHTML(
                        'pages.signUp.form.photo.selectImageButton',
                      )}
                    </p>
                  )}
                </SelectImageButton>
                <FastField name="name">
                  {({ field, meta }: FastFieldProps) => {
                    return (
                      <Input
                        {...field}
                        label={getTranslatedText(
                          'pages.signUp.form.name.label',
                        )}
                        placeholder={getTranslatedText(
                          'pages.signUp.form.name.placeholder',
                        )}
                        id="SignInName"
                        type="text"
                        error={meta?.touched && meta?.error && meta?.error}
                        onFocus={() => setFocusedField('name')}
                      />
                    );
                  }}
                </FastField>
                <Field name="username">
                  {({ field, meta }: FieldProps) => {
                    return (
                      <Input
                        {...field}
                        label={getTranslatedText(
                          'pages.signUp.form.username.label',
                        )}
                        placeholder={getTranslatedText(
                          'pages.signUp.form.username.placeholder',
                        )}
                        id="SignUpUsername"
                        type="text"
                        tooltip={getTranslatedText(
                          'pages.signUp.form.username.tooltipText',
                        )}
                        error={meta?.touched && meta?.error && meta?.error}
                        showloading={!!asyncValidationLoading.username}
                        onFocus={() => setFocusedField('username')}
                        maxLength={30}
                        onChange={e => {
                          let value = e.target.value
                            .replace(/\s+/g, '')
                            .toLowerCase();
                          value = removeAccentsFromText(value);

                          e.target.value = value;
                          field.onChange(e);
                        }}
                      />
                    );
                  }}
                </Field>
                <Field name="docNumber">
                  {({ field, meta }: FieldProps) => {
                    return (
                      <InputMask
                        {...field}
                        mask="999.999.999-99"
                        label={getTranslatedText(
                          'pages.signUp.form.docNumber.label',
                        )}
                        placeholder={getTranslatedText(
                          'pages.signUp.form.docNumber.placeholder',
                        )}
                        id="SignUpDocNumber"
                        type="text"
                        error={meta?.touched && meta?.error && meta?.error}
                        showloading={!!asyncValidationLoading?.docNumber}
                        onFocus={() => setFocusedField('docNumber')}
                      />
                    );
                  }}
                </Field>
                <Field name="email">
                  {({ field, meta }: FieldProps) => {
                    return (
                      <Input
                        {...field}
                        label={getTranslatedText(
                          'pages.signUp.form.email.label',
                        )}
                        placeholder={getTranslatedText(
                          'pages.signUp.form.email.placeholder',
                        )}
                        id="SignUpEmail"
                        type="email"
                        onChange={e => {
                          const value = removeAccentsFromText(e.target.value);

                          e.target.value = value;
                          field.onChange(e);
                        }}
                        error={meta?.touched && meta?.error && meta?.error}
                        showloading={!!asyncValidationLoading.email}
                        onFocus={() => setFocusedField('email')}
                      />
                    );
                  }}
                </Field>
                <Field name="gender">
                  {({ field, meta }: FieldProps) => {
                    return (
                      <Radio
                        {...field}
                        items={[
                          {
                            value: 'M',
                            label: getTranslatedText(
                              'pages.signUp.form.gender.male',
                            ),
                          },
                          {
                            value: 'F',
                            label: getTranslatedText(
                              'pages.signUp.form.gender.female',
                            ),
                          },
                          {
                            value: 'O',
                            label: getTranslatedText(
                              'pages.signUp.form.gender.other',
                            ),
                          },
                        ]}
                        label={getTranslatedText(
                          'pages.signUp.form.gender.label',
                        )}
                        error={meta?.touched && meta?.error && meta?.error}
                        onChange={e => {
                          if (focusedField !== 'gender') {
                            setFocusedField('gender');
                          }
                          field.onChange(e);
                        }}
                      />
                    );
                  }}
                </Field>
                <FastField name="password">
                  {({ field, meta }: FastFieldProps) => {
                    return (
                      <Input
                        {...field}
                        label={getTranslatedText(
                          'pages.signUp.form.password.label',
                        )}
                        placeholder={getTranslatedText(
                          'pages.signUp.form.password.placeholder',
                        )}
                        id="SignInPassword"
                        type="password"
                        error={meta?.touched && meta?.error && meta?.error}
                        onFocus={() => setFocusedField('password')}
                        onChange={e => {
                          e.target.value = e.target.value.replace(/\s+/g, '');
                          field.onChange(e);
                        }}
                      />
                    );
                  }}
                </FastField>
                <FastField name="confirmPassword">
                  {({ field, meta }: FastFieldProps) => {
                    return (
                      <Input
                        {...field}
                        label={getTranslatedText(
                          'pages.signUp.form.confirmPassword.label',
                        )}
                        placeholder={getTranslatedText(
                          'pages.signUp.form.confirmPassword.placeholder',
                        )}
                        id="SignInConfirmPassword"
                        type="password"
                        error={meta?.touched && meta?.error && meta?.error}
                        onFocus={() => setFocusedField('confirmPassword')}
                        onChange={e => {
                          e.target.value = e.target.value.replace(/\s+/g, '');
                          field.onChange(e);
                        }}
                      />
                    );
                  }}
                </FastField>
                <FastField name="phone">
                  {({ field, meta }: FastFieldProps) => {
                    return (
                      <InputMask
                        {...field}
                        mask="(99)99999-9999"
                        id="SignUpPhone"
                        label={getTranslatedText(
                          'pages.signUp.form.phone.label',
                        )}
                        placeholder={getTranslatedText(
                          'pages.signUp.form.phone.placeholder',
                        )}
                        type="text"
                        error={meta?.touched && meta?.error && meta?.error}
                        onFocus={() => setFocusedField('phone')}
                      />
                    );
                  }}
                </FastField>
                <CustomAntButton
                  type="primary"
                  htmlType="submit"
                  disabled={formikProps.isSubmitting}
                >
                  {!formikProps.isSubmitting
                    ? getTranslatedText('pages.signUp.form.submitButton')
                    : getTranslatedText(
                        'pages.signUp.form.submitButtonLoading',
                      )}
                </CustomAntButton>
              </form>
            )}
          </Formik>
        </AnimationContainer>
      </Content>
    </Container>
  );
};

export default SignUp;
