import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  > small {
    width: 100%;
    text-align: center;
    color: ${props => props.theme.colors.light['light-2']};
  }

  > ul {
    width: 100%;
    max-width: ${props => props.theme.containerWidth};
    margin: 1rem auto 2rem;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1rem;

    @media only screen and (max-width: ${props => props.theme.breakpoints.xl}) {
      grid-template-columns: 1fr 1fr;
    }

    @media only screen and (max-width: ${props => props.theme.breakpoints.md}) {
      grid-template-columns: 1fr;
    }
  }
`;
