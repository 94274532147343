import styled, { css } from 'styled-components';
import { Button as AntButton } from 'antd';
import { darken } from 'polished';

interface ButtonProps {
  $textPrimary: boolean;
  $useCustomIcon: boolean;
  $useSecondary: boolean;
}

export const Button = styled(AntButton)<ButtonProps>`
  ${props =>
    props.$textPrimary &&
    css`
      color: ${props.theme.colors.primary['primary+1']};

      &:hover,
      &:focus,
      &:active {
        color: ${props.theme.colors.primary.primary0};
      }
    `}

  ${props =>
    props.$useCustomIcon &&
    css`
      line-height: 0;
    `}

    ${props =>
    props.$useSecondary &&
    css`
      background-color: ${props.theme.colors.secondary.secondary0};
      border-color: ${props.theme.colors.secondary.secondary0};

      &:hover,
      &:focus,
      &:active {
        background-color: ${() =>
          darken(0.1, props.theme.colors.secondary.secondary0)};
        border-color: ${() =>
          darken(0.1, props.theme.colors.secondary.secondary0)};
      }
    `}
`;
