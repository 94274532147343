import { Link } from 'react-router-dom';
import styled from 'styled-components';

interface IScoreText {
  $notScore?: boolean;
}

export const Container = styled.div`
  > small {
    margin-bottom: 1rem;
    text-align: center;
    color: ${props => props.theme.colors.light['light-2']};
  }

  > div {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 3.5rem;

    p {
      margin-left: 1rem;
      color: ${props => props.theme.colors.light['light-2']};
    }

    h6 {
      text-align: center;

      span {
        color: ${props => props.theme.colors.primary.primary0};
      }
    }
  }

  ul {
    margin-bottom: 0;
    display: flex;
    flex-wrap: wrap;
  }
`;

export const CardGameItem = styled(Link)`
  width: 33%;
  margin-right: 0.33%;
  margin-bottom: 0.5rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${props => props.theme.colors.dark['dark+1']};
  border-radius: 0.5rem;
  position: relative;

  > div {
    display: flex;
    justify-content: space-between;
  }

  @media only screen and (min-width: ${props => props.theme.breakpoints.xxl}) {
    width: 24.5%;
    margin-right: 0.5%;
  }

  @media only screen and (max-width: ${props => props.theme.breakpoints.xl}) {
    width: 49%;
    margin-right: 0.5%;
  }

  @media only screen and (max-width: ${props => props.theme.breakpoints.sm}) {
    width: 100%;
    margin-right: 0;
  }

  strong {
    color: ${props => props.theme.colors.light['light-2']};
    font-size: ${props => props.theme.typograph.sizes.p};
    margin-bottom: 1.5rem;
  }
`;

export const ViewMoreButton = styled.button`
  width: 33%;
  margin-right: 0.33%;
  margin-bottom: 0.5rem;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${props => props.theme.colors.dark.dark0};
  border: 1px solid ${props => props.theme.colors.dark['dark+1']};
  border-radius: 0.5rem;

  p {
    color: ${props => props.theme.colors.light['light-2']};
    text-align: center;
  }

  @media only screen and (min-width: ${props => props.theme.breakpoints.xxl}) {
    width: 24.5%;
    margin-right: 0.5%;
  }

  @media only screen and (max-width: ${props => props.theme.breakpoints.xl}) {
    width: 49%;
    margin-right: 0.5%;
  }

  @media only screen and (max-width: ${props => props.theme.breakpoints.sm}) {
    width: 100%;
    margin-right: 0;
  }
`;

export const ScoreText = styled.p<IScoreText>`
  white-space: nowrap;
  margin-left: 0.5rem;

  color: ${props =>
    !props.$notScore
      ? props.theme.colors.primary.primary0
      : props.theme.colors.danger};
`;
