import { TPagesTexts } from '../../en/pages';

import SignIn from './SignIn';
import SignUp from './SignUp';
import Home from './Home';
import CoachFriend from './CoachFriend';
import Versus from './Versus';
import VersusVIP from './VersusVIP';
import Championships from './Championships';
import AthletesOfTheWeek from './AthletesOfTheWeek';
import PushGamesHistory from './PushGamesHistory';
import NotFound from './NotFound';
import Chat from './Chat';
import Ranking from './Ranking';
import Profile from './Profile';
import ForgotPassword from './ForgotPassword';

const pagesTexts: TPagesTexts = {
  ...CoachFriend,
  ...SignIn,
  ...SignUp,
  ...ForgotPassword,
  ...Home,
  ...Versus,
  ...VersusVIP,
  ...Championships,
  ...AthletesOfTheWeek,
  ...PushGamesHistory,
  ...NotFound,
  ...Chat,
  ...Ranking,
  ...Profile,
};

export default pagesTexts;
