import React from 'react';
import { Modal } from 'antd';

import { FaFacebook, FaTwitter, FaWhatsapp, FaTelegram } from 'react-icons/fa';

import { getShareSocialLinks } from '@/utils/formatters';
import handleWindowOpen from '@/utils/handleWindowOpen';
import { showToast } from '@/hooks/showToast';

import { ModalContent, ShareButton } from './styles';

export type TSocial = 'facebook' | 'twitter' | 'whatsapp' | 'telegram';

interface IShareModalProps {
  opened: boolean;
  onClose(): void;
  url: string;
}

const ShareModal: React.FC<IShareModalProps> = ({ opened, onClose, url }) => {
  const handleShareOnSocial = (social: TSocial): void => {
    const shareLink = getShareSocialLinks(social, url);

    handleWindowOpen(shareLink);
  };

  return (
    <Modal
      title="Compartilhar"
      visible={opened}
      onCancel={onClose}
      footer={null}
    >
      <ModalContent>
        <div>
          <ShareButton
            onClick={() => handleShareOnSocial('facebook')}
            $social="facebook"
          >
            <FaFacebook size={20} />
            <span>Facebook</span>
          </ShareButton>

          <ShareButton
            onClick={() => handleShareOnSocial('twitter')}
            $social="twitter"
          >
            <FaTwitter size={20} />
            <span>Twitter</span>
          </ShareButton>

          <ShareButton
            onClick={() => handleShareOnSocial('whatsapp')}
            $social="whatsapp"
          >
            <FaWhatsapp size={20} />
            <span>Whatsapp</span>
          </ShareButton>

          <ShareButton
            onClick={() => handleShareOnSocial('telegram')}
            $social="telegram"
          >
            <FaTelegram size={20} />
            <span>Telegram</span>
          </ShareButton>
        </div>
        <div>
          <div>{url}</div>
          <ShareButton
            onClick={() => {
              navigator.clipboard.writeText(url);
              showToast({
                type: 'info',
                title: 'Link copiado para área de transferência',
              });
            }}
          >
            Copiar link
          </ShareButton>
        </div>
      </ModalContent>
    </Modal>
  );
};

export default ShareModal;
