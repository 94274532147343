const NotificationsTexts = {
  'components.notifications.title': 'Notificações',
  /* Buttons */
  'components.notifications.markAllAsReadButton': 'Marcar todas como lidas',
  /* Messages */
  'components.notifications.messages.getNotificationsError.title':
    'Erro ao carregar as notificações',
  'components.notifications.messages.newNotification.title':
    'Você recebeu uma nova notificação',
  'components.notifications.messages.submitDeleteNotificationSuccess.title':
    'Notificação excluída!',
  'components.notifications.messages.submitMarkWithReadNotificationError.title':
    'Não foi possível marcar a notificação como lida',
  'components.notifications.messages.submitMarkWithReadNotificationsError.title':
    'Não foi possível marcar as notificações como lidas',
  'components.notifications.messages.submitMarkWithReadNotificationError.description':
    'Aconteceu um erro inesperado ao marcar a notificação como lida',
  'components.notifications.messages.submitMarkWithReadNotificationsError.description':
    'Aconteceu um erro inesperado ao marcar as notificações como lidas',
  'components.notifications.messages.noNotifications':
    'Você não possui novas <span>notificações</span>',
};

export default {
  ...NotificationsTexts,
};
