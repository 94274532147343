import React, { createContext, useContext, useState } from 'react';

interface ISelectedAdOnNotificationList {
  _cardId: string;
  _adId: string;
}

interface AthleteCardAdsContextData {
  selectedAdOnNotificationList: ISelectedAdOnNotificationList | null;
  setSelectedAdOnNotificationList: React.Dispatch<
    React.SetStateAction<ISelectedAdOnNotificationList | null>
  >;
}

const AthleteCardAdsContext = createContext<AthleteCardAdsContextData>(
  {} as AthleteCardAdsContextData,
);

const AthleteCardAdsProvider: React.FC = ({ children }) => {
  const [selectedAdOnNotificationList, setSelectedAdOnNotificationList] =
    useState<ISelectedAdOnNotificationList | null>(null);

  return (
    <AthleteCardAdsContext.Provider
      value={{
        selectedAdOnNotificationList,
        setSelectedAdOnNotificationList,
      }}
    >
      {children}
    </AthleteCardAdsContext.Provider>
  );
};

function useAthleteCardAds(): AthleteCardAdsContextData {
  const context = useContext(AthleteCardAdsContext);

  if (!context) {
    throw new Error(
      'useAthleteCardAds must be used within an AthleteCardAdsProvider',
    );
  }

  return context;
}

export { useAthleteCardAds, AthleteCardAdsProvider };
