import React from 'react';
import { Tooltip } from 'antd';

import { Container } from './styles';
import { ITooltipBadgeProps } from './types';

const TooltipBadge: React.FC<ITooltipBadgeProps> = ({
  type = 'primary',
  ...rest
}) => {
  return (
    <Tooltip {...rest}>
      <Container $type={type}>?</Container>
    </Tooltip>
  );
};

export default TooltipBadge;
