import styled, { css } from 'styled-components';

interface IContainer {
  $size: number;
  $hasActionForShowUserData: boolean;
}

export const Container = styled.span<IContainer>`
  border: 0;
  background: transparent;
  width: ${props => props.$size}px;
  height: ${props => props.$size}px;
  border-radius: 50%;
  opacity: 1;
  transition: 0.2s opacity;
  cursor: inherit;

  &:hover {
    ${props =>
      props.$hasActionForShowUserData &&
      css`
        opacity: 0.85;
      `}
  }

  ${props =>
    props.$hasActionForShowUserData &&
    css`
      cursor: pointer;
    `}

  img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }

  div {
    border-radius: 50%;
  }
`;

export const ModalContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  img,
  div:nth-child(1) {
    width: 104px;
    height: 104px;
    border-radius: 50%;
  }

  img {
    object-fit: cover;
  }

  div:nth-child(2) {
    text-align: center;
    margin: 16px 0;

    h6 {
      line-height: 100%;
      margin-bottom: 4px;
    }

    small {
      color: ${props => props.theme.colors.light['light-2']};
    }
  }
`;
