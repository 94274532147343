import React, { useCallback, useMemo } from 'react';

import DefaultAvatar from '@/assets/DefaultAvatar.svg';
import { useIntl } from '@/context/IntlContext';
import { UploadRequests } from '@/services/api/requests/Upload';
import { EAthletePosition, EGameType } from '../../types';

import {
  AthleteInfoContainer,
  AthleteNameContainer,
  CardOptionsContainer,
  Container,
  ResultDivider,
  SelectedAthleteScore,
  SelectedOption,
  SelectedOptionLabel,
  SelectedOptionResultDescription,
} from './styles';
import { EOptionStatus, ICardProps } from './types';
import { getAthleteShortPosition } from '../../utils';

const Card: React.FC<ICardProps> = ({
  playedAthlete,
  cardDetails,
  isCardFinished,
  isItGameMine,
  username,
}) => {
  const intl = useIntl();

  const {
    name,
    number,
    position,
    photo: athletePhoto,
  } = playedAthlete._athlete;
  const { name: teamName, abbrev, image: teamImage } = playedAthlete._team;

  function getSelectedAthleteScoreLabel(): string {
    if (!position) {
      return 'Quantidade selecionada';
    }
    if (position === EAthletePosition.GOALKEEPER) {
      return 'Quantidade de defesas selecionada';
    }
    if (
      position === EAthletePosition.ATP ||
      position === EAthletePosition.WTA
    ) {
      return 'Quantidade de sets selecionada';
    }
    if (position === EAthletePosition.F1_START) {
      return 'Posição no grid de largada selecionada';
    }
    if (position === EAthletePosition.F1_FINISH) {
      return 'Posição de chegada selecionada';
    }
    if (position === EAthletePosition.POKER__HAND_CHAMPION_SEQUENCE) {
      return 'Hand Champion Sequence';
    }
    if (position === EAthletePosition.POKER__ELIMINATION_SEQUENCE) {
      return 'Elimination Sequence';
    }
    if (position === EAthletePosition.GOAL_MINUTE) {
      return 'Minuto do gol selecionado';
    }
    if (position === EAthletePosition.FIRST_GOAL_MINUTE) {
      return 'Minuto do 1˚ gol na partida selecionado';
    }
    if (position === EAthletePosition.SECOND_GOAL_MINUTE) {
      return 'Minuto do 2˚ gol na partida selecionado';
    }
    if (position === EAthletePosition.FIRST_GOAL_MINUTE_OF_THE_ROUND) {
      return 'Minuto do 1˚ gol na rodada selecionado';
    }
    if (position === EAthletePosition.SECOND_GOAL_MINUTE_OF_THE_ROUND) {
      return 'Minuto do 2˚ gol na rodada selecionado';
    }
    if (position === EAthletePosition.FIRST_HALF_GOAL_MINUTE) {
      return 'Minuto do gol no 1˚ tempo selecionado';
    }
    if (position === EAthletePosition.SECOND_HALF_GOAL_MINUTE) {
      return 'Minuto do gol no 2˚ tempo selecionado';
    }
    if (position === EAthletePosition.GOAL_INSIDE_AREA) {
      return 'Quantidade de gols dentro da área na partida selecionada';
    }
    if (position === EAthletePosition.GOAL_OUTSIDE_AREA) {
      return 'Quantidade de gols fora da área na partida selecionada';
    }
    if (position === EAthletePosition.GOAL_INSIDE_AREA_OF_THE_ROUND) {
      return 'Quantidade de gols dentro da área na rodada selecionada';
    }
    if (position === EAthletePosition.GOAL_OUTSIDE_AREA_OF_THE_ROUND) {
      return 'Quantidade de gols fora da área na rodada selecionada';
    }
    if (position === EAthletePosition.GOALS_IN_THE_FIRST_HALF) {
      return 'Quantidade de gols no 1˚ tempo selecionada';
    }
    if (position === EAthletePosition.GOALS_IN_THE_SECOND_HALF) {
      return 'Quantidade de gols no 2˚ tempo selecionada';
    }
    if (position === EAthletePosition.GOALS_IN_THE_FIRST_HALF_OF_THE_ROUND) {
      return 'Quantidade de gols no 1˚ tempo da rodada selecionada';
    }
    if (position === EAthletePosition.GOALS_IN_THE_SECOND_HALF_OF_THE_ROUND) {
      return 'Quantidade de gols no 2˚ tempo da rodada selecionada';
    }

    if (position === EAthletePosition.FOOTBALL__FIELD_GOAL_AMOUNT) {
      return 'Quantidade de "Field Goal" selecionada';
    }
    if (position === EAthletePosition.FOOTBALL__TOUCHDOWN_AMOUNT) {
      return 'Quantidade de "Touchdown" selecionada';
    }
    if (position === EAthletePosition.FOOTBALL__TWO_POINT_CONVERSION_AMOUNT) {
      return 'Quantidade de conversões de 2 pontos selecionada';
    }
    if (position === EAthletePosition.FOOTBALL__EXTRA_KICK_AMOUNT) {
      return 'Quantidade de chutes extra selecionada';
    }
    if (position === EAthletePosition.FOOTBALL__SAFETY_AMOUNT) {
      return 'Quantidade de "Safety" selecionada';
    }
    if (position === EAthletePosition.FOOTBALL__PICK_SIX_TOUCHDOWN_AMOUNT) {
      return 'Quantidade de Pick-six "touchdown" selecionada';
    }

    return 'Quantidade de gols selecionada';
  }

  const formattedPosition = getAthleteShortPosition(position);

  const selectedAthleteScoreLabel = getSelectedAthleteScoreLabel();

  const selectedGoalOption = useMemo(() => {
    const { _athleteGoal: selectedGoalOptionId } = playedAthlete;
    return cardDetails.goalOptions.find(
      option => option._id === selectedGoalOptionId,
    );
  }, [cardDetails.goalOptions, playedAthlete]);

  const selectedStatisticOption = useMemo(() => {
    const { _athleteStatistic: selectedStatisticOptionId } = playedAthlete;
    return cardDetails.statisticOptions.find(
      option => option._id === selectedStatisticOptionId,
    );
  }, [cardDetails.statisticOptions, playedAthlete]);

  const athleteClassicValue = useMemo(() => {
    const { _id: _athleteId } = playedAthlete._athlete;

    const classicValue = cardDetails.athletes.find(
      athlete => athlete._athlete === _athleteId,
    )?.classicValue;
    return classicValue;
  }, [cardDetails.athletes, playedAthlete._athlete]);

  const getResultStatus = useCallback(
    (score: number) => {
      if (score) {
        return EOptionStatus.WON;
      }
      if (isCardFinished) {
        return EOptionStatus.LOST;
      }

      return EOptionStatus.WAITING;
    },
    [isCardFinished],
  );

  const getResultStatusDescription = (status: EOptionStatus): string => {
    const userFirstName = username.split(' ')[0];

    if (status === EOptionStatus.WON) {
      return `${isItGameMine ? 'Você' : userFirstName} acertou!`;
    }
    if (status === EOptionStatus.LOST) {
      return `${isItGameMine ? 'Você' : userFirstName} não acertou...`;
    }

    return 'Aguarde o resultado';
  };

  const goalOptionStatus = useMemo(() => {
    const { goalScore } = playedAthlete;
    return getResultStatus(goalScore);
  }, [getResultStatus, playedAthlete]);

  const statisticOptionStatus = useMemo(() => {
    const { statisticScore } = playedAthlete;
    return getResultStatus(statisticScore);
  }, [getResultStatus, playedAthlete]);

  const scoreStatus = useMemo(() => {
    const { playerScore } = playedAthlete;
    return getResultStatus(playerScore);
  }, [getResultStatus, playedAthlete]);

  return (
    <Container>
      <AthleteInfoContainer>
        <div>
          {(number || formattedPosition) && (
            <h4>
              {number}
              <span>{formattedPosition}</span>
            </h4>
          )}
        </div>
        <img
          src={
            athletePhoto
              ? UploadRequests.getFileUrl(athletePhoto)
              : DefaultAvatar
          }
          alt={name}
        />
        <div>
          <div>
            <img src={teamImage} alt={teamName} />
            <p>{abbrev}</p>
          </div>
        </div>
      </AthleteInfoContainer>
      <AthleteNameContainer>
        <h6>{name}</h6>
      </AthleteNameContainer>
      {cardDetails.gameType === EGameType.OPTIONS && (
        <CardOptionsContainer>
          <strong>Resultados</strong>
          <div>
            <SelectedOptionLabel>Tipo de gol selecionado</SelectedOptionLabel>
            <SelectedOption $result={goalOptionStatus}>
              <p>
                {selectedGoalOption?.name}
                <span>
                  {selectedGoalOption?.value}{' '}
                  {intl.getTranslatedText('common.scoredPointsDescription')}
                </span>
              </p>
            </SelectedOption>
            <SelectedOptionResultDescription $result={goalOptionStatus}>
              {getResultStatusDescription(goalOptionStatus)}
            </SelectedOptionResultDescription>
          </div>
          <ResultDivider />
          <div>
            <SelectedOptionLabel>Estatística selecionada</SelectedOptionLabel>
            <SelectedOption $result={statisticOptionStatus}>
              <p>
                {selectedStatisticOption?.name}
                <span>
                  {selectedStatisticOption?.value}{' '}
                  {intl.getTranslatedText('common.scoredPointsDescription')}
                </span>
              </p>
            </SelectedOption>
            <SelectedOptionResultDescription $result={statisticOptionStatus}>
              {getResultStatusDescription(statisticOptionStatus)}
            </SelectedOptionResultDescription>
          </div>
        </CardOptionsContainer>
      )}
      {cardDetails.gameType === EGameType.CLASSIC && (
        <CardOptionsContainer>
          <strong>Resultados</strong>
          <div>
            <SelectedOptionLabel>
              {selectedAthleteScoreLabel}
            </SelectedOptionLabel>
            <SelectedAthleteScore $result={scoreStatus}>
              <h6>{playedAthlete.athleteScore}</h6>
              <small>
                {athleteClassicValue}{' '}
                {intl.getTranslatedText('common.scoredPointsDescription')}
              </small>
            </SelectedAthleteScore>
            <SelectedOptionResultDescription $result={scoreStatus}>
              {getResultStatusDescription(scoreStatus)}
            </SelectedOptionResultDescription>
          </div>
        </CardOptionsContainer>
      )}
    </Container>
  );
};

export default Card;
