import React from 'react';
import { useLocation, useRouteMatch } from 'react-router-dom';
import * as dateFns from 'date-fns';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import { useIntl } from '../../../../context/IntlContext';

import { Container, CardGameItem, ViewMoreButton, ScoreText } from './styles';
import { IMyGamesProps } from './types';
import { ECardTypeEnum } from '../../types';

const loadingIcon = <LoadingOutlined style={{ fontSize: 20 }} spin />;
const { parseISO, format } = dateFns;

const MyGames: React.FC<IMyGamesProps> = ({
  myGames,
  loading,
  loadMore,
  isOnLastPage,
  cardType,
}) => {
  const { search: searchLocation } = useLocation();
  const searchParams = new URLSearchParams(searchLocation);
  const influencerCodeByParams = searchParams.get('influencer_code');

  const { url } = useRouteMatch();

  const intl = useIntl();

  if (loading && myGames.length === 0) {
    return (
      <Container>
        <div>
          <Spin style={{ lineHeight: 0 }} indicator={loadingIcon} />
          <p>
            {intl.getTranslatedText(
              'pages.athletesOfTheWeek.card.myGames.loadingMyGames',
            )}
          </p>
        </div>
      </Container>
    );
  }

  if (myGames.length === 0) {
    return (
      <Container>
        <div>
          <h6>
            {intl.getTranslatedTextWithHTML(
              'pages.athletesOfTheWeek.card.myGames.noGames',
            )}
          </h6>
        </div>
      </Container>
    );
  }

  return (
    <Container>
      {cardType === ECardTypeEnum.RUNNING && (
        <small>
          {intl.getTranslatedText(
            'pages.athletesOfTheWeek.card.ranking.messages.partialInfo',
          )}
        </small>
      )}
      <ul>
        {myGames.map(myGame => (
          <CardGameItem
            key={myGame._id}
            to={`${url}/my_games/${myGame._id}${
              influencerCodeByParams
                ? `?influencer_code=${influencerCodeByParams}`
                : ''
            }`}
          >
            <div>
              <strong>{myGame.name}</strong>
              <ScoreText $notScore={!myGame.totalScore}>
                {myGame.totalScore.toFixed(2).replace(/[.,]00$/, '')}{' '}
                {myGame.totalScore === 1
                  ? intl.getTranslatedText('common.scoredPointDescription')
                  : intl.getTranslatedText('common.scoredPointsDescription')}
              </ScoreText>
            </div>
            <small>
              {format(
                parseISO(myGame.createdAt),
                intl.getTranslatedText('common.formatDates.carriedOut'),
              )}
            </small>
          </CardGameItem>
        ))}
        {!isOnLastPage && (
          <ViewMoreButton disabled={loading} onClick={loadMore}>
            {!loading ? (
              <p>{intl.getTranslatedText('common.buttons.viewMore')}</p>
            ) : (
              <p>{intl.getTranslatedText('common.messages.defaultLoading')}</p>
            )}
          </ViewMoreButton>
        )}
      </ul>
    </Container>
  );
};

export default MyGames;
