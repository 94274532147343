interface IAppErrorOptions {
  type?: 'validation';
}

class AppError {
  public readonly title: string;

  public readonly description?: string;

  public readonly options?: IAppErrorOptions;

  constructor(title: string, description?: string, options?: IAppErrorOptions) {
    this.title = title;
    this.description = description;
    this.options = options;
  }
}

export default AppError;
