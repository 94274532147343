import styled from 'styled-components';

export const Container = styled.div`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  margin-right: 16px;
  background-color: ${props => props.theme.colors.dark.dark0};
  border: 1px solid ${props => props.theme.colors.dark['dark+4']};
  display: flex;
  justify-content: center;
  align-items: center;

  img,
  svg {
    width: 28px;
    height: 28px;
  }
`;

export const PurchaseCoinsImgContainer = styled.img`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  margin-right: 16px;
`;
