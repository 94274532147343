import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FiChevronLeft } from 'react-icons/fi';
import { useRouteMatch, useHistory, Link } from 'react-router-dom';
import { format, parseISO } from 'date-fns';

import { useAuth } from '@/context/AuthContext';
import { useIntl } from '@/context/IntlContext';

import Breadcrumbs from '@/components/Breadcrumbs';
import Loading from '@/components/Loading';
import NotFound from '@/components/NotFound';
import api from '@/services/api';
import socket from '@/services/socket';
import { UploadRequests } from '@/services/api/requests/Upload';
import DefaultAvatar from '@/assets/DefaultAvatar.svg';

import {
  Container,
  Header,
  CardInfoContainer,
  ScoreText,
  UserInfoContainer,
  CardDetailsContainer,
} from './styles';
import { IRouteParams, IPlayedCardGame, ICardGameDetails } from './types';
import Card from './Card';
import { ECardTypeEnum } from '../types';

const GameDetails: React.FC = () => {
  const isFirstRender = useRef(true);
  const { params } = useRouteMatch<IRouteParams>();
  const history = useHistory();

  const intl = useIntl();
  const { user: me } = useAuth();

  const [cardGameId, setCardGameId] = useState('');
  const [playedCardGame, setPlayedCardGame] = useState<IPlayedCardGame | null>(
    null,
  );
  const [cardGameDetails, setCardGameDetails] =
    useState<ICardGameDetails | null>(null);
  const [loadingPlayedCardGame, setLoadingPlayedCardGame] = useState(true);

  const getPlayedGameCard = useCallback(async (): Promise<void> => {
    try {
      const { data } = await api.get<{
        doc: IPlayedCardGame;
        cardDetails: ICardGameDetails;
      }>(`/api/played-athlete/${params.played_card_game_id}`);

      const isItGameMine = data.doc._user._id === me?._id;
      const isNewCard = data.cardDetails.cardType === ECardTypeEnum.NEW;
      if (!isItGameMine && isNewCard) {
        history.push(`/`);
        return;
      }

      const athletesWithAbreevOnTeam = data.doc.athletes.map(athlete => ({
        ...athlete,
        _team: {
          ...athlete._team,
          abbrev: athlete._team.name.substring(0, 3).toUpperCase(),
        },
      }));

      setCardGameId(data.doc._card._id);
      setPlayedCardGame({
        ...data.doc,
        athletes: athletesWithAbreevOnTeam,
      });
      setCardGameDetails(data.cardDetails);
    } catch (error) {
      //
    }
    setLoadingPlayedCardGame(false);
  }, [history, me?._id, params.played_card_game_id]);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      getPlayedGameCard();
    }
  }, [getPlayedGameCard, history, me, params]);

  useEffect(() => {
    if (cardGameId) {
      socket.on(`athlete-end:${cardGameId}`, () => {
        getPlayedGameCard();
      });

      return () => {
        socket.off(`athlete-end:${cardGameId}`);
      };
    }

    return () => ({});
  }, [cardGameId, getPlayedGameCard]);

  if (loadingPlayedCardGame) {
    return (
      <Container>
        <Breadcrumbs />
        <Header>
          <h5>
            {intl.getTranslatedText(
              'pages.athletesOfTheWeek.myCardGameDetails.title',
            )}
          </h5>
        </Header>
        <Loading />
      </Container>
    );
  }

  if (!playedCardGame || !cardGameDetails) {
    return (
      <NotFound>
        <h4>
          {intl.getTranslatedTextWithHTML(
            'pages.athletesOfTheWeek.myCardGameDetails.messages.noGame',
          )}
        </h4>
        <Link to="/">
          <FiChevronLeft size={16} />
          {intl.getTranslatedText(
            'pages.athletesOfTheWeek.myCardGameDetails.goToHome',
          )}
        </Link>
      </NotFound>
    );
  }

  return (
    <Container>
      <Breadcrumbs />
      <Header>
        <h5>
          {intl.getTranslatedText(
            'pages.athletesOfTheWeek.myCardGameDetails.title',
          )}
        </h5>
      </Header>
      <CardInfoContainer>
        <div>
          {playedCardGame._user._id !== me?._id && (
            <UserInfoContainer>
              <img
                src={
                  playedCardGame._user.photo
                    ? UploadRequests.getFileUrl(playedCardGame._user.photo)
                    : DefaultAvatar
                }
                alt={playedCardGame._user.name}
              />
              <div>
                <p>{playedCardGame._user.name}</p>
                <small>{playedCardGame._user.username}</small>
              </div>
            </UserInfoContainer>
          )}
          <CardDetailsContainer>
            <small>{playedCardGame._card.name}</small>
            <small>
              {format(
                parseISO(playedCardGame.createdAt),
                intl.getTranslatedText('common.formatDates.carriedOut'),
              )}
            </small>
          </CardDetailsContainer>
        </div>
        <div>
          <ScoreText $notScore={!playedCardGame.totalScore}>
            {playedCardGame.totalScore.toFixed(2).replace(/[.,]00$/, '')}{' '}
            {intl.getTranslatedText('common.scoredPointsDescription')}
          </ScoreText>
        </div>
      </CardInfoContainer>
      <ul>
        {playedCardGame?.athletes.map(playedAthlete => (
          <Card
            key={playedAthlete._athlete._id}
            playedAthlete={playedAthlete}
            cardDetails={cardGameDetails}
            isCardFinished={playedCardGame.finished}
            isItGameMine={playedCardGame._user._id === me?._id}
            username={playedCardGame._user.name}
          />
        ))}
      </ul>
    </Container>
  );
};

export default GameDetails;
