import React, { useCallback, useMemo, useRef, useState } from 'react';

import { UploadRequests } from '@/services/api/requests/Upload';
import DefaultAvatar from '@/assets/DefaultAvatar.svg';
import { FiMinus, FiPlus } from 'react-icons/fi';
import { EAthletePosition, ECardGameOptionTopic, EGameType } from '../../types';

import {
  AthleteInfoContainer,
  AthleteNameContainer,
  CardOptionsContainer,
  ChangeGoalAmountInput,
  ChangeGolAmountButton,
  Container,
  SelectGolAmountContainer,
} from './styles';
import OptionSelect from './OptionSelect';
import { ICardProps } from './types';
import { getAthleteShortPosition } from '../../utils';

const Card: React.FC<ICardProps> = ({
  userGameAthlete,
  goalOptions,
  statisticOptions,
  setUserGame,
  gameType,
}) => {
  const { athlete } = userGameAthlete;
  const { name, number, position, photo: athletePhoto } = athlete._athlete;
  const { name: teamName, abbrev, image: teamImage } = athlete._team;

  function getSelectAthleteScoreLabel(): string {
    if (!position) {
      return 'Selecione a quantidade na partida';
    }
    if (position === EAthletePosition.GOALKEEPER) {
      return 'Selecione a quantidade de defesas desse jogador na partida';
    }
    if (
      position === EAthletePosition.ATP ||
      position === EAthletePosition.WTA
    ) {
      return 'Selecione a quantidade de sets na partida';
    }
    if (position === EAthletePosition.F1_START) {
      return 'Selecione a posição no grid de largada';
    }
    if (position === EAthletePosition.F1_FINISH) {
      return 'Selecione a posição de chegada';
    }
    if (position === EAthletePosition.POKER__HAND_CHAMPION_SEQUENCE) {
      return 'Hand Champion Sequence';
    }
    if (position === EAthletePosition.POKER__ELIMINATION_SEQUENCE) {
      return 'Elimination Sequence';
    }
    if (position === EAthletePosition.GOAL_MINUTE) {
      return 'Selecione o minuto do gol na partida';
    }
    if (position === EAthletePosition.FIRST_GOAL_MINUTE) {
      return 'Selecione o minuto do 1˚ gol da partida';
    }
    if (position === EAthletePosition.SECOND_GOAL_MINUTE) {
      return 'Selecione o minuto do 2˚ gol da partida';
    }
    if (position === EAthletePosition.FIRST_GOAL_MINUTE_OF_THE_ROUND) {
      return 'Selecione o minuto do 1˚ gol da rodada';
    }
    if (position === EAthletePosition.SECOND_GOAL_MINUTE_OF_THE_ROUND) {
      return 'Selecione o minuto do 2˚ gol da rodada';
    }
    if (position === EAthletePosition.FIRST_HALF_GOAL_MINUTE) {
      return 'Selecione o minuto do gol no 1˚ tempo';
    }
    if (position === EAthletePosition.SECOND_HALF_GOAL_MINUTE) {
      return 'Selecione o minuto do gol no 2˚ tempo';
    }
    if (position === EAthletePosition.GOAL_INSIDE_AREA) {
      return 'Selecione a quantidade de gols dentro da área na partida';
    }
    if (position === EAthletePosition.GOAL_OUTSIDE_AREA) {
      return 'Selecione a quantidade de gols fora da área na partida';
    }
    if (position === EAthletePosition.GOAL_INSIDE_AREA_OF_THE_ROUND) {
      return 'Selecione a quantidade de gols dentro da área na rodada';
    }
    if (position === EAthletePosition.GOAL_OUTSIDE_AREA_OF_THE_ROUND) {
      return 'Selecione a quantidade de gols fora da área na rodada';
    }
    if (position === EAthletePosition.GOALS_IN_THE_FIRST_HALF) {
      return 'Selecione a quantidade de gols no 1˚ tempo';
    }
    if (position === EAthletePosition.GOALS_IN_THE_SECOND_HALF) {
      return 'Selecione a quantidade de gols no 2˚ tempo';
    }
    if (position === EAthletePosition.GOALS_IN_THE_FIRST_HALF_OF_THE_ROUND) {
      return 'Selecione a quantidade de gols no 1˚ tempo da rodada';
    }
    if (position === EAthletePosition.GOALS_IN_THE_SECOND_HALF_OF_THE_ROUND) {
      return 'Selecione a quantidade de gols no 2˚ tempo da rodada';
    }

    if (position === EAthletePosition.FOOTBALL__FIELD_GOAL_AMOUNT) {
      return 'Selecione a quantidade de "Field Goal"';
    }
    if (position === EAthletePosition.FOOTBALL__TOUCHDOWN_AMOUNT) {
      return 'Selecione a quantidade de "Touchdown"';
    }
    if (position === EAthletePosition.FOOTBALL__TWO_POINT_CONVERSION_AMOUNT) {
      return 'Selecione a quantidade de conversões de 2 pontos';
    }
    if (position === EAthletePosition.FOOTBALL__EXTRA_KICK_AMOUNT) {
      return 'Selecione a quantidade chutes extra';
    }
    if (position === EAthletePosition.FOOTBALL__SAFETY_AMOUNT) {
      return 'Selecione a quantidade "Safety"';
    }
    if (position === EAthletePosition.FOOTBALL__PICK_SIX_TOUCHDOWN_AMOUNT) {
      return 'Selecione a quantidade de Pick-six "touchdown"';
    }

    return 'Selecione a quantidade de gols desse jogador na partida';
  }

  const formattedPosition = getAthleteShortPosition(position);
  const selectAthleteScoreLabel = getSelectAthleteScoreLabel();

  const inputGoalAmountRef = useRef<HTMLInputElement>({} as HTMLInputElement);
  const [inputGoalAmountValue, setInputGoalAmountValue] = useState('0');

  const selectedGoalOption = useMemo(() => {
    const { _athleteGoal: selectedGoalOptionId } = userGameAthlete;
    return goalOptions.find(option => option._id === selectedGoalOptionId);
  }, [goalOptions, userGameAthlete]);

  const selectedStatisticOption = useMemo(() => {
    const { _athleteStatistic: selectedStatisticOptionId } = userGameAthlete;
    return statisticOptions.find(
      option => option._id === selectedStatisticOptionId,
    );
  }, [statisticOptions, userGameAthlete]);

  const handleSelectOption = useCallback(
    (optionId: string, topic: ECardGameOptionTopic) => {
      setUserGame(oldState =>
        oldState.map(oldUserGameAthlete => {
          if (
            oldUserGameAthlete.athlete._athlete._id === athlete._athlete._id
          ) {
            const updatedUserGameAthlete = { ...oldUserGameAthlete };
            if (topic === ECardGameOptionTopic.GOAL) {
              updatedUserGameAthlete._athleteGoal = optionId;
            } else if (topic === ECardGameOptionTopic.STATISTIC) {
              updatedUserGameAthlete._athleteStatistic = optionId;
            }

            return updatedUserGameAthlete;
          }

          return oldUserGameAthlete;
        }),
      );
    },
    [athlete._athlete, setUserGame],
  );

  const setGoalAmount = useCallback(
    (athleteScore: number) => {
      if (athleteScore < 0) {
        return;
      }

      setUserGame(oldState =>
        oldState.map(oldUserGameAthlete => {
          if (
            oldUserGameAthlete.athlete._athlete._id === athlete._athlete._id
          ) {
            return {
              ...oldUserGameAthlete,
              athleteScore,
            };
          }

          return oldUserGameAthlete;
        }),
      );
    },
    [athlete._athlete, setUserGame],
  );

  const handleChangeGolAmount = useCallback(
    (value: string) => {
      inputGoalAmountRef.current.style.width = `${value.length}ch`;
      setGoalAmount(value ? parseInt(value) : 0);
      setInputGoalAmountValue(value);
    },
    [setGoalAmount],
  );

  const handleChangeInputGoalAmountValue = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      let value = e.target.value.replace(/\D/g, '').slice(0, 3);
      if (value) {
        value = parseInt(value).toString();
      }

      handleChangeGolAmount(value);
    },
    [handleChangeGolAmount],
  );

  return (
    <Container>
      <AthleteInfoContainer>
        <div>
          {(number || formattedPosition) && (
            <h4>
              {number}
              <span>{formattedPosition}</span>
            </h4>
          )}
        </div>
        <img
          src={
            athletePhoto
              ? UploadRequests.getFileUrl(athletePhoto)
              : DefaultAvatar
          }
          alt={name}
        />
        <div>
          <div>
            <img src={teamImage} alt={teamName} />
            <p>{abbrev}</p>
          </div>
        </div>
      </AthleteInfoContainer>
      <AthleteNameContainer>
        <h6>{name}</h6>
      </AthleteNameContainer>
      {gameType === EGameType.OPTIONS && (
        <CardOptionsContainer>
          <div>
            <small>Selecione uma estatística na partida</small>
            <OptionSelect
              options={statisticOptions}
              selectedOption={selectedStatisticOption}
              handleSelectOption={optionId =>
                handleSelectOption(optionId, ECardGameOptionTopic.STATISTIC)
              }
            />
          </div>
          <div>
            <small>Selecione um tipo de gol na partida</small>
            <OptionSelect
              options={goalOptions}
              selectedOption={selectedGoalOption}
              handleSelectOption={optionId =>
                handleSelectOption(optionId, ECardGameOptionTopic.GOAL)
              }
            />
          </div>
        </CardOptionsContainer>
      )}
      {gameType === EGameType.CLASSIC && (
        <CardOptionsContainer>
          <div>
            <small>{selectAthleteScoreLabel}</small>
            <SelectGolAmountContainer>
              <div>
                <ChangeGolAmountButton
                  $action="decrease"
                  disabled={userGameAthlete.athleteScore === 0}
                  onClick={() =>
                    handleChangeGolAmount(
                      (userGameAthlete.athleteScore - 1).toString(),
                    )
                  }
                >
                  <FiMinus size={20} />
                </ChangeGolAmountButton>
                <ChangeGoalAmountInput
                  ref={inputGoalAmountRef}
                  value={inputGoalAmountValue}
                  inputMode="numeric"
                  onChange={e => handleChangeInputGoalAmountValue(e)}
                  onBlur={e => {
                    if (!e.target.value.length) {
                      setInputGoalAmountValue('0');
                    }
                  }}
                />
                <ChangeGolAmountButton
                  $action="increase"
                  onClick={() =>
                    handleChangeGolAmount(
                      (userGameAthlete.athleteScore + 1).toString(),
                    )
                  }
                >
                  <FiPlus size={20} />
                </ChangeGolAmountButton>
              </div>
              <small>
                {athlete.classicValue} ponto
                {athlete.classicValue > 1 ? 's' : ''}
              </small>
            </SelectGolAmountContainer>
          </div>
        </CardOptionsContainer>
      )}
    </Container>
  );
};

export default Card;
