import React, { useState } from 'react';

import CustomAntButton from '@/components/CustomAntButton';
import Input from '@/components/Input';

import api from '@/services/api';
import { IUser } from '@/types/user';
import { useAuth } from '@/context/AuthContext';
import { ISearchUserProps } from './searchUser.types';

import { InputContainer } from '../styles';

const SearchUser: React.FC<ISearchUserProps> = ({ handleSelectUser }) => {
  const { user: me } = useAuth();

  const [searchTerm, setSearchTerm] = useState('');
  const [searchError, setSearchError] = useState('');
  const [searchingUser, setSearchingUser] = useState(false);

  async function handleSearchUser(): Promise<void> {
    const equalToMyDocNumber = searchTerm === me?.docNumber;
    const equalToMyEmail = searchTerm === me?.email;
    const equalToMyUsername = searchTerm === me?.username;
    const equalToMe = equalToMyDocNumber || equalToMyEmail || equalToMyUsername;
    if (equalToMe) {
      setSearchError('Não é possível realizar transferências para si mesmo');
      return;
    }

    setSearchingUser(true);
    setSearchError('');

    try {
      const { data } = await api.get<IUser[]>('/api/user/search/eq', {
        params: {
          query: searchTerm,
        },
      });
      const user = data[0];
      setSearchingUser(false);
      handleSelectUser(user);
    } catch (error) {
      setSearchingUser(false);
      setSearchError('Esse usuário não foi encontrado');
    }
  }

  return (
    <>
      <InputContainer>
        <h6>
          Qual o <span>CPF</span>, <span>email</span> ou{' '}
          <span>nome de usuário</span> de quem vai receber?
        </h6>
        <Input
          placeholder="Digite aqui"
          id="searchUserInput"
          type="text"
          value={searchTerm}
          onChange={e => setSearchTerm(e.target.value)}
          disabled={searchingUser}
          error={searchError}
        />
      </InputContainer>
      <CustomAntButton
        htmlType="button"
        loading={searchingUser}
        type="primary"
        onClick={() => handleSearchUser()}
      >
        Selecionar usuário
      </CustomAntButton>
    </>
  );
};

export default SearchUser;
