import styled from 'styled-components';
import { shade } from 'polished';
import { animated } from 'react-spring';

interface IResponseButton {
  $typeOfButton: boolean;
}

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: 100;
`;

export const BackgroundMask = styled.div`
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.75);
`;

export const PushGameContainer = styled(animated.div)`
  width: calc(100% - 32px);
  max-width: 688px;
  position: absolute;
  z-index: 105;
  /* bottom: 15%; */
  left: 0;
  right: 0;
  margin: 0 auto;
  background: ${props => props.theme.colors.dark.dark0};
  border: 2px solid ${props => props.theme.colors.primary['primary-1']};
  border-radius: 8px;
  box-shadow: 0px 0px 20px rgba(0, 148, 32, 0.5);
  padding: 16px;

  > p {
    text-align: center;
    margin-bottom: 16px;
  }
`;

export const Header = styled.div`
  display: flex;
  margin-bottom: 24px;
  align-items: center;

  > button {
    margin-left: auto;
  }
`;

export const GamePrice = styled.small`
  padding: 2px 8px;
  border-radius: 4px;

  background: ${props => props.theme.colors.primary['primary+1']};
  color: ${props => props.theme.colors.dark.dark0} !important;
`;

export const MatchInfoContainer = styled.button`
  background: ${props => props.theme.colors.dark.dark0};
  width: 100%;
  border: 0;
  transition: 0.2s background;
  padding: 16px 0;
  margin-bottom: 16px;
  border-bottom: 1px solid ${props => props.theme.colors.dark['dark+1']};

  &:hover {
    background: ${props => shade(0.1, props.theme.colors.dark.dark0)};
  }

  > div:last-child {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      color: ${props => props.theme.colors.light['light-2']};
      margin-right: 4px;
    }

    small {
      color: ${props => props.theme.colors.light['light-2']};
    }
  }
`;

export const TeamsContainer = styled.div`
  width: 100%;
  flex-direction: column;
  align-items: center;
  padding-bottom: 8px;

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    margin-bottom: 16px;
    max-width: 752px;

    h6 {
      font-weight: ${props => props.theme.typograph.weights.medium};
      color: ${props => props.theme.colors.dark['dark+4']};
      margin: 0 24px;
    }
  }

  > small {
    color: ${props => props.theme.colors.primary.primary0};
    text-align: center;
    width: 100%;
  }
`;

export const Team = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 30%;

  div {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background: ${props => props.theme.colors.dark['dark+2']};
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 8px;

    img {
      height: 48px;
    }
  }

  strong {
    color: ${props => props.theme.colors.light['light-2']};
    text-align: center;
  }

  @media only screen and (min-width: 1200px) {
    div {
      width: 104px;
      height: 104px;

      img {
        height: 56px;
      }
    }

    strong {
      font-size: ${props => props.theme.typograph.sizes.h6}px;
    }
  }
`;

export const AccumulatedValueContainer = styled.div`
  padding: 8px 16px;
  background: ${props => props.theme.colors.dark['dark+1']};
  border-radius: 8px;
  border: 1px solid ${props => props.theme.colors.primary.primary0};
  display: flex;
  align-items: center;
  width: fit-content;
  margin: 0 auto;
  margin-bottom: 16px;

  svg {
    color: #e4ce00;
  }

  small {
    margin-left: 8px;
  }
`;

export const RestTimerContainer = styled.div`
  margin-top: 16px;

  p {
    color: ${props => props.theme.colors.light['light-2']};
    text-align: center;
  }
`;

export const MatchInfoDetailsContainer = styled.div`
  width: 100%;
  padding: 16px 0;

  > div {
    width: 100%;
    max-width: 752px;
    display: flex;
    flex-direction: column;
    margin: 0 auto;

    div {
      width: 100%;

      & + div {
        margin-top: 8px;
      }

      small {
        color: ${props => props.theme.colors.light['light-2']};
        text-align: start;
      }

      small:nth-child(1) {
        color: ${props => props.theme.colors.primary.primary0};
      }
    }
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 24px;

  button:nth-child(2) {
    margin-left: 32px;
  }
`;

export const ResponseButton = styled.button<IResponseButton>`
  background: ${props => props.theme.colors.dark.dark0};
  border: 0;
  transition: 0.2s background;
  width: 64px;
  height: 64px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background: ${props => shade(0.1, props.theme.colors.dark.dark0)};
  }

  svg {
    color: ${props =>
      props.$typeOfButton
        ? props.theme.colors.primary.primary0
        : props.theme.colors.danger};
  }
`;

export const SubmittingContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;

  p {
    margin-left: 16px;
    color: ${props => props.theme.colors.light['light-2']};
  }
`;
