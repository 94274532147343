import AvatarCropper from './AvatarCropper';
import NotificationsTexts from './Notifications';
import UserAvatarTexts from './UserAvatar';
import PushGameModalTexts from './PushGameModal';
import ChatContainerTexts from './ChatContainer';

const componentsTexts = {
  ...AvatarCropper,
  ...NotificationsTexts,
  ...UserAvatarTexts,
  ...PushGameModalTexts,
  ...ChatContainerTexts,
};

export type TComponentsTexts = typeof componentsTexts;

export default componentsTexts;
