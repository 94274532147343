import { useState, useEffect, useCallback } from 'react';

interface IUseChatAudioPlayer {
  audioElement: HTMLAudioElement | null;
}

interface IUseChatAudioData {
  curTime: number;
  duration: number;
  playing: boolean;
  setPlaying: React.Dispatch<React.SetStateAction<boolean>>;
  setClickedTime: React.Dispatch<React.SetStateAction<number>>;
  setFinishAudioPlay(): void;
}

const useChatAudioPlayer = ({
  audioElement,
}: IUseChatAudioPlayer): IUseChatAudioData => {
  const [duration, setDuration] = useState(0);
  const [curTime, setCurTime] = useState(0);
  const [playing, setPlaying] = useState(false);
  const [clickedTime, setClickedTime] = useState(0);

  const setFinishAudioPlay = useCallback((): void => {
    const audio = audioElement;

    if (audio) {
      // audio.pause();
      audio.currentTime = 0;
      setPlaying(false);
      setCurTime(0);
      setClickedTime(0);
    }
  }, [audioElement]);

  useEffect(() => {
    const audio = audioElement;

    // state setters wrappers
    const setAudioData = (): void => {
      if (audio) {
        setDuration(audio.duration);
        setCurTime(audio.currentTime);
      }
    };
    const setAudioTime = (): void => {
      if (audio && playing) {
        // if(playing)
        setCurTime(audio.currentTime);
      }
    };
    // const setFinishAudioPlay = (): void => {
    //   if (audio) {
    //     setCurTime(0);
    //     setPlaying(false);
    //   }
    // };
    if (audio) {
      // if (playing) {
      //   audio.play();
      // } else {
      //   audio.pause();
      // }

      if (clickedTime && clickedTime !== curTime) {
        audio.currentTime = clickedTime;
        setClickedTime(0);
      }

      // DOM listeners: update React state on DOM events
      audio.addEventListener('loadeddata', setAudioData);

      audio.addEventListener('timeupdate', setAudioTime);

      audio.addEventListener('ended', setFinishAudioPlay);
    }
    // effect cleanup
    return () => {
      if (audio) {
        audio.removeEventListener('loadeddata', setAudioData);
        audio.removeEventListener('timeupdate', setAudioTime);
        audio.addEventListener('ended', setFinishAudioPlay);
      }
    };
  }, [audioElement, clickedTime, curTime, playing, setFinishAudioPlay]);

  useEffect(() => {
    const audio = audioElement;

    // React state listeners: update DOM on React state changes
    if (audio) {
      if (playing) {
        audio.play();
      } else {
        audio.pause();
      }
    }
  }, [audioElement, playing]);

  // useEffect(() => {
  //   const audio = audioElement.current;

  //   if (audio) {
  //     if (playing) {
  //       audio.play();
  //     } else {
  //       audio.pause();
  //     }
  //   }
  // }, [audioElement, playing]);

  // useEffect(() => {
  //   const audio = audioElement.current;

  //   // var percentages = document.getElementsByClassName("aone1l")

  //   if (audio) {
  //     const loop = (): void => {
  //       const { buffered } = audio;
  //       let loaded;
  //       let played;

  //       if (buffered.length) {
  //         loaded = (100 * buffered.end(0)) / audio.duration;
  //         played = (100 * audio.currentTime) / audio.duration;
  //         // percentages[0].innerHTML = loaded.toFixed(2);
  //         // percentages[1].innerHTML = played.toFixed(2);
  //         console.log(
  //           `CARREGADO: ${loaded.toFixed(2)} | TOCADO: ${played.toFixed(2)}`,
  //         );
  //       }

  //       setTimeout(loop, 50);
  //     };

  //     loop();
  //   }
  // }, [audioElement]);

  return {
    curTime,
    duration,
    playing,
    setPlaying,
    setClickedTime,
    setFinishAudioPlay,
  };
};

export default useChatAudioPlayer;
