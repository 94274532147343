import styled from 'styled-components';

// interface IMessageContainerProps {
//   $lastMessageOfGroup?: boolean;
// }

export const Container = styled.div`
  margin-top: 8px;
  display: flex;
  align-items: flex-end;

  span {
    margin-right: 8px;
  }

  > div:nth-child(2) {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
`;

// export const MessageContainer = styled.button<IMessageContainerProps>`
//   /* width: min-content; */
//   border: 0;
//   text-align: start;
//   max-width: calc(100vw - 20vw - 40px - 16px);
//   border-radius: 8px;
//   ${props =>
//     props.$lastMessageOfGroup &&
//     css`
//       border-bottom-left-radius: 0;
//     `}
//   padding: 8px 8px 4px;
//   background: ${props => props.theme.colors.dark['dark+2']};
//   /* margin-right: auto; */
//   transition: 0.2s background;

//   & + button {
//     margin-top: 4px;
//   }

//   &:hover {
//     background: ${props => shade(0.07, props.theme.colors.dark['dark+2'])};
//   }

//   > p {
//     width: 100%;
//   }
// `;

// export const UserName = styled.small`
//   width: 100%;
//   font-weight: ${props => props.theme.typograph.weights.bold};
//   color: ${props => props.theme.colors.primary['primary+1']};
//   margin-bottom: 4px;
//   white-space: nowrap;
//   overflow: hidden;
//   text-overflow: ellipsis;
// `;

// export const HourAndMessageStatusContainer = styled.div`
//   width: 100%;
//   margin-top: 8px;
//   display: flex;
//   justify-content: flex-end;
//   align-items: center;

//   small {
//     font-size: 12px;
//     color: ${props => props.theme.colors.light['light-2']};
//   }

//   svg {
//     color: ${props => props.theme.colors.light['light-2']};
//     margin-left: 4px;
//   }
// `;
