import React, { useEffect } from 'react';
import { FiArrowLeft, FiX } from 'react-icons/fi';

import { useBullet, EBulletPagePath } from '@/context/BulletContext';
import CustomAntButton from '../CustomAntButton';

import Main from './Main';
import ChooseCoinsPack from './Deposit/ChooseCoinsPack';
import Payment from './Deposit/Payment';
import Withdraw from './Withdraw';
import Transfer from './Transfer';

import { Container, Header, BackgroundMask, Wrapper } from './styles';

const Bullet: React.FC = () => {
  const bullet = useBullet();

  useEffect(() => {
    if (bullet.bulletOpened) {
      document.body.classList.add('noscroll');
    } else {
      document.body.classList.remove('noscroll');
    }
  }, [bullet.bulletOpened]);

  if (!bullet.bulletOpened) return <></>;

  return (
    <Wrapper>
      <BackgroundMask onClick={() => bullet.toggleBulletOpened()} />
      <Container>
        <Header $isOnMain={bullet.currentPage.path === EBulletPagePath.MAIN}>
          <div>
            {bullet.currentPage.name && (
              <>
                <CustomAntButton
                  icon={<FiArrowLeft size={20} />}
                  useCustomIcon
                  type="text"
                  htmlType="button"
                  onClick={() => bullet.navigateTo(EBulletPagePath.MAIN)}
                />
                <h6>{bullet.currentPage.name}</h6>
              </>
            )}
          </div>
          <CustomAntButton
            icon={<FiX size={20} />}
            useCustomIcon
            type="text"
            htmlType="button"
            onClick={() => bullet.toggleBulletOpened()}
          />
        </Header>
        {bullet.currentPage.path === EBulletPagePath.MAIN && <Main />}
        {bullet.currentPage.path === EBulletPagePath.DEPOSIT && (
          <ChooseCoinsPack />
        )}
        {bullet.currentPage.path === EBulletPagePath.PAYMENT && (
          <Payment
            transactionId={bullet.currentPage?.params?.transactionId || ''}
          />
        )}
        {bullet.currentPage.path === EBulletPagePath.WITHDRAW && <Withdraw />}
        {bullet.currentPage.path === EBulletPagePath.TRANSFER && <Transfer />}
      </Container>
    </Wrapper>
  );
};

export default Bullet;
