import React from 'react';

import { IGroupMessages } from '..';

import Message from './Message';

import { Container } from './styles';

interface IMyMessagesGroup {
  group: IGroupMessages;
  messagesContentContainerRef: React.RefObject<HTMLDivElement>;
}

const MyMessagesGroup: React.FC<IMyMessagesGroup> = ({
  group,
  messagesContentContainerRef,
}) => {
  return (
    <Container>
      {group.messages.map((message, index) => (
        <Message
          key={message._id}
          message={message}
          lastMessageOfGroup={index === group.messages.length - 1}
          messagesContentContainerRef={messagesContentContainerRef}
        />
      ))}
    </Container>
  );
};

export default MyMessagesGroup;
