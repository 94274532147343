const PushGameModalTexts = {
  'components.notifications.title': 'Notificações',

  'components.pushGameModal.matchInfoDetails.date': 'Data e hora',
  'components.pushGameModal.matchInfoDetails.place': 'Local',
  'components.pushGameModal.countdown': '{seconds} segundos restantes',

  /* Buttons */
  'components.pushGameModal.showMoreDetailsOfMatchButton':
    'Clique para ver mais detalhes',
  'components.pushGameModal.showLessDetailsOfMatchButton':
    'Clique para ver menos detalhes',
  /* --------- Messages --------- */
  'components.pushGameModal.messages.closePushGameModalConfirm.title':
    'Deseja realmente sair?',
  'components.pushGameModal.messages.closePushGameModalConfirm.confirmButton':
    'Sair',
  /* */
  'components.pushGameModal.messages.submitPushGameConfirm.title':
    'Deseja realmente realizar esse jogo?',
  'components.pushGameModal.messages.submitPushGameConfirm.confirmButton':
    'Realizar jogo',
  'components.pushGameModal.messages.submitPushGameSuccess.title':
    'Jogo realizado!',
};

export default {
  ...PushGameModalTexts,
};
