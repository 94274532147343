import React from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import { useIntl } from '../../context/IntlContext';

import { Container } from './styles';

interface ILoadingWithMask {
  description?: string;
}

const loadingIcon = <LoadingOutlined style={{ fontSize: 20 }} spin />;

const LoadingWithMask: React.FC<ILoadingWithMask> = ({ description }) => {
  const intl = useIntl();

  return (
    <Container>
      <Spin style={{ lineHeight: 0 }} indicator={loadingIcon} />
      <p>
        {description ||
          intl.getTranslatedText('common.messages.defaultLoading')}
      </p>
    </Container>
  );
};

export default LoadingWithMask;
