import * as Yup from 'yup';
import { debounce } from 'lodash';

import { validateCpf } from '@/utils/validate';
import { showToast } from '@/hooks/showToast';

import api from '@/services/api';
import {
  ICheckIfFieldIsAvailableParams,
  IGetSignUpValidationSchemaParams,
  UniqueFieldEnum,
} from './types';
import { asyncValidationLoadingInitialValue } from './constants';

export const usernameValidationRegex = /^[a-zA-Z0-9.\-_]+$/;

export function getSignUpValidationSchema(
  params: IGetSignUpValidationSchemaParams,
): Yup.ObjectSchema {
  const {
    getTranslatedText,
    setAsyncValidationLoading,
    focusedField,
    availableFields,
    setAvailableFields,
  } = params;

  async function checkIfFieldIsAvailable({
    fieldName,
    value,
    handleFieldAvailability,
  }: ICheckIfFieldIsAvailableParams): Promise<void> {
    try {
      setAsyncValidationLoading({
        ...asyncValidationLoadingInitialValue,
        checkingSomeField: true,
        [fieldName]: true,
      });
      const response = await api.get('/api/account/checker', {
        params: {
          [fieldName]: value,
        },
      });

      handleFieldAvailability(!response.data);
    } catch (error) {
      showToast({
        type: 'error',
        title: getTranslatedText('common.errors.unexpectedError.title'),
        description: getTranslatedText(
          'common.errors.unexpectedError.description',
        ),
      });
    }

    setAsyncValidationLoading(asyncValidationLoadingInitialValue);
  }

  const usernameValidationDebounced = debounce(checkIfFieldIsAvailable, 500);
  const emailValidationDebounced = debounce(checkIfFieldIsAvailable, 500);
  const docNumberValidationDebounced = debounce(checkIfFieldIsAvailable, 500);

  return Yup.object().shape({
    username: Yup.string()
      .matches(usernameValidationRegex, {
        excludeEmptyString: true,
        message: getTranslatedText(
          'pages.signUp.form.username.validation.invalid',
        ),
      })
      .max(30, getTranslatedText('pages.signUp.form.username.validation.max'))
      .required(
        getTranslatedText('pages.signUp.form.username.validation.required'),
      )
      .test(
        'usernameUnavailable',
        getTranslatedText('pages.signUp.form.username.validation.unavailable'),
        function validate(value) {
          usernameValidationDebounced.cancel();
          if (focusedField === 'username') {
            const isCharsValid = !!value?.match(usernameValidationRegex);

            if (!value || !isCharsValid) {
              return true;
            }

            return new Promise(resolve =>
              usernameValidationDebounced({
                fieldName: UniqueFieldEnum.USERNAME,
                value,
                handleFieldAvailability: isFieldAvailable => {
                  resolve(isFieldAvailable);
                  setAvailableFields(oldState => ({
                    ...oldState,
                    username: isFieldAvailable,
                  }));
                },
              }),
            );
          }
          return availableFields.username;
        },
      ),
    email: Yup.string()
      .required(
        getTranslatedText('pages.signUp.form.email.validation.required'),
      )
      .email(getTranslatedText('pages.signUp.form.email.validation.invalid'))
      .test(
        'emailUnavailable',
        getTranslatedText('pages.signUp.form.email.validation.unavailable'),
        async function validate(value) {
          emailValidationDebounced.cancel();
          if (focusedField === 'email') {
            if (!value || !(await Yup.string().email().isValid(value))) {
              return true;
            }

            return new Promise(resolve =>
              emailValidationDebounced({
                fieldName: UniqueFieldEnum.EMAIL,
                value,
                handleFieldAvailability: isFieldAvailable => {
                  resolve(isFieldAvailable);
                  setAvailableFields(oldState => ({
                    ...oldState,
                    email: isFieldAvailable,
                  }));
                },
              }),
            );
          }
          return availableFields.email;
        },
      ),
    password: Yup.string()
      .min(6, getTranslatedText('pages.signUp.form.password.validation.min'))
      .required(
        getTranslatedText('pages.signUp.form.password.validation.required'),
      ),
    confirmPassword: Yup.string().when(
      'password',
      (password: string, field: Yup.StringSchema) => {
        return password
          ? field
              .required(
                getTranslatedText(
                  'pages.signUp.form.confirmPassword.validation.required',
                ),
              )
              .oneOf(
                [Yup.ref('password')],
                getTranslatedText(
                  'pages.signUp.form.confirmPassword.validation.incorrect',
                ),
              )
          : field;
      },
    ),
    name: Yup.string().required(
      getTranslatedText('pages.signUp.form.name.validation.required'),
    ),
    gender: Yup.string()
      .required(
        getTranslatedText('pages.signUp.form.gender.validation.required'),
      )
      .oneOf(
        ['M', 'F', 'O'],
        getTranslatedText('pages.signUp.form.gender.validation.invalid'),
      ),
    docNumber: Yup.string()
      .required(
        getTranslatedText('pages.signUp.form.docNumber.validation.required'),
      )
      .transform(value => value.replace(/\./g, '').replace(/-/g, ''))
      .test(
        'cpf',
        getTranslatedText('pages.signUp.form.docNumber.validation.invalid'),
        value => {
          const cpfValid = validateCpf(value);

          if (cpfValid === 'valid') {
            return true;
          }
          return false;
        },
      )
      .test(
        'docNumberUnavailable',
        getTranslatedText('pages.signUp.form.docNumber.validation.unavailable'),
        function validate(value) {
          docNumberValidationDebounced.cancel();
          if (focusedField === 'docNumber') {
            if (!value || validateCpf(value) !== 'valid') {
              return true;
            }

            return new Promise(resolve =>
              docNumberValidationDebounced({
                fieldName: UniqueFieldEnum.DOC_NUMBER,
                value,
                handleFieldAvailability: isFieldAvailable => {
                  resolve(isFieldAvailable);
                  setAvailableFields(oldState => ({
                    ...oldState,
                    docNumber: isFieldAvailable,
                  }));
                },
              }),
            );
          }
          return availableFields.docNumber;
        },
      ),
    phone: Yup.string()
      .required(
        getTranslatedText('pages.signUp.form.phone.validation.required'),
      )
      .transform(value =>
        value.replace(/\(/g, '').replace(/\)/g, '').replace(/-/g, ''),
      )
      .matches(/[0-9]{11}/, {
        excludeEmptyString: true,
        message: getTranslatedText(
          'pages.signUp.form.phone.validation.invalid',
        ),
      }),
    photo: Yup.object().shape({
      previewUrl: Yup.string().required(
        getTranslatedText('pages.signUp.form.photo.validation.required'),
      ),
    }),
  });
}
