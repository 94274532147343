const NotFoundTexts = {
  'pages.notFound.title': 'Ops... Está perdido?',
  'pages.notFound.description':
    'A página que você está procurando não foi encontrada',

  'pages.notFound.goToHomeLink': 'Ir para a página inicial',
};

export default {
  ...NotFoundTexts,
};
