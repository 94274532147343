/* eslint-disable jsx-a11y/media-has-caption */
import React, {
  useState,
  useRef,
  useEffect,
  useMemo,
  useCallback,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { Input, Spin } from 'antd';
import {
  FiX,
  FiSend,
  FiMic,
  FiCheck,
  FiTrash2,
  FiPlayCircle,
  FiPauseCircle,
} from 'react-icons/fi';
import { LoadingOutlined } from '@ant-design/icons';
import { v4 as uuid } from 'uuid';
import * as dateFns from 'date-fns';
import { Recorder } from 'vmsg';

import { useAuth } from '../../../../context/AuthContext';
import { useIntl } from '../../../../context/IntlContext';
import {
  IChatMessage,
  IChatContainer,
} from '../../../../context/ChatP2PContext';
import useAudioPlayer from '../../../../hooks/useAudioPlayer';

import CustomAntButton from '../../../../components/CustomAntButton';

import UserMessagesGroup from './UserMessagesGroup';
import MyMessagesGroup from './MyMessagesGroup';

import NewVoiceMessagePreviewAudioBar from './NewVoiceMessagePreviewAudioBar';

import {
  Container,
  Content,
  Footer,
  RecordAudioMessageFooter,
  NewVoiceMessagePreviewPlayerContainer,
  PlayOrStopButton,
  LoadMoreMessagesButton,
  LoadingMessagesContainer,
} from './styles';
import { showToast } from '../../../../hooks/showToast';

export interface IChatContainerRefMethods {
  scrollMessagesToBottom(): void;
}

export interface IGroupMessages {
  _id: string;
  _user: {
    _id: string;
    name: string;
    email?: string;
    photo: string;
  };
  messages: IChatMessage[];
}

interface INewVoiceMessageState {
  isRecording: boolean;
  recordedTime: number;
  blobURL: string;
  file: null | File;
  loadingStartRecorder: boolean;
}

const { TextArea } = Input;
const loadingIcon = <LoadingOutlined style={{ fontSize: 16 }} spin />;
const { format, addSeconds } = dateFns;

const ChatContainer: React.ForwardRefRenderFunction<
  IChatContainerRefMethods,
  IChatContainer
> = (
  {
    messages,
    handleSubmitNewMessage,
    hasMoreMessages,
    handleGetMoreMessages,
    loadingMessages,
  },
  ref,
) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const audioRef = useRef<HTMLAudioElement>(null);

  const { curTime, duration, playing, setPlaying, setClickedTime } =
    useAudioPlayer({
      audioElement: audioRef.current,
    });
  const intl = useIntl();
  const { user: me } = useAuth();

  const [opened, setOpened] = useState(false);

  const [currentFooter, setCurrentFooter] = useState<'text' | 'voice'>('text');

  const [newMessageValue, setNewMessageValue] = useState('');
  const [newVoiceMessage, setNewVoiceMessage] = useState<INewVoiceMessageState>(
    {
      isRecording: false,
      recordedTime: 0,
      blobURL: '',
      file: null,
      loadingStartRecorder: false,
    },
  );

  // const [recorder, setRecorder] = useState<Recorder | null>(null);

  // const mp3Recorder = useMemo(() => {
  //   return new MicRecorder({ bitRate: 160 });
  // }, []);
  const recorder = useMemo(() => {
    return new Recorder({
      wasmURL: 'https://unpkg.com/vmsg@0.3.0/vmsg.wasm',
    });
  }, []);

  const messagesGroups = useMemo<IGroupMessages[]>(() => {
    const groups: IGroupMessages[] = [];

    messages.forEach(message => {
      if (
        groups.length === 0 ||
        message._user._id !== groups[groups.length - 1]._user._id
      ) {
        groups.push({
          _id: uuid(),
          _user: message._user,
          messages: [message],
        });
      } else {
        groups[groups.length - 1].messages.push(message);
      }
    });

    return groups;
  }, [messages]);

  const scrollContentToBottom = useCallback(() => {
    const contentDiv = contentRef.current;

    if (contentDiv) {
      contentDiv.scrollTop = contentDiv.scrollHeight - contentDiv.clientHeight;
    }
  }, []);

  useImperativeHandle(ref, () => ({
    scrollMessagesToBottom: scrollContentToBottom,
  }));

  useEffect(() => {
    if (opened) {
      scrollContentToBottom();
    }
  }, [opened, scrollContentToBottom]);

  useEffect(() => {
    if (messagesGroups.length === 0) {
      setOpened(false);
    }
  }, [messagesGroups.length]);

  const startVoiceMessageRecord = useCallback(async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: true,
      });

      try {
        setNewVoiceMessage(oldState => ({
          ...oldState,
          loadingStartRecorder: true,
        }));
        stream.getTracks().map(track => track.stop());
        await recorder.initAudio();
        await recorder.initWorker();
        recorder.startRecording();

        setCurrentFooter('voice');
        setNewVoiceMessage(oldState => ({
          ...oldState,
          isRecording: true,
          loadingStartRecorder: false,
        }));
      } catch (error) {
        // console.error(error);
      }
    } catch (error) {
      showToast({
        type: 'warn',
        title: intl.getTranslatedText(
          'pages.chats.chat.messages.startVoiceMessageRecordError.title',
        ),
        description: intl.getTranslatedText(
          'pages.chats.chat.messages.startVoiceMessageRecordError.description',
        ),
      });
    }
  }, [intl, recorder]);

  const stopVoiceMessageRecord = useCallback(
    async (cancelRecord = false): Promise<void> => {
      try {
        if (!cancelRecord) {
          if (recorder) {
            const blob = await recorder.stopRecording();

            const file = new File([blob], `chat-audio-${Date.now()}.mp3`, {
              type: blob.type,
              lastModified: Date.now(),
            });
            const blobURL = URL.createObjectURL(blob);

            setNewVoiceMessage(oldState => ({
              ...oldState,
              isRecording: false,
              file,
              blobURL,
            }));

            audioRef.current?.load();
          }
        } else {
          setNewVoiceMessage(oldState => {
            if (oldState.isRecording && recorder) {
              recorder.stopRecording();
            }

            return {
              isRecording: false,
              recordedTime: 0,
              blobURL: '',
              file: null,
              loadingStartRecorder: false,
            };
          });

          setCurrentFooter('text');
        }
        setNewMessageValue('');
      } catch (error) {
        // console.log(error);
      }
    },
    [recorder],
  );

  useEffect(() => {
    let interval = 0;
    if (newVoiceMessage.isRecording) {
      interval = window.setInterval(() => {
        setNewVoiceMessage(oldState => {
          return {
            ...oldState,
            recordedTime: oldState.recordedTime + 1,
          };
        });
      }, 1000);
    }

    return () => window.clearInterval(interval);
  }, [newVoiceMessage.isRecording, stopVoiceMessageRecord]);

  useEffect(() => {
    if (newVoiceMessage.recordedTime === 59) {
      stopVoiceMessageRecord();
    }
  }, [newVoiceMessage.recordedTime, stopVoiceMessageRecord]);

  const footerViewer = useMemo(() => {
    if (currentFooter === 'voice') {
      if (newVoiceMessage.isRecording) {
        return (
          <RecordAudioMessageFooter $mode="record">
            <CustomAntButton
              icon={<FiX size={20} />}
              useCustomIcon
              type="default"
              danger
              htmlType="button"
              onClick={() => stopVoiceMessageRecord(true)}
            />
            <span />
            <p>
              {format(
                addSeconds(
                  new Date(0),
                  parseInt(newVoiceMessage.recordedTime.toString()),
                ),
                'mm:ss',
              )}
            </p>
            <CustomAntButton
              icon={<FiCheck size={20} />}
              useCustomIcon
              type="primary"
              htmlType="button"
              onClick={() => stopVoiceMessageRecord()}
            />
          </RecordAudioMessageFooter>
        );
      }

      return (
        <RecordAudioMessageFooter
          $mode="preview"
          onSubmit={e => {
            e.preventDefault();

            if (newVoiceMessage.file) {
              handleSubmitNewMessage({
                audio: newVoiceMessage.file,
              });
              stopVoiceMessageRecord(true);
            }
          }}
        >
          <NewVoiceMessagePreviewPlayerContainer>
            <PlayOrStopButton
              type="button"
              onClick={() => {
                setPlaying(oldState => !oldState);
              }}
            >
              {!playing ? (
                <FiPlayCircle size={21} />
              ) : (
                <FiPauseCircle size={21} />
              )}
            </PlayOrStopButton>
            <NewVoiceMessagePreviewAudioBar
              duration={duration}
              curTime={curTime}
              onTimeUpdate={time => {
                // console.log(time);
                setClickedTime(time);
              }}
            />
            <p>
              {duration &&
                format(
                  addSeconds(new Date(0), parseInt(duration.toString())),
                  'mm:ss',
                )}
            </p>
          </NewVoiceMessagePreviewPlayerContainer>
          <CustomAntButton
            icon={<FiTrash2 size={20} />}
            useCustomIcon
            type="default"
            danger
            htmlType="button"
            onClick={() => stopVoiceMessageRecord(true)}
          />
          <CustomAntButton
            icon={<FiSend size={20} />}
            useCustomIcon
            type="primary"
            htmlType="submit"
          />
        </RecordAudioMessageFooter>
      );
    }

    return (
      <Footer
        onSubmit={e => {
          e.preventDefault();
          handleSubmitNewMessage({
            text: newMessageValue,
          });
          setNewMessageValue('');
        }}
      >
        <TextArea
          placeholder={intl.getTranslatedText(
            'pages.chats.chat.messageTextarea.placeholder',
          )}
          autoSize={{ maxRows: 5 }}
          value={newMessageValue}
          onChange={e => setNewMessageValue(e.target.value)}
        />
        {!newMessageValue ? (
          <CustomAntButton
            icon={<FiMic size={20} />}
            useCustomIcon
            type="primary"
            htmlType="button"
            onClick={() => startVoiceMessageRecord()}
            loading={newVoiceMessage.loadingStartRecorder}
          />
        ) : (
          <CustomAntButton
            icon={<FiSend size={20} />}
            useCustomIcon
            type="primary"
            htmlType="submit"
          />
        )}
      </Footer>
    );
  }, [
    curTime,
    currentFooter,
    duration,
    handleSubmitNewMessage,
    intl,
    newMessageValue,
    newVoiceMessage.file,
    newVoiceMessage.isRecording,
    newVoiceMessage.loadingStartRecorder,
    newVoiceMessage.recordedTime,
    playing,
    setClickedTime,
    setPlaying,
    startVoiceMessageRecord,
    stopVoiceMessageRecord,
  ]);

  if (loadingMessages && messagesGroups.length === 0) {
    return (
      <Container>
        <Content ref={contentRef}>
          <LoadingMessagesContainer>
            <div>
              <Spin style={{ lineHeight: 0 }} indicator={loadingIcon} />
              <p>
                {intl.getTranslatedText('pages.chats.chat.loadingMessages')}
              </p>
            </div>
          </LoadingMessagesContainer>
        </Content>
      </Container>
    );
  }

  return (
    <>
      <Container>
        <Content ref={contentRef}>
          {hasMoreMessages && (
            <LoadMoreMessagesButton
              onClick={() => {
                if (!loadingMessages) {
                  handleGetMoreMessages();
                }
              }}
              disabled={loadingMessages}
            >
              {!loadingMessages ? (
                intl.getTranslatedText(
                  'pages.chats.chat.loadMoreMessagesButton',
                )
              ) : (
                <Spin style={{ lineHeight: 0 }} indicator={loadingIcon} />
              )}
            </LoadMoreMessagesButton>
          )}
          {messagesGroups.map(messagesGroup => {
            if (messagesGroup._user._id === me?._id) {
              return (
                <MyMessagesGroup
                  key={messagesGroup._id}
                  group={messagesGroup}
                  messagesContentContainerRef={contentRef}
                />
              );
            }
            return (
              <UserMessagesGroup
                key={messagesGroup._id}
                group={messagesGroup}
                messagesContentContainerRef={contentRef}
              />
            );
          })}
        </Content>
      </Container>
      {footerViewer}
      <audio
        // preload="none"
        loop={false}
        ref={audioRef}
        style={{ display: 'none' }}
        src={newVoiceMessage.blobURL}
        // playsInline
      />
    </>
  );
};

export default forwardRef(ChatContainer);
