import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  position: absolute;
  z-index: 105;
  background: rgba(0, 0, 0, 0.75);
  display: flex;
  align-items: center;
  justify-content: center;

  /* transform: translateY(-72px);

  @media only screen and (max-width: 1199px) {
    transform: translateY(-61px);
  } */

  svg {
    color: ${props => props.theme.colors.primary.primary0};
  }

  p {
    margin-left: 16px;
  }
`;
