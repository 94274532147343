import React, { useCallback, useEffect, useState } from 'react';

import Pack1 from '@/assets/coins-packs/pack-1.jpg';
import Pack2 from '@/assets/coins-packs/pack-2.jpg';
import Pack3 from '@/assets/coins-packs/pack-3.jpg';

import CustomAntButton from '@/components/CustomAntButton';
import { FiCopy } from 'react-icons/fi';

import api from '@/services/api';
import { showToast } from '@/hooks/showToast';
import { useIntl } from '@/context/IntlContext';
import socket from '@/services/socket';
import { useAuth } from '@/context/AuthContext';
import { EBulletPagePath, useBullet } from '@/context/BulletContext';
import { ETransactionType } from '../../bullet.types';
import { IPurchaseCoinsPackResponseDTO } from '../deposit.types';

import {
  Container,
  TutorialStepContainer,
  PurchaseContainer,
  PurchaseDetailsContainer,
  QRCode,
} from './styles';
import ProcessingPayment from '../../ProcessingPayment';

interface IPaymentProps {
  transactionId: string;
}

function getPurchasedCoinsPackImage(purchasedPack: ETransactionType): string {
  if (purchasedPack === ETransactionType.PURCHASE_FIRST_PACK) return Pack1;
  if (purchasedPack === ETransactionType.PURCHASE_SECOND_PACK) return Pack2;
  if (purchasedPack === ETransactionType.PURCHASE_THIRD_PACK) return Pack3;

  return '';
}

const Payment: React.FC<IPaymentProps> = ({ transactionId }) => {
  const { user: me } = useAuth();
  const intl = useIntl();
  const bullet = useBullet();

  const [transaction, setTransaction] = useState<IPurchaseCoinsPackResponseDTO>(
    {} as IPurchaseCoinsPackResponseDTO,
  );
  const [processingPaymentLoading, setProcessingPaymentLoading] =
    useState(true);

  useEffect(() => {
    async function getTransaction(): Promise<void> {
      try {
        const { data } = await api.get<IPurchaseCoinsPackResponseDTO>(
          `/api/payment-deposit/${transactionId}`,
        );
        setTransaction(data);
        setProcessingPaymentLoading(false);
      } catch (error) {
        setProcessingPaymentLoading(false);
        showToast({
          title: intl.getTranslatedText('common.errors.unexpectedError.title'),
          description: intl.getTranslatedText(
            'common.errors.unexpectedError.description',
          ),
          type: 'error',
        });
      }
    }

    getTransaction();

    socket.on(
      `balance:${me?._id}`,
      async (data: { amount: number; type: 'in' | 'out' }) => {
        if (data.type === 'in') {
          bullet.navigateTo(EBulletPagePath.MAIN);
          showToast({
            type: 'success',
            title: 'Compra de moedas realizada com sucesso!',
          });
        }
      },
    );

    return () => {
      socket.off(`balance:${me?._id}`);
    };
  }, [bullet, intl, me?._id, transactionId]);

  const handleCopyQrCodeToClipboard = useCallback(
    (code: string) => {
      const textArea = document.createElement('textarea');
      textArea.value = code;

      // Avoid scrolling to bottom
      textArea.style.top = '0';
      textArea.style.left = '0';
      textArea.style.position = 'fixed';

      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();

      document.execCommand('copy');

      document.body.removeChild(textArea);

      showToast({
        type: 'info',
        title: intl.getTranslatedText(
          'components.chatContainer.chatContainerMessage.messages.copyMessageToClipboardSuccess.title',
        ),
      });
    },
    [intl],
  );

  if (processingPaymentLoading) {
    return (
      <Container>
        <ProcessingPayment flow="deposit" />
      </Container>
    );
  }

  return (
    <Container>
      <p>Aguardando pagamento</p>
      <ul>
        <TutorialStepContainer>
          <span>1</span>
          <small>
            Abra o app ou banco de sua preferência. Escolha a opção pagar com
            código Pix &quot;copia e cola&quot;, ou código QR.
          </small>
        </TutorialStepContainer>
        <TutorialStepContainer>
          <span>2</span>
          <small>
            Copie e cole o código, ou escaneie o código QR com a câmera do seu
            celular. Confira todas as informações e autorize o pagamento.
          </small>
          <div>
            <QRCode value={transaction.data.gateway.qr_code} />
            <CustomAntButton
              useCustomIconWithText
              icon={<FiCopy size={16} />}
              type="primary"
              onClick={() =>
                handleCopyQrCodeToClipboard(transaction.data.gateway.qr_code)
              }
            >
              Copiar código PIX
            </CustomAntButton>
          </div>
        </TutorialStepContainer>
        <TutorialStepContainer>
          <span>3</span>
          <small>
            Você vai receber a confirmação do pagamento no seu e-mail. E pronto!
          </small>
        </TutorialStepContainer>
      </ul>
      <hr />
      <PurchaseContainer>
        <div>
          <h5>Resumo da compra</h5>
          <ul>
            <PurchaseDetailsContainer>
              <img
                src={getPurchasedCoinsPackImage(
                  transaction.data.transaction.details.type,
                )}
                alt="package"
              />
              <div>
                <strong>{transaction.data.transaction.details.title}</strong>
                <small>
                  {transaction.data.transaction.details.description}
                </small>
              </div>
            </PurchaseDetailsContainer>
          </ul>
        </div>
      </PurchaseContainer>
    </Container>
  );
};

export default Payment;
