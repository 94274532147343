const MyVersusRoomsTexts = {
  'pages.versusVIP.myRooms.title': 'My room',

  // /* ------------ Buttons ------------ */
  'pages.versusVIP.myRooms.submitArchiveRoomConfirmButton': 'Archive',
  'pages.versusVIP.myRooms.submitUnarchiveRoomConfirmButton': 'Turn active',
  'pages.versusVIP.myRooms.goToHomeLink': 'Back to home',
  'pages.versusVIP.myRooms.goToActiveRoomsButton': 'Back to active room',
  'pages.versusVIP.myRooms.showArchivedRoomsButton': 'Show archived rooms',
  'pages.versusVIP.myRooms.showActiveRoomsButton': 'Show active rooms',
  /* ------------ Messages ------------ */
  'pages.versusVIP.myRooms.messages.getRoomsError.title':
    'Unexpected error to load rooms',
  'pages.versusVIP.myRooms.messages.getArchivedRoomsError.title':
    'Unexpected error to load hidden rooms',
  'pages.versusVIP.myRooms.messages.submitArchiveRoomConfirm.description':
    'Do you really want to archive this room?',
  'pages.versusVIP.myRooms.messages.submitArchiveRoomSuccess.title':
    'Room archived!',
  'pages.versusVIP.myRooms.messages.submitUnarchiveRoomConfirm.description':
    'Do you really want to unarchive this room?',
  'pages.versusVIP.myRooms.messages.submitUnarchiveRoomSuccess.title':
    'Room unarchive!',
  'pages.versusVIP.myRooms.messages.noRooms':
    "You aren't in any <span>room</span> yet",
  'pages.versusVIP.myRooms.messages.viewingArchivedRooms':
    'You are viewing your archived rooms',
  'pages.versusVIP.myRooms.messages.noArchivedRooms':
    "You haven't any <span>room archived</span>",
};

const PublicVersusRoomsTexts = {
  'pages.versusVIP.publicRooms.title': 'Public rooms',
  'pages.versusVIP.publicRooms.searchInput.placeholder':
    'Search by room name, team, or user',

  /* ------------ Messages ------------ */
  'pages.versusVIP.publicRooms.messages.viewingFindedOnSearchRooms':
    'Loading found rooms',
  'pages.versusVIP.publicRooms.messages.noPublicRooms':
    'No public room available at <span>Versus VIP</span>',
  'pages.versusVIP.publicRooms.messages.noRooms': 'No room found',
};

const NewVersusRoomTexts = {
  'pages.versusVIP.newRoom.title': 'New room',
  'pages.versusVIP.newRoom.step1': 'Room',
  'pages.versusVIP.newRoom.step2': 'Match',
  'pages.versusVIP.newRoom.step3': 'Accept',

  'pages.versusVIP.newRoom.submitNewRoomConfirmButton': 'New room',

  'pages.versusVIP.newRoom.form.roomType.validation.invalid':
    'Invalid room type',
  'pages.versusVIP.newRoom.form.roomType.validation.required':
    'Room type is required',
  'pages.versusVIP.newRoom.form.name.validation.required':
    'Room name is required',
  'pages.versusVIP.newRoom.form.price.validation.min':
    'Minimum value is R$10.000',
  'pages.versusVIP.newRoom.form.price.validation.required':
    'Room price is required',

  'pages.versusVIP.newRoom.messages.blockPage.title':
    'Do you really want to quit this page?',
  'pages.versusVIP.newRoom.messages.getChampionshipsError.title':
    'Unexpected error to load championships',
  'pages.versusVIP.newRoom.messages.getMatchsOfChampionshipError.title':
    'Unexpected error to load championship games',
  'pages.versusVIP.newRoom.messages.submitNewRoomConfirm.title':
    'Do you wish to create this room?',
  'pages.versusVIP.newRoom.messages.submitNewRoomSuccess.title': 'Done!',
  'pages.versusVIP.newRoom.messages.submitNewRoomSuccess.description':
    'Invite your friend and then do your game. Enjoy!',
  /* Room */
  ...{
    /* roomType - form */
    'pages.versusVIP.newRoom.form.roomType.label': 'Room type',
    'pages.versusVIP.newRoom.form.roomType.privateRoomTitle': 'Private room',
    'pages.versusVIP.newRoom.form.roomType.privateRoomDescription':
      'Only your friends or invited can join',
    'pages.versusVIP.newRoom.form.roomType.publicRoomTitle': 'Public room',
    'pages.versusVIP.newRoom.form.roomType.publicRoomDescription':
      'Open to wide world',
    /* name - form */
    'pages.versusVIP.newRoom.form.name.label': 'Room name',
    'pages.versusVIP.newRoom.form.name.placeholder': 'Type a room name',
    /* price - form */
    'pages.versusVIP.newRoom.form.price.label': 'Game price',
    'pages.versusVIP.newRoom.form.price.placeholder':
      'Enter an amount from R$10.000',
  },
  /* Match */
  ...{
    'pages.versusVIP.newRoom.form.selectedChampionship.label': 'Championship',
    'pages.versusVIP.newRoom.form.selectedMatch.label': 'Match',

    'pages.versusVIP.newRoom.form.matchInfo.date.label': 'Date and hour',
    'pages.versusVIP.newRoom.form.matchInfo.place.label': 'Place',

    'pages.versusVIP.newRoom.messages.selectChampionship':
      'Select a championship',
    'pages.versusVIP.newRoom.messages.loadingChampionships':
      'Loading championships...',
    'pages.versusVIP.newRoom.messages.selectMatch': 'Select a match',
    'pages.versusVIP.newRoom.messages.loadingMatchs':
      'Loading championship games...',
    'pages.versusVIP.newRoom.messages.noChampionshipMatchs':
      'This championship has no <span>matches</span> available yet',
  },
};

export default {
  ...MyVersusRoomsTexts,
  ...PublicVersusRoomsTexts,
  ...NewVersusRoomTexts,
};
