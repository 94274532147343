import React from 'react';
import { Input as InputAnt, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import { Container, LabelContainer } from './styles';
import TooltipBadge from '../TooltipBadge';
import { InputProps } from './types';

const Input: React.FC<InputProps> = ({
  label,
  placeholder,
  id,
  error,
  showloading,
  tooltip,
  ...rest
}) => {
  const antIcon = (
    <LoadingOutlined style={{ fontSize: 16, color: '#009420' }} spin />
  );
  return (
    <Container error={!!error}>
      <LabelContainer error={!!error}>
        <label htmlFor={id}>{label}</label>
        {!!tooltip && (
          <TooltipBadge title={tooltip}>
            <span>?</span>
          </TooltipBadge>
        )}
      </LabelContainer>
      <div>
        <InputAnt bordered id={id} {...rest} placeholder={placeholder} />
        {showloading && <Spin indicator={antIcon} />}
      </div>
      {error && <span>{error}</span>}
    </Container>
  );
};

export default Input;
