import { rgba, darken } from 'polished';
import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

interface IRankingSelectorProps {
  $selected: boolean;
}

interface IRankingBanner {
  $bg: string;
}

export const Container = styled.div`
  padding: 4rem 0;

  @media only screen and (max-width: ${props => props.theme.breakpoints.xl}) {
    padding: 2.5rem 0;
  }

  @media only screen and (max-width: ${props => props.theme.breakpoints.sm}) {
    padding: 2rem 0;
  }
`;

export const RankingsSession = styled.div`
  position: relative;
  margin-bottom: 2rem;

  > div:nth-child(1),
  > div:nth-child(3) {
    width: 100%;
    display: flex;
    flex-direction: column;

    padding: 0 3.5rem;
    @media only screen and (max-width: ${props => props.theme.breakpoints.xl}) {
      padding: 0 2.5rem;
    }

    @media only screen and (max-width: ${props => props.theme.breakpoints.sm}) {
      padding: 0 1rem;
    }

    &:nth-child(3) {
      margin-top: 1.5rem;
    }
  }

  > div:nth-child(2) {
    width: 100%;
    display: flex;
    margin-top: 0.25rem;
    display: flex;
    flex-wrap: nowrap;
    overflow-x: scroll;

    &::after,
    &::before {
      content: '';
      min-width: 3.5rem;
      @media only screen and (max-width: ${props =>
          props.theme.breakpoints.xl}) {
        min-width: 2.5rem;
      }

      @media only screen and (max-width: ${props =>
          props.theme.breakpoints.sm}) {
        min-width: 1rem;
      }
    }

    &::-webkit-scrollbar {
      display: none;
    }

    @media only screen and (max-width: ${props => props.theme.breakpoints.md}) {
      max-width: 100%;
    }
  }

  > p {
    padding: 0 3.5rem;
    margin-top: 1.5rem;

    span {
      color: ${props => props.theme.colors.primary.primary0};
    }

    padding: 0 3.5rem;
    @media only screen and (max-width: ${props => props.theme.breakpoints.xl}) {
      padding: 0 2rem;
    }

    @media only screen and (max-width: ${props => props.theme.breakpoints.sm}) {
      padding: 0 1rem;
    }
  }
`;

export const RankingBanner = styled(Link)<IRankingBanner>`
  width: 100%;
  padding: 1rem;
  background: linear-gradient(
      0deg,
      rgba(69, 69, 69, 0.92),
      rgba(69, 69, 69, 0.92)
    ),
    url(${props => props.$bg}) no-repeat center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  border-radius: 8px;

  > div {
    align-self: center;
    display: flex;
    margin-top: 4rem;
    z-index: 5;
  }

  > small {
    text-align: end;
    color: ${props => props.theme.colors.light['light-2']};
  }
`;

export const RankingTop3Item = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 6.5rem;

  &:nth-child(2) {
    margin: 0 1rem;
  }

  p:first-child {
    color: ${props => props.theme.colors.light['light-2']};
    margin-bottom: 0.25rem;
  }

  svg {
    color: #f8c700;
    margin-bottom: 0.5rem;
  }

  span {
    border: 3px solid ${props => props.theme.colors.primary.primary0};
    box-sizing: content-box;
    margin-bottom: 0.5rem;
  }

  p {
    text-align: center;
  }

  small {
    text-align: center;
    color: ${props => props.theme.colors.light['light-2']};
  }

  small:last-child {
    color: ${props => props.theme.colors.primary.primary0};
    margin-top: 0.5rem;
  }

  &:nth-child(2) {
    span {
      box-shadow: 0px 0px 20px
        ${props => rgba(props.theme.colors.primary.primary0, 0.5)};
    }

    transform: translateY(-60px);
  }
`;

export const RankingSelector = styled.button<IRankingSelectorProps>`
  border: 0;
  padding: 0.25rem 0.5rem;
  background: ${props => props.theme.colors.dark['dark+2']};
  color: ${props => props.theme.colors.light['light-2']};
  border-radius: 4px;
  transition: 0.2s box-shadow;
  flex-shrink: 0;

  & + button {
    margin-left: 0.5rem;
  }

  ${props =>
    props.$selected &&
    css`
      background: ${props.theme.colors.primary['primary+1']};
      color: ${props.theme.colors.dark.dark0};
    `}

  p {
    display: -webkit-box;
    -webkit-line-clamp: 1;
  }

  &:hover {
    box-shadow: 1px 1px 3px
      ${props => darken(0.1, props.theme.colors.dark.dark0)};
  }
`;

export const CarouselContainer = styled.div`
  width: 100%;
  margin-bottom: 2rem;
`;

export const AdvertisementItemContainer = styled.div`
  width: 100%;
  position: relative;
  padding-top: 42.85%;
  margin-bottom: 0.5rem;

  a {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;

    img {
      z-index: 5;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    div.shimmer {
      z-index: 5 !important;
      width: 100% !important;
      height: 100% !important;
      border: 1px solid ${props => props.theme.colors.light['light-2']} !important;
    }
  }
`;
