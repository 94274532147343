import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import * as dateFns from 'date-fns';
import { Menu, Dropdown, Modal } from 'antd';

import { FiChevronLeft, FiTrash2, FiCornerUpLeft } from 'react-icons/fi';
import { IoEllipsisVertical } from 'react-icons/io5';

import Breadcrumbs from '../../../components/Breadcrumbs';
import CustomAntButton from '../../../components/CustomAntButton';
import Loading from '../../../components/Loading';

import { showToast } from '../../../hooks/showToast';
import { useIntl } from '../../../context/IntlContext';

import api from '../../../services/api';

import {
  Container,
  Header,
  CardGameList,
  CardGameItem,
  LoadMoreCardsButton,
} from './styles';

const { format, parseISO } = dateFns;

export interface IPlayedAthleteCardGame {
  _id: string;
  _card: {
    _id: string;
    name: string;
  };
  totalScore: number;
  status: boolean;
  finished: boolean;
  name: string;
  createdAt: string;
  _user: {
    altName: string;
    email: string;
    name: string;
    photo: {
      _id: string;
      filename: string;
    };
    username: string;
    _id: string;
  };
}

const { confirm } = Modal;

const CardGamesHistory: React.FC = () => {
  const { url } = useRouteMatch();

  const intl = useIntl();

  const [playedCardGames, setPlayedCardGames] = useState<
    IPlayedAthleteCardGame[]
  >([]);
  const [playedCardGamesPagination, setPlayedCardGamesPagination] = useState({
    limit: 10,
    currentPage: 1,
    totalPages: 0,
  });

  const [archivedPlayedCardGames, setArchivedPlayedCardGames] = useState<
    IPlayedAthleteCardGame[]
  >([]);
  const [
    archivedPlayedCardGamesPagination,
    setArchivedPlayedCardGamesPagination,
  ] = useState({
    limit: 10,
    currentPage: 1,
    totalPages: 0,
  });

  const [showArchivedGames, setShowArchivedGames] = useState(false);
  const [loadingPlayedCardGames, setLoadingPlayedCardGames] = useState(true);

  const getPlayedCardGames = useCallback(
    async (page = 1): Promise<void> => {
      setLoadingPlayedCardGames(true);
      try {
        const { data } = await api.get<{
          docs: IPlayedAthleteCardGame[];
          page: number;
          pages: number;
          total: number;
        }>('/api/played-athlete', {
          params: {
            limit: playedCardGamesPagination.limit,
            page,
          },
        });

        if (page === 1) {
          setPlayedCardGames(data.docs);
        } else {
          setPlayedCardGames(oldState => [...oldState, ...data.docs]);
        }
        if (data.total > 0) {
          setPlayedCardGamesPagination(oldState => ({
            ...oldState,
            currentPage: data.page,
            totalPages: data.pages,
          }));
        }
      } catch (error) {
        showToast({
          type: 'error',
          title: intl.getTranslatedText(
            'pages.athletesOfTheWeek.cardGamesHistory.messages.getPlayedCardGamesError.title',
          ),
          description: intl.getTranslatedText(
            'common.errors.unexpectedError.description',
          ),
        });
      }
      setLoadingPlayedCardGames(false);
    },
    [intl, playedCardGamesPagination.limit],
  );

  const getArchivedPlayedCardGames = useCallback(
    async (page = 1): Promise<void> => {
      setLoadingPlayedCardGames(true);
      try {
        const { data } = await api.get<{
          docs: IPlayedAthleteCardGame[];
          page: number;
          pages: number;
          total: number;
        }>('/api/played-athlete', {
          params: {
            status: false,
            limit: archivedPlayedCardGamesPagination.limit,
            page,
          },
        });

        if (page === 1) {
          setArchivedPlayedCardGames(data.docs);
        } else {
          setArchivedPlayedCardGames(oldState => [...oldState, ...data.docs]);
        }
        if (data.total > 0) {
          setArchivedPlayedCardGamesPagination(oldState => ({
            ...oldState,
            currentPage: data.page,
            totalPages: data.pages,
          }));
        }
      } catch (error) {
        showToast({
          type: 'error',
          title: intl.getTranslatedText(
            'pages.athletesOfTheWeek.cardGamesHistory.messages.getPlayedCardGamesError.title',
          ),
          description: intl.getTranslatedText(
            'common.errors.unexpectedError.description',
          ),
        });
      }
      setLoadingPlayedCardGames(false);
    },
    [archivedPlayedCardGamesPagination.limit, intl],
  );

  useEffect(() => {
    // const { id } = params;
    if (!showArchivedGames) {
      getPlayedCardGames();
    } else if (showArchivedGames) {
      getArchivedPlayedCardGames();
    }
  }, [getArchivedPlayedCardGames, getPlayedCardGames, showArchivedGames]);

  const handleArchiveGame = useCallback(
    async (gameId: string): Promise<void> => {
      try {
        await new Promise(resolve => {
          confirm({
            title: intl.getTranslatedText('common.messages.warning'),
            content: intl.getTranslatedText(
              'pages.athletesOfTheWeek.cardGamesHistory.messages.archiveGameConfirm.description',
            ),
            okText: intl.getTranslatedText('common.buttons.archive'),
            cancelText: intl.getTranslatedText('common.buttons.cancel'),
            okButtonProps: {
              danger: true,
            },
            onOk() {
              resolve(true);
            },
          });
        });

        await api.delete(`/api/played-athlete/${gameId}`);

        showToast({
          type: 'success',
          title: intl.getTranslatedText(
            'pages.athletesOfTheWeek.cardGamesHistory.messages.archiveGameSuccess.title',
          ),
        });

        setPlayedCardGames(oldPlayedCardGames => {
          return oldPlayedCardGames.filter(
            playedCardGame => playedCardGame._id !== gameId,
          );
        });
      } catch (error) {
        showToast({
          type: 'error',
          title: intl.getTranslatedText('common.errors.unexpectedError.title'),
          description: intl.getTranslatedText(
            'common.errors.unexpectedError.description',
          ),
        });
      }
    },
    [intl],
  );

  const handleUnarchiveGame = useCallback(
    async (gameId: string): Promise<void> => {
      try {
        await new Promise(resolve => {
          confirm({
            title: intl.getTranslatedText('common.messages.warning'),
            content: intl.getTranslatedText(
              'pages.athletesOfTheWeek.cardGamesHistory.messages.unarchiveGameConfirm.description',
            ),
            okText: intl.getTranslatedText('common.buttons.unarchive'),
            cancelText: intl.getTranslatedText('common.buttons.cancel'),
            onOk() {
              resolve(true);
            },
          });
        });

        await api.put(`/api/played-athlete/${gameId}`, {
          status: true,
        });

        showToast({
          type: 'success',
          title: intl.getTranslatedText(
            'pages.athletesOfTheWeek.cardGamesHistory.messages.unarchiveGameSuccess.title',
          ),
        });

        setArchivedPlayedCardGames(oldArchivedPlayedCardGames => {
          return oldArchivedPlayedCardGames.filter(
            archivedPlayedCardGame => archivedPlayedCardGame._id !== gameId,
          );
        });
      } catch (error) {
        showToast({
          type: 'error',
          title: intl.getTranslatedText('common.errors.unexpectedError.title'),
          description: intl.getTranslatedText(
            'common.errors.unexpectedError.description',
          ),
        });
      }
    },
    [intl],
  );

  const playedCardsVisualization = useMemo(() => {
    if (!showArchivedGames) {
      return (
        <>
          {playedCardGames.length > 0 ? (
            <CardGameList>
              {playedCardGames.map(playedCardGame => (
                <CardGameItem
                  key={playedCardGame._id}
                  to={`${url}/${playedCardGame._id}`}
                >
                  <CustomAntButton
                    type="text"
                    htmlType="button"
                    icon={<FiTrash2 size={20} />}
                    useCustomIcon
                    danger
                    onClick={e => {
                      e.preventDefault();
                      handleArchiveGame(playedCardGame._id);
                    }}
                  />
                  <strong>{playedCardGame.name}</strong>
                  <small>
                    {format(
                      parseISO(playedCardGame.createdAt),
                      intl.getTranslatedText('common.formatDates.carriedOut'),
                    )}
                  </small>
                </CardGameItem>
              ))}
              {playedCardGamesPagination.currentPage <
                playedCardGamesPagination.totalPages && (
                <LoadMoreCardsButton
                  onClick={() => {
                    if (loadingPlayedCardGames) return;
                    getPlayedCardGames(
                      playedCardGamesPagination.currentPage + 1,
                    );
                  }}
                  disabled={loadingPlayedCardGames}
                >
                  <h6>
                    {!loadingPlayedCardGames
                      ? intl.getTranslatedText('common.buttons.viewMore')
                      : intl.getTranslatedText(
                          'common.messages.defaultLoading',
                        )}
                  </h6>
                </LoadMoreCardsButton>
              )}
            </CardGameList>
          ) : (
            <div>
              <h6>
                {intl.getTranslatedTextWithHTML(
                  'pages.athletesOfTheWeek.cardGamesHistory.messages.noGames',
                )}
              </h6>
              <Link to="/athletes_of_the_week">
                <FiChevronLeft size={16} />
                {intl.getTranslatedText(
                  'pages.athletesOfTheWeek.cardGamesHistory.goToAthletesOfTheWeekLink',
                )}
              </Link>
            </div>
          )}
        </>
      );
    }
    return (
      <>
        <div>
          <small>
            {intl.getTranslatedText(
              'pages.athletesOfTheWeek.cardGamesHistory.messages.viewingArchivedGames',
            )}
          </small>
          <CustomAntButton
            onClick={() =>
              setShowArchivedGames(
                oldShowArchivedGames => !oldShowArchivedGames,
              )
            }
            type="text"
            danger
          >
            {intl.getTranslatedText(
              'pages.athletesOfTheWeek.cardGamesHistory.showActiveGamesButton',
            )}
          </CustomAntButton>
        </div>
        {archivedPlayedCardGames.length > 0 ? (
          <CardGameList>
            {archivedPlayedCardGames.map(archivedPlayedCard => (
              <CardGameItem
                key={archivedPlayedCard._id}
                to={`${url}/${archivedPlayedCard._id}`}
                $archived
              >
                <CustomAntButton
                  type="text"
                  htmlType="button"
                  icon={<FiCornerUpLeft size={20} />}
                  useCustomIcon
                  onClick={e => {
                    e.preventDefault();
                    handleUnarchiveGame(archivedPlayedCard._id);
                  }}
                />
                <strong>{archivedPlayedCard.name}</strong>
                <small>
                  {format(
                    parseISO(archivedPlayedCard.createdAt),
                    intl.getTranslatedText('common.formatDates.carriedOut'),
                  )}
                </small>
              </CardGameItem>
            ))}
            {archivedPlayedCardGamesPagination.currentPage <
              archivedPlayedCardGamesPagination.totalPages && (
              <LoadMoreCardsButton
                onClick={() => {
                  if (loadingPlayedCardGames) return;
                  getArchivedPlayedCardGames(
                    archivedPlayedCardGamesPagination.currentPage + 1,
                  );
                }}
                disabled={loadingPlayedCardGames}
              >
                <h6>
                  {!loadingPlayedCardGames
                    ? intl.getTranslatedText('common.buttons.viewMore')
                    : intl.getTranslatedText('common.messages.defaultLoading')}
                </h6>
              </LoadMoreCardsButton>
            )}
          </CardGameList>
        ) : (
          <div>
            <h6>
              {intl.getTranslatedTextWithHTML(
                'pages.athletesOfTheWeek.cardGamesHistory.messages.noArchivedGames',
              )}
            </h6>
          </div>
        )}
      </>
    );
  }, [
    archivedPlayedCardGames,
    archivedPlayedCardGamesPagination.currentPage,
    archivedPlayedCardGamesPagination.totalPages,
    getArchivedPlayedCardGames,
    getPlayedCardGames,
    handleArchiveGame,
    handleUnarchiveGame,
    intl,
    loadingPlayedCardGames,
    playedCardGames,
    playedCardGamesPagination.currentPage,
    playedCardGamesPagination.totalPages,
    showArchivedGames,
    url,
  ]);

  if (loadingPlayedCardGames) {
    if (
      (!showArchivedGames && playedCardGamesPagination.totalPages === 0) ||
      (showArchivedGames && archivedPlayedCardGamesPagination.totalPages === 0)
    ) {
      return (
        <Container>
          <Breadcrumbs />
          <Header>
            <h5>
              {intl.getTranslatedText(
                'pages.athletesOfTheWeek.cardGamesHistory.title',
              )}
            </h5>
          </Header>
          <Loading />
        </Container>
      );
    }
  }

  return (
    <Container>
      <Breadcrumbs />
      <Header>
        <h5>
          {intl.getTranslatedText(
            'pages.athletesOfTheWeek.cardGamesHistory.title',
          )}
        </h5>
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item
                onClick={() =>
                  setShowArchivedGames(
                    oldShowArchivedGames => !oldShowArchivedGames,
                  )
                }
                danger={!showArchivedGames}
              >
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  {!showArchivedGames ? (
                    <>
                      <FiTrash2 style={{ marginRight: 8 }} size={19} />
                      {intl.getTranslatedText(
                        'pages.athletesOfTheWeek.cardGamesHistory.showArchivedGamesButton',
                      )}
                    </>
                  ) : (
                    <>
                      <FiCornerUpLeft style={{ marginRight: 8 }} size={19} />
                      {intl.getTranslatedText(
                        'pages.athletesOfTheWeek.cardGamesHistory.showActiveGamesButton',
                      )}
                    </>
                  )}
                </div>
              </Menu.Item>
            </Menu>
          }
          placement="bottomRight"
          arrow
        >
          <CustomAntButton
            icon={<IoEllipsisVertical size={20} />}
            useCustomIcon
            type="text"
          />
        </Dropdown>
      </Header>
      {/* {playedCardGames.length > 0 ? (
        <CardGameList>
          {playedCardGames.map(playedCardGame => (
            <CardGameItem
              key={playedCardGame._id}
              to={`${url}/${playedCardGame._id}`}
            >
              <CustomAntButton
                type="text"
                htmlType="button"
                icon={<FiTrash2 size={20} />}
                useCustomIcon
                onClick={e => {
                  e.preventDefault();
                  handleArchiveGame(playedCardGame._id);
                }}
              />
              <strong>{playedCardGame.name}</strong>
              <small>
                {format(
                  parseISO(playedCardGame.createdAt),
                  "'Realizado em' dd/MM/yyyy 'às' HH:mm:ss",
                )}
              </small>
            </CardGameItem>
          ))}
        </CardGameList>
      ) : (
        <div>
          <h6>
            Você não possui nenhum <span>jogo</span> nesse campeonato
          </h6>
          <Link to={`/championships/${params.id}`}>
            <FiChevronLeft size={16} />
            Voltar para os detalhes do campeonato
          </Link>
        </div>
      )} */}
      {playedCardsVisualization}
    </Container>
  );
};

export default CardGamesHistory;
